import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cpf'
})
export class CpfPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if(value == null) return null;
    
   //retirando os caracteres especiais do texto
   let ch = /\^|~|\|\/|_|\?|,|\*|\.|\-/g;
   value = value.replace(ch, ""); 

    if (value.trim().length == 11) 
    {
      return value.substring(0, 3) + "." +
        value.substring(3, 6) + "." +
        value.substring(6, 9) + "-" +
        value.substring(9);
    }
    else if(value.trim().length == 14) 
    {
      return value.substring(0, 2) + "." +
        value.substring(2, 5) + "." +
        value.substring(5, 8) + "/" +
        value.substring(8, 12) + "-" +
        value.substring(12);
    }
    else return value;
  }
}
