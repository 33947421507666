import { DoacaoMedicamentoModule } from './core/doacao-medicamento/doacao-medicamento.module';
import { ModulosComponent } from './core/security/modulos/modulos.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './core/security/login/login.component';
import { NavegacaoComponent } from './core/navegacao/navegacao.component';
import { LoggedInGuard } from './core/security/loggedin.guard';
import { InicioComponent } from './core/inicio/inicio.component';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { DebugComponent } from './core/shared/debug/debug.component';

const routes: Routes =
  [
    { path: '', component: LoginComponent },
    { path: 'login/:to', component: LoginComponent },
    { path: 'login', component: LoginComponent },
    { path: 'modulos', component: ModulosComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
    {
      path: 'navegacao', component: NavegacaoComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard],
      children:
        [
          { path: 'distribuicao', loadChildren: () => import('./core/distribuicao/distribuicao.module').then(mod => mod.DistribuicaoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'dashbord', loadChildren: () => import('./core/dashbord/dashbord.module').then(mod => mod.DashbordModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'situacao-processo', loadChildren: () => import('./core/situacao-processo/situacao-processo.module').then(mod => mod.SituacaoProcessoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'dispensacao', loadChildren: () => import('./core/dispensacao/dispensacao.module').then(mod => mod.DispensacaoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'regional', loadChildren: () => import('./core/regional/regional.module').then(mod => mod.RegionalModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'tipo-medicamento', loadChildren: () => import('./core/tipo-medicamento/tipo-medicamento.module').then(mod => mod.TipoMedicamentoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'motivo-devolucao', loadChildren: () => import('./core/motivo-devolucao/motivo-devolucao.module').then(mod => mod.MotivoDevolucaoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'tipo-atendimento', loadChildren: () => import('./core/tipo-atendimento/tipo-atendimento.module').then(mod => mod.TipoAtendimentoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'unidade-saude', loadChildren: () => import('./core/unidade-saude/unidade-saude.module').then(mod => mod.UnidadeSaudeModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'usuario', loadChildren: () => import('./core/usuario/usuario.module').then(mod => mod.UsuarioModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'grupo', loadChildren: () => import('./core/grupo/grupo.module').then(mod => mod.GrupoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'profissional', loadChildren: () => import('./core/profissional/profissional.module').then(mod => mod.ProfissionalModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'regiao', loadChildren: () => import('./core/regiao/regiao.module').then(mod => mod.RegiaoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'recebimento', loadChildren: () => import('./core/recebimento/recebimento.module').then(mod => mod.RecebimentoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'prescricao', loadChildren: () => import('./core/prescricao/prescricao.module').then(mod => mod.PescricaoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'estoque', loadChildren: () => import('./core/estoque/estoque.module').then(mod => mod.EstoqueModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'paciente', loadChildren: () => import('./core/paciente/paciente.module').then(mod => mod.PacienteModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'material', loadChildren: () => import('./core/material/material.module').then(mod => mod.MaterialModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'nivel', loadChildren:() => import('./core/nivel/nivel.module').then(mod => mod.NivelModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'periodicidade', loadChildren:()=>import('./core/periodicidade/periodicidade.module').then(mod=> mod.PeriodicidadeModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'responsavel', loadChildren: ()=> import('./core/responsavel/responsavel.module').then(mod => mod.ResponsavelModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'tipo-especialidade', loadChildren:()=>import('./core/especialidade/especialidade.module').then(mod => mod.EspecialidadeModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'tipo-estabelecimento', loadChildren:()=>import('./core/tipo-estabelecimento/tipo-estabelecimento.module').then(mod => mod.TipoEstabelecimentoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'tipo-programa', loadChildren:() => import('./core/tipo-programa/tipo-programa.module').then(mod => mod.TipoProgramaModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'tipo-registro', loadChildren: ()=> import('./core/tipo-registro/tipo-registro.module').then(mod => mod.TipoRegistroModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'unidade-assistencia', loadChildren: () => import('./core/unidade-assistencia/unidade-assistencia.module').then(mod => mod.UnidadeAssistenciaModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'parametros', loadChildren:()=> import('./core/parametros/parametros.module').then(mod => mod.ParametrosModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'centro-custo', loadChildren:()=>import('./core/centro-custo/centro-custo.module').then(mod => mod.CentroCustoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'contas-bancarias', loadChildren: () => import('./judicial/contas-bancarias/contas-bancarias.module').then(mod => mod.ContasBancariasModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'mapa-planejamento', loadChildren: () => import('./core/mapa-planejamento/mapa-judicial.module').then(mod => mod.MapaJudicialModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'relatorio', loadChildren: () => import('./judicial/relatorio/relatorio.module').then(mod => mod.RelatorioModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'parametrizacao', loadChildren: () => import('./core/parametrizacao-medicamentos/Parametrizacao.module').then(mod => mod.ParametrizacaoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'tipo-mapa', loadChildren: () => import('./core/tipo-mapa/tipo-mapa.module').then(mod => mod.TipoMapaModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'motivo-sequestro', loadChildren: () => import('./judicial/motivo-sequestro/motivo-sequestro.module').then(mod => mod.MotivoSequestroModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'tipo-fornecimento', loadChildren: () => import('./judicial/tipo-fornecimento/tipo-fornecimento.module').then(mod => mod.TipoFornecimentoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'tipo-documento', loadChildren: () => import('./judicial/tipo-documento/tipo-documento.module').then(mod => mod.TipoDocumentoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'motivo-exclusao', loadChildren: () => import('./judicial/motivo-exclusao/motivo-exclusao.module').then(mod => mod.MotivoExclusaoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'processo', loadChildren: () => import('./judicial/processo/processo.module').then(mod => mod.ProcessoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'comsus', loadChildren: () => import('./judicial/comsus/comsus.module').then(mod => mod.ComsusModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'sequestro-valores', loadChildren: () => import('./judicial/sequestro-valores/sequestro-valores.module').then(mod => mod.SequestroValoresModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'relatorio-tuberculose', loadChildren: () => import('./tuberculose/relatorio/relatorio-tuberculose.module').then(mod => mod.RelatorioTuberculoseModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'devolucao-valores', loadChildren: () => import('./judicial/devolucao-valores/devolucao-valores.module').then(mod => mod.DevolucaoValoresModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'origem-medicamento', loadChildren:() => import('./judicial/origem-medicamento/origem-medicamento.module').then(mod => mod.OrigemMedicamentoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'origem-destino', loadChildren: ()=> import('./judicial/origem-destino/origem-destino.module').then(mod => mod.OrigemDestinoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'tipo-solicitacao', loadChildren: ()=> import('./judicial/tipo-solicitacao/tipo-solicitacao.module').then(mod => mod.TipoSolicitacaoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'atualizacao-prescricao', loadChildren:()=>import('./judicial/atualizacao-prescricao/atualizacao-prescricao.module').then(mod => mod.AtualizacaoPrescricaoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'comarca', loadChildren:()=>import('./judicial/comarca/comarca.module').then(mod => mod.ComarcaModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'tratamento', loadChildren:()=>import('./tuberculose/prescricao-tuberculose/prescricao.tuberculose.module').then(mod => mod.PrescricaoTuberculoseModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'referencia-material', loadChildren:()=>import('./core/referencia-material/referencia-material.module').then(mod => mod.ReferenciaMaterialModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'transferencia-entre-modulos', loadChildren:()=>import('./core/transferencia-entre-modulos/transferencia-entre-modulos.module').then(mod => mod.TransferenciaEntreModulosModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'doacao-medicamento', loadChildren:()=>import('./core/doacao-medicamento/doacao-medicamento.module').then(mod => mod.DoacaoMedicamentoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'programa', loadChildren: () => import('./core/programa/programa.module').then(mod => mod.ProgramaModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard]},
          { path: 'avisos', loadChildren: () => import('./core/avisos/avisos.module').then(mod => mod.AvisosModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard]},
          { path: 'alerta', loadChildren: () => import('./core/alerta/alerta.module').then(mod => mod.AlertaModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard]},
          { path: 'cid', loadChildren:()=>import('./core/cid/cid.module').then(mod=> mod.CidModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'categoria-situacao', loadChildren: () => import('./core/categoria-situacao/categoria-situacao.module').then(mod => mod.CategoriaSituacaoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard]},
          { path: 'fluxo-prescricao', loadChildren: () => import('./core/fluxo-prescricao/fluxo-prescricao.module').then(mod => mod.FluxoPrescricaoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard]},
          { path: 'estoque-minimo', loadChildren: () => import('./core/estoque-minimo/estoque-minimo.module').then(mod => mod.EstoqueMinimoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard]},
          { path: 'situacao-procedimento', loadChildren: () => import('./core/situacao-procedimento/situacao-procedimento.module').then(mod => mod.SituacaoProcedimentoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard]},
          { path: 'procedimento', loadChildren: () => import('./especializado/procedimento/procedimento.module').then(mod => mod.ProcedimentoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard]},
          { path: 'autorizador-grupo-medicamento', loadChildren: () => import('./core/autorizadores-grupo-medicamento/autorizador-grupo-medicamento.module').then(mod => mod.AutorizadorGrupoMedicamentoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard]},
          { path: 'inicio', component: InicioComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'inventario', loadChildren: () => import('./core/inventario/inventario.module').then(mod => mod.InventarioModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'nat', loadChildren: () => import('./core/nat/nat.module').then(mod => mod.NatModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'debug', component: DebugComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'configuracao', loadChildren: () => import('./core/configuracao/configuracao.module').then(mod => mod.ConfiguracaoModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
          { path: 'classificacao-substancia-anvisa', loadChildren: () => import('./core/classificacao-substancia-anvisa/classificacao-substancia-anvisa.module').then(mod => mod.ClassificacaoSubstanciaAnvisaModule), canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
        ]
    },
    { path: '**', component: NotFoundComponent }
  ];
@NgModule({
  imports:
    [
      RouterModule.forRoot(routes, {
        preloadingStrategy: PreloadAllModules,
        scrollPositionRestoration:'enabled'
      })
    ],
  exports:
    [
      RouterModule
    ],
  providers:
    [
      LoggedInGuard
    ]
})

export class AppRoutes { }
