import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, inject, OnInit, Inject } from '@angular/core';
import { HistoricoObservacaoItemPrescricao } from '@app/core/prescricao/historico-observacao-item-prescricao.model';

@Component({
  selector: 'app-modal-observacoes-prescricao',
  templateUrl: './modal-observacoes-prescricao.component.html',
  styleUrls: ['./modal-observacoes-prescricao.component.scss']
})
export class ModalObservacoesPrescricaoComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ModalObservacoesPrescricaoComponent>,
    @Inject(MAT_DIALOG_DATA) private observacoes: HistoricoObservacaoItemPrescricao[]
  ) { }

  ngOnInit() {
  }

  public close(): void {
    this.dialogRef.close();
  }

}
