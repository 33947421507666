import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SGS_URL, CORE } from '@app/app.api';

import { Cid } from '@app/core/cid/cid.model';
import { ListaJSON } from '@app/core/shared/default/lista-json.model';

import { LoginService } from '@app/core/security/login/login.service';

@Injectable({
	providedIn: 'root'
})
export class CidService {

	idReferencia: string = '';

	//objeto de pesquisa no back-end com (page-index, page-size, sort, direction,e filtros)
	private _filterCid: any;

	//objeto para ser salvo no banco de dados (criar/editar)
	private _cid: Cid;

	static alertaEmitter = new EventEmitter();

	constructor(private httpclient: HttpClient,
		private loginService: LoginService) {
		this.idReferencia = this.loginService.user.referencia.id;
	}

	//buca uma lista de Cids no banco de dados de acordo com o Digitado
	buscaCidsByDescricao(nome: string): Observable<Cid[]> {
		return this.httpclient.post<Cid[]>(`${SGS_URL.CORE}/${CORE.CID}/getAllCidsByDescricao`, { nome: nome });
	}

	//buca uma lista de Cids no banco de dados com o Filter
	searchCids():Observable<ListaJSON<Cid>> {
    return this.httpclient.post<ListaJSON<Cid>>(`${SGS_URL.CORE}/${CORE.CID}/p`, this._filterCid );
  }

	/**Método para salvar um objeto e recuperar o id para preencher a classe **/
	save(): Observable<any> {
		const id_usuario = this.loginService.user.id;
		const id_referencia = this.loginService.user.referencia.id;
		return this.httpclient.post<any>(`${SGS_URL.CORE}/${CORE.CID}/c/${id_referencia}/${id_usuario}`, this._cid)
	}

	//Gets e Sets
  public get filterCid(): any {
    return this._filterCid;
  }
  public set filterCid(value: any) {
    this._filterCid = value;
  }
  public get cid(): Cid {
    return this._cid;
  }
  public set cid(value: Cid) {
    this._cid = value;
  }

}
