import { MatIconModule, MatCardModule, MatInputModule, MatButtonModule, MatTooltipModule, MatCheckboxModule } from "@angular/material";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { ToastaModule } from "ngx-toasta";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { DialogModule } from 'primeng/dialog';
import { InputTextConfirmDialogComponent } from './input-text-confirm-dialog/input-text-confirm-dialog.component';
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { ModalGenericoDescricaoComponent } from './modal-generico-descricao/modal-generico-descricao.component';


@NgModule({
  declarations:
  [
    ConfirmDialogComponent,
    InputTextConfirmDialogComponent,
    ModalGenericoDescricaoComponent
  ],
  imports:
  [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    MatCheckboxModule,
    DialogModule,
    ToastaModule
  ],
  exports:
  [
    ConfirmDialogComponent,
    InputTextConfirmDialogComponent,
    ModalGenericoDescricaoComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class DialogPersonalModule { }
