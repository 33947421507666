import { Referencia } from "@app/core/centro-custo/Referencia.model";
import { TipoParecer } from "./tipo-parecer";

export class DecisaoSuperiorAnaliseTecnicaResponse {
  public id: number;
  public descricao: string;
  public status: boolean;
  public referencia_id: number;
  public referencia : Referencia;
  public natTipoParecer: TipoParecer;
}
