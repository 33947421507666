import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SGS_URL, CORE } from '@app/app.api';
import { JustificativaAnaliseTecnicaRequest } from '@app/core/shared/models/justificativa-analise-tecnica-request';
import { PosicaoAnaliseTecnicaRequest } from '@app/core/shared/models/posicao-analise-tecnica-request';
import { PosicaoAnaliseTecnicaResponse } from '@app/core/shared/models/posicao-analise-tecnica-response';
import { ResponseComLength } from '@app/core/shared/models/response-com-length.model';
import { Observable } from 'rxjs';
import { PosicaoAnaliseTecnicaComponent } from './posicao-analise-tecnica.component';

@Injectable({
  providedIn: 'root'
})
export class PosicaoAnaliseTecnicaService {

  constructor(private http: HttpClient) { }

  public getPosicaoAnaliseByFilter(params: any): Observable<ResponseComLength> {
    return this.http.post<ResponseComLength>(
      `${SGS_URL.JUDICIAL}/${CORE.NAT_POSICAO_ANALISE_TECNICA}/pesquisar`,
      { params }
    );
  }

  public updatePosicaoAnalise(params: PosicaoAnaliseTecnicaRequest): Observable<any>{
    return this.http.post<any>(
      `${SGS_URL.JUDICIAL}/${CORE.NAT_POSICAO_ANALISE_TECNICA}/update`,
      { params }
    );
  }

  public postPosicaoAnalise(params: PosicaoAnaliseTecnicaRequest): Observable<any>{
    return this.http.post<any>(
      `${SGS_URL.JUDICIAL}/${CORE.NAT_POSICAO_ANALISE_TECNICA}/save`,
      { params }
    );
  }
}
