import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { EspecialidadePesquisa } from './especialidade-pesquisa/especialidade-pesquisa.model';
import { Especialidade } from './especialidade.model';
import { LoginService } from '@app/core/security/login/login.service';
import { ListaJSON } from '@app/core/shared/default/lista-json.model';
import { SGS_URL } from '@app/app.api';

@Injectable()
export class EspecialidadeService {

    especialidade: Especialidade

    constructor(private http: HttpClient,
      private loginService: LoginService){}

    cadastrar(especialidade: Especialidade) : Observable<any> {
      let id_referencia = this.loginService.user.referencia.id;
      let id_usuario = this.loginService.user.id;
      return this.http.post<any>(`${SGS_URL.CORE}/especialidade/c/${id_usuario}/${id_referencia}`, especialidade)
    }

    listar(especialidadePesquisa: EspecialidadePesquisa): Observable<ListaJSON<Especialidade>> {
      return this.http.post<ListaJSON<Especialidade>>(`${SGS_URL.CORE}/especialidade/p`, especialidadePesquisa)
    }

    buscaEspecialidade(id_especialidade: string): Observable<Especialidade> {
      return this.http.post<Especialidade>(`${SGS_URL.CORE}/especialidade/id/${id_especialidade}`, {})
    }

    getEspecialidade() {
      if (this.especialidade === undefined) {
        this.especialidade = new Especialidade("", "", "")
      }
      return this.especialidade
    }

    setEspecialidade(especialidade: Especialidade) {
      this.especialidade = especialidade
    }
}
