import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SGS_URL, CORE } from '@app/app.api';

import { FluxoPrescricao } from '@app/core/fluxo-prescricao/fluxo-prescricao.model';
import { ListaJSON } from '@app/core/shared/default/lista-json.model';

import { LoginService } from '@app/core/security/login/login.service';

@Injectable({
	providedIn: 'root'
})
export class FluxoPrescricaoService {

	//objeto de pesquisa no back-end com (page-index, page-size, sort, direction,e filtros)
	private _filterFluxoPrescricao: any;

	//objeto para ser salvo no banco de dados (criar/editar)
	private _fluxoPrescricao: FluxoPrescricao;

	static alertaEmitter = new EventEmitter();

	constructor(private httpclient: HttpClient,
		private loginService: LoginService) {
	}

	//buca uma lista de Fluxos Prescrição no banco de dados com o Filter
	searchFluxosPrescricao():Observable<ListaJSON<FluxoPrescricao>> {
		this.filterFluxoPrescricao.ref = this.loginService.user.referencia.id;
    return this.httpclient.post<ListaJSON<FluxoPrescricao>>(`${SGS_URL.CORE}/${CORE.FLUXO_PRESCRICAO}/p`, this._filterFluxoPrescricao );
  }

	//buca Fluxo Prescrição no banco de dados pelo ID
	buscaFluxoPrescricaoById(id_fluxo_prescricao: string):Observable<FluxoPrescricao> {
	  return this.httpclient.post<FluxoPrescricao>(`${SGS_URL.CORE}/${CORE.FLUXO_PRESCRICAO}/id/${id_fluxo_prescricao}`, {})
  }

	/**Método para salvar um objeto e recuperar o id para preencher a classe **/
	save(): Observable<any> {
		const id_usuario = this.loginService.user.id;
		const id_referencia = this.loginService.user.referencia.id;
		return this.httpclient.post<any>(`${SGS_URL.CORE}/${CORE.FLUXO_PRESCRICAO}/c/${id_referencia}/${id_usuario}`, this._fluxoPrescricao)
	}

	updateStatus(idFluxo: string, status: string): Observable<any> {
		let id_usuario = this.loginService.user.id
		let id_referencia = this.loginService.user.referencia.id;
		return this.httpclient.post<any>(`${SGS_URL.CORE}/${CORE.FLUXO_PRESCRICAO}/s/${idFluxo}/${id_usuario}/${status}/${id_referencia}`, {})
	}

	public novoFluxoPrescricao(){
		this._fluxoPrescricao = new FluxoPrescricao("", null, null, this.loginService.user.referencia, false);
	}

	//Gets e Sets
  public get filterFluxoPrescricao(): any {
    return this._filterFluxoPrescricao;
  }
  public set filterFluxoPrescricao(value: any) {
    this._filterFluxoPrescricao = value;
  }
  public get fluxoPrescricao(): FluxoPrescricao {
    return this._fluxoPrescricao;
  }
  public set fluxoPrescricao(value: FluxoPrescricao) {
    this._fluxoPrescricao = value;
  }

}
