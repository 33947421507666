import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SGS_URL, CORE } from '@app/app.api';
import { DecisaoSuperiorAnaliseTecnicaFilter } from '@app/core/shared/models/decisao-superior-analise-tecnica-filter';
import { DecisaoSuperiorAnaliseTecnicaRequest } from '@app/core/shared/models/decisao-superior-analise-tecnica-request';


import { ResponseComLength } from '@app/core/shared/models/response-com-length.model';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DecisaoSuperiorAnaliseTecnicaService {

  constructor(private http: HttpClient) { }

  public getDecisaoSuperiorAnaliseByFilter(params: DecisaoSuperiorAnaliseTecnicaFilter): Observable<ResponseComLength> {
    return this.http.post<ResponseComLength>(
      `${SGS_URL.JUDICIAL}/${CORE.NAT_DECISAO_SUPERIOR_ANALISE_TECNICA}/listar-decisao-superior-analise-tecnica`,
      { params }
    );
  }

  public updateDecisaoSuperiorAnalise(params: DecisaoSuperiorAnaliseTecnicaRequest): Observable<any>{
    return this.http.post<any>(
      `${SGS_URL.JUDICIAL}/${CORE.NAT_DECISAO_SUPERIOR_ANALISE_TECNICA}/update-decisao-superior-analise-tecnica`,
      { params }
    );
  }

  public postDecisaoSuperiorAnalise(params: DecisaoSuperiorAnaliseTecnicaRequest): Observable<any>{
    return this.http.post<any>(
      `${SGS_URL.JUDICIAL}/${CORE.NAT_DECISAO_SUPERIOR_ANALISE_TECNICA}/save`,
      { params }
    );
  }

  public cadastrartDecisaoSuperiorAnalise(params: DecisaoSuperiorAnaliseTecnicaRequest): Observable<any>{
    return this.http.post<any>(
      `${SGS_URL.JUDICIAL}/${CORE.NAT_DECISAO_SUPERIOR_ANALISE_TECNICA}/cadastrar-decisao-superior-analise-tecnica`,
      { params }
    );
  }

  
}
