import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SGS_URL, CORE } from '@app/app.api';
import { Observable } from 'rxjs';
import { TipoPrograma } from './tipo-programa.model';

@Injectable({
  providedIn: 'root'
})
export class TipoProgramaService {

  public lsIdsSelecionadosMultiSelect: any[] = [];
  public haItensSelecionadosMultiSelect = false;

  constructor(private http: HttpClient) { }

  public buscaTipoProgramaByNome(nome: string): Observable<TipoPrograma[]> {
    return this.http.post<TipoPrograma[]>(`${SGS_URL.CORE}/${CORE.TIPO_PROGRAMA}/buscaTipoPrograma`, { filter: nome });
  }

  public getNewLsIdsSelecionados() {
    this.lsIdsSelecionadosMultiSelect = [];
  }

}
