import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { LoginService } from "../security/login/login.service";
import { ListaJSON } from '../shared/default/lista-json.model';
import { MotivoDevolucao } from './motivo-devolucao.model';
import { MotivoDevolucaoPesquisa } from './motivo-devolucao-pesquisa/tipo-devolucao-pesquisa.model';
import { SGS_URL } from '@app/app.api';

@Injectable()
export class MotivoDevolucaoService {

  motivoDevolucao: MotivoDevolucao

  constructor(private http: HttpClient, private loginService: LoginService ) {}

  cadastrar(motivoDevolucao: MotivoDevolucao): Observable<any> {
    let id_referencia = this.loginService.user.referencia.id;
    let id_usuario = this.loginService.user.id;
    return this.http.post<any>(`${SGS_URL.CORE}/motivoDevolucao/c/${id_usuario}/${id_referencia}`, motivoDevolucao)
  }

  listar(motivoDevolucaoPesquisa: MotivoDevolucaoPesquisa): Observable<ListaJSON<MotivoDevolucao>> {
    return this.http.post<ListaJSON<MotivoDevolucao>>(`${SGS_URL.CORE}/motivoDevolucao/p`, motivoDevolucaoPesquisa)
  }

  buscaMotivoDevolucao(id_motivo_devolucao: string): Observable<MotivoDevolucao> {
    return this.http.post<MotivoDevolucao>(`${SGS_URL.CORE}/motivoDevolucao/id/${id_motivo_devolucao}`, {})
  }

  all(): Observable<MotivoDevolucao[]> {
    return this.http.get<MotivoDevolucao[]>(`${SGS_URL.CORE}/motivoDevolucao/all`);
  }

  getMotivoDevolucao() {
    if (this.motivoDevolucao === undefined) {
      this.motivoDevolucao = new MotivoDevolucao("", "", "")
    }
    return this.motivoDevolucao
  }

  setMotivoDevolucao(motivoDevolucao: MotivoDevolucao) {
    this.motivoDevolucao = motivoDevolucao
  }

  listarMotivoDevolucaoMaterial(nome: string): Observable<MotivoDevolucao[]> {
    return this.http.post<MotivoDevolucao[]>(`${SGS_URL.CORE}/motivoDevolucao/listarMaterial/${nome}/`, {})
  }
}
