import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatAutocompleteModule,
  MatIconModule,
  MatStepperModule,
  MatTableModule,
} from "@angular/material";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { NgxLoadingModule } from "ngx-loading";
import { NgxMaskModule } from "ngx-mask";
import { ToastaModule } from "ngx-toasta";
import { ButtonsModule } from "../../buttons/buttons.module";
import { DialogPersonalModule } from "../../dialog-personal/dialog.personal.module";
import { MaterialImportBasicModule } from "../../material-import-basic.module";
import { PipeModule } from "../../pipe/pipe.module";
import { ComponentsModule } from "../components.module";
import { RegionalAutocompleteComponent } from "./regional-autocomplete/regional-autocomplete.component";
import { UnidadeAutocompleteComponent } from "./unidade-autocomplete/unidade-autocomplete.component";
import { MaterialAutocompleteComponent } from "./material-autocomplete/material-autocomplete.component";
import { TipoAnaliseTecnicaAutocompleteComponent } from "./tipo-analise-tecnica-autocomplete/tipo-analise-tecnica-autocomplete.component";
import { PosicaoAnaliseTecnicaAutocompleteComponent } from "./posicao-analise-tecnica-autocomplete/posicao-analise-tecnica-autocomplete.component";
import { JustificativaAnaliseTecnicaAutocompleteComponent } from './justificativa-analise-tecnica-autocomplete/justificativa-analise-tecnica-autocomplete.component';
import { QuestaoAnaliseTecnicaComponent } from './questao-analise-tecnica/questao-analise-tecnica.component';
import { PacienteAutocompleteComponent } from './paciente-autocomplete/paciente-autocomplete.component';
import { SituacaoProcessoAutocompleteComponent } from './situacao-processo-autocomplete/situacao-processo-autocomplete.component';
import { DecisaoSuperiorAutocompleteComponent } from './decisao-superior-autocomplete/decisao-superior-autocomplete.component';
import { PessoaFisicaAutocompleteComponent } from './pessoa-fisica-autocomplete/pessoa-fisica-autocomplete.component';
import { PeriodicidadeAutocompleteComponent } from './periodicidade-autocomplete/periodicidade-autocomplete.component';
import { ClassificacaoSubstanciaAnvisaAutocompleteComponent } from "./classificacao-substancia-anvisa-autocomplete/classificacao-substancia-anvisa-autocomplete.component";

@NgModule({
  declarations: [
    RegionalAutocompleteComponent,
    UnidadeAutocompleteComponent,
    MaterialAutocompleteComponent,
    TipoAnaliseTecnicaAutocompleteComponent,
    PosicaoAnaliseTecnicaAutocompleteComponent,
    JustificativaAnaliseTecnicaAutocompleteComponent,
    QuestaoAnaliseTecnicaComponent,
    PacienteAutocompleteComponent,
    SituacaoProcessoAutocompleteComponent,
    DecisaoSuperiorAutocompleteComponent,
    PessoaFisicaAutocompleteComponent,
    PeriodicidadeAutocompleteComponent,
    ClassificacaoSubstanciaAnvisaAutocompleteComponent
  ],
  exports: [
    RegionalAutocompleteComponent,
    UnidadeAutocompleteComponent,
    MaterialAutocompleteComponent,
    TipoAnaliseTecnicaAutocompleteComponent,
    PosicaoAnaliseTecnicaAutocompleteComponent,
    JustificativaAnaliseTecnicaAutocompleteComponent,
    QuestaoAnaliseTecnicaComponent,
    PacienteAutocompleteComponent,
    SituacaoProcessoAutocompleteComponent,
    DecisaoSuperiorAutocompleteComponent,
    PessoaFisicaAutocompleteComponent,
    PeriodicidadeAutocompleteComponent,
    ClassificacaoSubstanciaAnvisaAutocompleteComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialImportBasicModule,
    MatStepperModule,
    CurrencyMaskModule,
    NgxMaskModule,
    ButtonsModule,
    PipeModule,
    NgxLoadingModule,
    ToastaModule,
    DialogPersonalModule,
    MatMomentDateModule,
    ComponentsModule,
    MatTableModule,
    MatAutocompleteModule,
    MatIconModule,
  ],
})
export class AutocompleteSesModule {}
