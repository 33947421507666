import { Nivel } from "../grupo/grupo-nivel.model";

export class UsuarioNivel {
  constructor(public id: string,
              public id_usuario: string,
              public nome_usuario: string,
              public referencia: string,
              public usuario: string,
              public status: boolean,
              public niveis: Nivel[]) {}
}
