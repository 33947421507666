import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PacienteService } from "@app/core/paciente/paciente.service";
import { ResponsavelService } from "@app/core/responsavel/responsavel.service";

@Component({
  selector: "ses-pessoa-fisica-autocomplete",
  templateUrl: "./pessoa-fisica-autocomplete.component.html",
  styleUrls: ["./pessoa-fisica-autocomplete.component.scss"],
})
export class PessoaFisicaAutocompleteComponent implements OnInit {
  @Input() required: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();
  @Input() id: string = "material";
  @Input() pessoaFisicaidSelecionada: string;
  @Input() pessoaFisicaNomeSelecionada: string;

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */
  @Output() idSelecionada = new EventEmitter<string>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any>();

  constructor(private pacienteService: PacienteService, private responsavelService: ResponsavelService) {}

  public elementoSelecionado: any;
  public lsElemento: any[] = [];
  private lastNameSearch: string = "";

  ngOnInit() {
    if(this.pessoaFisicaNomeSelecionada){
      this.pesquisa(this.pessoaFisicaNomeSelecionada);
    }else{
      this.pesquisa("a");
    }

    this.limparEvent.subscribe({
      next: (resp) => {
        this.limpar();
      },
    });
  }

  selecionarMaterial() {
    this.idSelecionada.emit(this.elementoSelecionado.id);
    this.elementosCompletoSelecionadas.emit(this.elementoSelecionado);
  }

  public filter(evento: any) {
    let name = evento.target.value;
    if (name.length >= 3 && name !== this.lastNameSearch) {
      this.pesquisa(name);
    } else {
      this.lsElemento = [];
    }
  }
  public pesquisa(name: string) {
    this.responsavelService
      .listResonsaveisComponente(name)
      .subscribe({
        next: (resp) => {
          if (resp) {
            this.lastNameSearch = name;
            this.lsElemento = resp;

            if(this.pessoaFisicaidSelecionada){
              this.elementoSelecionado = this.lsElemento.find(filter => filter.id == this.pessoaFisicaidSelecionada)
            }
          }
        }
      });
  }

  public limpar() {
    this.elementoSelecionado = null;
    this.idSelecionada.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  public displayFn(material: any) {
    return material ? material.nome : "";
  }
}
