import { Processo } from '../processo/processo.model';
import { OrigemDestino } from '../origem-destino/origem-destino.model';
import { TipoProtocolo } from './tipo-protocolo.model';
import { TipoDocumento } from '../tipo-documento/tipo-documento.model';
import { Municipio } from '@app/core/regional/municipio.model';
import { TipoSolicitacao } from '../tipo-solicitacao/tipo-solicitacao.model';
import { ProfissionalEntity } from '@app/core/profissional/profissional-entity.model';

export class Protocolo {
    constructor(
    public id: string,
    public processo: Processo,
    public data_recebimento: Date,
    public numero_documento: string,
    public tipo_protocolo: TipoProtocolo,
    public objeto: string,
    public tipo_documento: TipoDocumento,
    public ci_saida: string,
    public sgp_e: string,
    public data_correspondencia: Date,
    public data_cadastro: Date,
    public data_decisao: Date,
    public sem_limite: boolean,
    public origem_destino: OrigemDestino,
    public uf_origem_destino: string,
    public municipio_origem_destino: Municipio,
    public tipo_solicitacao: TipoSolicitacao,
    public sequestro_valores: string,
    public juiz: ProfissionalEntity,
    public advogado_autor: ProfissionalEntity,
    public observacao: string,
    public limite_cumprimento: Date,
    public dilacao_prazo: boolean,
    public dilacao_date:Date,  

    ) {}


  }
