import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-generico-descricao',
  templateUrl: './modal-generico-descricao.component.html',
  styleUrls: ['./modal-generico-descricao.component.scss']
})
export class ModalGenericoDescricaoComponent implements  OnInit {
  @Input() title: string = "Título";        //título da caixa de confirmação
  @Input() btnOkText: string = 'Salvar';       //nome do botão OK
  @Input() btnCancelText: string = 'Cancelar';   //nome do botão Cancel
  @Input() display:boolean = false;         //Vizibilidade do Painel [true = visible][false = hidden]
  @Output("confirm") confirmEvent = new EventEmitter<[string, boolean]>();  //Adicionar evento no botão OK
  @Output("cancel") cancelEvent   = new EventEmitter<boolean>();  //Adicionar evento no botão OK

  @Input() exibirbtnOK:boolean = true; //Boolean para exibir ou não o botão OK
  @Input() exibirbtnNot:boolean = true; //Boolean para exibir ou não o botão Não

  @Input() descricao: string = "Padrão";  //Valor digitado no input. Pode vir vazio ou preenchido

  constructor() { }

  ngOnInit() { }

  accept() {
    this.confirmEvent.emit([this.descricao, true]);
    this.descricao = ""
  }

  decline() {
    this.confirmEvent.emit([null, false]);
    this.descricao = ""
  }

  onClose() {
    this.cancelEvent.emit(false);
    this.descricao = ""
  }

}
