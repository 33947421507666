import { Paginador } from "./paginador.model";

export class ParametrizacaoQuestoesAnaliseTecnicaFilter extends Paginador{
  paginar: boolean = true;
  direction: string;
  pageIndex:number;
  sort: string;
  pageSize:number;
  referencia:string;
  descricao: string;
  recomendacoes: string;
  status: boolean;
}
