import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator } from '@angular/material';

import { AlertaDTO } from '../alerta-dto.model';
import { AlertaService } from '../alerta.service';
import { MessageService } from '@app/core/shared/message.service';
import { NavegacaoService } from '@app/core/navegacao/navegacao.service';

@Component({
	selector: 'app-alerta-cadastro',
	templateUrl: './alerta-cadastro.component.html',
	styleUrls: ['./alerta-cadastro.component.scss']
})
export class AlertaCadastroComponent implements OnInit {


	@ViewChild("paginatorAlerta") paginator: MatPaginator;

	dataSource = new MatTableDataSource<AlertaDTO>();
	displayedColumns: string[] = ['titulo', 'ativo'];

	//Filtros da tela
	ativoFiltro: Boolean;

	constructor(private alertaService: AlertaService, private messageService: MessageService, private navegacaoService: NavegacaoService) { }

	ngOnInit() {
		this.paginator.pageIndex = 0;
		this.paginator.pageSize = 5;
		this.carregaListaAlerta();
	}

	private carregaListaAlerta() {
		this.alertaService.findAlertaByCriterio(this.ativoFiltro).subscribe(result => {
			this.dataSource.data = result;
			this.paginator.length = result.length;
		});
	}

	onChangeAtivo(alertaDTO: AlertaDTO) {
		alertaDTO.ativo = !alertaDTO.ativo;
		this.alertaService.updateAlertaAtivo(alertaDTO.id, alertaDTO.ativo).subscribe(result => {
			if (result > 0) {
				this.messageService.addToast("Operação realizada com sucesso!", "", "success");
			} else {
				this.messageService.addToast("Ocorreu um erro!", "", "error");
			}
		}, err => {
			this.messageService.addToast("Ocorreu um erro!", "", "error");
		});
	}

}
