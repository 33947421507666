import { ReferenciaPrograma } from "../estoque/ReferenciaPrograma";
import { Material } from "../material/material.model";

export class ItemEntrada
{
    constructor
    (
        public id:string,
        public ano_registro:number,
        public seq_registro:number,
        public seq_guia:number,
        public situacao:number,
        public seq_item:number,
        public codigo_referencia: number,
        public codigo_fabricante:string,
        public quantidade_aceita:number,
        public mensagem_erro:string,
        public quantidade:number,
        public saldo:number,
        public saldo_bloqueado:number,
        public valor_unitario:string,
        public valor_total:string,
        public valor_residual:string,
        public data_fabricacao:Date,
        public data_validade:Date,
        public lote:string,
        public fechado:boolean,
        public material:Material,
        public qtd_transferir:number, //utilizado apenas no front-end para fins de cálculos
        public saldoModulo:number,
        public mes_registro: number,
        public referencia_programa: ReferenciaPrograma
    ){}
}
