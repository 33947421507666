import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from '@app/core/security/login/login.service';
import { Observable } from 'rxjs';
import { SGS_URL, TUBERCULOSE } from '@app/app.api';
import { RelatorioPrescricaoTuberculose } from './relatorio-prescricao-tuberculose/relatorio-prescricao-tuberculose.model';

@Injectable({
  providedIn: 'root'
})
export class RelatorioTuberculoseService {

  constructor(
    private httpclient: HttpClient,
    private loginService: LoginService
  ) { }

  public verificaDadosRelatorio(dto: RelatorioPrescricaoTuberculose): Observable<any> {
    return this.httpclient.post<any>(`${SGS_URL.TUBERCULOSE}/${TUBERCULOSE.DOWNLOAD_RELATORIO}/verificaDadosRelatorio`, dto);
  }

}
