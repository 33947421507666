export class Avisos {
	constructor
		(
			public id: string,
			public dt_inicio: Date,
            public dt_expiracao: Date,
            public nivel_aviso: string,
            public titulo: string,
            public observacao: string,
			public ativo: boolean,
			public nivel: string,
			public referencia_id: string
	) { }
}
