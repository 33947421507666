import { Injectable } from "@angular/core"
import { ToastaService, ToastaConfig, ToastOptions } from 'ngx-toasta';
import { ToastModal } from "./mensagem-aviso-modal/toastModal.model";

@Injectable()
export class MessageService {

  public toastModal: ToastModal = new ToastModal(null, null, false, null, null, null, null);

  constructor(private toastaService: ToastaService, private toastaConfig: ToastaConfig) {
    this.toastaConfig.theme = 'default'
  }

  addToast(response: any, titulo: string, type: string, tempo?: number) {
    let msg = "";
    let timeDefault = 7000;

    if (tempo != null || tempo != undefined) {
      timeDefault = tempo;
    }

    try {
      if (typeof response === "string") {
        msg = response
      } else {
        if (!response && !response.error && !response.error.descricao) {
          msg = this.getMessageFromErro(response)
        } else {
          msg = response.error.descricao;
          if (response.error.exception) console.error(response.error.exception)
          if (response.error.stack) console.error(response.error.stack)
        }
      }
      if (response.status) {
        switch (response.status) {
          case '200': type = 'success'; break;
          default: type = 'error'; break;
        }
      }
    } catch (e) {
      console.error(e)
    }
    if (!msg && !titulo) {
      msg = "Sistema Off! Se persistir, contate o Administrador"
    }

    let toastOptions: ToastOptions = {
      title: titulo,
      msg: msg,
      showClose: true,
      timeout: timeDefault,
      theme: 'default'
    };

    switch (type) {
      case 'default': this.toastaService.default(toastOptions); break;
      case 'info': this.toastaService.info(toastOptions); break;
      case 'success': this.toastaService.success(toastOptions); break;
      case 'wait': this.toastaService.wait(toastOptions); break;
      case 'error': this.toastaService.error(toastOptions); break;
      case 'warning': this.toastaService.warning(toastOptions); break;
    }
  }

  getMessageFromErro(response: any) {
    let msg = "Erro não tratado"
    try {
      if (!response) {
        msg = "Sem resposta"
      } else if (response.statusText && response.statusText === "Unknown Error") {
        msg = "Erro desconhecido"
      }
      if (msg && response && response.status) {
        msg += "(" + response.status + ")"
      }
    } catch (e) {
      // Ignored
    }
    return msg;
  }

  public addToastModal(titulo: string, mensagem: string, tempo: number, tipoAviso: string): void {
    
    this.getColorAndIcon(tipoAviso);
    this.toastModal.titulo = titulo;
    this.toastModal.mensagem = mensagem;
    this.toastModal.tipoAviso = tipoAviso;
    this.toastModal.show = true;
    this.toastModal.timer = tempo != null ? tempo : 7; // Seguindo o padrão de 7 segundos dos toasts do SGS
    setTimeout(() => {
      this.toastModal.show = false
    }, tempo * 1000);
  }

  public getColorAndIcon(tipoAviso: string): void {
    if (tipoAviso == "warning") {
      this.toastModal.corFundo = "#F97A1F";
      this.toastModal.icone = "warning"
    } else if (tipoAviso == "success") {
      this.toastModal.corFundo = "#4CAF50";
      this.toastModal.icone = "check_circle_outline";
    } else if (tipoAviso == "error") {
      this.toastModal.corFundo = "#F1432A";
      this.toastModal.icone = "warning";
    } else {
      this.toastModal.corFundo = "pink";
      this.toastModal.icone = "refresh";
    }
  }

  public clearAll(){
    this.toastaService.clearAll()
  }
}
