export class Logradouro {


    public id: string = "";
    public logradouro: string = "";
    public bairro: string = "";
    public municipio: string = "";
    public uf: string = "";
    public cep: string = "";
    public tipo: string = "";

    constructor(
        id: string,
        logradouro: string,
        bairro: string,
        municipio: string,
        uf: string,
        cep: string,
        tipo: string) {
            this.id = id;
            this.logradouro = logradouro;
            this.bairro = bairro;
            this.municipio = municipio;
            this.uf = uf;
            this.cep = cep;
            this.tipo = tipo;
         }
}
