import { Prescricao } from './../../core/prescricao/prescricao.model';
import { PrescricaoTuberculoseIndicacao } from "./prescricao-tuberculose-indicacao.model";

export class PrescricaoTuberculose {
    constructor(
        public id: string,
        public prescricao: Prescricao,
        public num_prova_tuberculina: Number,
        public cod_sinan: string,
        public bcg: Boolean,
        public num_caso_indice: Number,
        public num_bac_escarro: Number, // 0-campo não preechido, 1-positiva, 2-negativa, 3-não realizada,
        public prova_tuberculina: Boolean,
        public tratamentoTuberculoseIndicacao: PrescricaoTuberculoseIndicacao,
        public observacao: string
    ) { }
}