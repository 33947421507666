import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { SGS_URL } from "../../app.api";
import { LoginService } from "../security/login/login.service";
import { ListaJSON } from '../shared/default/lista-json.model';
import { TipoAtendimentoPesquisa } from './tipo-atendimento-pesquisa/tipo-atendimento-pesquisa.model';
import { TipoAtendimento } from './tipo-atendimento.model';

@Injectable()
export class TipoAtendimentoService {

  tipoAtendimento: TipoAtendimento
  public lsIdsSelecionadosMultiSelect: any[] = [];
  public haItensSelecionadosMultiSelect = false;

  constructor(private http: HttpClient, private loginService: LoginService ) {}

  cadastrar(tipoAtendimento: TipoAtendimento): Observable<any> {
    let id_referencia = this.loginService.user.referencia.id;
    let id_usuario = this.loginService.user.id;
    return this.http.post<any>(`${SGS_URL.CORE}/tipoAtendimento/c/${id_usuario}/${id_referencia}`, tipoAtendimento)
  }

  listar(tipoAtendimentoPesquisa: TipoAtendimentoPesquisa): Observable<ListaJSON<TipoAtendimento>> {
    return this.http.post<ListaJSON<TipoAtendimento>>(`${SGS_URL.CORE}/tipoAtendimento/p`, tipoAtendimentoPesquisa)
  }

  buscaTipoAtendimento(id_tipo_atendimento: string): Observable<TipoAtendimento> {
    return this.http.post<TipoAtendimento>(`${SGS_URL.CORE}/tipoAtendimento/id/${id_tipo_atendimento}`, {})
  }

  getTipoAtendimento() {
    if (this.tipoAtendimento === undefined) {
      this.tipoAtendimento = new TipoAtendimento("", "", "")
    }
    return this.tipoAtendimento
  }

  setTipoAtendimento(tipoAtendimento: TipoAtendimento) {
    this.tipoAtendimento = tipoAtendimento
  }

  listarTipoAtendimentoMaterial(nome: string): Observable<TipoAtendimento[]> {
    return this.http.post<TipoAtendimento[]>(`${SGS_URL.CORE}/tipoAtendimento/listarMaterial/${nome}/`, {})
  }

  listarTodosTipoAtendimentoMaterial(): Observable<TipoAtendimento[]> {
    return this.http.post<TipoAtendimento[]>(`${SGS_URL.CORE}/tipoAtendimento/listarTodosMaterial/`, {})
  }
  public getNewLsIdsSelecionados() {
    this.lsIdsSelecionadosMultiSelect = [];
  }
}
