import { LoginService } from '@app/core/security/login/login.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { MotivoSequestroPesquisa } from "./motivo-sequestro-pesquisa/motivo-sequestro-pesquisa.model";
import { SGS_URL} from "../../app.api";
import { MotivoSequestro } from "./motivo-sequestro.model";
import { ListaJSON } from '@app/core/shared/default/lista-json.model';

@Injectable()
export class MotivoSequestroService {
  motivoSequestro: MotivoSequestro

  constructor(private http: HttpClient, private loginService: LoginService, ) { }

  cadastrar( motivoSequestro: MotivoSequestro): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id
    return this.http.post<any>(`${SGS_URL.JUDICIAL}/motivo_sequestro/c/${id_referencia}/${id_usuario}`, motivoSequestro)
  }

  inativar( motivoSequestro: MotivoSequestro): Observable<any> 
  {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id
    return this.http.get<any>(`${SGS_URL.JUDICIAL}/motivo_sequestro/s/${motivoSequestro.id}/${id_usuario}/${motivoSequestro.status == 'Ativo'}/${id_referencia}`);
  }


  listar(motivoSequestroPesquisa: MotivoSequestroPesquisa): Observable<ListaJSON<MotivoSequestro>> {
    return this.http.post<ListaJSON<MotivoSequestro>>(`${SGS_URL.JUDICIAL}/motivo_sequestro/p`, motivoSequestroPesquisa)
  }

  getMotivoSequestro() {
    if (this.motivoSequestro === undefined) {
      this.resetMotivoSequestro();
    }
    return this.motivoSequestro
  }

  buscaMotivoSequestro(id_MotivoSequestro: string): Observable<MotivoSequestro> {
    return this.http.post<MotivoSequestro>(`${SGS_URL.JUDICIAL}/motivo_sequestro/id/${id_MotivoSequestro}`, {})
  }

  setMotivoSequestro(motivoSequestro: MotivoSequestro) {
    this.motivoSequestro = motivoSequestro
  }

  novoMotivoSequestro(): MotivoSequestro {
    return (this.motivoSequestro = new MotivoSequestro("", "", "Ativo"));
  }

  resetMotivoSequestro(): MotivoSequestro {
    return (this.motivoSequestro = new MotivoSequestro(this.motivoSequestro.id, "", "Ativo"));
  }

}
