import { Pessoa } from "./pessoa.model";

export class PessoaFisica
{
    public id!:string;
    public cpf:string = '';
    public rg?:string
    public mae?:string
    public sexo?:number
    public raca?:number
    public escolaridade?:number
    public estado_civil?:number
    public apelido?:string
    public email?:string
    public dt_nascimento?:Date
    public pessoa?:Pessoa
    public orgao_emissor?:string
    
    constructor
    (
        id :string,
        cpf:string,
        rg:string,
        mae:string,
        sexo:number,
        raca:number,
        escolaridade:number,
        estado_civil:number,
        apelido:string,
        email:string,
        dt_nascimento:Date,
        pessoa:Pessoa,
        orgao_emissor:string,
    )
    {
        this.id = id,
        this.cpf = cpf,
        this.rg = rg,
        this.mae = mae,
        this.sexo = sexo,
        this.raca = raca,
        this.escolaridade = escolaridade,
        this.estado_civil = estado_civil,
        this.apelido = apelido,
        this.email = email,
        this.dt_nascimento = dt_nascimento,
        this.pessoa = pessoa,
        this.orgao_emissor = orgao_emissor
    }
}
