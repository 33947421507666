import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Paciente } from '../paciente/paciente.model';
import { NavegacaoService } from '@app/core/navegacao/navegacao.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '@app/core/security/login/login.service';

import { UsuarioService } from '@app/core/usuario/usuario.service';
import { Router } from "@angular/router";
import { Avisos } from './avisos.model';
import { AvisosService } from './avisos.service';
import { MessageService } from '../shared/message.service';
import { Subscription } from 'rxjs-compat';
import { tap } from 'rxjs/operators';
import { NovoComponent } from './cadastro/novo.component';
import { PacienteService } from '../paciente/paciente.service';


@Component({
  selector: 'app-avisos',
  templateUrl: './avisos.component.html',
  styleUrls: ['./avisos.component.scss']
})
export class AvisosComponent implements OnInit {

  @ViewChild("paginatorAvisos") paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort

  private listarSubscribe: Subscription;
  private displayedColumns: string[] = ['Data Inicio', 'Data Expiração', 'Titulo', 'Departamento', 'Ações'];
  dataSource = new MatTableDataSource<Avisos>();


  constructor(
    private router: Router,
    private userService: UsuarioService,
    private modalService: NgbModal,
    private navegacaoService: NavegacaoService,
    private loginService: LoginService,
    private avisosService: AvisosService,
    private messageService: MessageService,
    private pacienteService: PacienteService,
    private novoComponent: NovoComponent
    ) { }

  ngOnInit() {
    this.paginator.pageIndex = 0;
		this.paginator.pageSize = 5;
  }

  ngAfterViewInit() {
    this.buscar();
  }

  ngOnDestroy(): void {
    this.limpar();
    if (this.listarSubscribe) this.listarSubscribe.unsubscribe;
  }

  buscar() {
    //this.loading = true;
    this.avisosService.listar().subscribe(result => {
      this.dataSource.data = result;
			this.paginator.length = result.lenght;
			this.dataSource.paginator = this.paginator;
    });
  }

  limpar() {
    this.avisosService.avisosFilter.dt_inicio = null;
    this.avisosService.avisosFilter.dt_expiracao = null;
    this.avisosService.avisosFilter.nivel_aviso = null;
    this.avisosService.avisosFilter.titulo = null;
    this.avisosService.avisosFilter.observacao = null;
    this.avisosService.avisosFilter.ativo = null;
    //this.dataSource = new MatTableDataSource<Avisos>();
  }

  novoAviso(){
    this.avisosService.aviso = this.avisosService.getNovoAviso();
    this.avisosService.titulo = 'Cadastrar Novo';
    this.router.navigate(['navegacao/avisos/cadastro']);
  }

  editarAviso(aviso: Avisos){

    this.avisosService.aviso.dt_inicio = null;
    this.avisosService.aviso.dt_expiracao = null;
    this.avisosService.aviso.nivel_aviso = null;
    this.avisosService.aviso.titulo = null;
    this.avisosService.aviso.observacao = null;

    this.avisosService.aviso = aviso;
    this.avisosService.titulo = 'Editar';
    this.avisosService.aviso.dt_inicio = new Date(aviso.dt_inicio);
    if (aviso.dt_expiracao != null){
      this.avisosService.aviso.dt_expiracao = new Date(aviso.dt_expiracao);
    }
    //this.novoComponent.destinoAviso = this.avisosService.aviso.nivel;
    this.router.navigate(['navegacao/avisos/cadastro']);

    // this.avisosService.buscaAviso(aviso.id).subscribe(
    //   aviso => {
    //     this.avisosService.setAviso(aviso);
    //     this.router.navigate(['navegacao/avisos/cadastro']);
    //   })

    // this.avisoEditar = aviso;

  }

  onChangeStatus(aviso: Avisos) {

    aviso.ativo = !aviso.ativo;
    this.avisosService.updateStatusAtivo(aviso.id, aviso.ativo)
      .subscribe(id => {
        this.messageService.addToast("Status do Aviso Atualizado com Sucesso!", "", "success")
        //paciente.ativo = status;
      },
        response => {
          aviso.ativo = !status;
          this.messageService.addToast(response.error.descricao, "", "error")
        })
  }

  sortTable(event: MatSort) {
    this.avisosService.avisosFilter.sort = event ? event.active : "dt_inicio";
    this.avisosService.avisosFilter.direction = event ? event.direction : "desc";

    this.buscar();
  }


}
