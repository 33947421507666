/**
 * Classe modelo para enviar e receber informações do back-end [model rest e dto]
 */

import { BancoModel } from "./banco.model";

export class ContasBancariasModel
{
    constructor(
                public id: string,        //chave primária do banco de dados
                public cnpj: string,      //campo obrigatório
                public nome: string,      //campo NÃO obrigatório
                public agencia:string,    //campo obrigatório
                public conta:string,      //campo obrigatório
                public observacao:string,  //campo obrigatório tipo TEXT
                public status:boolean,    //se a conta está ou não ativa.
                public banco_id:BancoModel
    ){}
} 