import { User } from '@app/core/security/login/user.model';
import { UsuarioNivelAcess } from '@app/core/usuario/usuario-nivel-acess.model';
import EncryptStorage from "encrypt-storage";
import { environment } from 'src/environments/environment';

export abstract class StorageCriptorafado {

  private static encryptStorage = EncryptStorage(environment.ENCRYPTED_KEY, {
    storageType: 'sessionStorage',
  });


  constructor(){};

  static getSessionUser(): User {
    try {
        let _session: User = this.encryptStorage.getItem('TOKEN_USER');
        return _session;
    } catch {
        return null;
    }
  }

  static setSessionUser(session: any) {
    this.encryptStorage.setItem('TOKEN_USER', session);
  }


  static getSessionInfoUser(): UsuarioNivelAcess {
    try {
        let _session: UsuarioNivelAcess = this.encryptStorage.getItem('TOKEN_INFO_USER');
        return _session;
    } catch {
        return null;
    }
  }

  static setSessionInfoUser(session: any) {
    this.encryptStorage.setItem('TOKEN_INFO_USER', session);
  }

  static clean(){
    this.encryptStorage.clear();
  }
}
