import { Injectable } from "@angular/core"
import { HttpClient } from '@angular/common/http'
import { Historico } from "./historico.model"
import { Observable } from 'rxjs';
import { SGS_URL} from "@app/app.api";

@Injectable()
export class HistoricoService {

    constructor(private http: HttpClient){}

    getHistoricos(id_protocolo: String, id_classe : String): Observable<Historico[]> {
      return this.http.post<Historico[]>(`${SGS_URL.JUDICIAL}/historico/buscaHistorico`, {id_protocolo,id_classe})
    }

}

