import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MotivoDevolucaoService } from '@app/core/motivo-devolucao/motivo-devolucao.service';

@Component({
  selector: 'ses-motivo-devolucao-multiselect',
  templateUrl: './motivo-devolucao-multiselect.component.html',
  styleUrls: ['./motivo-devolucao-multiselect.component.scss']
})
export class MotivoDevolucaoMultiselectComponent implements OnInit {
  @Input() required: boolean = true;
  @Input() filtroAtivo: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();
  @Input() appendTo: any = '';
  @Input() motivosSelecionados: string[];

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */
  @Output() idsSelecionadas = new EventEmitter<string[]>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any[]>();

  constructor(private motivoDevolucaoService: MotivoDevolucaoService) { }


  public lsElementos: any[] = [];
  public lsSelecionadas: any[] = [];

  ngOnInit() {
    this.carregaElementos();
    this.limparEvent.subscribe({
      next: (resp) => {
        this.limpar();
      },
    });
  }

  public carregaElementos() {
    this.motivoDevolucaoService.all()
      .subscribe({
        next: response =>{
          this.lsElementos = response

          this.lsSelecionadas = []
          if(this.motivosSelecionados)
          this.motivosSelecionados.forEach(element => {
            this.lsSelecionadas.push(
              this.lsElementos.find(filtro => filtro.id == +element)
              )
              this.selecionaElementos();
          })


          if(this.filtroAtivo){
            this.lsElementos = this.lsElementos.filter(filtro => filtro.status)
          }
        }
      })
  }

  filtraPesquisa(event: any) {
    if (event.target.value && event.target.value.length >= 3) {
      this.carregaElementos();
    }
  }

  public limpar() {
    this.lsSelecionadas = [];
    this.idsSelecionadas.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  selecionaElementos(event?: any) {
    let listIds: string[] = [];
    this.lsSelecionadas.forEach((element) => listIds.push(element.id));
    this.idsSelecionadas.emit(listIds);
    this.elementosCompletoSelecionadas.emit(this.lsElementos);
  }
}
