import { ItemEntrada } from '@app/core/recebimento/item-entrada.model';
import { ReferenciaPrograma } from './../../estoque/ReferenciaPrograma';
import { ItemEntradaQtdDisp } from './../item-entrada-qtd-disp.model';
import { DispensacaoService } from './../dispensacao.service';
import { Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { Component, inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Material } from '@app/core/material/material.model';
import { ItemDispensacao } from '../item-dispensacao.model';
import { MessageService } from '@app/core/shared/message.service';
@Component({
  selector: 'app-item-entrada-modal',
  templateUrl: './item-entrada-modal.component.html',
  styleUrls: ['./item-entrada-modal.component.scss']
})
export class ItemEntradaModalComponent implements OnInit, OnDestroy {

  private loading: boolean;
  // private itemDispensacaoTemp: ItemDispensacao;
  private qtdDispensar: number;
  private maxDispensar: number;
  private displayerDispensar: number = 0;
  private max: number = 0;
  private itemDispensacaoTemp: ItemDispensacao;

  private subscribeFindItemInput: Subscription;

  private displayedColumnsItensEntrada = ["descricao", "lote", "referencia", "validade", "quantidade", "qtd_dispensar"];

  private dataSourceItemEntrada: MatTableDataSource<ItemEntradaQtdDisp> = new MatTableDataSource<ItemEntradaQtdDisp>();

  constructor(
    private dialogRef: MatDialogRef<ItemEntradaModalComponent>,
    @Inject(MAT_DIALOG_DATA) private item: any,
    private dispensacaoService: DispensacaoService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.itemDispensacaoTemp = this.item.item;
    this.criaModalItensEntrada(this.itemDispensacaoTemp);
  }

  ngOnDestroy(): void {
    if (this.subscribeFindItemInput) this.subscribeFindItemInput.unsubscribe();
  }

  public close(): void {
    this.dispensacaoService.updateItensDispensacao(this.itemDispensacaoTemp);
    this.dialogRef.close();
  }

  /**
   * O sistema irá buscar no banco de dados todos os itens de entrada correspondente ao medicamento que
   * será dispensado
  **/
   criaModalItensEntrada(item: ItemDispensacao) {
    this.loading = true;

    //Lista temporaria para criar a dataSourceItemEntrada
    let listaItemEntradaTemp : ItemDispensacao;

    //Ponteiro ALERT {valores podem não ser reais}
    listaItemEntradaTemp = Object.assign({}, item);

    if (item.itensEntrada != null) {
      this.subscribeFindItemInput = this.dispensacaoService.findItenInputByMedicamento(this.dispensacaoService.dispensacaoSelecionada.unidadeSaude.id, item.itemPrescricao.medicamento.id).subscribe(resp => {

        //Pega a lista atualizada do banco (Resp) e atualiza com a listaAuxiliarDispensacao
        resp.forEach(itemEntradaResp=>{

          //Atualiza a Resp com a listaAuxiliarDispensacao
          this.dispensacaoService.listaAuxiliarDispensacao.forEach(itemDispensacaoAUX => {
            if(itemDispensacaoAUX.itemPrescricao.id == item.itemPrescricao.id)
            {
              if(itemDispensacaoAUX.itensEntrada.some(itensEntrada => itensEntrada.itemEntrada.id == itemEntradaResp.id)){
                itemDispensacaoAUX.itensEntrada.forEach(itemEntradaAUX => {
                  if(itemEntradaAUX.itemEntrada.id == itemEntradaResp.id)
                  {
                    itemEntradaResp.qtd_transferir=itemEntradaAUX.itemEntrada.qtd_transferir;
                    itemEntradaResp.saldo = itemEntradaAUX.itemEntrada.saldo;
                  }
                });
              }
            }
          });


        })

        //Define como 0 os saldos e qtd_transferir se null e undefined
        resp.forEach(itemEntrada => {
          if(itemEntrada.saldo == null || itemEntrada.saldo == undefined){itemEntrada.saldo = 0}
          if(itemEntrada.qtd_transferir == null || itemEntrada.qtd_transferir == undefined){itemEntrada.qtd_transferir = 0}
        });

        //Atualiza ou adiciona na listaItemEntradaTemp com base na Resp
        resp.forEach(itemEntrada => {
          if(listaItemEntradaTemp.itensEntrada.some(itemEntradaTMP => itemEntradaTMP.itemEntrada.id == itemEntrada.id))
          {
            listaItemEntradaTemp.itensEntrada.forEach(itemEntradaTMP => {
                if(itemEntrada.id == itemEntradaTMP.itemEntrada.id){
                  itemEntradaTMP.itemEntrada.saldo = itemEntrada.saldo;
                  itemEntradaTMP.itemEntrada.qtd_transferir = itemEntrada.qtd_transferir;
                }
            });
          }else{
            listaItemEntradaTemp.itensEntrada.push((new ItemEntradaQtdDisp(item.itemPrescricao.id, itemEntrada, 0)));
          }
        });

        this.dataSourceItemEntrada = this.montarListaItemEntradaAgrupadoParaModal(listaItemEntradaTemp);
        this.loading = false;
      },
        error => this.loading = false
      );
    } else {
      this.loading = false;
    }

    //qtdDispensar = Quantidade da prescrição - quantidade já dispensada
    this.qtdDispensar = Number(item.itemPrescricao.quantidade) - item.itemPrescricao.qtd_dispensada;

    //maxDispensar é a quantidade máxima que a prescrição pode dispensar, cada prescrição pode dispensar o dobro da quantidade prescrita
    //maxDispensar = Quantidade da prescrição * 2 - quantidade já dispensada
    this.maxDispensar = (Number(item.itemPrescricao.quantidade) * 2) - item.itemPrescricao.qtd_dispensada;
    this.displayerDispensar = this.displayerSaldoRetirar(item);
  }

  // Removido por ser inutilizado - mantido para futuras necessidades
  /*
  private orderEstoque(event: MatSort) {
    let direct = event && event.direction == "desc" ? -1 : 0;
    let indirect = direct == 0 ? -1 : 0;
    let collumn = event && event.active ? event.active : 'validade'

    this.itemDispensacaoTemp.itensEntrada.sort((a, b) => {
      if (collumn == 'validade')   return a.itemEntrada.data_validade > b.itemEntrada.data_validade ? direct : indirect;
      if (collumn == 'descricao')  return a.itemEntrada.material.descricao > b.itemEntrada.material.descricao ? direct : indirect;
      if (collumn == 'lote')       return a.itemEntrada.lote > b.itemEntrada.lote ? direct : indirect;
      if (collumn == 'quantidade') return a.itemEntrada.saldo > b.itemEntrada.saldo ? direct : indirect;
      else return Number(a.qtd_dispensada) > Number(b.qtd_dispensada) ? direct : indirect;
    });
  }
  */

  //Monta lista para exibição agrupando por lote no modal do step 2
  //Recebe a linha da lista de um determinado medicamento de um determinado paciente (lotes não agrupados)
  montarListaItemEntradaAgrupadoParaModal(item: ItemDispensacao) {

    //Lista contendo apenas itens com lote diferente p/ exibição agrupada
    let listaLotesAgrupados: ItemEntradaQtdDisp[] = [];

    //Cria novo objeto para evitar ponteiro
    item.itensEntrada.forEach(itemEntrada => {
      let ie = new ItemEntradaQtdDisp(item.itemPrescricao.id, new
        ItemEntrada(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, new
          Material(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null), null, null, null, null), null);
      // informações a serem exibidas no modal
      // adição de valores de forma manual p/ evitar de alterar valores do objeto referencia
      ie.itemEntrada.id = itemEntrada.itemEntrada.id;
      ie.itemEntrada.qtd_transferir = itemEntrada.itemEntrada.qtd_transferir;
      ie.itemEntrada.lote = itemEntrada.itemEntrada.lote;
      ie.itemEntrada.data_validade = itemEntrada.itemEntrada.data_validade;
      ie.itemEntrada.material.descricao = itemEntrada.itemEntrada.material.descricao;
      ie.itemEntrada.saldo = itemEntrada.itemEntrada.saldo;
      ie.itemEntrada.referencia_programa= new ReferenciaPrograma(null, null, null, null)
      ie.itemEntrada.referencia_programa.descricao = itemEntrada.itemEntrada.referencia_programa.descricao;
      ie.itemEntrada.referencia_programa.id = itemEntrada.itemEntrada.referencia_programa.id;
      ie.itemEntrada.referencia_programa.nome = itemEntrada.itemEntrada.referencia_programa.nome;
      listaLotesAgrupados.push(ie);
    });

    //Compara os lote e referencia_programa somando assim suas quantidade
    listaLotesAgrupados.forEach(itemEntrada => {
      listaLotesAgrupados.forEach(itemEntradaAUX => {
        if(
          (itemEntrada.itemEntrada.lote == itemEntradaAUX.itemEntrada.lote) &&
          (itemEntrada.itemEntrada.id != itemEntradaAUX.itemEntrada.id) &&
          (itemEntrada.itemEntrada.referencia_programa.id == itemEntradaAUX.itemEntrada.referencia_programa.id)
          )
        {
          itemEntrada.itemEntrada.saldo += itemEntradaAUX.itemEntrada.saldo;
          itemEntradaAUX.itemEntrada.saldo = 0;

          itemEntrada.itemEntrada.qtd_transferir += itemEntradaAUX.itemEntrada.qtd_transferir;
          itemEntradaAUX.itemEntrada.qtd_transferir = 0;
        }
      });
    });

    //Remove os itemEntrada com saldo e qtd_transferir igual 0
    let index =0
    while (index<listaLotesAgrupados.length)
    {
      if(listaLotesAgrupados[index].itemEntrada.saldo<1 && listaLotesAgrupados[index].itemEntrada.qtd_transferir<1)
      {
        listaLotesAgrupados.splice(index, 1)
      }
      else{index++}
    }

    return this.dataSourceItemEntrada = new MatTableDataSource<ItemEntradaQtdDisp>(listaLotesAgrupados);
  }

  displayerSaldoRetirar(item: ItemDispensacao): number {
    //Quantidade dispensada anterior
    let quantidadeTotalDispensadaModal = 0;
    this.dispensacaoService.listaAuxiliarDispensacao.forEach(itemDispensacao => {

      if(itemDispensacao.itemPrescricao.id == item.itemPrescricao.id)
      {
        item.itensEntrada.forEach(itemEntrada => {
          itemDispensacao.itensEntrada.forEach(itemEntradaAUX => {
            if(itemEntrada.itemEntrada.id == itemEntradaAUX.itemEntrada.id)
            {
              quantidadeTotalDispensadaModal += itemEntradaAUX.itemEntrada.qtd_transferir;
            }
          });
        });
      }

    });
    return this.maxDispensar-quantidadeTotalDispensadaModal;
  }

  //atualiza o valor a ser dispensado quando adiciona ou remove valor nos inputs number do modal
  async atualizaValoresADispensarModal(item: ItemEntradaQtdDisp) {
    this.subscribeFindItemInput = this.dispensacaoService.findItenInputByMedicamento(this.dispensacaoService.dispensacaoSelecionada.unidadeSaude.id, this.itemDispensacaoTemp.itemPrescricao.medicamento.id).subscribe(resp => {

      resp.forEach(itemEntradaResp=>{
        this.dispensacaoService.listaAuxiliarDispensacao.forEach(itemDispensacaoAUX => {
          //Por o item ser ItemEntradaQtdDisp o valor do id é igual a prescrição que ele pertence
          if(itemDispensacaoAUX.itemPrescricao.id == item.id)
          {

            itemDispensacaoAUX.itensEntrada.forEach(itemEntradaAUX => {
              if(itemEntradaAUX.itemEntrada.id == itemEntradaResp.id)
              {
                itemEntradaResp.qtd_transferir=itemEntradaAUX.itemEntrada.qtd_transferir;
                itemEntradaResp.saldo = itemEntradaAUX.itemEntrada.saldo;
              }
            });
          }
        });
      })

      resp.forEach(itemEntrada => {
          if(itemEntrada.saldo == null || itemEntrada.saldo == undefined){itemEntrada.saldo = 0}
          if(itemEntrada.qtd_transferir == null || itemEntrada.qtd_transferir == undefined){itemEntrada.qtd_transferir = 0}
      });

      //faz correcao do input do usuario, se ele colocar expresões matematicas ele ira indicar 0
      if(item.itemEntrada.saldo == null || item.itemEntrada.saldo == undefined){item.itemEntrada.saldo = 0}
      if(item.itemEntrada.qtd_transferir == null || item.itemEntrada.qtd_transferir == undefined){item.itemEntrada.qtd_transferir = 0}

      let itemEntradaAnteriorSaldo = 0
      let itemEntradaAnteriorQtd_transferir = 0

      this.dispensacaoService.listaAuxiliarDispensacao.forEach(itemDispensado => {
        if(itemDispensado.itemPrescricao.id==item.id)
        {
          itemDispensado.itensEntrada.forEach(itemEntrada => {
            if(itemEntrada.itemEntrada.lote==item.itemEntrada.lote && itemEntrada.itemEntrada.referencia_programa.id==item.itemEntrada.referencia_programa.id)
            {
              itemEntradaAnteriorSaldo+=itemEntrada.itemEntrada.saldo
              itemEntradaAnteriorQtd_transferir+=itemEntrada.itemEntrada.qtd_transferir
            }
          });
        }
      });

      resp.sort((a, b) => (a.data_validade < b.data_validade) ? -1 : 1);

      let quantidadeTotalDispensadaModal = 0;

      //quantidade dispensada anterior
      resp.forEach(itemEntrada => {
        if(itemEntrada.id != item.itemEntrada.id)
        {
          quantidadeTotalDispensadaModal += itemEntrada.qtd_transferir;
        }
      });

      //Analisa as qtd_transferir total para verificar se é maior que a quantidade * 2
      if(item.itemEntrada.saldo != itemEntradaAnteriorSaldo || item.itemEntrada.qtd_transferir != itemEntradaAnteriorQtd_transferir)
      {
        //verifica se a quantidade digitada pelo usuario é um numero inteiro
        if(!(item.itemEntrada.qtd_transferir%1===0)){item.itemEntrada.qtd_transferir=itemEntradaAnteriorQtd_transferir}
        if(item.itemEntrada.qtd_transferir>this.maxDispensar){item.itemEntrada.qtd_transferir=this.maxDispensar-quantidadeTotalDispensadaModal}
        if(item.itemEntrada.qtd_transferir>itemEntradaAnteriorSaldo+itemEntradaAnteriorQtd_transferir){item.itemEntrada.qtd_transferir=itemEntradaAnteriorSaldo+itemEntradaAnteriorQtd_transferir}
      }


      if(item.itemEntrada.qtd_transferir < 0){item.itemEntrada.qtd_transferir = 0}

      if(item.itemEntrada.saldo != itemEntradaAnteriorSaldo || item.itemEntrada.qtd_transferir != itemEntradaAnteriorQtd_transferir)
      {
        let saldoParaOutroItemEntrada = item.itemEntrada.qtd_transferir - itemEntradaAnteriorQtd_transferir


        resp.forEach(itemEntrada =>
        {
          if(itemEntrada.lote == item.itemEntrada.lote && itemEntrada.referencia_programa.id == item.itemEntrada.referencia_programa.id)
          {
            if(itemEntrada.saldo > 0 && saldoParaOutroItemEntrada > 0)
            {
              if(itemEntrada.saldo >= saldoParaOutroItemEntrada)
              {
                itemEntrada.qtd_transferir += saldoParaOutroItemEntrada;
                itemEntrada.saldo -= saldoParaOutroItemEntrada;
                saldoParaOutroItemEntrada = 0;
              }
              else
              {
                itemEntrada.qtd_transferir += itemEntrada.saldo;
                saldoParaOutroItemEntrada -= itemEntrada.saldo;
                itemEntrada.saldo = 0;
              }
            }
            else if (saldoParaOutroItemEntrada < 0){
              if((itemEntrada.quantidade - itemEntrada.saldo) >= (saldoParaOutroItemEntrada * -1))
              {
                itemEntrada.qtd_transferir += saldoParaOutroItemEntrada;
                itemEntrada.saldo -= saldoParaOutroItemEntrada;
                saldoParaOutroItemEntrada = 0
              }
              else
              {
                if ((itemEntrada.saldo - itemEntrada.qtd_transferir) < itemEntrada.quantidade)
                {
                  itemEntrada.qtd_transferir += (itemEntrada.saldo - itemEntrada.qtd_transferir)
                  itemEntrada.saldo += (itemEntrada.saldo - itemEntrada.qtd_transferir)

                  saldoParaOutroItemEntrada += (itemEntrada.saldo - itemEntrada.qtd_transferir)
                }
              }
            }
            //Não é o Item ItemEntrada que estamos validando
            else{saldoParaOutroItemEntrada}
          }
        });

        //Atualiza a listaAuxiliarDispensacao com o resp que foi alterado
        this.dispensacaoService.listaAuxiliarDispensacao.forEach(itemDispensacao => {
          if(itemDispensacao.itemPrescricao.id == item.id){
            //Atualiza a quantidade a transferir e saldo dos item
            resp.forEach(itensEntradaResp => {
              itemDispensacao.itensEntrada.forEach(itensEntradaAUX => {
                  if(itensEntradaResp.id == itensEntradaAUX.itemEntrada.id){
                    itensEntradaAUX.itemEntrada.qtd_transferir = itensEntradaResp.qtd_transferir;
                    itensEntradaAUX.itemEntrada.saldo = itensEntradaResp.saldo;
                  }
              });
            });
          }
        });

        this.dispensacaoService.listaAuxiliarDispensacao
      }
    })
  }

  obtemValorMaxPorLote(item: ItemEntrada): number {
    let retorno: number = 0;
    this.itemDispensacaoTemp.itensEntrada.forEach((element, i)=> {
        if(item.lote == element.itemEntrada.lote && item.referencia_programa.id == element.itemEntrada.referencia_programa.id){
          retorno = retorno + element.itemEntrada.saldo
        }
    });
    return retorno;
  }

}
