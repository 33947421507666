import { LoginService } from './../security/login/login.service';
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UnidadeSaude } from "./unidade-saude.model";
import { CentroCusto } from '../centro-custo/centro-custo.model';
import { UnidadeAssistencia } from '../unidade-assistencia/unidade-assistencia.model';
import { SGS_URL, CORE } from '@app/app.api';
import { ListaJSON } from '../shared/default/lista-json.model';
import { DescricaoModel } from '../shared/descricao/descricao.model';

@Injectable({
  providedIn: "root"
})
export class UnidadeSaudeService {

  private _unidadeSaude: UnidadeSaude;
  private _unidadeFilter: any;
  private _unidadeSaudeFilter: any;
  public lsIdsSelecionadosMultiSelect: any[] = [];
  public haItensSelecionadosMultiSelect = false;
  public referencia: any = this.loginService.user.referencia.id;

  constructor(private http: HttpClient, private loginService: LoginService) { }

  getUnidadeSaude(): UnidadeSaude {
    if (this.unidadeSaude === undefined) {
      this.unidadeSaude = this.getNovaUnidadeSaude();
    }
    return this.unidadeSaude;
  }

  getNovaUnidadeSaude(): UnidadeSaude {
    this.unidadeSaude = new UnidadeSaude(
      "",
      "",
      null,
      true,
      null,
      null,
      false,
      false
    )
    return this.unidadeSaude;
  }

  buscarUnidadesSaudes(): Observable<ListaJSON<UnidadeSaude>> {
    return this.http.post<ListaJSON<UnidadeSaude>>(`${SGS_URL.CORE}/${CORE.UNIDADE_SAUDE}/p`, this._unidadeSaudeFilter);
  }

  getAllUnidadesSaude(){
    return this.http.get<any[]>(`${SGS_URL.CORE}/${CORE.UNIDADE_SAUDE}/get_unidades_judicial`);
  }

  cadastrar(): Observable<any> {
    let id_referencia = this.loginService.user.referencia.id;
    let id_usuario = this.loginService.user.id;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.UNIDADE_SAUDE}/c/${id_referencia}/${id_usuario}`, this._unidadeSaude);
  }

  updateStatus(id_unidade: string, status: string): Observable<any> {
    let id_referencia = this.loginService.user.referencia.id;
    let id_usuario = this.loginService.user.id;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.UNIDADE_SAUDE}/s/${id_unidade}/${id_usuario}/${status}/${id_referencia}`, {})
  }

  buscaUnidadeSaude(
    id_unidadeSaude: string,
    id_usuario: string
  ): Observable<UnidadeSaude> {
    return this.http.post<UnidadeSaude>(
      `${SGS_URL.CORE}/${CORE.UNIDADE_SAUDE}/buscar/${id_unidadeSaude}/${id_usuario}`,
      {}
    );
  }

  buscaUnidadeAssistenciaByNome(nome: string): Observable<UnidadeAssistencia[]> {
    return this.http.post<UnidadeAssistencia[]>(`${SGS_URL.CORE}/${CORE.UNIDADE_ASSISTENCIA}/getListUnidadesAssistenciaisNotR`, this._unidadeFilter);
  }

  buscaUnidadeSaudeByNome(nome: string): Observable<DescricaoModel[]> {
    this.referencia = this.loginService.user.referencia.id;
    return this.http.post<DescricaoModel[]>(`${SGS_URL.CORE}/${CORE.UNIDADE_SAUDE}/buscaUnidadeSaudeByNome`, { nome: nome, referencia: this.referencia });
  }

  buscaUnidadeSaudeByNomeAndReferencia(nome: string, referencia: any): Observable<DescricaoModel[]> {
    this.referencia = referencia;
    return this.http.post<DescricaoModel[]>(`${SGS_URL.CORE}/${CORE.UNIDADE_SAUDE}/buscaUnidadeSaudeByNome`, { nome: nome, referencia: this.referencia });
  }

  //buscar todos os centros de custo não utilizados
  searchListCentroCusto(): Observable<CentroCusto[]> {
    return this.http.post<CentroCusto[]>(`${SGS_URL.CORE}/${CORE.CENTRO_CUSTO}/getListCentroCusto`, this._unidadeFilter);
  }

  getById(idUnidadeSaude: string): Observable<UnidadeSaude> {
    return this.http.get<UnidadeSaude>(
      `${SGS_URL.CORE}/${CORE.UNIDADE_SAUDE}/getById/${idUnidadeSaude}`,
      {}
    );
  }

  getUnidadeAssisById(id_unidadeAssistencia: string): Observable<UnidadeAssistencia> {
    return this.http.post<UnidadeAssistencia>(`${SGS_URL.CORE}/${CORE.UNIDADE_ASSISTENCIA}/getUnidade`,{id_unidadeAssistencia:id_unidadeAssistencia});
  }

  verificaPrincipalMunicipio(id_muni:string): Observable<UnidadeSaude[]>
  {
    let id_ref = this.loginService.user.referencia.id;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.UNIDADE_SAUDE}/verificaPrincipalMunicipio`,{id_muni:id_muni, id_ref:id_ref});
  }

  getPrincipalByMuniPac(id_pac:string): Observable<UnidadeSaude[]>
  {
    let id_ref = this.loginService.user.referencia.id;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.UNIDADE_SAUDE}/getPrincipalByMuniPac`,{id_pac:id_pac, id_ref:id_ref});
  }

  public get unidadeFilter(): any {
    return this._unidadeFilter;
  }
  public set unidadeFilter(value: any) {
    this._unidadeFilter = value;
  }
  public get unidadeSaude(): UnidadeSaude {
    return this._unidadeSaude;
  }
  public set unidadeSaude(value: UnidadeSaude) {
    this._unidadeSaude = value;
  }
  public get unidadeSaudeFilter(): any {
    return this._unidadeSaudeFilter;
  }
  public set unidadeSaudeFilter(value: any) {
    this._unidadeSaudeFilter = value;
  }
  public getNewLsIdsSelecionados() {
    this.lsIdsSelecionadosMultiSelect = [];
  }
}
