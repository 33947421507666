import { Injectable } from "@angular/core"
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Municipio } from "@app/core/regional/municipio.model"
import { SGS_URL } from "@app/app.api"


@Injectable()
export class MunicipioService {

  public lsIdsSelecionadosMultiSelect: any[] = [];
  public haItensSelecionadosMultiSelect = false;

    constructor(private http: HttpClient){}

    //Lista os municipios de SC
    listar(nome: string): Observable<Municipio[]> {
      return this.http.post<Municipio[]>(`${SGS_URL.CORE}/endereco/municipio/listar/${nome}/`, {})
    }

    //Lista os municipios sem UF, ou seja do Brasil todo
    listarBR(nome: string): Observable<Municipio[]> {
      return this.http.post<Municipio[]>(`${SGS_URL.CORE}/endereco/municipio/listarBR/${nome}/`, {})
    }

    getMunicipiosByIDUnidadeAssistencia(idUnidadeAssistencia: string): Observable<Municipio[]> {
      return this.http.post<Municipio[]>(`${SGS_URL.CORE}/endereco/municipio/getMunicipiosByIDUnidadeAssistencia/${idUnidadeAssistencia}/`, {});
    }

    getLsMunicipiosDTOByLsIDUnidadeAssistencia(lsIdUnidadeAssistencia: string): Observable<Municipio[]> {
      return this.http.post<Municipio[]>(`${SGS_URL.CORE}/endereco/municipio/getLsMunicipiosDTOByLsIDUnidadeAssistencia/${lsIdUnidadeAssistencia}/`, {});
    }

    searchLocale(nome: string, uf: string): Observable<Municipio[]> {
      return this.http.post<Municipio[]>(`${SGS_URL.CORE}/endereco/municipio/listarlocalidade`, {nome: nome, uf:uf})
    }

    buscaMunicipiosLocalidadeByNomeUf(nome: string, uf: string): Observable<Municipio[]> {
      return this.http.post<Municipio[]>(`${SGS_URL.CORE}/endereco/municipio/buscaMunicipiosLocalidadeByNomeUf`, {nome: nome, uf:uf})
    }

    public getNewLsIdsSelecionados() {
      this.lsIdsSelecionadosMultiSelect = [];
    }

}
