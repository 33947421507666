import { UnidadeAssistencia } from './../unidade-assistencia/unidade-assistencia.model';
import { CentroCusto } from '../centro-custo/centro-custo.model';
import { Regional } from '../regional/regional.model';

export class UnidadeSaude {
  constructor(
    public id: string,
    public nome: string,
    public unidade_assistencia: UnidadeAssistencia,
    public status: boolean,
    public centro_custo:CentroCusto,
    public regional:Regional,
    public descentralizada:boolean,
    public principal_municipio:boolean
    ) 
    { }
}
