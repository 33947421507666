import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { CORE, SGS_URL } from "@app/app.api";
import { LoginService } from "@app/core/security/login/login.service";
import { UsuarioService } from "@app/core/usuario/usuario.service";
import { Observable } from "rxjs";
import { InventarioEstabelecimentoField } from "../shared/models/inventario-estabelecimento.field.model";
import { Inventario } from "../shared/models/inventario.model";
import { InvetarioEstabelecimentoAbertoFilter } from "../shared/models/invetario-estabelecimento-aberto.filter.models";
import { InvetarioEstabelecimentosAbertoFilter } from "../shared/models/invetario-estabelecimentos-aberto.filter.models";
import { ItemListagemContagem } from "../shared/models/item-listagem-contagem.model";
import { MaterialInventarioModalFilter } from "../shared/models/material-inventario-modal.filter.model";
import { MaterialInventarioModal } from "../shared/models/material-inventario-modal.model";
import { MaterialInventario } from "../shared/models/material-inventario.model";
import { ResponseComLength } from "../shared/models/response-com-length.model";
import { SituacaoIntegracaoInventario } from "../shared/models/situacao-integracao-inventario.model"

@Injectable({
  providedIn: "root",
})
export class InventarioService {

  //#region Filtros
  public filterInventarioListagem = this.getFilterListagemInventario();
  public filterPosicaoInicialEstoque = this.getFilterPontoInicial();
  public filterConsultaContagem = this.getFilterConsultaContagem();
  public filterUsuarioLogado = this.getFilterInventarioEstabelecimento();
  public filterInventarioEstabelecimento =
    this.getFilterInventarioEstabelecimentoListInventarioEstabelecimento();
  public filterListagemContagem = this.getFilterListagemContagem();
  //#endregion

  //#region Eventos
  private _eventRecarregarConsultaInventario: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  private _eventRecarregarTabelaMaterial: EventEmitter<any> =
    new EventEmitter<any>();
  private _eventRecarregarConsultaContagem: EventEmitter<any> =
    new EventEmitter<any>();

  public eventRecarregarJustificar: EventEmitter<boolean> = new EventEmitter<boolean>();
  public eventRecarregarEnviar: EventEmitter<boolean> = new EventEmitter<boolean>();
  //#endregion

  //#region Variaveis
  public idInventarioSelecionado = null;
  public inventarioEstabelecimentoId!: number;
  public idInventarioEstabelecimentoSelecionado = null;
  public materialIncluido: any;
  public _paramentrosMateriaisContagem = this.getParametrosMateriaisContagem();
  //#endregion

  constructor(
    private userService: UsuarioService,
    private loginService: LoginService,
    private httpclient: HttpClient
  ) {}

  //#region API

  /**
   * Retorna a situacao_integracao_inventario
   * @returns SituacaoIntegracaoInventario[]
   */
  public findAllSituacaoInventario(): Observable<
    SituacaoIntegracaoInventario[]
  > {
    return this.httpclient.get<SituacaoIntegracaoInventario[]>(
      `${SGS_URL.CORE}/${CORE.SITUACAO_INTEGRACAO_INVENTARIO}/listar`,
      {}
    );
  }

   /**
   * Retorna as situacao_integracao_inventario que correspondem ao inventario finalizado
   * @returns SituacaoIntegracaoInventario[]
   */
    public getAllSituacaoInventarioByIds(lsStatus : string): Observable<
    SituacaoIntegracaoInventario[]
  > {
    return this.httpclient.get<SituacaoIntegracaoInventario[]>(
      `${SGS_URL.CORE}/${CORE.SITUACAO_INTEGRACAO_INVENTARIO}/listarByIds/${lsStatus}`,
      {}
    );
  }

  /**
   * Retorna as informações do inventario baseada na ID
   * @param idInventario
   * @returns Inventario
   */
  public getInformacoesInventario(
    idInventario: number
  ): Observable<Inventario> {
    return this.httpclient.get<Inventario>(
      `${SGS_URL.CORE}/${CORE.INVENTARIO}/info/${idInventario}`,
      {}
    );
  }

  public verificaPontoInicialVazio(inventario_estabelecimento_id: number):Observable<boolean>{
    return this.httpclient.post<boolean>
    (
      `${SGS_URL.CORE}/${CORE.INVENTARIO_ESTABELECIMENTO}/contagem/verificaPontoInicialVazio`,
      {
        params: {inventario_estabelecimento_id}
      }
    )
  }


  /**
   * Retorna a lista de lotes do itens entrada so sistema
   * @returns string[]
   */
  public getListLoteModalContagem(lote: string): Observable<string[]>
  {
    return this.httpclient.post<string[]>
    (
      `${SGS_URL.CORE}/${CORE.ITEM_ENTRADA}/searchLote`,
      { params: {lote} }
    );
  }

  /**
   * Retorna a lista de inventario estabelecimento do inventário
   * @returns InventarioEstabelecimentoField
   */
  public getListInventarioEstabelecimento(): Observable<ResponseComLength> {
    this.filterInventarioEstabelecimento.inventario_id =
      this.idInventarioSelecionado;
    this.filterInventarioEstabelecimento.usuario_id = this.loginService.user.id;
    return this.httpclient.post<ResponseComLength>(
      `${SGS_URL.CORE}/${CORE.INVENTARIO_ESTABELECIMENTO}/p`,
      { params: this.filterInventarioEstabelecimento }
    );
  }

  /**
   *  Retorna dados para ser exibidos em tabelas
   * @returns ContagemField[]
   */
  public getLsInventarioContagem(): Observable<ResponseComLength> {
    return this.httpclient.post<ResponseComLength>(
      `${SGS_URL.CORE}/${CORE.INVENTARIO_ESTABELECIMENTO}/materiais-contagem/${this.inventarioEstabelecimentoId}`,
      { params: this.filterConsultaContagem }
    );
  }

  /**
   * Método para verificar se o inventário está aberto ou não
   * @param InvetarioEstabelecimentoAbertoFilter
   * @returns Boolean
   */
  public getSituacaoInvetarioEstabelecimentoAberto(
    invetarioEstabelecimentoAbertoFilter: InvetarioEstabelecimentoAbertoFilter
  ): Observable<boolean> {
    return this.httpclient.post<any>(
      `${SGS_URL.CORE}/${CORE.INVENTARIO_ESTABELECIMENTO}/estabelecimento-em-inventario/`,
      {
        params: invetarioEstabelecimentoAbertoFilter,
      }
    );
  }

  /**
   * Método para verificar se o inventário está com situacao com status 101(ponto inicial de estoque nao gerado)
   * @param id : number
   * @returns Boolean
   */
  public verificarSituacaoInventarioEstabelecimentoPontoInicialNaoGerado(identificador: number): Observable<boolean>
  {
    return this.httpclient.post<any>(`${SGS_URL.CORE}/${CORE.INVENTARIO_ESTABELECIMENTO}/ponto-inicial-estoque`,{params: {"identificador":identificador}});
  }

  /**
   * Método que retorna uma lista com os IDs de estabelecimentos que não estão em inventário
   * @param InvetarioEstabelecimentosAbertoFilter
   * @returns number[]
   */
  public getSituacaoInvetarioEstabelecimentosAberto(
    invetarioEstabelecimentosAbertoFilter: InvetarioEstabelecimentosAbertoFilter
  ): Observable<number[]> {
    return this.httpclient.post<number[]>(
      `${SGS_URL.CORE}/${CORE.INVENTARIO_ESTABELECIMENTO}/estabelecimentos-em-inventario`,
      {
        params: invetarioEstabelecimentosAbertoFilter,
      }
    );
  }

  /**
   * Retorna a lista de item material inventario para a inclusão no modal
   * @returns ItemMaterialInventario[]
   */
  public getListagemModalContagem(materialInventarioModalFilter: MaterialInventarioModalFilter): Observable<any> {
    return this.httpclient.post<any>(
      `${SGS_URL.CORE}/${CORE.ITEM_ENTRADA}/listItensModal`,
      { params: materialInventarioModalFilter }
    );
  }

  /**
   * Registra a contagem do material e retorna a id
   * @param itemListagemContagem
   * @returns Id
   */
  public registrarContagem(
    itemListagemContagem: ItemListagemContagem
  ): Observable<string> {
    let postbody = {
      params: {
        usuario_id: Number(this.loginService.user.id),
        qtd_contada: itemListagemContagem.qtd_contada,
        material_inventario_id: itemListagemContagem.id,
        contagem_material_id: itemListagemContagem.contagem_material_id,
      },
    };
    return this.httpclient.post<string>(
      `${SGS_URL.CORE}/${CORE.INVENTARIO_ESTABELECIMENTO}/contagem-material`,
      postbody
    );
  }

  /**
   *  Lista os inventários para a tela de inventário
   * @param filtro
   * @returns filtro
   */
  public findAllInventarioByFilter(filtro: any): Observable<any> {
    return this.httpclient.post<any>(`${SGS_URL.CORE}/${CORE.INVENTARIO}/p`, {
      params: filtro,
    });
  }

  /**
   * Gera o ponto inicial do estoque
   * @returns void
   */
  public geraListagemInicialContagem(): Observable<any> {
    return this.httpclient.post<any>(
      `${SGS_URL.CORE}/${CORE.INVENTARIO_ESTABELECIMENTO}/materiais-contagem`,
      { params: this.filterPosicaoInicialEstoque }
    );
  }

  /**
   * Gera o ponto inicial do estoque
   * @returns void
   */
  public getInfoInventarioEstabelecimento(
    idEstabelecimento: number
  ): Observable<InventarioEstabelecimentoField> {
    return this.httpclient.post<InventarioEstabelecimentoField>(
      `${SGS_URL.CORE}/${CORE.INVENTARIO_ESTABELECIMENTO}/info`,
      { params: { identificador: idEstabelecimento } }
    );
  }

  /**
   * Incluir uma nova contagem
   * @returns number
   */
  public incluirMaterialInventario(materialInventario: MaterialInventarioModal): Observable<number> {
    let postbody = {params: materialInventario};
    return this.httpclient.post<number>(`${SGS_URL.CORE}/${CORE.INVENTARIO_ESTABELECIMENTO}/material-inventario/`, postbody);
  }


  /**
   * Inicia o processo de validação das contagens registradas
   * @returns number
   */
   public contarMateriais(): Observable<any> {
      let postbody = {
        params: {
          inventario_estabelecimento_id: this.inventarioEstabelecimentoId,
        },
      };
      return this.httpclient.post<any>(
        `${SGS_URL.CORE}/${CORE.INVENTARIO_ESTABELECIMENTO}/contar`,
        postbody
      );
    }

    /**
    * Método para verificar se o istabelecimento pode dispencar
    * @param InvetarioEstabelecimentoAbertoFilter
    * @returns Boolean
    */
    getSituacaoInvetarioEstabelecimentoAbertoDispensacao(_filter: InvetarioEstabelecimentoAbertoFilter): Observable<boolean> {
      return this.httpclient.post<any>(
        `${SGS_URL.CORE}/${CORE.INVENTARIO_ESTABELECIMENTO}/estabelecimento-em-inventario-dispensacao`,{params: _filter});
    }

    /**
   * Verifica se pode habilitar o botao 'Calcular Todos'
   * @param isRegional
   * @param estabelecimentoId
   * @returns boolean
   */
     public habilitaCalcularTodos(estabelecimentoId : number): Observable<boolean> {
      let postbody = {
        params: {
          estabelecimento_id: estabelecimentoId
        },
      };
      return this.httpclient.post<any>(
        `${SGS_URL.CORE}/${CORE.INVENTARIO_ESTABELECIMENTO}/verifica-calcular-todos`,
        postbody
      );
    }

    public aprovarInventario(inventario_id: number, id_usuario_aprovador: number, justificativa: string): Observable<boolean> {
      return this.httpclient.post<boolean>(
        `${SGS_URL.CORE}/${CORE.INVENTARIO}/aprovar-inventario`,
        {params:{inventario_id,id_usuario_aprovador,justificativa}}
      );
    }

    public verificarAprovarInventario(inventario_id: number): Observable<boolean> {
      return this.httpclient.post<boolean>(
        `${SGS_URL.CORE}/${CORE.INVENTARIO}/verificar-aprovar-inventario`,
        {params:{inventario_id}}
      );
    }

  //#endregion

  //#region Objeto

  public getFilterPontoInicial() {
    return {
      inventario_estabelecimento_id:
        this.idInventarioEstabelecimentoSelecionado,
      referencia_id: this.loginService.user.referencia.id,
    };
  }

  public getFilterConsultaContagem() {
    return {
      contagem_cd: "3",
      direction: "asc",
      pageSize: 25,
      pageIndex: 0,
      lote: null,
      medicamento_id: null,
      sort: "material_nome",
    };
  }

  public getFilterInventarioEstabelecimentoListInventarioEstabelecimento() {
    return {
      direction: "desc",
      pageSize: 10,
      pageIndex: 0,
      referencia_id: "1",
      sort: "usuario_nome",
      inventario_id: "4",
      usuario_id: "96",
    };
  }

  public getFilterListagemContagem() {
    return {
      pageSize: 10,
      pageIndex: 0,
      direction: "desc",
      sort: "cod_ciasc",
    };
  }

  public getParametrosMateriaisContagem() {
    return {
      direction: "asc",
      sort: "data_validade",
      pageIndex: 0,
      pageSize: 1,
    };
  }

  public getFilterInventarioEstabelecimento() {
    return {
      referencia: this.loginService.user.referencia.id,
      direction: "asc",
      sort: "data_validade",
      pageIndex: 0,
      pageSize: 10,
      inventario_id: null,
      usuario_id: this.userService.usuario.id,
    };
  }

  public getFilterListagemInventario() {
    return {
      dataInicioIni: null,
      dataInicioFim: null,
      dataFimIni: null,
      dataFimFim: null,
      regionalId: null,
      unidadeSaudeId: null,
      situacaoIntegracaoInventarioId: null,
      direction: "asc",
      pageSize: 10,
      pageIndex: 0,
      referencia: this.loginService.user.referencia.id,
      sort: "status",
    };
  }

  //#endregion

  //#region  Getter and Setters

  public get eventRecarregarConsultaInventario(): EventEmitter<boolean> {
    return this._eventRecarregarConsultaInventario;
  }
  public set eventRecarregarConsultaInventario(value: EventEmitter<boolean>) {
    this._eventRecarregarConsultaInventario = value;
  }

  public get eventRecarregarTabelaMaterial(): EventEmitter<boolean> {
    return this._eventRecarregarTabelaMaterial;
  }

  public set eventRecarregarTabelaMaterial(value: EventEmitter<boolean>) {
    this._eventRecarregarTabelaMaterial = value;
  }

  public get eventRecarregarConsultaContagem(): EventEmitter<boolean> {
    return this._eventRecarregarConsultaContagem;
  }

  public set eventRecarregarConsultaContagem(value: EventEmitter<boolean>) {
    this._eventRecarregarConsultaContagem = value;
  }

  //#endregion
}
