import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'processo'
})
export class ProcessoPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if(value == null) return null;
    
   //retirando os caracteres especiais do texto
   let ch = /\^|~|\|\/|_|\?|,|\*|\.|\-/g;
   value = value.replace(ch, ""); 

    if (value.trim().length == 20){
      return value.substring(0, 7) + "-" +
        value.substring(7, 9) + "." +
        value.substring(9, 13) + "." +
        value.substring(13, 16) + "." +
        value.substring(16, 20);
    }
    //5012236-48.2018.500.7241
    
    else if(value.trim().length < 13){
      return value.substring(0, 7) + "-" +
        value.substring(7, 9) + "." +
        value.substring(9, 13);
    }
    //0390700-47.233
    else return value;
  }
}