import { Endereco } from '@app/core/shared/endereco/endereco.model';
import { Contato } from '../shared/models/contato.model';
export class Pessoa
{
    constructor (
            public id: string,
            public nome: string,
            public enderecos: Endereco[],
            public telefones: Contato[]
        )
        { }
}
