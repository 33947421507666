import { ProfissionalEntity } from './profissional-entity.model';
import { Injectable } from '@angular/core';
import { Profissional } from './profissional.model';
import { HttpClient } from '@angular/common/http';
import { LoginService } from '@app/core/security/login/login.service';
import { Observable } from 'rxjs';
import { SGS_URL, CORE } from '@app/app.api';
import { ProfissionalPesquisa } from './profissional-pesquisa/profissional-pesquisa.model';
import { ListaJSON } from '@app/core/shared/default/lista-json.model';
import { DescricaoModel } from '@app/core/shared/descricao/descricao.model';
import { Contato } from '@app/core/shared/models/contato.model';
import { Logradouro } from '@app/core/shared/endereco/logradouro.model';
import { Endereco } from '@app/core/shared/endereco/endereco.model';
import { Especialidade } from '../especialidade/especialidade.model';

@Injectable()
export class ProfissionalService {

  profissional: Profissional
  hasLogradouro = false
  mostrarModalCadastroProfissional = false;

  constructor(
    private http: HttpClient,
    private loginService: LoginService) { }

  cadastrar(profissional: Profissional): Observable<any> {
    let id_usuario = this.loginService.user.id
    let id_referencia = this.loginService.user.referencia.id
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.PROFISSIONAL}/c/${id_referencia}/${id_usuario}`, profissional)
  }

  updateStatus(id_profissional: string, status: string): Observable<any> {
    let id_usuario = this.loginService.user.id
    let id_referencia = this.loginService.user.referencia.id
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.PROFISSIONAL}/s/${id_profissional}/${id_usuario}/${status}/${id_referencia}`, {})
  }

  listar(profissionalPesquisa: ProfissionalPesquisa): Observable<ListaJSON<Profissional>> {
    return this.http.post<ListaJSON<Profissional>>(`${SGS_URL.CORE}/${CORE.PROFISSIONAL}/p`, profissionalPesquisa)
  }

  listarJuizes(nome: String): Observable<Profissional[]> {
    return this.http.post<Profissional[]>(`${SGS_URL.CORE}/${CORE.PROFISSIONAL}/listarJuizes`, {nome:nome})
  }

  listarAdvogados(nome: String): Observable<Profissional[]> {
    return this.http.post<Profissional[]>(`${SGS_URL.CORE}/${CORE.PROFISSIONAL}/buscaAdvogadosByNomeNumeroRegistro`, {nome:nome})
  }

  buscaProfissional(id_profissional: string): Observable<Profissional> {
    return this.http.post<Profissional>(`${SGS_URL.CORE}/${CORE.PROFISSIONAL}/id/${id_profissional}`, {})
  }

  listarUfs(): Observable<DescricaoModel[]> {
    return this.http.post<DescricaoModel[]>(`${SGS_URL.CORE}/${CORE.PROFISSIONAL}/listarUfs`, {})
  }

  listarTodosAtivos(nome: string): Observable<Profissional[]> {
    return this.http.post<Profissional[]>(`${SGS_URL.CORE}/${CORE.PROFISSIONAL}/listarTodosAtivos/${nome}/`, {})
  }

  public listarProfssionalAtivo(filtro:string, tipo_doc:string ,isJuiz:boolean ):Observable<ProfissionalEntity[]>
  {
    return this.http.post<ProfissionalEntity[]>(`${SGS_URL.CORE}/${CORE.PROFISSIONAL}/getActives`, {nome:filtro, doc:tipo_doc , juiz:isJuiz})
  }

  setProfissional(profissional: Profissional) {
    this.profissional = profissional
  }

  getNovoProfissional(): Profissional {
    let logradouro = { id: "", logradouro: "", bairro: "", municipio: "", uf: "", tipo: "", cep: "" }
    let endereco = { id: "", logradouro: logradouro, numero: "", complemento: "", referencia: "", zona: "", id_logradouro: "", tipo: "" ,nome_logradouro:"", nome_bairro: "", cep: ""}
    let contatos = [{ id: "", tipo: "", telefone: "", contato: "", index: 1 }, { id: "", tipo: "", telefone: "", contato: "", index: 2 },
    { id: "", tipo: "", telefone: "", contato: "", index: 3 }, { id: "", tipo: "", telefone: "", contato: "", index: 4 }]
    let especialidades: Especialidade[] = [] ;
    this.profissional = new Profissional("", "", "", "", "", endereco, "", contatos, null, especialidades, "", false)
    return this.profissional
  }

  getUmProfissional(): Profissional {
    let logradouro = { id: "", logradouro: "", bairro: "", municipio: "", uf: "", tipo: "", cep: "" }
    let endereco = { id: "", logradouro: logradouro, numero: "", complemento: "", referencia: "", zona: "", id_logradouro: "", tipo: "", nome_logradouro:"", nome_bairro:"", cep: ""}
    let contatos = [{ id: "", tipo: "", telefone: "", contato: "", index: 1 }, { id: "", tipo: "", telefone: "", contato: "", index: 2 },
    { id: "", tipo: "", telefone: "", contato: "", index: 3 }, { id: "", tipo: "", telefone: "", contato: "", index: 4 }]
    return new Profissional("", "", "", "", "", endereco, "", contatos, null, null, "", false)
  }

  corrigeContatos(contatos: Contato[]): Contato[] {
    let local = [{ id: "", tipo: "", telefone: "", contato: "", index: 1 }, { id: "", tipo: "", telefone: "", contato: "", index: 2 },
    { id: "", tipo: "", telefone: "", contato: "", index: 3 }, { id: "", tipo: "", telefone: "", contato: "", index: 4 }]
    let i: number
    for (i = 0; i < contatos.length; i++) {
      local[i] = contatos[i]
    }
    return local
  }

  getProfissional(): Profissional
    {
    if (this.profissional === undefined) {
      this.profissional = this.getNovoProfissional()
    }
    if(!this.profissional.endereco)
    {
      let logradouro = new Logradouro(null, null, null, null, null, null, null);
      this.profissional.endereco = new Endereco(null, null, null, null, null, logradouro, null, null, null, null);
    }
    if(!this.profissional.endereco.logradouro) this.profissional.endereco.logradouro = new Logradouro(null, null, null, null, null, null, null);
    return this.profissional
  }

}
