import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoggedInGuard } from '@app/core/security/loggedin.guard';

import { AlertaCadastroComponent } from './alerta-cadastro/alerta-cadastro.component';

const routes: Routes = [
    { path: '',  component: AlertaCadastroComponent, canLoad: [LoggedInGuard], canActivateChild: [LoggedInGuard] },
    { path: 'cadastro',  component: AlertaCadastroComponent, canLoad: [LoggedInGuard], canActivateChild: [LoggedInGuard] },
];

@NgModule({
  imports: [
      RouterModule.forChild(routes)
    ],
  exports: [
    RouterModule
  ]
})
export class AlertaRoutes { }