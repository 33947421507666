import { JUDICIAL } from './../../app.api';
import { NatPosicionamento } from './nat-posiciomento.model';
import { ProcessoService } from '@app/judicial/processo/processo.service';
import { Periodicidade } from '../../core/periodicidade/periodicidade.model';
import { Injectable, EventEmitter } from "@angular/core";
import { Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ItemPrescricao } from "./item-prescricao.model";
import { HistoricoSituacao } from "./historico-situacao.model";
import { ItemPrescricaoPesquisa } from "./prescricao-processo/item-prescricao-pesquisa.model";
import { NatInfo } from './nat-info.model';
import { NatJuiz } from './nat-juiz.model';
import { TipoProcedimento } from '@app/judicial/tipo-procedimento/tipo-procedimento.model';
import { MotivoExclusao } from '@app/judicial/motivo-exclusao/motivo-exclusao.model';
import { UnidadeSaude } from '@app/core/unidade-saude/unidade-saude.model';
import { HistoricoObservacaoItemPrescricao } from './historico-observacao-item-prescricao.model';
import { LoginService } from '@app/core/security/login/login.service';
import { ListaJSON } from '@app/core/shared/default/lista-json.model';
import { TipoPrograma } from '@app/core/tipo-programa/tipo-programa.model';
import { DescricaoModel } from '@app/core/shared/descricao/descricao.model';
import { Material } from '@app/core/material/material.model';
import { Valores } from '@app/core/shared/models/valores.model';
import { Profissional } from '@app/core/profissional/profissional.model';
import { InformacaoTecnicaEscolha } from '@app/judicial/informacao-tecnica-escolha/InformacaoTecnicaEscolha.model';
import { Prescricao } from './prescricao.model';
import { SGS_URL, CORE } from '@app/app.api';
import { Paciente } from '../paciente/paciente.model';
import { HistoricoTransferencia } from './historico-transferencia.model';
import { Complemento } from '../material/material-cadastro/complemento/complemento.model';
import { AnaliseTecnicaPrescricaoFilter } from '../shared/models/analise-tecnica-prescricao-filter';
import { ResponseComLength } from '../shared/models/response-com-length.model';

@Injectable({
  providedIn: 'root'
})
export class PrescricaoService {
  private _isVisibledFilter: boolean = false; //utilizado na tela de prescrição pesquisa

  private _desabilitaBotaoNovaObservacao: boolean = false;; //utilizado na tela de prescrição pesquisa

  private _prescricaoSelecionada: Prescricao = this.getNewPrescricao();
  private _listPrescricao: Prescricao[] = [];
  private _listPrescricaoLenght: number = 0;
  private _prescricaoFilter = this.getFilterPrescricao();
  private _prescricaoFilterPaciente = this.getFilterPrescricao();
  private _sortHistoricoSituacao = this.getFilterHistoricoSituacao();
  private _filterPrescricaoNotWithdraw = this.filterPrescricaoNotWithdrawEmpety();
  private _filterPrescricaoPesquisa = this.getfilterPrescricaoPesquisa();
  private _complementoMaterialSelecionado: Complemento;

  //utilizado para chamar/encerrar o modal de tranferencia de prescricao entre unidades de saude
  private _chamarModalTransferencia: boolean = false;

  private _backRouter = 0;
  public BACK_PROCESSO = 0;
  public BACK_PACIENTE = 1;
  public BACK_HOME = 2;
  public BACK_PESQUISA = 3;

  // Internação compulsória (sem laldo médico)
  public laudoMedico: boolean = true;

  item: ItemPrescricao
  histObs: HistoricoObservacaoItemPrescricao

  private _eventUpdateTable: EventEmitter<boolean> = new EventEmitter();
  private _eventListPrescricao: EventEmitter<boolean> = new EventEmitter();
  private _eventUpdatePrescricao: EventEmitter<boolean> = new EventEmitter();
  private _eventLoadingPrescricao: EventEmitter<boolean> = new EventEmitter();
  private _atualizarListaPrescricao: EventEmitter<boolean> = new EventEmitter();

  constructor(private http: HttpClient, private loginService: LoginService, private processoService: ProcessoService) { }

  //busca todas as prescrição de um processo
  public getPrescricaos(): Observable<ListaJSON<Prescricao>> {
    if (this.loginService.infoUser != null) {
      this._prescricaoFilter.listaUnidades = (this.loginService.infoUser != null) ? (this.loginService.infoUser.unidades != null  ? this.getListaIdUnidadeSaude(this.loginService.infoUser.unidades) : null) : null;
      return this.http.post<ListaJSON<Prescricao>>(`${SGS_URL.CORE}/${CORE.PRESCRICAO}/p`, this._prescricaoFilter);
    }
  }

  //busca as unidades de assistencias
  public buscarUnidadeAssistencia(value: string): Observable<UnidadeSaude[]> {
    return this.http.post<UnidadeSaude[]>(`${SGS_URL.CORE}/${CORE.UNIDADE_SAUDE}/buscaUnidadesSaudeByProcedimento`, { nome: value });
  }

  //atualiza a situação do processo de uma prescrição
  public updateSituacaoProcessoFromPrescricao(id_prescricao, id_situacao_processo): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.PRESCRICAO}/update_situacao_processo_from_prescricao`,
      {
        idPrescricao: id_prescricao,
        idSituacaoProcesso: id_situacao_processo,
        idUsuario: id_usuario,
        idReferencia: id_referencia
      });
  }

  //atualiza a situação do processo de uma lista de prescrições
  public updateSituacaoProcessoFromAllPrescricao(list_ids_prescricoes, id_situacao_processo): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.PRESCRICAO}/update_situacao_processo_from_all_prescricao`,
      {
        ids: list_ids_prescricoes,
        idSituacao: id_situacao_processo,
        idUsuario: id_usuario,
        idReferencia: id_referencia
      });
  }

  public geteRelatorioMedicamentosNaoRetirados(dataInicial,dataFinal,lsSituacao,lsUnidade_saude,regional,paciente,medicamento,isPdf,referencia):string {
    return `${SGS_URL.CORE}/${CORE.PRESCRICAO}/getRelatorioMedicamentosNaoRetirados/${dataInicial}/${dataFinal}/${lsSituacao}/${lsUnidade_saude}/${regional}/${paciente}/${medicamento}/${isPdf}/${referencia}`;
  }

  public verificaDadosRelatorioPacienteSemDispensacao(idsRegional, idsUnidadeSaude, dataUltimaDispensacao, idsMedicamento, idsSituacao, idsTipoMedicamento, referencia)
  {
    return this.http.get<Boolean>(`${SGS_URL.CORE}/${CORE.PRESCRICAO}/verificaDadosRelatorioPacienteSemDispensacao/${dataUltimaDispensacao}/${idsRegional}/${idsUnidadeSaude}/${idsMedicamento}/${idsSituacao}/${idsTipoMedicamento}/${referencia}`);
  }

  public getRelatorioPacienteSemDispensacao(idsRegional, idsUnidadeSaude, dataUltimaDispensacao, idsMedicamento, idsSituacao, idsTipoMedicamento, isPdf, referencia):string
  {
    return `${SGS_URL.CORE}/${CORE.PRESCRICAO}/getRelatorioPacienteSemDispensacao/${dataUltimaDispensacao}/${idsRegional}/${idsUnidadeSaude}/${idsMedicamento}/${idsSituacao}/${idsTipoMedicamento}/${isPdf}/${referencia}`;
  }

  public verificaDadosRelatorioMedicamentosNaoRetirados(dataInicial,dataFinal,lsSituacao,lsUnidade_saude,regional,paciente,medicamento,isPdf,referencia):Observable<Boolean> {
    return this.http.get<Boolean>(`${SGS_URL.CORE}/${CORE.PRESCRICAO}/verificaDadosRelatorioMedicamentosNaoRetirados/${dataInicial}/${dataFinal}/${lsSituacao}/${lsUnidade_saude}/${regional}/${paciente}/${medicamento}/${isPdf}/${referencia}`);
  }

  public getLsMedicamentoNaoRetiradoByReferenciaDesc(referencia, descMaterial):Observable<Material[]>{
    return this.http.post<Material[]>(`${SGS_URL.CORE}/${CORE.PRESCRICAO}/getLsMedicamentoNaoRetiradoByReferenciaDesc/`, {"idReferencia": referencia, "descMaterial": descMaterial} );
  }

  public getNovoHistObservacao(): HistoricoObservacaoItemPrescricao {
    this.histObs = new HistoricoObservacaoItemPrescricao("", "", new Date(), this.loginService.user);
    return this.histObs
  }

  public getProfissionaisByGrupoMaterial(id_grupo: string, nome_funcionario: string): Observable<any>
  {
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.PROFISSIONAL}/getProfissionaisporGrupoMaterial`, { grupoMaterial: ""+id_grupo , nomeFuncionario: ""+nome_funcionario});
  }

  public cadastrar(): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.PRESCRICAO}/c/${id_referencia}/${id_usuario}`, this._prescricaoSelecionada);
  }

  public buscaItemPrescricao(idItemPrescricao): Observable<ItemPrescricao> {
    return this.http.get<ItemPrescricao>(`${SGS_URL.CORE}/${CORE.PRESCRICAO}/buscarItemPescricao/${idItemPrescricao}`);
  }

  public buscaPrescricaoById(id: string): Observable<Prescricao> {
    return this.http.post<Prescricao>(`${SGS_URL.CORE}/${CORE.PRESCRICAO}/id/${id}`, {});
  }

  public findPrescricaoById(): Observable<Prescricao> {
    return this.http.post<Prescricao>(`${SGS_URL.CORE}/${CORE.PRESCRICAO}/prescricao`, { id: this.prescricaoSelecionada.id });
  }

  public buscaHistoricoByItem(item_id: string): Observable<HistoricoSituacao[]> {
    return this.http.post<HistoricoSituacao[]>(`${SGS_URL.CORE}/${CORE.PRESCRICAO}/historicos/${item_id}`, {});
  }

  public buscaHistoricoObservacoesByItem(item_id: string): Observable<HistoricoObservacaoItemPrescricao[]> {
    return this.http.post<HistoricoObservacaoItemPrescricao[]>(`${SGS_URL.CORE}/${CORE.PRESCRICAO}/listaObservacoes/${item_id}`, {});
  }

  //busca os tipos de programas de uma prescrição
  public buscaTipoProgramaByNome(nome: string): Observable<TipoPrograma[]> {
    return this.http.post<TipoPrograma[]>(`${SGS_URL.CORE}/${CORE.TIPO_PROGRAMA}/buscaTipoPrograma`, { filter: nome });
  }

  //busca as periodicidades no banco de dados
  public buscaPeriodicidadeByNome(nome: string): Observable<Periodicidade[]> {
    return this.http.post<Periodicidade[]>(`${SGS_URL.CORE}/${CORE.PERIODICIDADE}/buscaPeriodicidadeByNome`, { nome: nome });
  }

  public buscaMedicosByNome(nome: string): Observable<Profissional[]> {
    return this.http.post<Profissional[]>(`${SGS_URL.CORE}/${CORE.PROFISSIONAL}/buscaMedicosByNomeNumeroRegistro`, { nome: nome });
  }

  public buscaEstabelecimentosByNome(nome: string): Observable<DescricaoModel[]> {
    return this.http.post<DescricaoModel[]>(`${SGS_URL.CORE}/${CORE.UNIDADE_ASSISTENCIA}/buscaEstabelecimentosByNome/${nome}`, {});
  }

  public buscaFornecimentosByNome(nome: string): Observable<DescricaoModel[]> {
    return this.http.post<DescricaoModel[]>(`${SGS_URL.JUDICIAL}/${JUDICIAL.TIPO_FORNECIMENTO}/buscaFornecimentosByNome`, { nome: nome });
  }
  public getAllPrescricaoByPaciente(id): Observable<ListaJSON<Prescricao>> {
    this._prescricaoFilterPaciente.listaUnidades = (this.loginService.infoUser != null) ? (this.loginService.infoUser.unidades != null  ? this.getListaIdUnidadeSaude(this.loginService.infoUser.unidades) : null) : null;
    return this.http.post<ListaJSON<Prescricao>>(`${SGS_URL.CORE}/${CORE.PRESCRICAO}/findAllPrescricaoByPaciente`, this._prescricaoFilterPaciente);
  }

  public getAllPrescricoes(): Observable<ListaJSON<Prescricao>> {
    this._filterPrescricaoPesquisa.referencia = this.loginService.user.referencia.id;
    return this.http.post<ListaJSON<Prescricao>>(`${SGS_URL.CORE}/${CORE.PRESCRICAO}/findAllPrescricao`, this._filterPrescricaoPesquisa);
  }

  //busca os tipos de procedimentos contído no banco de dados
  public searchTipoProcedimentoByFilter(filter: string): Observable<TipoProcedimento[]> {
    return this.http.post<TipoProcedimento[]>(`${SGS_URL.JUDICIAL}/${JUDICIAL.TIPO_PROCEDIMENTO}/searchTipoProcedimentoByFilter`, { nome: filter });
  }

  //busca medicamentos filtrando
  buscaMedicamentosByNome(nome: string, tipo?: any): Observable<Material[]> {
    let convertString = tipo != null ? tipo.toString() : null
    return this.http.post<Material[]>(`${SGS_URL.CORE}/${CORE.MATERIAL}/buscaMedicamentosByNome`, { nome: nome, tipo: convertString, referencia: this.loginService.user.referencia.id });
  }

  getAllMovitoExclusao(): Observable<MotivoExclusao[]> {
    return this.http.post<MotivoExclusao[]>(`${SGS_URL.JUDICIAL}/${JUDICIAL.MOTIVO_EXCLUSAO}/buscaMotivos`, {});
  }

  getAllPositioning(): Observable<NatPosicionamento[]> {
    return this.http.get<NatPosicionamento[]>(`${SGS_URL.JUDICIAL}/${JUDICIAL.NAT_POSICIONAMENTO}/get-all-nat-posicionamento-active`);
  }

  getAllInfoNatByPositionNat(): Observable<NatInfo[]> {
    return this.http.post<NatInfo[]>(`${SGS_URL.JUDICIAL}/${JUDICIAL.NAT_INFO}/get-all-nat-info-active`, { posicionamento: this._prescricaoSelecionada.itens[0].nat_posicionamento.id });
  }

  getAllInfoNatByNatJuiz(): Observable<NatJuiz[]> {
    return this.http.get<NatInfo[]>(`${SGS_URL.JUDICIAL}/${JUDICIAL.NAT_JUIZ}/get-all-nat-juiz-active`);
  }

  getAllInfoTec(): Observable<InformacaoTecnicaEscolha[]> {
    return this.http.get<InformacaoTecnicaEscolha[]>(`${SGS_URL.JUDICIAL}/${JUDICIAL.INFO_TEC_NAT}/get-all-info-tec`);
  }

  buscaDescricaoMedicamento(id_material: string): Observable<Valores> {
    return this.http.get<Valores>(`${SGS_URL.CORE}/${CORE.MATERIAL}/buscaDescricaoMedicamento/${id_material}`);
  }

  getItensPrescricaoByProcesso(idProcesso: String): Observable<ItemPrescricaoPesquisa[]> {
    return this.http.post<ItemPrescricaoPesquisa[]>(`${SGS_URL.CORE}/${CORE.PRESCRICAO}/buscarItensPrescricaoByProcesso`, { processo: idProcesso, referencia: this.loginService.user.referencia ? this.loginService.user.referencia.id : null })
  }

  getMotivoBySituacaoProcesso(idSituacaoProcesso: String): Observable<MotivoExclusao[]> {
    return this.http.post<MotivoExclusao[]>(`${SGS_URL.CORE}/${JUDICIAL.MOTIVO_EXCLUSAO}/getMotivoBySituacaoProcesso`, { idSituacaoProcesso: idSituacaoProcesso });
  }

  listaFornecimentos(): Observable<DescricaoModel[]> {
    return this.http.post<DescricaoModel[]>(`${SGS_URL.JUDICIAL}/${JUDICIAL.TIPO_FORNECIMENTO}/listaFornecimentos`, {})
  }

  listaPrescricaoByPacienteMedicamentoSituacao(params: AnaliseTecnicaPrescricaoFilter): Observable<ResponseComLength> {
    return this.http.post<ResponseComLength>(`${SGS_URL.CORE}/${CORE.PRESCRICAO}/listar-prescricao-by-paciente`, {params})
  }

  //busca as prescrições que geram mapas que não foram retirado nos ultimos 3 meses
  public findAllPrescricaoNoWithdrawn(): Observable<ListaJSON<Prescricao>> {
    return this.http.post<ListaJSON<Prescricao>>(`${SGS_URL.CORE}/${CORE.PRESCRICAO}/findAllPrescricaoNoWithdrawn`, this._filterPrescricaoNotWithdraw)
  }

  public findAllPaciente(filtro: string): Observable<Paciente[]> {
    return this.http.post<Paciente[]>(`${SGS_URL.JUDICIAL}/${CORE.PACIENTE}/findAlllPacienteByNameOrCpf`,
      {
        filtro: filtro
      })
  }

  public findPacienteByLsUnidadesSaude(filtro: string, lsUnidadesSaude: string[]): Observable<Paciente[]> {
    return this.http.post<Paciente[]>(`${SGS_URL.JUDICIAL}/${CORE.PACIENTE}/findPacienteByLsUnidadesSaude`, {
      filtro: filtro,
      ls_unidade_saude: lsUnidadesSaude
      })
  }

  //busca os pacientes cadastrados naquela Regional
  public findPacienteByRegional(filtro: string, regional: string): Observable<Paciente[]> {
    let referencia = this.loginService.user && this.loginService.user.referencia? this.loginService.user.referencia.id: null;
    return this.http.post<Paciente[]>(`${SGS_URL.JUDICIAL}/${CORE.PACIENTE}/findPacienteByRegional`, {filtro: filtro, regional: regional, referencia:referencia });
  }

  //busca o histórico de todas as situações de uma prescrição
  getNovoHistoricoSituacaoByPrescricao(): Observable<HistoricoSituacao[]> {
    return this.http.post<HistoricoSituacao[]>(`${SGS_URL.CORE}/${CORE.HISTORICO_SITUACAO}/search-all_situacao-processo-by-prescricao`, this._sortHistoricoSituacao);
  }

  //busca o histórico de todas as transferências de uma prescrição
  getNovoHistoricoTransferenciaByPrescricao(id): Observable<HistoricoTransferencia[]> {
    return this.http.post<HistoricoTransferencia[]>(`${SGS_URL.CORE}/historicoTransferencia/getHistoricoTransferenciaByPrescricao`, { idPrescricao: id });
  }

  //cria um objeto de pesquisa vazio
  getFilterPrescricao() {
    return {
      referencia: this.loginService.user.referencia ? this.loginService.user.referencia.id : null,
      paciente: null,
      processo: null,
      direction: "desc",
      sort: "data_cadastro",
      pageIndex: 0,
      pageSize: 5,
      idUnidadeSaude: (this.loginService.infoUser != null) ? (this.loginService.infoUser.unidadeorigin != null ? this.loginService.infoUser.unidadeorigin.id : null) : null,
      listaUnidades: (this.loginService.infoUser != null) ? (this.loginService.infoUser.unidades != null  ? this.getListaIdUnidadeSaude(this.loginService.infoUser.unidades) : null) : null,
    }
  }

  //cria uma prescrição vazia
  public getNewPrescricao() {
    this.laudoMedico = true;
    return new Prescricao(null,
                          this.processoService.processo,
                          new Date(),
                          null,
                          1,
                          this.loginService.user.referencia,
                          [new ItemPrescricao(
                                              null,
                                              null,
                                              null,
                                              null,
                                              null,
                                              null,
                                              null,
                                              null,
                                              null,
                                              null,
                                              null,
                                              null,
                                              null,
                                              null,
                                              null,
                                              null,
                                              null,
                                              [new HistoricoObservacaoItemPrescricao(null, null, new Date(), this.loginService.user)],
                                              true,
                                              null,
                                              null,
                                              null,
                                              null,
                                              null,
                                              null,
                                              null,
                                              null,
                                              null,
                                              null,
                                              false,
                                              0,
                                              0
                          )],
                          null,
                          null,
                          true,
                          this.loginService.user,
                          [],
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          false);
  }

  public getFilterHistoricoSituacao() {
    return {
      prescricao: "",
      direction: 'desc',
      active: 'data_cadastro',
      pageIndex: 0,
      pageSize: 300,
    }
  }

  public filterPrescricaoNotWithdrawEmpety() {
    return {
      referencia: this.loginService.user.referencia ? this.loginService.user.referencia.id : null,
      paciente: "",
      medicamento: "",
      unidade_saude: "",
      direction: 'asc',
      active: 'medicamento',
      pageIndex: 0,
      pageSize: 5,
    }
  }

  buscaMedicamentosByReferenciaMaterialVinculado(nome: string, tipo?: any): Observable<Material[]> {
    let convertString = tipo != null ? tipo.toString() : null
    return this.http.post<Material[]>(`${SGS_URL.CORE}/${CORE.MATERIAL}/buscaMedicamentosByReferenciaMaterialVinculado`, { nome: nome, tipo: convertString, referencia: this.loginService.user.referencia.id });
  }

  buscaMedicamentosIrrestrito(nome: string, tipo?: any): Observable<Material[]> {
    let convertString = tipo != null ? tipo.toString() : null
    return this.http.post<Material[]>(`${SGS_URL.CORE}/${CORE.MATERIAL}/buscaMedicamentosByReferenciaMaterialVinculado`, { nome: nome, tipo: convertString });
  }

  public getfilterPrescricaoPesquisa() {
    return {
      referencia: this.loginService.user.referencia ? this.loginService.user.referencia.id : null,
      unindade_saude_id: null,
      medicamento: "",
      paciente: "",
      unidade_saude: "",
      municipio: "",
      dt_inicio: null,
      direction: 'asc',
      active: 'medicamento',
      ls_descricoes: [],
      pageIndex: 0,
      pageSize: 5,
    }
  }

  // Transferir Prescricao para outra Unidade de Saude
  public tranferirPrescricao(data: Date, idUnidadeSaude: string, idPrescricao: string): Observable<any> {

    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id;

    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.PRESCRICAO}/transferirPrescricao`, {
      idReferencia: id_referencia, idUsuario: id_usuario, idUnidadeSaude: idUnidadeSaude, idPrescricao: idPrescricao, data: data
    });
  }

  //busca as unidades de saude que estão vinculadas a regional
  public buscarUnidadeSaudeVinculo(value: string): Observable<UnidadeSaude[]> {
    const id_referencia = this.loginService.user.referencia.id;
    return this.http.post<UnidadeSaude[]>(`${SGS_URL.CORE}/${CORE.UNIDADE_SAUDE}/buscaUnidadesSaudeVinculo`, { nome: value, referencia: id_referencia });
  }

  private getListaIdUnidadeSaude(list: UnidadeSaude[]): number[] {
    var listaId: number[] = [];
    for (var us of list) {
      listaId.push(Number(us.id));
    }
    return listaId;
  }

  /*
  * Desabilita requisição dos campos de Prescritor e Data de prescrição caso a prescrição não tenha laudo médico
  * por conta de Internação compulsória, por exemplo, no caso do "Objeto da prescrição" ser um
  * procedimento (e não um medicamento)
  * Regra de 10/08/2021, tarefa #9280
  */
  public verificaLaudoMedico () {
    this.laudoMedico = true;
    // Se o tipo do objeto da prescrição é procedimento (ID 2)
    if (this.prescricaoSelecionada.objeto == 2) {
      this.laudoMedico = false;
    }
  }

  /**==========================[Métodos Sets e Gets]===============================**/

  public get prescricaoSelecionada(): Prescricao {
    return this._prescricaoSelecionada;
  }
  public set prescricaoSelecionada(value: Prescricao) {
    this._prescricaoSelecionada = value;
  }
  public get listPrescricao(): Prescricao[] {
    return this._listPrescricao;
  }
  public set listPrescricao(value: Prescricao[]) {
    this._listPrescricao = value;
  }
  public get prescricaoFilter() {
    return this._prescricaoFilter;
  }
  public set prescricaoFilter(value) {
    this._prescricaoFilter = value;
  }
  public get listPrescricaoLenght(): number {
    return this._listPrescricaoLenght;
  }
  public set listPrescricaoLenght(value: number) {
    this._listPrescricaoLenght = value;
  }
  public get sortHistoricoSituacao() {
    return this._sortHistoricoSituacao;
  }
  public set sortHistoricoSituacao(value) {
    this._sortHistoricoSituacao = value;
  }
  public get backRouter() {
    return this._backRouter;
  }
  public set backRouter(value) {
    this._backRouter = value;
  }
  public get prescricaoFilterPaciente() {
    return this._prescricaoFilterPaciente;
  }
  public set prescricaoFilterPaciente(value) {
    this._prescricaoFilterPaciente = value;
  }
  public get filterPrescricaoNotWithdraw() {
    return this._filterPrescricaoNotWithdraw;
  }
  public set filterPrescricaoNotWithdraw(value) {
    this._filterPrescricaoNotWithdraw = value;
  }
  public get eventUpdateTable(): EventEmitter<boolean> {
    return this._eventUpdateTable;
  }
  public set eventUpdateTable(value: EventEmitter<boolean>) {
    this._eventUpdateTable = value;
  }
  public get eventListPrescricao(): EventEmitter<boolean> {
    return this._eventListPrescricao;
  }
  public set eventListPrescricao(value: EventEmitter<boolean>) {
    this._eventListPrescricao = value;
  }
  public get filterPrescricaoPesquisa() {
    return this._filterPrescricaoPesquisa;
  }
  public set filterPrescricaoPesquisa(value) {
    this._filterPrescricaoPesquisa = value;
  }
  public get isVisibledFilter(): boolean {
    return this._isVisibledFilter;
  }
  public set isVisibledFilter(value: boolean) {
    this._isVisibledFilter = value;
  }
  public get desabilitaBotaoNovaObservacao(): boolean {
    return this._desabilitaBotaoNovaObservacao;
  }
  public set desabilitaBotaoNovaObservacao(value: boolean) {
    this._desabilitaBotaoNovaObservacao = value;
  }
  public get eventUpdatePrescricao(): EventEmitter<boolean> {
    return this._eventUpdatePrescricao;
  }
  public set eventUpdatePrescricao(value: EventEmitter<boolean>) {
    this._eventUpdatePrescricao = value;
  }
  public get eventLoadingPrescricao(): EventEmitter<boolean> {
    return this._eventLoadingPrescricao;
  }
  public set eventLoadingPrescricao(value: EventEmitter<boolean>) {
    this._eventLoadingPrescricao = value;
  }
  public get chamarModalTransferencia(): boolean {
    return this._chamarModalTransferencia;
  }
  public set chamarModalTransferencia(value: boolean) {
    this._chamarModalTransferencia = value;
  }
  public get atualizarListaPrescricao(): EventEmitter<boolean> {
    return this._atualizarListaPrescricao;
  }
  public set atualizarListaPrescricao(value: EventEmitter<boolean>) {
    this._atualizarListaPrescricao = value;
  }
  public get complementoMaterialSelecionado(): Complemento {
    return this._complementoMaterialSelecionado;
  }
  public set complementoMaterialSelecionado(value: Complemento) {
    this._complementoMaterialSelecionado = value;
  }
}
