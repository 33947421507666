import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JUDICIAL, SGS_URL } from '@app/app.api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrigemDestinoService {

  public lsIdsSelecionadosMultiSelect: any[] = [];
  public haItensSelecionadosMultiSelect = false;

  constructor(private http: HttpClient) { }

  public getOrigemDestinoByDescricao(descricao: string): Observable<any> {
    return this.http.post<any>(`${SGS_URL.CORE}/${JUDICIAL.ORIGEM_DESTINO}/getOrigemDestinoByDescricao/`, {descricao: descricao});
  }

  public getNewLsIdsSelecionados() {
    this.lsIdsSelecionadosMultiSelect = [];
  }
}
