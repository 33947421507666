export class PrescricaoTuberculoseIndicacao
{
    constructor(
        public id:string, 
        public titulo:string,
        public descricao:string,
        public status:Boolean,
        public questionario_prova_tuberculina:Boolean,
        public num_indicacao_questionario: Number
        ){}
}