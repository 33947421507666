import { PacienteService } from './../../paciente/paciente.service';
import { MessageService } from './../../shared/message.service';
import { VerificarService } from '../../shared/verificar/verificar.service';
import { EnderecoService } from './../../shared/endereco/endereco.service';
import { MunicipioService } from './../../shared/municipio/municipio.service';
import { DispensacaoService } from './../../dispensacao/dispensacao.service';
import { ResponsavelService } from './../responsavel.service';
import { MatDialogRef } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { Logradouro } from '@app/core/shared/endereco/logradouro.model';
import { LogradouroFiltro } from '@app/core/shared/endereco/logradouro-filtro.model ';
import { Municipio } from '@app/core/regional/municipio.model';
import { ResponsavelEntity } from '../responsavel-entity.model';
import { PessoaFisica } from '@app/core/paciente/pessoa-fisica.model';
import { Pessoa } from '@app/core/paciente/pessoa.model';
import { Option } from '@app/core/shared/models/option.model';
import { Responsavel } from '../responsavel.model';

@Component({
  selector: 'app-responsavel-cadastro-modal',
  templateUrl: './responsavel-cadastro-modal.component.html',
  styleUrls: ['./responsavel-cadastro-modal.component.scss']
})
export class ResponsavelCadastroModalComponent implements OnInit {

  private municipios: Array<Municipio>;
  private pessoaFisicaList: Array<PessoaFisica>;
  private loading: boolean;
  public parentescos: Array<Option> = [
    { chave: '1', valor: 'Pai' },
    { chave: '2', valor: 'Mãe' },
    { chave: '7', valor: 'Filho(a)' },
    { chave: '3', valor: 'Avós' },
    { chave: '4', valor: 'Irmãos' },
    { chave: '5', valor: 'Cônjuge' },
    { chave: '6', valor: 'Outros' }
  ]

  constructor(
    private dialogRef: MatDialogRef<ResponsavelCadastroModalComponent>,
    private responsavelService: ResponsavelService,
    private dispensacaoService: DispensacaoService,
    private enderecoService: EnderecoService,
    private verificarService: VerificarService,
    private messageService: MessageService,
    private municipioService: MunicipioService,
    private pacienteService: PacienteService
  ) { }

  ngOnInit() {
    this.responsavelService.setResponsavel(this.responsavelService.getNovoResponsavel());
  }
  
  public limparFormularioResponsavel(): void {
    this.responsavelService.responsavel = this.responsavelService.getNovoResponsavel();
  }

  public close(): void {
    this.limparFormularioResponsavel();
    this.dialogRef.close();
  }

  public cadastrarResponsavel(): void {

    this.verificaLogradouroAdicional()
    this.responsavelService.cadastrar(this.responsavelService.responsavel).subscribe(id => {
      this.responsavelService.responsavel.id = id.id
      this.messageService.addToastModal("Sucesso","Responsável cadastrado com sucesso!", 5, "success");
      this.vincularResponsavelAoPaciente();
      this.close();
    }, response => {
      if (response && response.error && response.error.descricao) {
        this.messageService.addToastModal("Erro!", response.error.descricao, 5, "error");
      }
    })
  }

  public vincularResponsavelAoPaciente(): void {

    //Adiciona o novo responsável criado ao array de responsáveis pelo paciente
    let responsavel = this.responsavelService.responsavel;
    this.pacienteService.paciente = this.dispensacaoService.dispensacaoSelecionada.paciente;
    this.pacienteService.paciente.autorizados.push(new ResponsavelEntity(responsavel.id, responsavel.cns, responsavel.parentesco, responsavel.telefone, responsavel.celular, true,
      new PessoaFisica(null, responsavel.cpf, responsavel.rg, null, null, null, null, null, null, null, null,
        new Pessoa(null, responsavel.nome, null, null), null))
    );

    //Envia para o backend o paciente selecionado, juntamente com o novo responsável
    this.pacienteService.cadastrar(this.pacienteService.paciente).subscribe(resp => {
      this.messageService.addToastModal("Sucesso","Responsável vinculado ao paciente", 5, "success");
      this.atualizaListaAutorizados();
    }, error => {
      this.messageService.addToastModal("Aviso","Necessário vincular o responsável manualmente em Cadastros -> Cadastro de paciente", 5, "warning");
    })
  }

  displayLogradouro(logr: Logradouro): string | undefined {
    if (logr) {
      return (typeof logr === "string") ? logr : logr.logradouro + ' - ' + logr.cep
    }
    return ""
  }

  displayMunicipio(mun?): string {
    if (mun) {
      return typeof mun === "string" ? mun : mun.nome
    }
    return ""
  }

  verificaCampo(): boolean {
    return this.responsavelService.responsavel.endereco.nome_logradouro == null || this.responsavelService.responsavel.endereco.nome_logradouro == "" ? false : true;
  }

  filterCep(cep: string) {
    if (cep.length == 8) {
      this.loading = true;
      this.enderecoService.buscarLogradouro(cep).subscribe(logradouro => {
        this.responsavelService.responsavel.endereco.logradouro = logradouro
        this.loading = false
      })
    } else {
      this.responsavelService.responsavel.endereco.logradouro = { id: "", logradouro: "", bairro: "", municipio: "", uf: "", tipo: "", cep: "" }
    }
  }


  verificaCpf() {
    this.verificarService.verificaCpf(this.responsavelService.responsavel.cpf, "responsavel").subscribe(resp => { resp },
      response => {
        this.messageService.addToastModal("Erro!", response.error.descricao, 5, "error");
      }
    )
  }

  verificaCns() {
    this.verificarService.verificaCns(this.responsavelService.responsavel.cns, "responsavel").subscribe(resp => {
      if (resp) { resp }
    }, response => {
      if (response) {
        // verificação do atributo error do Response para evitar erro de undefined e disparar toastr "Sistema Off..." (tarefa 8871)
        if (response.error && response.error.descricao) {
          this.messageService.addToastModal("Erro!", response.error.descricao, 5, "error");
        }
      }
    }
    )
  }

  filterMunicipio(event) {
    if (typeof event === "string" && event !== "") {
      let uf = this.responsavelService.responsavel.endereco.logradouro.uf;
      this.municipioService.searchLocale(event, uf).subscribe(data => this.municipios = data)
    }
  }

  retiraAcentos(event) {
    return String.fromCharCode(event.charCode).match(/[^a-zA-Z0-9 ]/g) === null
  }

  verificaLogradouroAdicional() {
    if (this.responsavelService.hasLogradouro == true) {
      this.responsavelService.responsavel.endereco.nome_logradouro = this.responsavelService.responsavel.endereco.logradouro.logradouro;
      this.responsavelService.responsavel.endereco.logradouro.logradouro = "";
      this.responsavelService.hasLogradouro = false;
    } else {
      this.responsavelService.responsavel.endereco.nome_logradouro !== "" ? this.responsavelService.responsavel.endereco.nome_logradouro = "" : ""
    }
    this.responsavelService.responsavel.endereco.nome_logradouro = this.responsavelService.responsavel.endereco.logradouro.logradouro;
    this.responsavelService.responsavel.endereco.nome_bairro = this.responsavelService.responsavel.endereco.logradouro.bairro;
    this.responsavelService.responsavel.endereco.cep = this.responsavelService.responsavel.endereco.logradouro.cep;
    
  }

  public atualizaListaAutorizados(): void {
    this.pacienteService.buscaPaciente(this.dispensacaoService.dispensacaoSelecionada.paciente.id).subscribe(resp => {
      if (resp) {
        this.pessoaFisicaList = [];
        resp.autorizados.forEach(autorizado => {
          this.pessoaFisicaList.push(autorizado.pessoaFisica);
        })
        this.dispensacaoService.dispensacaoSelecionada.retiradoPor = this.pessoaFisicaList[this.pessoaFisicaList.length - 1];
      }
    })
  }

  setMunicipio(event) {
    this.responsavelService.responsavel.endereco.logradouro.id = event.id;
    this.responsavelService.responsavel.endereco.logradouro.municipio = event.nome;
    this.responsavelService.responsavel.endereco.logradouro.uf = event.uf;
    this.responsavelService.responsavel.endereco.logradouro.tipo = '4';
    this.responsavelService.responsavel.endereco.logradouro.cep = "00000000";
    this.responsavelService.responsavel.endereco.logradouro.logradouro = "";
    this.responsavelService.responsavel.endereco.logradouro.bairro = "";
    this.municipios = []
  }

  setLogradouro(event: Logradouro) {
    if (event != null && typeof event === 'object') {
      this.responsavelService.responsavel.endereco.logradouro = event
      this.logradouros = []
    }
  }

  private logradouros: Array<LogradouroFiltro>;
  filterLogradouro(event) {
    if (typeof event === "string" && event !== "" && event.length > 4 && event.length % 3 == 0) {
      this.loading = true;
      let filtro: LogradouroFiltro = { logradouro: "", bairro: "", municipio: "", uf: "" }
      filtro.logradouro = this.responsavelService.responsavel.endereco.logradouro.logradouro
      filtro.bairro = this.responsavelService.responsavel.endereco.logradouro.bairro
      filtro.municipio = this.responsavelService.responsavel.endereco.logradouro.municipio
      filtro.uf = this.responsavelService.responsavel.endereco.logradouro.uf

      this.enderecoService.buscarLogradourosByFiltro(filtro).subscribe(logradouros => {
        this.logradouros = logradouros
        this.logradouros.length === 0 ? this.responsavelService.hasLogradouro = true : this.responsavelService.hasLogradouro = false
        this.loading = false
      })
    }
  }

  public verificaLogradouro(): boolean {
    if (this.responsavelService.responsavel.endereco.logradouro.logradouro == null) {
      return true;
    }
    return this.responsavelService.responsavel.endereco.logradouro.logradouro.length > 0 ? false : true;
  }

}
