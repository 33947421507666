import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  private params = [];

  @Input() title: string = "Título";        //título da caixa de confirmação
  @Input() message: string  = "Mensagem";   //pergunta da caixa de confirmação
  @Input() lista: any [] = [];              //Linhas para detalhar o item a ser excluidos
  @Input() btnOkText: string = 'Sim';       //nome do botão OK
  @Input() btnCancelText: string = 'Não';   //nome do botão Cancel
  @Input() display:boolean = false;         //Vizibilidade do Painel [true = visible][false = hidden]
  @Output("confirm") confirmEvent = new EventEmitter<boolean>();  //Adicionar evento no botão OK
  @Output("cancel") cancelEvent   = new EventEmitter<boolean>();  //Adicionar evento no botão OK

  @Input() exibirbtnOK:boolean = true;
  @Input() exibirbtnNot:boolean = true;
  
  constructor() { }

  ngOnInit() 
  { }

  displayeItem(item)
  {
    if(item == null)    return item;
    if(item.length < 5) return item;
    if(item.substring(0, 5) == '<red>') return item.substring(5);
    return item;
  }

  getClass(item)
  {   
    if(item == null)    return "";
    if(item.length < 5) return "";
    if(item.substring(0, 5) == '<red>') return "text-danger font-weight-bold";

    return "";
  }

  accept()
  {
    this.confirmEvent.emit(true);
  }

  decline()
  {
    this.confirmEvent.emit(false);
  }

  onClose() 
  {
    this.cancelEvent.emit(false);  
  }
  
}
