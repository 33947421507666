
import { Paciente } from "@app/core/paciente/paciente.model";import { ProfissionalEntity } from "@app/core/profissional/profissional-entity.model";
import { Reu } from "./reu.model";

export class Processo {
  constructor(
              public id: string,    
              public autor:Paciente,    
              public num_processo: string,               
              public advogado_autor:ProfissionalEntity,
              public juiz_autor:ProfissionalEntity,       
              public tipo_acao:string,
              public justica:string,
              public reus:Reu,
              public uniao:string,
              public municipio:string,
              public data_inclusao:Date
              ) {}
  }
