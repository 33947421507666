import { Paginador } from '@app/core/shared/models/paginador.model';

export class ClassificacaoSubstanciaAnvisaFilter extends Paginador{
  public descricao : string;
  public codigo : string;
  public paginar : boolean = true;

  constructor(){
    super();
  };
}
