import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MapaJudicialService } from "@app/core/mapa-planejamento/mapa-judicial.service";
import { ParametrizacaoQuestoesAnaliseTecnicaService } from "@app/core/nat/parametrizacao-questoes-analise/parametrizacao-questoes-analise-tecnica.service";
import { ParametrizacaoQuestoesAnaliseTecnicaFilter } from "@app/core/shared/models/parametrizacao-questoes-analise-tecnica-filter";
import { ParametrizacaoQuestoesAnaliseTecnicaResponse } from "@app/core/shared/models/parametrizacao-questoes-analise-tecnica-response";
import { SituacaoProcesso } from "@app/core/situacao-processo/situacao-processo.model";
import { SituacaoProcessoService } from "@app/core/situacao-processo/situacao-processo.service";

@Component({
  selector: "ses-situacao-processo-autocomplete",
  templateUrl: "./situacao-processo-autocomplete.component.html",
  styleUrls: ["./situacao-processo-autocomplete.component.scss"],
})
export class SituacaoProcessoAutocompleteComponent implements OnInit {
  @Input() required: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();
  @Input() situacaoProcessoSelecionada: SituacaoProcesso;

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */

  @Output() idSelecionada = new EventEmitter<string>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any>();

  constructor(private situacaoProcessoService: SituacaoProcessoService) {}

  public lsSituacaoProcesso: any[] = [];

  ngOnInit() {
    this.situacaoProcessoService.buscaSituacaoProcessoByReferencia().subscribe({
      next: (response) => {
        this.lsSituacaoProcesso = response;
      },
    });
    this.limparEvent.subscribe({
      next: () => {
        this.limpar();
      },
    });
  }

  public selecionarParametrizacaoQuestoes() {
    this.idSelecionada.emit(
      this.situacaoProcessoSelecionada
        ? this.situacaoProcessoSelecionada.id + ""
        : null
    );
    this.elementosCompletoSelecionadas.emit(
      this.situacaoProcessoSelecionada
    );
  }

  public filter(name: string): any[] {
    let filterValue = name.toLowerCase();
    if (name !== "") {
      return this.lsSituacaoProcesso.filter((option) =>
        option.descricao.toLowerCase().includes(filterValue)
      );
    }
    return this.lsSituacaoProcesso;
  }

  public limpar() {
    this.situacaoProcessoSelecionada = null;
    this.idSelecionada.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  public displayFn(posicao: any) {
    return posicao ? posicao.descricao : "";
  }
}
