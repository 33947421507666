export class MaterialPesquisa {

  constructor(
    public descricao: string,    
    public cdciasc: string,
    //public cdses: string,
    public descricaoComercial: string,
    public pageIndex: number,
    public pageSize: number,
    public sort: string,
    public direction: string) { }
    
}
