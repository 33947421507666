import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from "@angular/core";
import { Observable } from 'rxjs';
import { InputFilter } from './dashbotd_modal_filtro/inputs/input-filter.model';
import { LoginService } from "../security/login/login.service";
import { Dashbord } from './dashbord.model';
import { SGS_URL, CORE } from '@app/app.api';

/*
ID CHARTS
1 - getBuscarPacientesAtivos();
2 - getBuscarSequestroValoresPacientesAtivos();
3 - getMovimentacaoEntradaRegional();
*/
@Injectable()
export class DashbordService {

  eventDashUpdate = new EventEmitter();
  public panelOpen:string = 'paciente';
  public graficosSelecionado: Dashbord;
  public filtrosAlterados: [InputFilter];

  constructor(private http: HttpClient, private loginService: LoginService, ) { }

  getBuscarPacientesAtivos(listFilters): Observable<Dashbord> {
    return this.http.post<Dashbord>(`${SGS_URL.CORE}/${CORE.DASHBORD}/buscar_pacientes_ativos`, {filtros: listFilters})
  }

  getBuscarSequestroValoresPacientesAtivos(listFilters): Observable<Dashbord> {
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.DASHBORD}/sequestro_valores_pacientes_ativos`, {filtros: listFilters})
  }

  getMovimentacaoEntradaRegional(): Observable<Dashbord> {
    return this.http.get<Dashbord>(`${SGS_URL.CORE}/${CORE.DASHBORD}/movimentacao_entrada_regional`)
  }
}
