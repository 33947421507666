import { NavegacaoService } from '@app/core/navegacao/navegacao.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';
import { Material } from '@app/core/material/material.model';
import { MaterialService } from '@app/core/material/material.service';
import { MaterialPesquisa } from '@app/core/material/material-pesquisa/material-pesquisa.model';

@Component({
  selector: 'app-modal-medicamento',
  templateUrl: './modal-medicamento.component.html',
  styleUrls: ['./modal-medicamento.component.scss']
})

export class ModalMedicamentoComponent implements OnInit, AfterViewInit {
  private internalActionButtons = [];
  materiais: Material[] = [];
  displayedColumns: string[] = ['descricao', 'descricaoComercial', 'codCiasc'];
  dataSource = new MatTableDataSource<Material>(this.materiais);
  length = 0;
  public loading = false;
  public materialSelecionado: Material
  filtro = ""
  descricao = null;
  descricaoComercial = null;
  cdciasc = null;
  cdses = null;

  @ViewChild(MatSort) matSort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private router: Router,
    private materialService: MaterialService,
    public activeModal: NgbActiveModal,
    private navegacaoService: NavegacaoService) {
  }

  pesquisarMateriais() {
    this.loading = true;
    let fm = new MaterialPesquisa(
      this.descricao,
      this.cdciasc,
      this.cdses,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.matSort.active,
      this.matSort.direction)

    this.materialService.listar(fm).subscribe(response => {
      this.materialService.materiais = response.list;
      this.paginator.length = response.length !== 0 ? response.length : 0;
      this.dataSource = new MatTableDataSource<Material>(this.materiais)
      this.dataSource.sort = this.matSort;
      this.loading = false;
    })

  }

  listar(filtro) {
  }

  limpar() {
    this.materiais = [];
  }

  //TODO Usar o filterPredicate pra filtrar colunas especificas
  filtrar(filtro: string) {
    //this.dataSource.filter = filtro.trim().toLowerCase();
    this.filtro = filtro
    this.pesquisarMateriais()
  }

  ngOnInit() {
    this.paginator.pageSize = 5;
    this.pesquisarMateriais();
    this.paginator._intl.itemsPerPageLabel = "Itens por página"
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.pesquisarMateriais())).subscribe();

  }

  seleciona(material) {

    if (this.navegacaoService.copiarDados) {
      return;
    }

    this.materialService.material = material;
    this.activeModal.close()
  };




}
