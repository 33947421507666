import { NavegacaoService } from './../../../core/navegacao/navegacao.service';
import { ProtocoloService } from './../protocolo.service';
import { MatDialogRef, MatPaginator, MatTableDataSource } from '@angular/material';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Protocolo } from '../protocolo.model';
import { TipoDocumento } from '@app/judicial/tipo-documento/tipo-documento.model';
import { tap } from 'rxjs/operators';
import { Objeto } from '../objeto.model';
import { DescricaoModel } from '@app/core/shared/descricao/descricao.model';

@Component({
  selector: 'app-modal-modelos-protocolo',
  templateUrl: './modal-modelos-protocolo.component.html',
  styleUrls: ['./modal-modelos-protocolo.component.scss']
})
export class ModalModelosProtocoloComponent implements OnInit, AfterViewInit {

  private colunasExibidas = ['dataDeRecebimento', 'origemDestino', 'objeto', 'tipoDoDocumento', 'SGPe'];
  private dataSourceModelosDisponiveis: MatTableDataSource<Protocolo>;

  @ViewChild("paginadorModelosDisponiveis") private paginadorModelosDisponiveis: MatPaginator;
  private tamanhoDoPaginador: number;

  private origensDestinos: DescricaoModel[];

  objetos: Objeto[] = [
    { id: '1', descricao: 'MEDICAMENTO' },
    { id: '2', descricao: 'PROCEDIMENTO' },
    { id: '3', descricao: 'MEDICAMENTO/PROCEDIMENTO' }
  ];

  constructor(
    private dialogRef: MatDialogRef<ModalModelosProtocoloComponent>,
    private protocoloService: ProtocoloService,
    private navegacaoService: NavegacaoService
  ) { }

  ngOnInit() {
    this.getProtocolos();
  }

  ngAfterViewInit() {
    this.paginadorModelosDisponiveis.page.pipe(tap(() => this.getProtocolos())).subscribe();
  }

  public close(): void {
    this.dialogRef.close();
  }

	public copiarDados(dado: any) {

    if (this.navegacaoService.copiarDados) {
      return;
    }

    if (dado.tipo_protocolo == "1") {
      this.getOrigensMedicamentos(true);
    } else {
      this.getOrigensMedicamentos(false);
    }

		this.protocoloService.protocolo.tipo_protocolo = dado.tipo_protocolo;
		this.protocoloService.protocolo.data_recebimento = dado.data_recebimento;
		this.protocoloService.protocolo.tipo_documento = dado.tipo_documento;
		this.protocoloService.protocolo.sgp_e = dado.sgp_e;
    this.protocoloService.protocolo.origem_destino = dado.origem_destino;

		//Necessário converter de novo por que o campo Objeto de protocolo espera um número e não uma palavra
    this.objetos.forEach(objeto => {
      if (dado.objeto == objeto.descricao) {
        this.protocoloService.protocolo.objeto = objeto.id;
      }
    });

    //fecha a modal após copiar os dados
    this.dialogRef.close(true);

	}

  getOrigensMedicamentos(origemDestino: boolean) {
    this.protocoloService.getOrigemDestino(origemDestino).subscribe(resut => {
      this.origensDestinos = resut
    })
  }

  public getProtocolos(): void {

    this.protocoloService.protocoloFilter.pageSize = this.paginadorModelosDisponiveis ? this.paginadorModelosDisponiveis.pageSize : 5;
    this.protocoloService.protocoloFilter.pageIndex = this.paginadorModelosDisponiveis ? this.paginadorModelosDisponiveis.pageIndex : 0;
    this.protocoloService.protocoloFilter.sort = "data_cadastro";
    this.protocoloService.protocoloFilter.direction = "desc";

    this.protocoloService.protocoloFilter.pageSize = 5;

    this.protocoloService.getProtocolosByProcesso().subscribe(resp => {

      if (resp.list.length > 0) {
        resp.list.forEach(protocolo => {
          protocolo.data_recebimento = new Date(protocolo.data_recebimento);
          if (protocolo.tipo_documento == null) {
            protocolo.tipo_documento = new TipoDocumento(null, null, null, null, null);
          }

          // Foi preciso converter o campo objeto para ficar legível para o usuário
          for (let i = 0; i < resp.list.length; i++) {
            for (let j = 0; j < this.objetos.length; j++) {
              if (resp.list[i].objeto == this.objetos[j].id) {
                resp.list[i].objeto = this.objetos[j].descricao;
              }
            }
          }

        })

        this.paginadorModelosDisponiveis.length = resp.length;
        this.dataSourceModelosDisponiveis = new MatTableDataSource<Protocolo>(resp.list);

      }

    });
  }

}
