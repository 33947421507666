import { PessoaFisica } from '../paciente/pessoa-fisica.model';
export class ResponsavelEntity
{
    constructor (        
        public id:string,
        public cns:string,
        public id_parentesco:string,
        public telefone:string,
        public celular:string,
        public status:boolean,
        public pessoaFisica:PessoaFisica
    )
    {}    
}