import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { RegionalService } from "@app/core/regional/regional.service";
import { LoginService } from "@app/core/security/login/login.service";
import { GetAllByStatusFilter } from "@app/core/shared/models/get-all-by-status.filter.model";

@Component({
  selector: "ses-regional-autocomplete",
  templateUrl: "./regional-autocomplete.component.html",
  styleUrls: ["./regional-autocomplete.component.scss"],
})
export class RegionalAutocompleteComponent implements OnInit {
  @Input() filtroNivel: boolean = true;
  @Input() required: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();
  @Input() id:string = "regional"

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */
  @Output() idSelecionada = new EventEmitter<string>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any>();

  constructor(
    private loginService: LoginService,
    private regionalService: RegionalService
  ) {}

  public regionalSelecionada: any;
  public lsRegional: any[] = [];

  ngOnInit() {
    if (this.loginService.infoUser.regionais) {
      if (this.filtroNivel) {
        this.lsRegional = this.loginService.infoUser.regionais;
      } else {
        this.carregaRegionais();
      }
    }

    this.limparEvent.subscribe({
      next: (resp) => {
        this.limpar();
      },
    });
  }

  public carregaRegionais() {
    let _filter: GetAllByStatusFilter = new GetAllByStatusFilter();
    _filter.referencia = this.loginService.user.referencia.id;

    this.regionalService.getAllByStatus(_filter).subscribe({
      next: (resp) => {
        this.lsRegional = resp;
      },
    });
  }

  public filter(name: string): any[] {
    let filterValue = name.toLowerCase();
    if (name !== "") {
      return this.lsRegional.filter((option) =>
        option.descricao.toLowerCase().includes(filterValue)
      );
    }
    return this.lsRegional;
  }

  public limpar() {
    this.regionalSelecionada = null;
    this.idSelecionada.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  public selecionarRegional() {
    this.idSelecionada.emit(this.regionalSelecionada.id);
    this.elementosCompletoSelecionadas.emit(this.regionalSelecionada);
  }

  public displayFn(regional: any) {
    return regional ? regional.descricao : "";
  }
}
