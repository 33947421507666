import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicamentoPickListComponent } from './medicamento-pick-list/medicamento-pick-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule, MatTableModule, MatAutocompleteModule, MatIconModule } from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMaskModule } from 'ngx-mask';
import { ToastaModule } from 'ngx-toasta';
import { ButtonsModule } from '../../buttons/buttons.module';
import { DialogPersonalModule } from '../../dialog-personal/dialog.personal.module';
import { MaterialImportBasicModule } from '../../material-import-basic.module';
import { PipeModule } from '../../pipe/pipe.module';
import { ComponentsModule } from '../components.module';
import {PickListModule} from 'primeng/picklist';
import { CidPickListComponent } from './cid-pick-list/cid-pick-list.component';

@NgModule({
  declarations: [MedicamentoPickListComponent, CidPickListComponent],
  exports:[MedicamentoPickListComponent, CidPickListComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialImportBasicModule,
    MatStepperModule,
    CurrencyMaskModule,
    NgxMaskModule,
    ButtonsModule,
    PipeModule,
    NgxLoadingModule,
    ToastaModule,
    DialogPersonalModule,
    MatMomentDateModule,
    ComponentsModule,
    MatTableModule,
    MatAutocompleteModule,
    MatIconModule,
    PickListModule,
  ],
})
export class PickListSesModule { }
