import { MessageService } from './../../shared/message.service';
import { Router } from '@angular/router';
import { UsuarioService } from '@app/core/usuario/usuario.service';
import { LoginService } from '@app/core/security/login/login.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Referencia } from '@app/core/centro-custo/Referencia.model';
import { environment } from 'src/environments/environment';
import { WebStorageService } from 'angular-webstorage-service';
import { MatMenuTrigger } from '@angular/material';

@Component({
  selector: 'app-modulos',
  templateUrl: './modulos.component.html',
  styleUrls: ['./modulos.component.scss']
})
export class ModulosComponent implements OnInit
{

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  private titleHeaderStatus:string = ""
  private titleFooterStatus:string;
  private isVisibledTitle:boolean = false;
  private isLoading = false;
  readonly environment_name: string = environment.name;

  constructor(
    private loginService:LoginService,
    private userService:UsuarioService,
    private router:Router,
    private messageService:MessageService) { }

  ngOnInit()
  {
     if(!this.loginService.user) this.router.navigate(['login']);
     this.isVisibledTitle = environment.EXIBIR_HEADER;
     this.titleHeaderStatus = environment.ESTADO_PROJETO;
     this.titleFooterStatus = environment.ESTADO_PROJETO;
     this.openMenu();
  }

  public changeModuleNovo(idReferencia:string)
  {
    if(this.loginService.user == null)
    {
      this.messageService.addToast("Sessão Expirada, faça novamento o login", "Erro", "error")
      return ;
    }
    let user = this.loginService.user;
    user.referencia = this.getReferencia(idReferencia);
    this.loginService.user = user;
    this.updateInfoUser()
  }
  private iconModulo(modulo:Referencia)
  {
    if(modulo == null) return null;
    switch(modulo.id)
    {
      case '1': return 'gavel';
      case '2': return 'trending_up';
      case '3': return 'colorize';
      case '4': return 'bubble_chart';
      case '5': return 'healing';
      default: return 'local_hospital';
    }
  }

  private updateInfoUser() {
    this.isLoading = true;
    this.loginService.searchInfoUser().subscribe(resp =>
    {
        if(resp) {
          if (resp.regioes != null && resp.regioes.length > 0)
          {
            resp.regionais = [];
            for (let item of resp.regioes)
            {
              if(item.regionais == null) continue;
              if(item.regionais.length < 1) continue;

              for (let regional of item.regionais)
              {
                resp.regionais.push(regional);
              }
            }
          }
          if (resp.regionais != null && resp.regionais.length > 0)
          {
            resp.regionalorigin = resp.regionais[0];
          }
          else if (resp.unidades != null && resp.unidades.length > 0)
          {
            resp.unidadeorigin = resp.unidades[0]
            resp.regionaldestine = resp.unidades[0].regional;
          }
        }
        this.userService.infoUser = resp;
        this.isLoading = false;
        this.loginService.infoUser = this.userService.infoUser;
        this.router.navigate(['navegacao/inicio']);
      },
      error =>
      {
        this.messageService.addToast("Erro ao tentar recuperar os niveis do usuário", "Erro", "error")
        this.isLoading = false;
      }
    );
  }

  sair()
  {
    this.userService.infoUser = null;
    this.loginService.user = null;
    this.loginService.infoUser = null;
    this.router.navigate(['login']);
  }

  getReferencia(id: string): Referencia {
    for (let ref of this.loginService.user.referencias_permitidas) {
      if (ref.id.match(id)) {
        return ref;
      }
    }
  }

  openMenu(){
	Promise.resolve(null).then(() => this.menuTrigger.openMenu());
  }

  permiteVisualizar(id: string): boolean {
    for (let ref of this.loginService.user.referencias_permitidas) {
      if (ref.id.match(id)) {
        return true
      }
    }
    return false
  }
}
