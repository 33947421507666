import { Periodicidade } from '@app/core/periodicidade/periodicidade.model';
import { Material } from '@app/core/material/material.model';
export class AtualizacaoPrescricao {
  constructor (
    public material: Material,
    public qtd_atual: string,
    public qtd_nova: string,
    public periodicidade_atual: Periodicidade,
    public periodicidade_nova: Periodicidade){
  }

}
