import { ClassificacaoSubstanciaAnvisaFilter } from './../../../models/classificacao-substancia-anvisa-filter';
import { ClassificacaoSubstanciaAnvisaService } from './../../../../classificacao-substancia-anvisa/classificacao-substancia-anvisa.service';
import { ClassificacaoSubstanciaANVISA } from '@app/core/shared/models/classificacao-substancia-anvisa';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ses-classificacao-substancia-anvisa-autocomplete',
  templateUrl: './classificacao-substancia-anvisa-autocomplete.component.html',
  styleUrls: ['./classificacao-substancia-anvisa-autocomplete.component.scss']
})
export class ClassificacaoSubstanciaAnvisaAutocompleteComponent implements OnInit {
  @Input() filtroAtivo: boolean = true;
  @Input() required: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();
  @Input() id: string;

  //TODO: Alterar tipo para o correto
  @Input() elementoSelecionado: ClassificacaoSubstanciaANVISA = new ClassificacaoSubstanciaANVISA();

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */

  @Output() idSelecionada = new EventEmitter<string>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any>();

  //TODO: Alterar tipo para o correto
  constructor(
    private classificacaoSubstanciaAnvisaService: ClassificacaoSubstanciaAnvisaService
  ) {}

  //TODO: Alterar tipo para o correto
  public filterClassificacao: ClassificacaoSubstanciaAnvisaFilter = new ClassificacaoSubstanciaAnvisaFilter();
  public lsClassificacaoSubstanciaANVISA: any[] = [];

  ngOnInit() {
    this.filterClassificacao.paginar = false;

    this.classificacaoSubstanciaAnvisaService
        .findClassificacaoSubstanciaAnvisaByFilter(this.filterClassificacao)
        .subscribe({
          next: response => {
            this.lsClassificacaoSubstanciaANVISA = response.list;

            if(this.filtroAtivo)
              this.lsClassificacaoSubstanciaANVISA = this.lsClassificacaoSubstanciaANVISA.filter(filtro => filtro.status)

            if(this.id)
              this.elementoSelecionado = response.list.find(filtro => +filtro.id == +this.id)
          }
        })

    this.limparEvent.subscribe({
      next: () => {
        this.limpar();
      },
    });
  }

  public selecionarClassificacao() {
    this.idSelecionada.emit(this.elementoSelecionado ? this.elementoSelecionado.id + "" : null);
    this.elementosCompletoSelecionadas.emit(this.elementoSelecionado);
  }

  public filter(name: string): any[] {
    let filterValue = name.toLowerCase();
    if (name !== "") {
      return this.lsClassificacaoSubstanciaANVISA.filter((option) =>
        option.descricao.toLowerCase().includes(filterValue)
      );
    }
    return this.lsClassificacaoSubstanciaANVISA;
  }

  public limpar() {
    this.elementoSelecionado = null;
    this.idSelecionada.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  public displayFn(posicao: any) {
    return posicao ? posicao.descricao : "";
  }
}
