import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MedicamentoMultiselectComponent } from "./medicamento-multiselect/medicamento-multiselect.component";
import { MultiSelectModule } from "primeng/multiselect";
import { FormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { MatIconModule } from "@angular/material";
import { ButtonsModule } from "../../buttons/buttons.module";
import { MaterialImportBasicModule } from "../../material-import-basic.module";
import { RegionalMultiselectComponent } from './regional-multiselect/regional-multiselect.component';
import { UnidadeSaudeMultiselectComponent } from './unidade-saude-multiselect/unidade-saude-multiselect.component';
import { PacienteMultiselesctComponent } from './paciente-multiselesct/paciente-multiselesct.component';
import { PosicaoAnaliseTecnicaMultiselectComponent } from './posicao-analise-tecnica-multiselect/posicao-analise-tecnica-multiselect.component';
import { DecisaoSuperiorMultiselectComponent } from "./decisaoSuperiorMultiselect/decisaoSuperiorMultiselect.component";
import { JustificativaAnaliseTecnicaMultiselectComponent } from './justificativa-analise-tecnica-multiselect/justificativa-analise-tecnica-multiselect.component';
import { MotivoDevolucaoMultiselectComponent } from "./motivo-devolucao-multiselect/motivo-devolucao-multiselect.component";
import { ClassificacaoSubstanciaAnvisaMultiselectComponent } from "./classificacao-substancia-anvisa-multiselect/classificacao-substancia-anvisa-multiselect.component";

@NgModule({
  declarations: [
    MedicamentoMultiselectComponent,
    RegionalMultiselectComponent,
    UnidadeSaudeMultiselectComponent,
    PacienteMultiselesctComponent,
    PosicaoAnaliseTecnicaMultiselectComponent,
    DecisaoSuperiorMultiselectComponent,
    JustificativaAnaliseTecnicaMultiselectComponent,
    MotivoDevolucaoMultiselectComponent,
    ClassificacaoSubstanciaAnvisaMultiselectComponent],
  exports: [
    MedicamentoMultiselectComponent,
    RegionalMultiselectComponent,
    UnidadeSaudeMultiselectComponent,
    PacienteMultiselesctComponent,
    PosicaoAnaliseTecnicaMultiselectComponent,
    DecisaoSuperiorMultiselectComponent,
    JustificativaAnaliseTecnicaMultiselectComponent,
    MotivoDevolucaoMultiselectComponent,
    ClassificacaoSubstanciaAnvisaMultiselectComponent],
  imports: [
    CommonModule,
    FormsModule,
    MultiSelectModule,
    ButtonModule,
    MatIconModule,
    ButtonsModule,
    MaterialImportBasicModule,
  ],
})
export class MultiselectSesModule {}
