import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertaComponent } from './alerta.component';
import { AlertaRoutes } from './alerta.routes';
import { AvisosModule } from '../avisos/avisos.module'

import { MatTableModule, MatCardModule, MatPaginatorModule } from '@angular/material';
import { AlertaCadastroComponent } from './alerta-cadastro/alerta-cadastro.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


@NgModule({
	declarations: [AlertaComponent, AlertaCadastroComponent],
	imports: [
		CommonModule,
		MatTableModule,
		MatCardModule,
		MatPaginatorModule,
		AlertaRoutes,
		AvisosModule,
		MatSlideToggleModule],
	exports: [AlertaComponent]
})
export class AlertaModule { }
