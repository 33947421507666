import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { RegionalService } from "@app/core/regional/regional.service";
import { LoginService } from "@app/core/security/login/login.service";
import { GetAllByStatusFilter } from "@app/core/shared/models/get-all-by-status.filter.model";

@Component({
  selector: "ses-regional-multiselect",
  templateUrl: "./regional-multiselect.component.html",
  styleUrls: ["./regional-multiselect.component.scss"],
})
export class RegionalMultiselectComponent implements OnInit {
  @Input() filtroNivel: boolean = true;
  @Input() required: boolean = true;
  @Input() disabled: boolean = false;
  @Input() limparEvent = new EventEmitter<any>();
  @Input() selectionLimit: number = null;

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */
  @Output() idsSelecionadas = new EventEmitter<string[]>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any[]>();

  constructor(
    private loginService: LoginService,
    private regionalService: RegionalService
  ) {}

  public lsRegional: any[] = [];
  public regionalSelecionadas: any[] = [];

  ngOnInit() {
    if (this.filtroNivel) {
      this.lsRegional = this.loginService.infoUser.regionais;
        if(!this.lsRegional || this.lsRegional.length < 1)
          this.disabled = true;
    } else {
      this.carregaRegionais();
    }
    this.limparEvent.subscribe({
      next: (resp) => {
        this.limpar();
      },
    });
  }

  public carregaRegionais() {
    let _filter: GetAllByStatusFilter = new GetAllByStatusFilter();
    _filter.referencia = this.loginService.user.referencia.id;

    this.regionalService.getAllByStatus(_filter).subscribe({
      next: (resp) => {
        this.lsRegional = resp;
      },
    });
  }

  public limpar() {
    this.regionalSelecionadas = [];
    this.idsSelecionadas.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  selecionaRegional(event?: any) {
    let listIds: string[] = [];
    this.regionalSelecionadas.forEach((regional) => listIds.push(regional.id));
    this.idsSelecionadas.emit(listIds);
    this.elementosCompletoSelecionadas.emit(this.regionalSelecionadas);
  }
}
