import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PosicaoAnaliseTecnicaService } from "@app/core/nat/posicao-analise-tecnica/posicao-analise-tecnica.service";
import { PosicaoAnaliseTecnicaFilter } from "@app/core/shared/models/posicao-analise-tecnica-filter";

@Component({
  selector: "ses-posicao-analise-tecnica-multiselect",
  templateUrl: "./posicao-analise-tecnica-multiselect.component.html",
  styleUrls: ["./posicao-analise-tecnica-multiselect.component.scss"],
})
export class PosicaoAnaliseTecnicaMultiselectComponent implements OnInit {
  // @Input() filtroNivel: boolean = true;
  @Input() required: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */
  @Output() idsSelecionadas = new EventEmitter<string[]>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any[]>();

  constructor( private posicaoAnaliseTecnicaService: PosicaoAnaliseTecnicaService) {}

  public lsPaciente: any[] = [];
  public pacientelSelecionadas: any[] = [];
  public posicaoAnaliseTecnicaFilter: PosicaoAnaliseTecnicaFilter =  new PosicaoAnaliseTecnicaFilter()

  ngOnInit() {
    this.carregaPaciente();
    this.limparEvent.subscribe({
      next: (resp) => {
        this.limpar();
      },
    });
  }

  public carregaPaciente() {
    this.posicaoAnaliseTecnicaFilter.paginar = false;
    this.posicaoAnaliseTecnicaService.getPosicaoAnaliseByFilter(this.posicaoAnaliseTecnicaFilter).subscribe({
      next: (response) => {
        this.lsPaciente = response.list;
      },
    });
  }

  filtraPesquisa(event: any) {
    if (event.target.value && event.target.value.length >= 3) {
      this.carregaPaciente();
    }
  }

  public limpar() {
    this.pacientelSelecionadas = [];
    this.idsSelecionadas.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  selecionaRegional(event?: any) {
    let listIds: string[] = [];
    this.pacientelSelecionadas.forEach((regional) => listIds.push(regional.id));
    this.idsSelecionadas.emit(listIds);
    this.elementosCompletoSelecionadas.emit(this.lsPaciente);
  }
}
