import { EventEmitter } from '@angular/core';
import { Component, OnInit, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'modal-ses',
  templateUrl: './modal-ses.component.html',
  styleUrls: ['./modal-ses.component.scss']
})
export class ModalSesComponent implements OnInit {

  @Input() titulo = "";
  @Input() saveTitulo: string = 'Salvar';
  @Input() saveIcon: string = 'save';
  @Input() isClear: boolean = true;
  @Input() isSave: boolean = true;

  @Output() eventSalvar: EventEmitter<boolean> = new EventEmitter();
  @Output() eventFechar: EventEmitter<boolean> = new EventEmitter();
  @Output() eventLimpar: EventEmitter<boolean> = new EventEmitter();

  constructor(private dialogRef: MatDialogRef<ModalSesComponent>) { }

  ngOnInit() {
  }

  public salvar(){
    this.eventSalvar.emit(true);
  }

  public limpar(){
    this.eventLimpar.emit(true);
  }

  public fechar(){
    this.eventFechar.emit(true);
    this.dialogRef.close();
  }

}
