import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SGS_URL, CORE } from '@app/app.api';
import { Observable } from 'rxjs';
import { LoginService } from '../security/login/login.service';
import { DescricaoModel } from '../shared/descricao/descricao.model';

@Injectable({
  providedIn: 'root'
})
export class TipoMaterialService {

  public lsIdsSelecionadosMultiSelect: any[] = [];
  public haItensSelecionadosMultiSelect = false;

  constructor(private http: HttpClient, private loginService: LoginService) { }


  public listarTipoMaterial(nome: string): Observable<DescricaoModel[]> {
    return this.http.post<DescricaoModel[]>(`${SGS_URL.CORE}/${CORE.TIPO_MEDICAMENTO}/listarMaterial/${nome}/`, {})
  }

  public listarTodosTiposMateriais(): Observable<DescricaoModel[]> {
    return this.http.post<DescricaoModel[]>(`${SGS_URL.CORE}/${CORE.TIPO_MEDICAMENTO}/searchAllActive/`, {})
  }

  public getNewLsIdsSelecionados() {
    this.lsIdsSelecionadosMultiSelect = [];
  }
}
