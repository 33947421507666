import { NatPosicionamento } from './nat-posiciomento.model';
import { Periodicidade } from '../../core/periodicidade/periodicidade.model';
import { Cid } from '@app/core/cid/cid.model';
import { SituacaoProcesso } from "@app/core/situacao-processo/situacao-processo.model"
import { NatInfo } from './nat-info.model';
import { NatJuiz } from './nat-juiz.model';
import { MotivoExclusao } from '@app/judicial/motivo-exclusao/motivo-exclusao.model';
import { Material } from '@app/core/material/material.model';
import { Profissional } from '@app/core/profissional/profissional.model';
import { TipoFornecimento } from '@app/judicial/tipo-fornecimento/tipo-fornecimento.model';
import { HistoricoObservacaoItemPrescricao } from './historico-observacao-item-prescricao.model';
import { InformacaoTecnicaEscolha } from '@app/judicial/informacao-tecnica-escolha/InformacaoTecnicaEscolha.model';

export class ItemPrescricao {
  constructor(
     public id:string,
     public contra_cautela:boolean,
     public data_exclusao:Date,
     public data_vencimento:Date,
     public data_inicio:Date,
     public data_realizacao:Date,
     public numero_contrato:string,
     public quantidade:string,
     public cidP:Cid,
     public cidS:Cid,
     public medicamento:Material,
     public profissional:Profissional,
     public motivo_exclusao:MotivoExclusao,
     public periodicidade:Periodicidade,
     public periodicidade_contra:Periodicidade,
     public situacao_processo:SituacaoProcesso,
     public tipo_fornecimento:TipoFornecimento,
     public observacoes:HistoricoObservacaoItemPrescricao[],
     public status:boolean, // atributo utilizado para classe de sequestro de valores e alvará
     public nat_posicionamento:NatPosicionamento,
     public nat_info:NatInfo,
     public nat_info_especificar:string,
     public natJuiz:NatJuiz,
     public nat_juiz_especificar:string ,
     public qtd_dispensada:number,  //transient utilizado na dispensação
     public alvara:boolean,        //transient utilizado na dispensação
     public inf_tecnica:boolean,
     public data_contracautela:Date,
     public inf_tecnica_escolha:InformacaoTecnicaEscolha,
     public dose_unica:boolean,
     public qtdEstoque:number, //transiente utilizado na dispensação (qtd existente em estoque)
     public qtdEstoqueTotal:number, //transiente utilizado na dispensação  (qtd total que pode ser fornecida - sem vencidos)
     ) {}
}
