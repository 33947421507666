import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {FormControl} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { DashbordService } from './../../../dashbord.service'
import { InputFilter } from './../input-filter.model';

const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-datapicker-year-filter',
  templateUrl: './datapicker-year.componente.html',
  styleUrls: ['/datapicker-year.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class datapickerYearComponent implements OnInit {

  @Input() public inputFilter: InputFilter;
  date = new FormControl(moment());

  constructor(private dashbordService: DashbordService) {}

  ngOnInit() {

  }

  public encontrarElemento(lista, elemento){
    let index = -1;
    for(let i = 0; i < lista.length; i++){
      if(lista[i].identificadorBanco === elemento.identificadorBanco){
        index = i;
        break;
      }
    }
    return index;
  }

  changeInputDateSimples(event, input:InputFilter){
    if(this.dashbordService.filtrosAlterados && this.dashbordService.filtrosAlterados.length > 0){
      let indexObjetoVez = this.encontrarElemento(this.dashbordService.filtrosAlterados, input);
      if(indexObjetoVez != -1){
        this.dashbordService.filtrosAlterados[indexObjetoVez].value = [ event.year() ];
      } else {
        this.dashbordService.filtrosAlterados.push({ ...input, value: [event.year()] });
      }
    } else {
      this.dashbordService.filtrosAlterados = [{ ...input, value: [ event.year() ] }];
    }
  }

  changeYear(event, input:InputFilter, datepicker){
    const ctrlValue = this.date.value;
    ctrlValue.year(event.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.changeInputDateSimples(event, input);
  }

}
