import { CanLoad, Route, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { LoginService } from '../security/login/login.service';
import { NavegacaoService } from '../navegacao/navegacao.service';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { UsuarioService } from '@app/core/usuario/usuario.service';

@Injectable()
export class LoggedInGuard implements CanLoad, CanActivate {

  visualizaPaciente: string

  constructor(@Inject(SESSION_STORAGE) private storage: WebStorageService, private loginService: LoginService, private navegacaoService: NavegacaoService, private usuarioService:UsuarioService) 
  { }

  checkAuthentication(path: string): boolean {
    const loggedIn = this.loginService.isLoggeddIn()
    if (!loggedIn) 
    {
      this.loginService.handleLogin(`/${path}`)
    }
    return loggedIn
  }

  canLoad(route: Route): boolean 
  {
    this.navegacaoService.isLoading = true;
    return this.checkAuthentication(route.path)
  }
  
  canActivate(activatedRoute: ActivatedRouteSnapshot): boolean 
  { 
    this.navegacaoService.isLoading = false;
    return this.checkAuthentication(activatedRoute.routeConfig.path)
  }

}
