import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { SGS_URL, JUDICIAL} from '@app/app.api';
import { ContasBancariasModel } from './contas-bancarias-model';
import { ContasBancariasSearch } from './contas-bancarias-search';
import { LoginService } from '@app/core/security/login/login.service';
import { ListaJSON } from '@app/core/shared/default/lista-json.model';
import { BancoModel } from './banco.model';

/***
 * Classe de serviço para objeto do tipo Contas para Devolução de Alvará
 * Classe responsável pela interação com o Back-end
 */

@Injectable({
  providedIn: 'root'
})
export class ContasBancariasService {

  //conta selecionada para edição
  private _contasDevolucaoActive: ContasBancariasModel = new ContasBancariasModel("", "", "", "", "", "", true, new BancoModel("","","") );

  /** 
    Utilizado para passar os parametros de pesquisa para o back-end
    Contém informações do sort(coluna e direção) e paginator (offset e limite das informações)
  */
  private _searchContasDevolucao: ContasBancariasSearch;

  //construtor da classe
  constructor
    (
      private http: HttpClient,
      private loginService: LoginService
    ) { }

  /**Método para salvar um objeto e recuperar o id para preencher a classe **/
  save(): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id
    return this.http.post<any>(`${SGS_URL.JUDICIAL}/${JUDICIAL.CONTA_BANCARIA}/c/${id_referencia}/${id_usuario}`, this._contasDevolucaoActive)
  }

  //Busca lista de contas de devolução no back-end
  search():Observable<ListaJSON<ContasBancariasModel>> {    
    return this.http.post<ListaJSON<ContasBancariasModel>>(`${SGS_URL.JUDICIAL}/${JUDICIAL.CONTA_BANCARIA}/p`, this._searchContasDevolucao );
  }

  //Atualiza o status de um objeto no banco de dados
  updateActive(conta:ContasBancariasModel): Observable<any>
  {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id
    return this.http.post<any>(`${SGS_URL.JUDICIAL}/${JUDICIAL.CONTA_BANCARIA}/s/${conta.id}/${id_usuario}/${conta.status}/${id_referencia}`, {})
  }

  public getNovaContaBancaria(): ContasBancariasModel {
    return new ContasBancariasModel("", "", "", "", "", "", true,new BancoModel("","",""));
  }

  //Busca lista de bancos no back-end
  getListaBancos(filtro?:string):Observable<BancoModel[]> {  
    (filtro == undefined) ? filtro="null" : filtro ;
    return this.http.get<BancoModel[]>(`${SGS_URL.JUDICIAL}/${JUDICIAL.BANCO}/get_bancos/${filtro}`);
  }

  /**Métodos Sets e Gets **/

  public get contasDevolucaoActive(): ContasBancariasModel {
    return this._contasDevolucaoActive;
  }
  public set contasDevolucaoActive(value: ContasBancariasModel) {
    this._contasDevolucaoActive = value;
  }

  public get searchContasBancarias(): ContasBancariasSearch {
    return this._searchContasDevolucao;
  }
  public set searchContasBancarias(value: ContasBancariasSearch) {
    this._searchContasDevolucao = value;
  }

}
