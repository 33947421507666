
import { Injectable, EventEmitter } from "@angular/core"
import { HttpClient} from '@angular/common/http'
import { Observable } from 'rxjs'
import { LoginService } from "../security/login/login.service";
import { Recibo } from "./recibo.model";
import { SGS_URL, CORE } from "@app/app.api";
import { Dispensacao } from "../dispensacao/dispensacao.model";

@Injectable({
  providedIn: 'root'
})
export class ReciboService {

  recibo: Recibo
  // Evento que ira pegar o id da Dispensação na linha clicada
  eventRecibo: EventEmitter<string> = new EventEmitter<string>()

  idDispensacao = ""

  constructor(
    private http: HttpClient,
    private loginService: LoginService) {}


    public getModelRecibo(idDispensacao)
    {
      let id_usuario = this.loginService.user.id;
      let id_referencia = this.loginService.user.referencia.id; 
      this.http.get(`${SGS_URL.CORE}/${CORE.RECIBO}/getModelRecibo/${idDispensacao}/${id_usuario}/${id_referencia}`,{responseType:'arraybuffer'}).subscribe(resp =>{
        let file =  new Blob([resp], {type: 'application/pdf'});
        let fileURL = URL.createObjectURL(file);
        window.open(fileURL,'_blank');
      })
    }

  //busca o id do recibo da dispensação
  getAllRecibosByDispensacao(id_dispensacao:string):Observable<Recibo[]>
  {
    return this.http.post<Recibo[]>(`${SGS_URL.CORE}/${CORE.RECIBO}/getAllRecibosByDispensacao`, { dispensacao:id_dispensacao });
  }

  //envia um arquivo RECIBO para o back-end
  uploadRecibo(file:File, file_name:string, id_dipsensacao:string): Observable<any>
  {
    let id_usuario = this.loginService.user.id;
    let id_referencia = this.loginService.user.referencia.id;
    let formData: FormData = new FormData();

    formData.append('usuario', id_usuario);
    formData.append('referencia', id_referencia);
    formData.append('file', file);
    formData.append('filename', encodeURIComponent(file_name));
    formData.append('dispensacao', id_dipsensacao);
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.RECIBO}/update_recibo`, formData);

  }

  public getRecibo(idRecibo)
  {
    this.http.get(`${SGS_URL.CORE}/${CORE.RECIBO}/getRecibo/${idRecibo}`,{responseType:'arraybuffer'}).subscribe(resp =>{
      let file =  new Blob([resp], {type: 'application/pdf'});
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL,'_blank');
    })
  }

  /**
   * Realiza o count de recibos pelo id da dispensação
   * @param id_dispensacao id da dispensação
   * @returns
   */
  countRecibosByDispensacao(id_dispensacao: string): Observable<any> {
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.RECIBO}/countRecibosByDispesacao`, { idDispensacao: id_dispensacao });
  }

  /**
 * Cria uma variavel temporária (existsRecibo - boolean) para o objeto (dispensacao), utilizada para verificar se o recibo existe.
 * @param dispensacao
 * @returns Retorna uma lista de dispensação
 */
  public existsRecibo(dispensacoes: Dispensacao[]): Dispensacao[] {
    let dispensacoesReturn = [] as Dispensacao[];
    for (let dispensacao of dispensacoes) {
      try {
        this.countRecibosByDispensacao(dispensacao.id).subscribe(resp => {
          if (resp > 0) {
            dispensacao['existsRecibo'] = true;
          } else {
            dispensacao['existsRecibo'] = false;
          }
        }, err => {
          dispensacao['existsRecibo'] = false;
        });
      } catch (e) {
        dispensacao['existsRecibo'] = false;
      }
      dispensacoesReturn.push(dispensacao);
    }
    return dispensacoesReturn;
  }

}
