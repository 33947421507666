import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TipoAnaliseTecnicaService } from "@app/core/nat/tipo-analise-tecnica/tipo-analise-tecnica.service";
import { LoginService } from "@app/core/security/login/login.service";
import { TipoAnaliseTecnicaFilter } from "@app/core/shared/models/tipo-analise-tecnica-filter";

@Component({
  selector: "ses-tipo-analise-tecnica-autocomplete",
  templateUrl: "./tipo-analise-tecnica-autocomplete.component.html",
  styleUrls: ["./tipo-analise-tecnica-autocomplete.component.scss"],
})
export class TipoAnaliseTecnicaAutocompleteComponent implements OnInit {
  // @Input() filtroNivel: boolean = true;
  @Input() required: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();
  @Input() id: string = "tipo-analise-tecnica";
  @Input() tipoAnaliseTecnicaIdSelecionada: string;

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */
  @Output() idSelecionada = new EventEmitter<string>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any>();

  constructor(
    private loginService: LoginService,
    private tipoAnaliseTecnicaService: TipoAnaliseTecnicaService
  ) {}

  public tipoAnaliseTecnicaSelecionada: any;
  public lsTipoAnaliseTecnica: any[] = [];

  ngOnInit() {
    this.carregaTipoAnaliseTecnica();
    this.limparEvent.subscribe({
      next: (resp) => {
        this.limpar();
      },
    });
  }

  public carregaTipoAnaliseTecnica() {
    let filter: TipoAnaliseTecnicaFilter = new TipoAnaliseTecnicaFilter();
    filter.paginar = false;
    filter.referencia = +this.loginService.user.referencia.id;

    this.tipoAnaliseTecnicaService.getTipoAnaliseByFilter(filter).subscribe({
      next: (response) => {
        this.lsTipoAnaliseTecnica = response.list;

        if(this.tipoAnaliseTecnicaIdSelecionada){
          this.tipoAnaliseTecnicaSelecionada = this.lsTipoAnaliseTecnica.find(filtro => filtro.id == this.tipoAnaliseTecnicaIdSelecionada)
        }
      },
    });
  }

  public filter(name: string): any[] {
    let filterValue = name.toLowerCase();
    if (name !== "") {
      return this.lsTipoAnaliseTecnica.filter((option) =>
        option.descricao.toLowerCase().includes(filterValue)
      );
    }
    return this.lsTipoAnaliseTecnica;
  }

  public limpar() {
    this.tipoAnaliseTecnicaSelecionada = null;
    this.idSelecionada.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  public selecionarRegional() {
    this.idSelecionada.emit(this.tipoAnaliseTecnicaSelecionada.id);
    this.elementosCompletoSelecionadas.emit(this.tipoAnaliseTecnicaSelecionada);
  }

  public displayFn(tipoAnaliseTecnica: any) {
    return tipoAnaliseTecnica ? tipoAnaliseTecnica.descricao : "";
  }
}
