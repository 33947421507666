import {
  MatButtonModule,
  MatCardModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatSidenavModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatCheckboxModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatRadioModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatExpansionModule,
  MatTooltipModule
} from '@angular/material';
import { NgModule } from '@angular/core';
import { ToastaModule } from 'ngx-toasta';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMaskModule } from 'ngx-mask';
import { NullDefaultValueDirective } from './correcao-input/null-default-value.directive';
import { MensagemAvisoModalComponent } from './mensagem-aviso-modal/mensagem-aviso-modal.component';
import { DebugComponent } from './debug/debug.component';

@NgModule({
  declarations:
  [
    NullDefaultValueDirective
  ],
  imports:
  [
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatTableModule,
    MatSelectModule,
    MatButtonModule,
    MatTabsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatGridListModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSortModule,
    MatToolbarModule,
    MatSidenavModule,
    ToastaModule,
    NgxLoadingModule,
    NgxMaskModule
  ],
  exports:[
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatTableModule,
    MatSelectModule,
    MatButtonModule,
    MatTabsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatGridListModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSortModule,
    MatToolbarModule,
    MatSidenavModule,
    ToastaModule,
    NgxLoadingModule,
    NgxMaskModule,
    NullDefaultValueDirective
   ],
  schemas: [  ]
})
export class MaterialImportBasicModule { }
