import { ResponsavelEntity } from './responsavel-entity.model';
import { Injectable } from "@angular/core"
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Responsavel } from "./responsavel.model";
import { MatTableDataSource } from "@angular/material";
import { ResponsavelPesquisa } from "./responsavel-pesquisa/responsavel-pesquisa.model";
import { ResponsavelGrid } from "./responsavel-grid.model";
import { LoginService } from '@app/core/security/login/login.service';
import { ListaJSON } from '@app/core/shared/default/lista-json.model';
import { Logradouro } from '@app/core/shared/endereco/logradouro.model';
import { Endereco } from '@app/core/shared/endereco/endereco.model';
import { SGS_URL, CORE } from '@app/app.api';

@Injectable()
export class ResponsavelService {

  responsavel: Responsavel
  responsaveis: ResponsavelGrid[] = [];
  dataSource = new MatTableDataSource<ResponsavelGrid>(this.responsaveis)
  p_length: number
  p_index: number
  p_size: number
  nome: string
  cpf: string
  cns: string
  sel: string
  status: string
  hasLogradouro = false

  constructor(
    private http: HttpClient,
    private loginService: LoginService) {
  }

  cadastrar(responsavel: Responsavel): Observable<any> {
    let id_referencia = this.loginService.user.referencia.id;
    let id_usuario = this.loginService.user.id;
    return this.http.post<any>(`${SGS_URL.CORE}/responsavel/c/${id_referencia}/${id_usuario}`, responsavel)
  }

  listar(responsavelPesquisa: ResponsavelPesquisa): Observable<ListaJSON<ResponsavelGrid>> {
    return this.http.post<ListaJSON<ResponsavelGrid>>(`${SGS_URL.CORE}/${CORE.RESPONSAVEL}/p`, responsavelPesquisa)
  }

  listarFiltro(filtro: string): Observable<ResponsavelEntity[]> {
    return this.http.post<ResponsavelEntity[]>(`${SGS_URL.CORE}/${CORE.RESPONSAVEL}/ativos`, {nome: filtro})
  }

  getResponsaveisAtivoByNome(filtro: string): Observable<ResponsavelEntity[]> {
    return this.http.post<ResponsavelEntity[]>(`${SGS_URL.CORE}/${CORE.RESPONSAVEL}/getResponsaveisAtivoByNome`, {nome: filtro})
  }

  updateStatus(id_responsavel: string, status: string): Observable<any> {
    let id_referencia = this.loginService.user.referencia.id;
    let id_usuario = this.loginService.user.id;
    return this.http.post<any>(`${SGS_URL.CORE}/responsavel/s/${id_responsavel}/${id_usuario}/${status}/${id_referencia}`, {})
  }

  public listResonsaveisComponente(nomeCpf: string): Observable<any>{
    return this.http.post<any>(`${SGS_URL.CORE}/responsavel/list-responsaveis-component`, {
      'params':{
        'nome_cpf': nomeCpf
      }
    })
  }

  getResponsavel(): Responsavel {
    if (this.responsavel === undefined) {
      this.responsavel = this.getNovoResponsavel()
    }
    return this.responsavel
  }

  getResponsaveis(): ResponsavelGrid[] {
    return this.responsaveis
  }

  setResponsaveis(responsaveis: ResponsavelGrid[]) {
    this.responsaveis = responsaveis
  }

  getDataSource(): MatTableDataSource<ResponsavelGrid> {
    return this.dataSource
  }

  setDataSource(dataSource: MatTableDataSource<ResponsavelGrid>) {
    this.dataSource = dataSource
  }

  getP_length(): number {
    return this.p_length
  }

  setP_length(p_length: number) {
    this.p_length = p_length
  }

  getP_index(): number {
    return this.p_index
  }

  setP_index(p_index: number) {
    this.p_index = p_index
  }

  getP_size(): number {
    return this.p_size
  }

  setP_size(p_size: number) {
    this.p_size = p_size
  }

  getNome(): string {
    return this.nome
  }

  setNome(nome: string) {
    this.nome = nome
  }

  getCpf(): string {
    return this.cpf
  }

  setCpf(cpf: string) {
    this.cpf = cpf
  }

  getCns(): string {
    return this.cns
  }

  setCns(cns: string) {
    this.cns = cns
  }

  buscaResponsavel(id_responsavel: string): Observable<Responsavel> {
    return this.http.post<Responsavel>(`${SGS_URL.CORE}/responsavel/id/${id_responsavel}`, {})
  }

  setResponsavel(responsavel: Responsavel) {
    this.responsavel = responsavel
  }

  getNovoResponsavel(): Responsavel {
    let logradouro = new Logradouro(null, null, null, "", null, null, null);
    let endereco =   new Endereco(null, null, null, null, null, logradouro, '4', null, null, "");
    this.responsavel = new Responsavel("", "", "", "", "", "2", endereco, "", "", "", "")
    return this.responsavel
  }

  getSel(): string {
    return this.sel
  }

  setSel(sel: string) {
    this.sel = sel
  }

  getStatus(): string {
    return this.status
  }

  setStatus(status: string) {
    this.status = status
  }

}
