import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UnidadeAssistenciaGrid } from './unidade-assistencia-grid.model';
import { UnidadeAssistencia } from './unidade-assistencia.model';
import { SGS_URL } from '@app/app.api';
import { Profissional } from '../profissional/profissional.model';

@Injectable({
  providedIn: "root"
})
export class UnidadeAssistenciaService {
  unidadeAssistencia: UnidadeAssistencia;
  public unidades: UnidadeAssistenciaGrid[];
  public materialSelecionado: UnidadeAssistenciaGrid;
  constructor(private http: HttpClient) { }

  getUnidadeAssistencia(): UnidadeAssistencia {
    if (this.unidadeAssistencia === undefined) {
      this.unidadeAssistencia = this.getNovaUnidadeAssistencia();
    }
    return this.unidadeAssistencia;
  }

  getNovaUnidadeAssistencia(): UnidadeAssistencia {
    let logradouro = {
      id: "",
      logradouro: "",
      bairro: "",
      municipio: "",
      uf: "",
      tipo: "",
      cep: ""
    };
    let endereco = {
      id: "",
      logradouro: logradouro,
      numero: "",
      complemento: "",
      referencia: "",
      zona: "",
      id_logradouro: "",
      tipo: "",
      nome_logradouro:"",
      nome_bairro: "",
      cep: ""
    };
    let tipo_registro = {
      id: "",
      descricao: "",
      status: "",
      usuario: "",
      referencia: ""
    };
    let farmaceutico = new Profissional(
      "",
      "",
      "",
      "",
      "",
      null,
      "",
      null,
      null,
      null,
      "",
      false
    );
    let contato = { id: "", tipo: "", telefone: "", contato: "", index: 0 };
    this.unidadeAssistencia = new UnidadeAssistencia(
      "",
      "",
      "",
      endereco,
      "",
      "",
      null,
      "",
      farmaceutico,
      "",
      contato,
      true,
      "Ativo",
      ""
    );
    return this.unidadeAssistencia;
  }

  listar(pageSize, pageIndex, filtro): Observable<UnidadeAssistenciaGrid[]> {
    return this.http.post<UnidadeAssistenciaGrid[]>(
      `${SGS_URL.CORE}/unidadeAssistencia/listar/${pageSize}/${pageIndex}`,
      filtro
    );
  }

  cadastrar(unidadeAssistencia: UnidadeAssistencia): Observable<any> {
    return null;
    return this.http.post<any>(
      `${SGS_URL.CORE}/unidadeAssistencia/cadastro`,
      unidadeAssistencia
    );
  }

  update(unidadeAssistencia: UnidadeAssistencia, id_usuario : string, id_referencia : string): Observable<any> {

    return this.http.post<any>(
      `${SGS_URL.CORE}/unidadeAssistencia/c/${id_usuario}/${id_referencia}`,
      unidadeAssistencia
    );
  }

  updateStatus(
    id_unidade: string,
    id_usuario: string,
    ativo: string,
    id_referencia
  ): Observable<any> {
    return this.http.post<any>(
      `${SGS_URL.CORE}/unidadeAssistencia/status/${id_unidade}/${id_usuario}/${ativo}/${id_referencia}`,
      {}
    );
  }

  buscaUnidadeAssistencia(id_unidadeAssistencia: string, id_usuario: string): Observable<UnidadeAssistencia> {
    return this.http.post<UnidadeAssistencia>(`${SGS_URL.CORE}/unidadeAssistencia/buscar/${id_unidadeAssistencia}/${id_usuario}`,{});
  }

  setUnidadeAssistencia(unidadeAssistencia: UnidadeAssistencia) {
    this.unidadeAssistencia = unidadeAssistencia;
  }

  setUnidadesAssistencia(unidades: UnidadeAssistenciaGrid[]) {
    this.unidades = unidades
  }
}
