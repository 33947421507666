import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'codigoProcedimento'
})
export class CodigoProcedimentoPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value == null) return null;

    if (value.length === 11) {
      return value.substring(0, 2) + "." +
        value.substring(2, 4) + "." +
        value.substring(4, 6) + "." +
        value.substring(6, 10) + "-" +
        value.substring(10, 11);
    }
    else return value;
  }

}
