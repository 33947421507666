import { TipoAtendimento } from "@app/core/tipo-atendimento/tipo-atendimento.model";
import { SituacaoProcesso } from "@app/core/situacao-processo/situacao-processo.model";
import { Material } from "../../material.model";
import { DescricaoModel } from "@app/core/shared/descricao/descricao.model";


export class Complemento {
  constructor( public id: string,
               public descricaocomercial: string,
               public qtdmaxima: string,
               public qtdminima: string,
               public atendimento: TipoAtendimento,
               public situacao: SituacaoProcesso,
               public medicamento: DescricaoModel,
               public material: Material,
               public origem: DescricaoModel,
               public qtdmultiplicadora?: number,
               public classificacao_id?: string) { }
}
