import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatTooltipModule
} from "@angular/material";
import { MatBadgeModule } from "@angular/material/badge";
import { MatSelectModule } from "@angular/material/select";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { ToastaModule } from "ngx-toasta";
import { DialogModule } from "primeng/dialog";
import { CardSesComponent } from "./components-util/card-ses/card-ses.component";
import { EditorSesComponent } from "./components-util/editor-ses/editor-ses.component";
import { MatSelectMultipleDinamicComponent } from "./mat-select-multiple-dinamic/mat-select-multiple-dinamic/mat-select-multiple-dinamic.component";
import { MatSelectMultipleComponent } from "./mat-select-multiple/mat-select-multiple.component";
import { MultiSelectAutocompleteComponent } from "./multi-select-autocomplete/multi-select-autocomplete.component";
import { EditorModule } from 'primeng/editor';
import {ButtonModule} from 'primeng/button';
import { ModalSesComponent } from "./components-util/modal-ses/modal-ses.component";
import { ButtonsModule } from "../buttons/buttons.module";

@NgModule({
  declarations: [
    MatSelectMultipleComponent,
    MatSelectMultipleDinamicComponent,
    MultiSelectAutocompleteComponent,
    CardSesComponent,
    EditorSesComponent,
    ModalSesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    MatCheckboxModule,
    DialogModule,
    ToastaModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatSelectModule,
    AngularMultiSelectModule,
    EditorModule,
    ButtonModule,
    ButtonsModule
  ],
  exports: [
    MatSelectMultipleComponent,
    MatSelectMultipleDinamicComponent,
    MultiSelectAutocompleteComponent,
    CardSesComponent,
    EditorSesComponent,
    ModalSesComponent
  ],
  entryComponents: [ModalSesComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
