import { ItemEntrada } from '@app/core/recebimento/item-entrada.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ListaJSON } from '../../core/shared/default/lista-json.model';
import { UsuarioService } from '@app/core/usuario/usuario.service';
import { Dispensacao } from './dispensacao.model';
import { ItemDispensacao } from './item-dispensacao.model';
import { LoginService } from '../../core/security/login/login.service';
import { SGS_URL, CORE } from '@app/app.api';
import { Paciente } from '../../core/paciente/paciente.model';
import { ItemPrescricao } from '@app/core/prescricao/item-prescricao.model';
import { PacienteService } from '../paciente/paciente.service';


@Injectable({
  providedIn: 'root'
})
export class DispensacaoService {
  //dispensação ativa no servidor
  private _dispensacaoSelecionada: Dispensacao = this.getDispensacaoEmpty();

  //objeto com parametros de filtro para pesquisar a dispensação de uma unidade de saúde
  private _dispensasaoPesquisa = this.getDispensacaoPesquisaEmpty();

  //objeto com parametros de filtro para pesquisar a dispensação na aba do paciente
  private _dispensasaoPacientFilter = this.getDispensacaoPacientePesquisaEmpty();

  //exibe/oculta os filtros de pesquisa
  private _visibledFilter: boolean = false;

  //utilizado para exibição do detalhe da dispensação
  private _dispensacaoDetalhes: Dispensacao;

  public idDispensacaoSelecionada: Dispensacao;
  public dispensacoes: Dispensacao[];
  public itensDispensacao: ItemDispensacao[];

  public listaAuxiliarDispensacao: ItemDispensacao [] = [];
  public listaPreModificacao: ItemDispensacao

  private _backRouter: Number;

  public readonly DISPENSACAO = 0;
  public readonly PACIENTE = 1;

  constructor(private http: HttpClient,
    private loginService: LoginService,
    private userService: UsuarioService,
    private pacienteService: PacienteService
  ) { }


 /* //Busca as dispensações por regional
  public searchAllDispensacaoByRegional(idRegional: string){
    this._dispensasaoPesquisa.referencia = this.loginService.user.referencia.id;
    
    let lista: any = []

    this.pacienteService.getAllPacientesByRegional(idRegional, this._dispensasaoPesquisa, Dispensacao).subscribe(resp => {
      if(resp){
        lista = resp;
        return lista;
      }
    })
  }*/
  public atualizaListaPreModificacao(listapre)
  {
    this.listaPreModificacao = Object.assign({}, listapre)
  }

  //estorna uma dispensação
  public cancelDispensacao(idDispensacao: string, motivo: string): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.DISPENSACAO}/estornoDispensacao`, { idDispensacao: idDispensacao, usuario: id_usuario, referencia: id_referencia, motivo: motivo });
  }

  public verificaUsuarioEstorno(): Observable<any> {
    const id_usuario = this.loginService.user.id;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.DISPENSACAO}/verificaUsuarioEstorno`, { usuario: id_usuario });
  }

  public dispensarMedicamentos(): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id;
    this.dispensacaoSelecionada.referencia = this.loginService.user.referencia;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.DISPENSACAO}/c/${id_referencia}/${id_usuario}`, this.dispensacaoSelecionada);
  }

  public searchAllDispensacaoByPaciente(): Observable<ListaJSON<Dispensacao>> {
    this._dispensasaoPacientFilter.referencia = this.loginService.user.referencia.id;
    return this.http.post<ListaJSON<Dispensacao>>(`${SGS_URL.CORE}/${CORE.DISPENSACAO}/p`, this._dispensasaoPacientFilter)
  }

  public searchAllDispensacao(): Observable<ListaJSON<Dispensacao>> {
    if (!this.dispensasaoPesquisa.idUnidadeSaude) this.dispensasaoPesquisa.idUnidadeSaude = this.userService.infoUser && this.userService.infoUser.unidadeorigin ? this.userService.infoUser.unidadeorigin.id : null;
    this._dispensasaoPesquisa.referencia = this.loginService.user.referencia.id;
    return this.http.post<ListaJSON<Dispensacao>>(`${SGS_URL.CORE}/${CORE.DISPENSACAO}/p`, this._dispensasaoPesquisa)
  }

  public buscarDispensacaoAtribuiParametrosFiltroNaClasse(): Observable<ListaJSON<Dispensacao>> {
    return this.http.post<ListaJSON<Dispensacao>>(`${SGS_URL.CORE}/${CORE.DISPENSACAO}/p`, this._dispensasaoPesquisa)
  }

  //busca todos os itens de entrada referente a um medicamento específico de uma unidade de saúde
  public findItenInputByMedicamento(idUnidade: string, idMedicamento: string): Observable<ItemEntrada[]> {
    return this.http.post<ItemEntrada[]>(`${SGS_URL.CORE}/${CORE.ITEM_ENTRADA}/findAllItemInByMedicamento`, { referencia: this.loginService.user.referencia.id, unidade: idUnidade, medicamento: idMedicamento })
  }

  //busca todos os itens de entrada de um paciente
  public findAllItensPrescricaoByPaciente(): Observable<ItemDispensacao[]> {
    return this.http.post<ItemDispensacao[]>(`${SGS_URL.CORE}/${CORE.PRESCRICAO}/findAllItemPrescricaoByPaciente`, { referencia: this.loginService.user.referencia.id, mes: this.dispensacaoSelecionada.mes, ano: this.dispensacaoSelecionada.ano, paciente: this.dispensacaoSelecionada.paciente.id, unidadesaude: this.dispensacaoSelecionada.unidadeSaude.id })
  }

  public verificaFindAllItensPrescricaoByPaciente():Observable<ItemDispensacao[]> {
    return this.http.post<ItemDispensacao[]>(`${SGS_URL.CORE}/${CORE.PRESCRICAO}/verificaFindAllItemPrescricaoByPaciente`, { referencia: this.loginService.user.referencia.id, mes: this.dispensacaoSelecionada.mes, ano: this.dispensacaoSelecionada.ano, paciente: this.dispensacaoSelecionada.paciente.id, unidadesaude: this.dispensacaoSelecionada.unidadeSaude.id })
  }

  //busca todos os pacientes [completo] ativo  de uma determinada unidade de saúde
  public findAllPacienteByUnidadeSaude(value: string): Observable<Paciente[]> {
    return this.http.post<Paciente[]>(`${SGS_URL.CORE}/${CORE.PACIENTE}/findPacienteEntityByUnidadeSaude`, { filtro: value, referencia: this.loginService.user.referencia.id, idUnidade: this._dispensacaoSelecionada.unidadeSaude.id })
  }

  //cria uma dispensacao vazia
  public getDispensacaoEmpty() {
    let ano = this.dispensacaoSelecionada != null ? this.dispensacaoSelecionada.ano : null;
    let mes = this.dispensacaoSelecionada != null ? this.dispensacaoSelecionada.mes : null;
    return new Dispensacao(null, null, null, new Date(), null, null, null, [], mes, ano, '0', null, null, null, true, false);
  }

  //retorna um objeto de pesquisa/filtro para objetos do tipo dispensação
  public getDispensacaoPesquisaEmpty() {
    let unidade = this.userService.infoUser && this.userService.infoUser.unidadeorigin ? this.userService.infoUser.unidadeorigin.id : null;
    let regional = this.userService.infoUser && this.userService.infoUser.regionalorigin ? this.userService.infoUser.regionalorigin.id : null;
    return {
      tipo: 0, //0 filtra pela unidade de sáude, 1 filtra por paciente
      referencia: this.loginService.user.referencia != null ? this.loginService.user.referencia.id: null,
      idUnidadeSaude: unidade,
      idRegional: regional,
      paciente: null,
      retirado_por: null,
      data_inicio: null,
      data_final: null,
      isAtivo: true,
      isInativo: false,
      medicamento: null,
      sort: "data_cadastro",
      direction: "desc",
      pageSize: 10,
      pageIndex: 0
    }
  }

  //retorna um objeto de pesquisa/filtro para objetos do tipo dispensação
  public getDispensacaoPacientePesquisaEmpty() {
    return {
      tipo: 1, //0 filtra pela unidade de sáude, 1 filtra por paciente
      referencia: null,
      paciente: null,
      retirado_por: null,
      data_inicio: null,
      data_final: null,
      isAtivo: true,
      isInativo: false,
      sort: "data_cadastro",
      direction: "desc",
      pageSize: 10,
      pageIndex: 0
    }
  }



  // Exibe o botão de estorno caso a data esteja dentro do periodo correto
  verificaData(dispensacao: Dispensacao): boolean {

    let dataHoje = new Date();
    if (dispensacao.mes == 12 && dataHoje.getMonth() == 11) {
      return true;
    }

    let dataDispensado = new Date(dispensacao.data_cadastro);
    let terceiroDia = new Date(dataDispensado.getFullYear(), dataDispensado.getMonth() + 1, 3);
    if (dataHoje <= terceiroDia && dispensacao.mes == dispensacao.mes) {
      this.verificaUsuarioEstorno().subscribe(
        resp => {
          if (resp) {
            return true
          }
        }
      )
      return true
    } else {
      return false;
    }
  }


  //exibe o botão de estono caso a data seja o mesmo dia
  public enableEstorno(dispensacao: Dispensacao): boolean {

    if (dispensacao == null) return false;
    if (dispensacao.data_cadastro == null) return false;
    if (!dispensacao.status) return false;
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return !(dispensacao.data_cadastro < now);
  }

  //======================[Métodos Sets e Gets]==================================

  public get dispensacaoSelecionada(): Dispensacao {
    return this._dispensacaoSelecionada;
  }

  public set dispensacaoSelecionada(value: Dispensacao) {
    this._dispensacaoSelecionada = value;
  }

  public get dispensasaoPesquisa() {
    return this._dispensasaoPesquisa;
  }

  public set dispensasaoPesquisa(value) {
    this._dispensasaoPesquisa = value;
  }

  public get visibledFilter(): boolean {
    return this._visibledFilter;
  }

  public set visibledFilter(value: boolean) {
    this._visibledFilter = value;
  }

  public get dispensacaoDetalhes(): Dispensacao {
    return this._dispensacaoDetalhes;
  }

  public set dispensacaoDetalhes(value: Dispensacao) {
    this._dispensacaoDetalhes = value;
  }

  public get dispensasaoPacientFilter() {
    return this._dispensasaoPacientFilter;
  }
  public set dispensasaoPacientFilter(value) {
    this._dispensasaoPacientFilter = value;
  }
  public get backRouter(): Number {
    return this._backRouter;
  }
  public set backRouter(value: Number) {
    this._backRouter = value;
  }

  //retorna uma lista de todos os pacientes que possuem unidade de saúde igual a idUnidade ou prescrições igual a idUnidade apenas quando idUnidade for diferente de null
  public findAllPacienteByUnidadeSaudeDispensar(value: string): Observable<Paciente[]> {
    return this.http.post<Paciente[]>(`${SGS_URL.CORE}/${CORE.PACIENTE}/findPacienteEntityByUnidadeSaudeDispensar`, { filtro: value, referencia: this.loginService.user.referencia.id, idUnidade: this._dispensacaoSelecionada.unidadeSaude.id })
  }

  public updateItensDispensacao(item: ItemDispensacao)
  {

    this.dispensacaoSelecionada.itens_dispensados
    this.listaAuxiliarDispensacao

    item.itensEntrada.forEach(itemEntradaItem => {

      this.listaAuxiliarDispensacao.forEach(itemDispensadosAUX => {

        itemDispensadosAUX.itensEntrada.forEach(itensEntradaAUX => {

          if(itemEntradaItem.itemEntrada.id == itensEntradaAUX.itemEntrada.id)
          {
            itensEntradaAUX.itemEntrada.saldo = itemEntradaItem.itemEntrada.saldo;
          }

        });
      })
    });

    let quantidade_dispensada = 0;
    //Atualiza a dispensacaoSelecionada com a listaAuxiliarDispensacao
    this.dispensacaoSelecionada.itens_dispensados.forEach(itemDispensados => {
      quantidade_dispensada = 0;
      this.listaAuxiliarDispensacao.forEach(itemDispensadosAUX => {
          if(itemDispensados.itemPrescricao.id == itemDispensadosAUX.itemPrescricao.id){
            itemDispensados.itensEntrada.forEach(itensEntrada => {
              itemDispensadosAUX.itensEntrada.forEach(itensEntradaAUX => {
                if(itensEntrada.itemEntrada.id == itensEntradaAUX.itemEntrada.id){
                  itensEntrada.itemEntrada.saldo = itensEntradaAUX.itemEntrada.saldo
                  itensEntrada.itemEntrada.qtd_transferir = itensEntradaAUX.itemEntrada.qtd_transferir
                  quantidade_dispensada += itensEntradaAUX.itemEntrada.qtd_transferir;
                }
              });
            });
          }
        itemDispensados.quantidade_dispensada = quantidade_dispensada;
      });
    });
  
    
    this.atualizaEstoquePrescricao()
    this.dispensacaoSelecionada.itens_dispensados
    this.listaAuxiliarDispensacao
  }

  atualizaEstoquePrescricao()
  {
    let todosMedicamentos = new Map();
    //Pega todos os medicamentos da dispensação
    this.dispensacaoSelecionada.itens_dispensados.forEach(itemDispensado => {
      if(!todosMedicamentos.has(itemDispensado.itemPrescricao.medicamento.id))
      {
        todosMedicamentos.set(Number(itemDispensado.itemPrescricao.medicamento.id),0);
      }
    })

    //Soma as quantidades a transferir de todos os itemEntrada que possuem esse medicamento 
    todosMedicamentos.forEach((valor : number,chave : String)=>{
      this.dispensacaoSelecionada.itens_dispensados.forEach(itemDispensado=>{
         if(itemDispensado.itemPrescricao.medicamento.id==chave)
         {
          itemDispensado.itensEntrada.forEach(itemEntrada=>{
            valor+=itemEntrada.itemEntrada.qtd_transferir;
            todosMedicamentos.set(chave,valor);
          })
         }
      })
    })

    //Com a soma de toda quantidade a transferir de cada medicamento retira-se baseando no estoquetotal assim tendo o estoque 
    this.dispensacaoSelecionada.itens_dispensados.forEach(itemDispensado=>{
      todosMedicamentos.forEach((valor : number,chave : String)=>{
        if(itemDispensado.itemPrescricao.medicamento.id==chave)
        {          
          itemDispensado.itemPrescricao.qtdEstoque = itemDispensado.itemPrescricao.qtdEstoqueTotal - valor
        }
      })
    })

  }

}
