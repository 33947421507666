import { LoginService } from './../security/login/login.service';
import { UsuarioService } from '@app/core/usuario/usuario.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-inicio',
	templateUrl: './inicio.component.html',
	styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

	public isVisibledTitle:boolean;
	public titleFooterStatus: string;

	constructor(
		private usuarioService: UsuarioService,
		private loginService: LoginService
	) { }

	ngOnInit() {
		this.isVisibledTitle = environment.EXIBIR_HEADER;
		this.titleFooterStatus = environment.ESTADO_PROJETO;
	}

}
