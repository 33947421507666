import { Municipio } from "../regional/municipio.model";
import { Endereco } from "../shared/endereco/endereco.model";
import { Contato } from "../shared/models/contato.model";
import { Profissional } from "../profissional/profissional.model";

export class UnidadeAssistencia {
  constructor(
    public id: string,
    public nome: string,
    public cnes: string,
    public endereco: Endereco,
    public cnpj: string,
    public estabelecimento: string,
    public municipio:Municipio,
   public nome_resumido: string,
    public farmaceutico: Profissional,
    public email: string,
    public contato: Contato,
    public fl_municipio: boolean = false,
    public status: string,
    public tamanho:string
  )
  {}
}
