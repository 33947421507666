import { CacheService } from "./cache.service";

export class CacheServiceDebugger extends CacheService {

  public get(key: string): any {
    let retorno: any = super.get(key)
    return retorno
  }

  public set(key: string, newValue: any): any {
    let retorno: any = super.set(key, newValue)
    return retorno
  }

  public remove(key: string) {
    return super.remove(key)
  }

  public reload() {
    super.reload()
  }

  public clearSession() {
    super.clearSession()
  }

  public clear() {
    super.clear()
  }
}
