import { Referencia } from './../centro-custo/Referencia.model';
import { MotivoExclusao } from '@app/judicial/motivo-exclusao/motivo-exclusao.model';
import { CategoriaSituacao } from '@app/core/categoria-situacao/categoria-situacao.model';

export class SituacaoProcesso {
  constructor(
    public id: string,
    public referencia:Referencia,
    public descricao: string,
    public status: boolean,
    public motivo: boolean,
    public gerar_mapa: boolean,
    public nat: boolean,
    public editavel: boolean,
    public retorna_situacao_receita: boolean,
    public necessita_autorizacao: boolean,
    public autorizacao_tecnica: boolean,
    public motivos_exclusao: MotivoExclusao[],
    public categoria_situacao: CategoriaSituacao) {}
}
