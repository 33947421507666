import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Entrada } from './entrada.model';
import { ItemEntrada } from './item-entrada.model';
import { UsuarioService } from '@app/core/usuario/usuario.service';
import { LoginService } from '../security/login/login.service';
import { ListaJSON } from '../shared/default/lista-json.model';
import { SGS_URL, CORE } from '@app/app.api';
import { UnidadeSaude } from '../unidade-saude/unidade-saude.model';

@Injectable({
  providedIn: 'root'
})
export class RecebimentoService
{
  //filtro dos itens de Entradas
  private _filterAllItemIn = this.getEmptyFilterItenIn();
  private _entradaSelected: Entrada;

    //exibe/oculta o filtro de pesquisa
    private _visibledFilterConsultRecebiment: boolean = false;

  //construtor da classe
  constructor
  (
    private http: HttpClient,
    private userService:UsuarioService,
    private loginService:LoginService
  ) { }

  //busca todos as entradas abertas de uma determinada origem
  findAllIn(): Observable<ListaJSON<Entrada>> 
  {

    if(this.userService.infoUser.regionais && this.userService.infoUser.regionais.length > 1)
    {
      //MODO REGIONAL
      this._filterAllItemIn.regional  = this.userService.infoUser ? this.userService.infoUser.regionalorigin: null;
      this._filterAllItemIn.unidade_destino = this.userService.infoUser ? this.userService.infoUser.unidadeorigin: null;
    }
    else
    {
      //MODO UNIDADE
      this._filterAllItemIn.unidade = this.userService.infoUser ? this.userService.infoUser.unidadeorigin: null;
    }

    this._filterAllItemIn.referencia = this.loginService.user && this.loginService.user.referencia ? this.loginService.user.referencia.id : null;
    return this.http.post<ListaJSON<Entrada>>(`${SGS_URL.CORE}/${CORE.RECEBIMENTO}/searchReceipts`,  this._filterAllItemIn );
  }

    //busca todos as entradas abertas de uma determinada origem
  findAllInRecebimento(): Observable<ListaJSON<Entrada>> 
  {
    this._filterAllItemIn.referencia = this.loginService.user && this.loginService.user.referencia ? this.loginService.user.referencia.id : null;
    if(this._filterAllItemIn.unidade_destino !=null || this._filterAllItemIn.unidade_destino!= undefined)
    {
      if(!(this._filterAllItemIn.unidade_destino instanceof Object))
      {this._filterAllItemIn.unidade_destino = new UnidadeSaude(this._filterAllItemIn.unidade_destino,"",null,true,null,null,false,false)}
    }
    return this.http.post<ListaJSON<Entrada>>(`${SGS_URL.CORE}/${CORE.RECEBIMENTO}/searchReceiptsRecebimento`,  this._filterAllItemIn );
  }

  //busca todos os itens de entradas ordenados por uma coluna e direção informada
  getItensByIn(active, direction):Observable<ItemEntrada[]>
  {
    return this.http.post<ItemEntrada[]>(`${SGS_URL.CORE}/${CORE.RECEBIMENTO}/searchItensInbyIn`, 
    {
      id:this.entradaSelected.id, 
      column:active, 
      orientation:direction
    });
  }

  /**Atualiza o status da entrada selecionada
   * 
   * value :status do aceite da entrada true/false
   * recusa   :texto com o motivo da recusa da entradas
   */
  updateInput(value:boolean, motivo:string, send_email:boolean):Observable<Entrada>
  {
    let idUser    = this.loginService.user.id;
    let reference = this.loginService.user.referencia.id;

    return this.http.post<Entrada>(`${SGS_URL.CORE}/${CORE.RECEBIMENTO}/updateInAccept`, 
    {
      idIn: this.entradaSelected.id, 
      accept: value,
      user: idUser,
      reference: reference,
      motivo:motivo,
      email:send_email
    });
  }

      /**Atualiza o status da entrada selecionada
   * 
   * value :status do aceite da entrada true/false
   * recusa   :texto com o motivo da recusa da entradas
   */
       updateInputValorAceito(value:boolean, motivo:string, send_email:boolean):Observable<Entrada>
       {
         let idUser    = this.loginService.user.id;
         let reference = this.loginService.user.referencia.id;
         let entrada = this.entradaSelected;
         return this.http.post<Entrada>(`${SGS_URL.CORE}/${CORE.RECEBIMENTO}/updateInAccept`, 
         
         {
           entrada: entrada,
           accept: value,
           user: idUser,
           reference: reference,
           motivo:motivo,
           email:send_email
         });
       }

  /**Atualiza uma coleção de entradas
   * 
   * listIds  :Lista de ids de entradas que serão atualizadas
   * acceptIn :status do aceite da entrada true/false
   * recusa   :texto com o motivo da recusa da entradas
   */
  updateAllInput(listIds:string[], acceptIn:boolean, motivo:string, send_email:boolean):Observable<any>
  {
    let idUser    = this.loginService.user.id;
    let reference = this.loginService.user.referencia.id;

    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.RECEBIMENTO}/updateAllInAccept`, 
    {
      listIds: listIds, 
      accept: acceptIn,
      user: idUser,
      reference: reference,
      motivo:motivo,
      email:send_email
    });
  }

  //retorna um filtro vazio
  public getEmptyFilterItenIn()
  {
    return  {
      referencia:null,
      nota_fiscal: null,
      data_inicial: null,
      data_final: null,
      regional:  null,
      unidade:  null,
      unidade_destino: null,
      regional_destino: null,
      is_open: true,
      is_rejeite:false,
      is_aceite:false,
      active: 'aceite',
      direction: 'asc',
      pagesize:10,
      pageindex: 0,
      ls_id_regional:null,
      ls_id_unidade:null,
      ls_id_material: null,
      lote: null
    };
  }

  //Métodos sets e Gets
  public get filterAllItemIn() {
    return this._filterAllItemIn;
  }
  public set filterAllItemIn(value) {
    this._filterAllItemIn = value;
  }
  public get entradaSelected(): Entrada {
    return this._entradaSelected;
  }
  public set entradaSelected(value: Entrada) {
    this._entradaSelected = value;
  }
  public get visibledFilterConsultRecebiment(): boolean {
    return this._visibledFilterConsultRecebiment;
  }
  public set visibledFilterConsultRecebiment(value: boolean) {
    this._visibledFilterConsultRecebiment = value;
  }
}
