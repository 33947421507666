import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SGS_URL, CORE } from '@app/app.api';
import { ResponseComLength } from '@app/core/shared/models/response-com-length.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TipoParecerService {

  constructor(private http: HttpClient) { }

  public getTipoParecerByFilter(params: any): Observable<ResponseComLength> {
    return this.http.post<ResponseComLength>(
      `${SGS_URL.JUDICIAL}/${CORE.NAT_TIPO_PARECER}/pesquisar`,
      { params }
    );
  }
}
