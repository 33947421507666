import { Municipio } from '@app/core/regional/municipio.model';
import { ItemPrescricao } from '@app/core/prescricao/item-prescricao.model';

export class Ressarcimento {
  constructor(
    public id?: string,    
    public item_prescricao?: ItemPrescricao,
    public municipio?: Municipio,
    public destinatario?: string, //[1]= Município' [2]='União'
    public dt_cobranca?: Date,
    public dt_inicio_cobranca?: Date,
    public dt_fim_cobranca?: Date,
    public dt_pagamento?: Date,
    public vl_cobrado?: string,
    public num_documento_envi?: string,
    public num_documento?: string,
    public repasse?: string,
    public vl_ressarcido?: string,
    public tipo?: string, // "Padrão"  "Sequestro de Valores"
    public observacao?: string
  ) { }
}
