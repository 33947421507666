import { MatTooltipModule } from '@angular/material';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaveClearBackComponent } from './save-clear-back/save-clear-back.component';
import { MatIconModule, MatCardModule, MatInputModule, MatButtonModule } from '@angular/material';
import { NewClearComponent } from './new-clear/new-clear/new-clear.component';

  @NgModule({
    declarations: 
    [
      SaveClearBackComponent,
      NewClearComponent
  ],
  imports: 
  [    
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule
  ],
  exports:
  [
    NewClearComponent,
    SaveClearBackComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ButtonsModule { }
