import { LayoutModule } from "@angular/cdk/layout";
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import localePtBr from "@angular/common/locales/pt";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatDialogRef, MatFormFieldDefaultOptions, MatPaginatorIntl, MAT_FORM_FIELD_DEFAULT_OPTIONS, MAT_HAMMER_OPTIONS
} from "@angular/material";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatDialogModule } from "@angular/material/dialog";
import { MatStepperModule } from "@angular/material/stepper";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FluxoPrescricaoService } from "@app/core/fluxo-prescricao/fluxo-prescricao.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { StorageServiceModule } from "angular-webstorage-service";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { NgxLoadingModule } from "ngx-loading";
import { NgxMaskModule } from "ngx-mask";
import { ToastaModule } from "ngx-toasta";
import { DialogModule } from "primeng/dialog";
import { FileUploadModule } from "primeng/fileupload";
import { PickListModule } from "primeng/picklist";
import { AppComponent } from "./app.component";
import { AppRoutes } from "./app.routes";
import { AlertaModule } from "./core/alerta/alerta.module";
import { AlertaService } from "./core/alerta/alerta.service";
import { AvisosModule } from "./core/avisos/avisos.module";
import { AvisosService } from "./core/avisos/avisos.service";
import { CategoriaSituacaoService } from "./core/categoria-situacao/categoria-situacao.service";
import { CidService } from "./core/cid/cid.service";
import { DashbordService } from "./core/dashbord/dashbord.service";
import { datapickerMonthComponent } from "./core/dashbord/dashbotd_modal_filtro/inputs/datapicker-month/datapicker-month.component";
import { datapickerYearComponent } from "./core/dashbord/dashbotd_modal_filtro/inputs/datapicker-year/datapicker-year.component";
import { InputFilterComponent } from "./core/dashbord/dashbotd_modal_filtro/inputs/input-filter.component";
import { ModalFiltroComponent } from "./core/dashbord/dashbotd_modal_filtro/modal-filtro.component";
import { DispensacaoService } from "./core/dispensacao/dispensacao.service";
import { ItemEntradaModalComponent } from "./core/dispensacao/item-entrada-modal/item-entrada-modal.component";
import { ModalObservacoesPrescricaoComponent } from "./core/dispensacao/modal-observacoes-prescricao/modal-observacoes-prescricao.component";
import { EspecialidadeService } from "./core/especialidade/especialidade.service";
import { EstoqueMinimoService } from "./core/estoque-minimo/estoque-minimo.service";
import { EstoqueService } from "./core/estoque/estoque.service";
import { GrupoService } from "./core/grupo/grupo.service";
import { InicioComponent } from "./core/inicio/inicio.component";
import { MapaJudicialService } from "./core/mapa-planejamento/mapa-judicial.service";
import { MaterialService } from "./core/material/material.service";
import { MotivoDevolucaoService } from "./core/motivo-devolucao/motivo-devolucao.service";
import { NavegacaoComponent } from "./core/navegacao/navegacao.component";
import { NavegacaoService } from "./core/navegacao/navegacao.service";
import { NivelService } from "./core/nivel/nivel.service";
import { NotFoundComponent } from "./core/not-found/not-found.component";
import { PacienteService } from "./core/paciente/paciente.service";
import { ParametrosService } from "./core/parametros/parametros.service";
import { PrescricaoTransferenciaModalComponent } from "./core/prescricao/prescricao-transferencia-modal/prescricao-transferencia-modal.component";
import { PrescricaoService } from "./core/prescricao/prescricao.service";
import { ProfissionalService } from "./core/profissional/profissional.service";
import { RecebimentoService } from "./core/recebimento/recebimento.service";
import { ReceitaCadastroComponent } from "./core/receita/receita-cadastro/receita-cadastro.component";
import { ReceitaService } from "./core/receita/receita.service";
import { ReciboService } from "./core/recibo/recibo.service";
import { ReferenciamaterialService } from "./core/referencia-material/referenciamaterial.service";
import { ResponsavelCadastroModalComponent } from "./core/responsavel/responsavel-cadastro-modal/responsavel-cadastro-modal.component";
import { ResponsavelService } from "./core/responsavel/responsavel.service";
import { AuthInterceptor } from "./core/security/auth.interceptor";
import { LoggedInGuard } from "./core/security/loggedin.guard";
import { LoginComponent } from "./core/security/login/login.component";
import { LoginService } from "./core/security/login/login.service";
import { ModulosComponent } from "./core/security/modulos/modulos.component";
import { ButtonsModule } from "./core/shared/buttons/buttons.module";
import { CacheService } from "./core/shared/cache/cache.service";
import { CacheServiceDebugger } from "./core/shared/cache/session.cache.debugger";
import { AutocompleteSesModule } from "./core/shared/components/autocomplete-ses/autocomplete-ses.module";
import { ComponentsModule } from "./core/shared/components/components.module";
import { MultiselectSesModule } from "./core/shared/components/multiselect-ses/multiselect-ses.module";
import { PickListSesModule } from "./core/shared/components/pick-list-ses/pick-list-ses.module";
import { SelectedSesModule } from "./core/shared/components/selected-ses/selected-ses.module";
import { DebugCardComponent } from "./core/shared/debug/debug-card/debug-card.component";
import { DebugComponent } from "./core/shared/debug/debug.component";
import { ModalOpen } from "./core/shared/debug/modal-open";
import { DefaultService } from "./core/shared/default/default.service";
import { DialogPersonalModule } from "./core/shared/dialog-personal/dialog.personal.module";
import { InformDialogComponent } from "./core/shared/dialog-personal/inform-dialog/inform-dialog.component";
import { DocumentoService } from "./core/shared/documento/documento.service";
import { EnderecoService } from "./core/shared/endereco/endereco.service";
import { HistoricoService } from "./core/shared/historico/historico.service";
import { MaterialImportBasicModule } from "./core/shared/material-import-basic.module";
import { MensagemAvisoModule } from "./core/shared/mensagem-aviso-modal/mensagem-aviso.module";
import { MessageService } from "./core/shared/message.service";
import { ModalEnderecoComponent } from "./core/shared/modais/modal-endereco/modal-endereco.component";
import { ModalMedicamentoComponent } from "./core/shared/modais/modal-medicamento/modal-medicamento.component";
import { customMatPaginator } from "./core/shared/models/customMatPaginator";
import { MunicipioService } from "./core/shared/municipio/municipio.service";
import { PipeModule } from "./core/shared/pipe/pipe.module";
import { VerificarService } from "./core/shared/verificar/verificar.service";
import { SituacaoProcedimentoService } from "./core/situacao-procedimento/situacao-procedimento.service";
import { SituacaoProcessoService } from "./core/situacao-processo/situacao-processo.service";
import { TipoAtendimentoService } from "./core/tipo-atendimento/tipo-atendimento.service";
import { TipoEstabelecimentoService } from "./core/tipo-estabelecimento/tipo-estabelecimento.service";
import { TipoRegistroService } from "./core/tipo-registro/tipo-registro.service";
//SERVICES
import { TransferenciaEntreModulosService } from "./core/transferencia-entre-modulos/transferencia-entre-modulos.service";
import { UnidadeAssistenciaService } from "./core/unidade-assistencia/unidade-assistencia.service";
import { UnidadeSaudeService } from "./core/unidade-saude/unidade-saude.service";
import { UsuarioService } from "./core/usuario/usuario.service";
import { AlvaraService } from "./judicial/alvara/alvara.service";
import { AtualizacaoPrescricaoService } from "./judicial/atualizacao-prescricao/atualizacao-prescricao.service";
import { ContasBancariasService } from "./judicial/contas-bancarias/contas-bancarias.service";
import { DevolucaoValoresService } from "./judicial/devolucao-valores/devolucao-valores.service";
import { MotivoSequestroService } from "./judicial/motivo-sequestro/motivo-sequestro.service";
import { ModalModelosProtocoloComponent } from "./judicial/protocolo/modal-modelos-protocolo/modal-modelos-protocolo.component";
import { RessarcimentoService } from "./judicial/ressarcimento/ressarcimento.service";
import { TipoDocumentoService } from "./judicial/tipo-documento/tipo-documento.service";
import { PrescricaoTuberculoseService } from "./tuberculose/prescricao-tuberculose/prescricao-tuberculose.service";
import { RelatorioTuberculoseService } from "./tuberculose/relatorio/relatorio-tuberculose.service";


registerLocaleData(localePtBr);

const appearance: MatFormFieldDefaultOptions = {
  appearance: "outline",
};

@NgModule({
  declarations: [
    AppComponent,
    datapickerMonthComponent,
    datapickerYearComponent,
    InicioComponent,
    InputFilterComponent,
    LoginComponent,
    ModalEnderecoComponent,
    ModalFiltroComponent,
    ModalMedicamentoComponent,
    NavegacaoComponent,
    NotFoundComponent,
    ModulosComponent,
    InformDialogComponent,
    ReceitaCadastroComponent,
    ResponsavelCadastroModalComponent,
    ModalObservacoesPrescricaoComponent,
    ItemEntradaModalComponent,
    ModalModelosProtocoloComponent,
    PrescricaoTransferenciaModalComponent,
    DebugComponent,
    DebugCardComponent,
    ModalOpen
    //AvisosComponent
  ],
  imports: [
    AppRoutes,
    BrowserAnimationsModule,
    BrowserModule,
    ButtonsModule,
    MensagemAvisoModule,
    CurrencyMaskModule,
    DialogModule,
    DialogPersonalModule,
    ComponentsModule,
    FileUploadModule,
    FormsModule,
    HttpClientModule,
    LayoutModule,
    MatMomentDateModule,
    NgbModule,
    PickListModule,
    PipeModule,
    ReactiveFormsModule,
    StorageServiceModule,
    MaterialImportBasicModule,
    AlertaModule,
    AvisosModule,
    ToastaModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    NgxMaskModule.forRoot({}),
    MatStepperModule,
    MatDialogModule,
    AutocompleteSesModule,
    MultiselectSesModule,
    SelectedSesModule,
    PickListSesModule,
    ComponentsModule,
  ],
  providers: [
    TransferenciaEntreModulosService,
    CacheService,
    CacheServiceDebugger,
    ContasBancariasService,
    DashbordService,
    DefaultService,
    DevolucaoValoresService,
    DispensacaoService,
    DocumentoService,
    EnderecoService,
    EspecialidadeService,
    GrupoService,
    HistoricoService,
    LoggedInGuard,
    LoginService,
    MapaJudicialService,
    MaterialService,
    MessageService,
    MotivoSequestroService,
    MunicipioService,
    NavegacaoService,
    PacienteService,
    ParametrosService,
    PickListModule,
    PrescricaoService,
    ProfissionalService,
    RecebimentoService,
    ReceitaService,
    ReciboService,
    ResponsavelService,
    RessarcimentoService,
    SituacaoProcessoService,
    MotivoDevolucaoService,
    TipoAtendimentoService,
    TipoDocumentoService,
    TipoEstabelecimentoService,
    TipoRegistroService,
    UnidadeAssistenciaService,
    UnidadeSaudeService,
    UsuarioService,
    AlvaraService,
    VerificarService,
    EstoqueService,
    AtualizacaoPrescricaoService,
    PrescricaoTuberculoseService,
    NivelService,
    ReferenciamaterialService,
    RelatorioTuberculoseService,
    AlertaService,
    AvisosService,
    CidService,
    CategoriaSituacaoService,
    FluxoPrescricaoService,
    EstoqueMinimoService,
    SituacaoProcedimentoService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: customMatPaginator },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: appearance },
    {
      provide: MAT_HAMMER_OPTIONS,
      useValue: { cssProps: { userSelect: true } },
    },
    { provide: MatDialogRef },
  ],
  entryComponents: [
    ModalMedicamentoComponent,
    ModalEnderecoComponent,
    ModalFiltroComponent,
    InformDialogComponent,
    ReceitaCadastroComponent,
    ResponsavelCadastroModalComponent,
    ModalObservacoesPrescricaoComponent,
    ItemEntradaModalComponent,
    ModalModelosProtocoloComponent,
    PrescricaoTransferenciaModalComponent,
    ModalOpen
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
