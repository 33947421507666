import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telefone'
})
export class TelefonePipe implements PipeTransform {

  transform(value: any, args?: any): any
  {   
    if(value == null) return null;   
    value = value.trim(); 

    if(value.length == 8) return value.substring(0, 4) + " " +  value.substring(4);   
    if(value.length == 9) return value.substring(0, 5) + " " +  value.substring(5);   
    if(value.length == 10) return "(" + value.substring(0, 2) + ") " + value.substring(2, 6) + " " +  value.substring(6);  
    if(value.length == 11) return "(" + value.substring(0, 2) + ") " +  value.substring(2, 7) + " " +  value.substring(7);  

    return value;
  }
}
