import { Observable } from 'rxjs';
import { Injectable } from "@angular/core"
import { SGS_URL, CORE } from '@app/app.api';
import { HttpClient } from '@angular/common/http'
import { AtualizacaoPrescricao } from './atualizacao-prescricao.model';
import { LoginService } from '@app/core/security/login/login.service';
import { MaterialService } from '@app/core/material/material.service';

@Injectable({
  providedIn: 'root'
})
export class AtualizacaoPrescricaoService {

  public atualizacaoPrescricao: AtualizacaoPrescricao

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private materialService: MaterialService) {
  }

  getNovaAtualizacaoPrescricao(): AtualizacaoPrescricao {
    let material = this.materialService.getNovoMaterial()
    return this.atualizacaoPrescricao = new AtualizacaoPrescricao(material, "", "", null, null)
  }

  updatePrescricoes(atualizacaoPrescricao: AtualizacaoPrescricao): Observable<any> {
    const id_usuario = this.loginService.user.id
    const id_referencia = this.loginService.user.referencia.id
    return this.http.post<any>(`${SGS_URL.JUDICIAL}/${CORE.PRESCRICAO}/updatePrescricao/${id_referencia}/${id_usuario}`, atualizacaoPrescricao)
  }

}
