import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListaJSON } from '../shared/default/lista-json.model';
import { DescricaoModel } from '../shared/descricao/descricao.model';
import { TipoAtendimento } from '../tipo-atendimento/tipo-atendimento.model';
import { Sigtap } from './material-cadastro/sigtap/sigtap.model';
import { Material } from './material.model';
import { ComplementoCadastro } from './material-cadastro/complemento/complemento-cadastro.model';
import { Complemento } from './material-cadastro/complemento/complemento.model';
import { MatTableDataSource } from '@angular/material';
import { LoginService } from "../security/login/login.service";
import { SGS_URL, JUDICIAL, CORE } from '@app/app.api';
import { SituacaoProcesso } from '@app/core/situacao-processo/situacao-processo.model';
import { MaterialPesquisa } from './material-pesquisa/material-pesquisa.model';
import { Referencia } from '../centro-custo/Referencia.model';

@Injectable()
export class MaterialService {

  public materiais: Material[] = []
  public material: Material
  public complemento: Complemento
  public complementoCadastro: ComplementoCadastro
  private referencia: any = this.loginService.user.referencia;

  public lsIdsSelecionadosMultiSelect: any[] = [];
  public haItensSelecionadosMultiSelect = false;

  public lsReferenciasDisponiveis: Referencia[] = []
  public lsReferenciasMaterial: Referencia[] = []
  public lsReferenciasSelecionadas: Referencia[] = []

  dataSource = new MatTableDataSource<Material>(this.materiais)

  constructor(private http: HttpClient, private loginService: LoginService) { }

  salvarComplemento(complementoCadastro: ComplementoCadastro): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.MATERIAL}/cadastroComplemento/${id_referencia}/${id_usuario}`, complementoCadastro)
  }

  limparListasReferencia()
  {
    this.lsReferenciasDisponiveis=[]
    this.lsReferenciasMaterial=[]
    this.lsReferenciasSelecionadas=[]
  }

  getSituacoes(): Observable<SituacaoProcesso[]> {
    return this.http.get<any>(`${SGS_URL.CORE}/${CORE.SITUACAO_PROCESSO}/a`)
  }

  getMaterialById(id): Observable<Material> {
    return this.http.get<any>(`${SGS_URL.CORE}/${CORE.MATERIAL}/id/${id}`)
  }

  getMaterialByDescOrCiasc(value: string): Observable<Material[]> {
    this.referencia = this.loginService.user.referencia.id;
    return this.http.post<Material[]>(`${SGS_URL.CORE}/${CORE.MATERIAL}/listarMedicamentosActive`, { medicamento: value, referencia: this.referencia });
  }

  getMaterialByDescOrCiascAndRerencia(value: string, referencia: any): Observable<Material[]> {
    this.referencia = referencia;
    return this.http.post<Material[]>(`${SGS_URL.CORE}/${CORE.MATERIAL}/listarMedicamentosActive`, { medicamento: value, referencia: this.referencia });
  }

  getSigtaps(filtro): Observable<ListaJSON<Sigtap>> {
    return this.http.post<ListaJSON<Sigtap>>(`${SGS_URL.CORE}/${CORE.MATERIAL}/getSigtaps/`, filtro)
  }

  getMateriais(filtro: String): Observable<ListaJSON<Material>> {
    return this.http.post<ListaJSON<Material>>(`${SGS_URL.CORE}/${CORE.MATERIAL}/getMateriais/`, {filtro: filtro, referencia: this.loginService.user.referencia.id})
  }

  getTiposAtendimentos(): Observable<any> {
    return this.http.get<ListaJSON<TipoAtendimento>>(`${SGS_URL.CORE}/${CORE.TIPO_ATENDIMENTO}/a`)
  }

  incluirSigtap(idSigtap: number, idMaterial: number) {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.MATERIAL}/incluirSigtap`, { sigtap: idSigtap, material: idMaterial, user: id_usuario, references: id_referencia })
  }

  getSigtapsByMaterial(idMaterial: number): Observable<ListaJSON<Sigtap>> {
    return this.http.get<ListaJSON<Sigtap>>(`${SGS_URL.CORE}/${CORE.MATERIAL}/getSigtapsByMaterial/${idMaterial}`)
  }

  removerSigtap(idSigtap: any, idMaterial: number): any {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.MATERIAL}/removerSigtap`, { sigtap: idSigtap, material: idMaterial, user: id_usuario, references: id_referencia })
  }

  getTiposMedicamentos(): Observable<DescricaoModel[]> {
    return this.http.get<any>(`${SGS_URL.CORE}/${CORE.TIPO_MEDICAMENTO}/a`)
  }

  getComplemento(idMaterial): Observable<Complemento> {
    const idReferencia = this.loginService.user.referencia.id;
    return this.http.get<Complemento>(`${SGS_URL.CORE}/${CORE.MATERIAL}/getComplemento/${idMaterial}/${idReferencia}`)
  }

  buscaMedicamentosByReferenciaMaterialVinculado(nome: string, tipo?: any): Observable<Material[]> {
    let convertString = tipo != null ? tipo.toString() : null
    return this.http.post<Material[]>(`${SGS_URL.CORE}/${CORE.MATERIAL}/buscaMedicamentosByReferenciaMaterialVinculado`, { nome: nome, tipo: convertString, referencia: this.loginService.user.referencia.id });
  }

  getNovoComplemento(): Complemento {
    let tipoAtendimento = new TipoAtendimento("", "", "")
    let situacaoProcesso = new SituacaoProcesso("", null, "", true, false, false, false, false, false, false, false, null, null)
    let medicamento = new DescricaoModel("", "", "")
    let material = new Material("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", false, "", "", [], null, "",null)
    let origem = new DescricaoModel("", "", "")
    return this.complemento = new Complemento("", "", "", "", tipoAtendimento, situacaoProcesso, medicamento, material, origem)
  }

  getNovoMaterial(): Material {
    return this.material = new Material("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", false, "", "", [], null, "","")
  }

  getNovoComplementoCadastrar(): ComplementoCadastro {
    return this.complementoCadastro = new ComplementoCadastro("", "", "", "", "", "", "", "", "")
  }

  listarTipoMedicamentoMaterial(nome: string): Observable<DescricaoModel[]> {
    return this.http.post<DescricaoModel[]>(`${SGS_URL.CORE}/${CORE.TIPO_MEDICAMENTO}/listarMaterial/${nome}/`, {})
  }

  listarOrigemMaterial(nome: string): Observable<DescricaoModel[]> {
    return this.http.post<DescricaoModel[]>(`${SGS_URL.CORE}/${JUDICIAL.ORIGEM_MEDICAMENTO}/listarMaterial/${nome}/`, {})
  }

  listar(materialPesquisa: MaterialPesquisa): Observable<ListaJSON<Material>> {
    return this.http.post<ListaJSON<Material>>(`${SGS_URL.CORE}/${CORE.MATERIAL}/p`, materialPesquisa)
  }

  listFiltered(value: string): Observable<Material[]> {
    return this.http.post<Material[]>(`${SGS_URL.CORE}/${CORE.MATERIAL}/findAllMedicamentoActive`, { filter: value })
  }

  buscarMateriaisByPesquisa(materialPesquisa: MaterialPesquisa): Observable<ListaJSON<Material>> {
    return this.http.post<ListaJSON<Material>>(`${SGS_URL.CORE}/${CORE.MATERIAL}/p`, materialPesquisa)
  }

  setMateriais(materiais: Material[]) {
    this.materiais = materiais;
  }

  //busca medicamentos filtrando
  buscaMedicamentosByNome(nome: string, tipo?: any): Observable<Material[]> {
    let convertString = tipo != null ? tipo.toString() : null
    return this.http.post<Material[]>(`${SGS_URL.CORE}/${CORE.MATERIAL}/buscaMedicamentosByNome`, { nome: nome, tipo: convertString, referencia: this.loginService.user.referencia.id });
  }

  buscarByCodCiasc(value: string): Observable<Material[]> {
    return this.http.post<Material[]>(`${SGS_URL.CORE}/${CORE.MATERIAL}/buscarByCodCiasc`, {codigo: value, referencia: this.loginService.user.referencia.id});
  }

  buscarByCodSES(value: string): Observable<Material[]> {
    return this.http.post<Material[]>(`${SGS_URL.CORE}/${CORE.MATERIAL}/buscarByCodSES`, {codigo: value, referencia: this.loginService.user.referencia.id});
  }

  buscarAllReferencias():Observable<Referencia[]>{
    return this.http.post <Referencia[]> (`${SGS_URL.CORE}/${CORE.REFERENCIA}/todos`, {});
  }

  getReferenciasByMedicamento(value:string):Observable<Referencia[]>{
    return this.http.post <Referencia[]> (`${SGS_URL.CORE}/${CORE.REFERENCIA}/getReferenciasByMedicamento`, {idMaterial:value});
  }

  public getAllMedicamentos(){
    return this.http.post <any[]> (`${SGS_URL.CORE}/${CORE.MATERIAL}/findAllMedicamentoActive`, {filter:''});
  }

  public getNewLsIdsSelecionados() {
    this.lsIdsSelecionadosMultiSelect = [];
  }

}
