import { Prescricao } from '@app/core/prescricao/prescricao.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, TooltipPosition } from '@angular/material';
import { FormControl } from '@angular/forms';

import { MessageService } from '@app/core/shared/message.service';

import { NavegacaoService } from './../../navegacao/navegacao.service';
import { ReceitaService } from '../receita.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { PrescricaoService } from '@app/core/prescricao/prescricao.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PacienteService } from '@app/core/paciente/paciente.service';

@Component({
  selector: 'app-receita-cadastro',
  templateUrl: './receita-cadastro.component.html',
  styleUrls: ['./receita-cadastro.component.scss']
})
export class ReceitaCadastroComponent implements OnInit {

  //o paginator da tabela prescrição
  @ViewChild("pagintorPrescricao") private paginatorPrescricao: MatPaginator;

  private lsIdsPrescricoesSelecionadas: string[] = []
  private dataSourcePrescricao = new MatTableDataSource<Prescricao>();
  displayedColumns: string[] = ['ciasc', 'medicamento', 'data_inicio', 'periodicidade', 'situacao_processo', 'botao_check'];

  private paginatorPrescricaoSubscribe: Subscription;

  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);

  loading = false;

  public semArquivo = "<Nenhum arquivo selecionado>";
  private showErroDownloadInvalido: boolean = false;

  constructor(
    private prescricaoService: PrescricaoService,
    private receitaService: ReceitaService,
    private messageService: MessageService,
    private pacienteService: PacienteService,
    private navegacaoService: NavegacaoService,
    public dialogRef: MatDialogRef<ReceitaCadastroComponent>
  ) { }

  ngOnInit() {
    if (!this.navegacaoService.permissaoReceita.visualizar) this.receitaService.recallModalPesquisa = false;
    this.receitaService.recallModalCadastro = false;

    this.paginatorPrescricao.pageSize = 5;
    this.getPrescricoes();
  }

  //carrega o paginator tap
  ngAfterViewInit(): void {
    this.paginatorPrescricaoSubscribe = this.paginatorPrescricao.page.pipe(tap(() => this.getPrescricoes())).subscribe();
  }

  save() {
    if (this.verificaCamposRequired()) {
      this.loading = true
      this.lsIdsPrescricoesSelecionadas = [];
      this.dataSourcePrescricao.data.forEach(item => {
        if(item.prescricaoChecked)
        this.lsIdsPrescricoesSelecionadas.push(item.id)
      })
      
      this.receitaService.cadastrarReceita(this.lsIdsPrescricoesSelecionadas).subscribe(result => {
        this.loading = false
        this.receitaService.recallModalCadastro = false;
        this.messageService.addToast("Operação realizada com sucesso! ", "Sucesso!", "success");
        this.messageService.addToast("E-mail enviado com sucesso!", "Sucesso!", "success");
        this.receitaService.eventUpdateReceita.emit();
        this.close();
      },
        response => {
          this.loading = false
          this.receitaService.recallModalCadastro = false;
          this.messageService.addToast("Operação realizada com sucesso! ", "Sucesso!", "success");
          this.messageService.addToast(response.error.descricao, "Aviso!", "warning");
          this.receitaService.eventUpdateReceita.emit();
          this.close();
        }
      );
    }else {
      this.messageService.addToastModal("Aviso!","Campos Obrigatórios não Preenchidos", 5, "warning");
    }
  }

  clear() {
    this.close();
    this.receitaService.eventReloadModalReceita.emit();
  }

  limparTabelaPrescricoes() {
    this.dataSourcePrescricao = null;
    this.lsIdsPrescricoesSelecionadas = [];
    this.getPrescricoes();
  }

  private close() {
    this.receitaService.receita = this.receitaService.getNewReceita();
    this.dialogRef.close();
  }

  myUploader(event) {
    if (!event) return;

    if(event.type.search('application/pdf') < 0){
      this.showErroDownloadInvalido = true;
      this.receitaService.receita.file = null;
      this.receitaService.receita.file_name = null;
      this.messageService.addToastModal("Aviso!", "São permitidos apenas arquivos em formato PDF", 4, "warning");
      return;
    }

    // max permitido 1mb
    if (event.size > 1248576) {
      this.showErroDownloadInvalido = true;
      this.receitaService.receita.file = null;
      this.receitaService.receita.file_name = null;
      this.messageService.addToastModal("Erro!", "Receita não pode ser maior que 1MB", 4, "error");
      return;
    }

    this.showErroDownloadInvalido = false;
    this.receitaService.receita.file = event;
    this.receitaService.receita.file_name = event.name;
  }

  verificaEmail() {
    if (this.receitaService.receita && this.receitaService.receita.email_comaj && this.receitaService.receita.email_comaj.trim() != "") {
      return this.receitaService.receita.email_comaj.search(/@/gi) != -1 ? '' : 'Não encontrado @';
    }
  }

  // Busca as prescrições de um determinado processo
  getPrescricoes() {
    this.loading = true;

    if (this.paginatorPrescricao != null) {
      this.prescricaoService.prescricaoFilterPaciente.pageSize = this.paginatorPrescricao.pageSize;
      this.prescricaoService.prescricaoFilterPaciente.pageIndex = this.paginatorPrescricao.pageIndex;
    }

    this.prescricaoService.getAllPrescricaoByPaciente(this.pacienteService.paciente.id).subscribe(result => {
      if (result && result.list && result.list.length > 0) {
        this.prescricaoService.listPrescricao = result.list;
        this.prescricaoService.listPrescricaoLenght = result.length;

        this.dataSourcePrescricao = new MatTableDataSource<Prescricao>(result.list);
        for (let prescricao of result.list) {
          prescricao.prescricaoChecked = this.deixaCheckAtivo(prescricao.id);
        }
        this.paginatorPrescricao.length = result.length;
        this.loading = false;
      } else {
        this.loading = false;
        this.dataSourcePrescricao = new MatTableDataSource<Prescricao>();
        this.prescricaoService.listPrescricaoLenght = 0;
        this.paginatorPrescricao.length = 0;
      }
    });
  }

  verificaCamposRequired() {
    if (this.receitaService.receita.data_apresentacao == null || this.receitaService.receita.data_apresentacao == undefined) {
      this.messageService.addToastModal("Aviso", "Campo Data de apresentação é Obrigatório", 7, "warning");
      return false;
    }
    if (this.receitaService.receita.data_receituario == null || this.receitaService.receita.data_receituario == undefined) {
      this.messageService.addToastModal("Aviso", "Campo Data de receituário é Obrigatório", 7, "warning");
      return false;
    }
    if (this.receitaService.receita.file_name == null || this.receitaService.receita.file_name == undefined) {
      this.messageService.addToastModal("Aviso", "Nenhum arquivo selecionado", 7, "warning");
      return false;
    }
    if(this.receitaService.receita.itens_nao_cadastrados != true)
    {
      if(this.lsIdsPrescricoesSelecionadas.length < 1)
      {
        this.messageService.addToastModal("Aviso", "Campo apresentada ou Receita com itens não cadastrados no sistema sâo obrigatórios", 7, "warning");
        return false;
      }
    }

    return true;
  }

  // Ordena a tabela
  sortPrescricao(event: MatSort) {
    this.prescricaoService.prescricaoFilter.direction = event ? event.direction : "asc";
    this.prescricaoService.prescricaoFilter.sort = event ? event.active : "data_prescricao";
    this.getPrescricoes();
  }

  // Seleciona os ids das Prescricoes
  selectPrescricao(prescricao: Prescricao) {
    // Se já existe na lista a ser enviada para o backend, remove, se não, adiciona
    if (this.lsIdsPrescricoesSelecionadas.indexOf(prescricao.id) !== -1) {
      this.lsIdsPrescricoesSelecionadas.splice(this.lsIdsPrescricoesSelecionadas.indexOf(prescricao.id), 1);
    } else {
      this.lsIdsPrescricoesSelecionadas.push(prescricao.id);
    }
    this.atualizaCheckNoDataSource(prescricao);
  }

  // Atualiza a flag dentro do datasource
  atualizaCheckNoDataSource(prescricao: Prescricao) {
    this.dataSourcePrescricao.filteredData[this.dataSourcePrescricao.filteredData.indexOf(prescricao)].prescricaoChecked = this.deixaCheckAtivo(this.dataSourcePrescricao.filteredData[this.dataSourcePrescricao.filteredData.indexOf(prescricao)].id);
  }

  // Ativa ou não o check na coluna selecionada da tabela de prescrições
  deixaCheckAtivo(idPrescricao: string) {
    if (idPrescricao) {
      if (this.lsIdsPrescricoesSelecionadas.indexOf(idPrescricao) !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }

}
