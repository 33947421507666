import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PacienteService } from "@app/core/paciente/paciente.service";

@Component({
  selector: "ses-paciente-multiselesct",
  templateUrl: "./paciente-multiselesct.component.html",
  styleUrls: ["./paciente-multiselesct.component.scss"],
})
export class PacienteMultiselesctComponent implements OnInit {
  // @Input() filtroNivel: boolean = true;
  @Input() required: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */
  @Output() idsSelecionadas = new EventEmitter<string[]>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any[]>();

  constructor(private pacienteService: PacienteService) {}

  public lsPaciente: any[] = [];
  public pacientelSelecionadas: any[] = [];

  ngOnInit() {
    this.carregaPaciente("a");
    this.limparEvent.subscribe({
      next: (resp) => {
        this.limpar();
      },
    });
  }

  public carregaPaciente(nome: string) {
    this.pacienteService.buscarPacientesByNome(nome).subscribe({
      next: (response) => {
        this.lsPaciente = response;
      },
    });
  }

  filtraPesquisa(event: any) {
    if (event.target.value && event.target.value.length >= 3) {
      this.carregaPaciente(event.target.value);
    }
  }

  public limpar() {
    this.pacientelSelecionadas = [];
    this.idsSelecionadas.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  selecionaRegional(event?: any) {
    let listIds: string[] = [];
    this.pacientelSelecionadas.forEach((regional) => {
      return listIds.push(regional.id);
    });
    this.idsSelecionadas.emit(listIds);
    this.elementosCompletoSelecionadas.emit(this.lsPaciente);
  }
}
