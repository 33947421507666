import { Component, Input } from '@angular/core';

@Component({
  selector: 'mensagem-aviso-modal',
  templateUrl: './mensagem-aviso-modal.component.html',
  styleUrls: ['./mensagem-aviso-modal.component.scss']
})
export class MensagemAvisoModalComponent {
  
  @Input() titulo: string = "Título";      // Título do aviso
  @Input() mensagem: string = "Mensagem";  // Mensgem do aviso
  @Input() tipoAviso: string = "warning";  // Tipo do aviso, warning, error, sucess
  @Input() show: boolean = false;          // Aparece ou não o aviso
  @Input() timer: number = 7;              // Tempo em segundo para desaparecer mensagem (opcional)
  @Input() corFundo: string;               // Cor do fundo do aviso
  @Input() icone: string;                  // Ícone do toast

  constructor() {}

  timerParaDesaparecer() {
    this.show = true;
    setTimeout(() => {
      this.show = false;
    }, this.timer * 1000);
  }

  fecharToast() {
    this.timer = 0;
    this.timerParaDesaparecer();
  }

}
