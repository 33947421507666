import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MaterialService } from "@app/core/material/material.service";
import { LoginService } from "@app/core/security/login/login.service";

@Component({
  selector: "ses-material-autocomplete",
  templateUrl: "./material-autocomplete.component.html",
  styleUrls: ["./material-autocomplete.component.scss"],
})
export class MaterialAutocompleteComponent implements OnInit {
  @Input() required: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();
  @Input() id: string = "material";

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */
  @Output() idSelecionada = new EventEmitter<string>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any>();

  constructor(
    private loginService: LoginService,
    private materialService: MaterialService
  ) {}

  public materialSelecionada: any;
  public lsMaterial: any[] = [];
  private lastNameSearch: string = "";

  ngOnInit() {
    this.limparEvent.subscribe({
      next: (resp) => {
        this.limpar();
      },
    });
  }

  selecionarMaterial() {
    this.idSelecionada.emit(this.materialSelecionada.id);
    this.elementosCompletoSelecionadas.emit(this.materialSelecionada);
  }

  public filter(evento: any) {
    let name = evento.target.value;
    if (name.length >= 3 && name !== this.lastNameSearch) {
      this.materialService
        .getMaterialByDescOrCiascAndRerencia(
          name,
          this.loginService.user.referencia.id
        )
        .subscribe((resp) => {
          if (resp) {
            this.lastNameSearch = name;
            this.lsMaterial = resp;
          }
        });
    } else {
      this.lsMaterial = [];
    }
  }

  public limpar() {
    this.materialSelecionada = null;
    this.idSelecionada.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  public displayFn(material: any) {
    return material ? material.descricao : "";
  }
}
