import { Especialidade } from "../especialidade/especialidade.model";
import { Endereco } from "@app/core/shared/endereco/endereco.model";
import { Contato } from "@app/core/shared/models/contato.model";
import { TipoRegistro } from "@app/core/tipo-registro/tipo-registro.model";

export class Profissional {
  constructor(public id?: string,
              public numero_registro?: string,
              public status?: string,
              public cpf?: string,
              public nome?: string,
              public endereco?: Endereco,
              public email?: string,
              public contatos?: Contato[],
              public tipo_registro?: TipoRegistro,
              public especialidades?: Especialidade[],
              public uf?: string,
              public juiz?: boolean) {}
}
