import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-new-clear',
  templateUrl: './new-clear.component.html',
  styleUrls: ['./new-clear.component.scss']
})
export class NewClearComponent {

  @Input() private btnTextNew:string = 'Novo';    // texto dentro do botão New [defaul: Novo]
  @Input() private btnTextClear:string = 'Limpar'; // texto dentro do botão Clear [defaul: Limpar]
  @Input() private btnTextClearVisibled:string = "Filtros";   // texto dentro do botão Visibled [defaul: Pesquisar]

  @Input() private btnNewVisible:boolean = true;    // Exibe/oculta o botão Novo
  @Input() private btnClearVisible:boolean = true;  // Exibe/oculta o botão Limpar
  @Input() private btnVisible:boolean = false;  // Exibe/oculta o botão Limpar

  @Input() private iconButtonNew:string = 'add_circle_outline';  // Exibe/oculta o botão Limpar

  @Input() private visibled = false; //emitido pelo votão mais informações

  @Output('new')    newEvent:EventEmitter<any>  = new EventEmitter();
  @Output('clear')  clearEvent:EventEmitter<any> = new EventEmitter();
  @Output('visible')  visibleEvent:EventEmitter<any> = new EventEmitter();

  new()
  {
    this.newEvent.emit();
  }
  clear()
  {
    this.clearEvent.emit();
  }

  visibleDetail()
  {
    this.visibled = !this.visibled;
    this.visibleEvent.emit(this.visibled);
  }
}
