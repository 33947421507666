import { Paciente } from './../paciente/paciente.model';
import { Prescricao } from '../prescricao/prescricao.model';

export class Receita{
  constructor(public id: string,
              public file:File,
              public file_name:string,
              public descricao: string,
              public prescricao: Prescricao,
              public paciente:Paciente,
              public data_apresentacao: Date,
              public data_receituario: Date,
              public email_comaj: string,         
              public email_flag: boolean,
              public itens_nao_cadastrados: Boolean,
              ){}
}
