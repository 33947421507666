import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JutificativaAnaliseTecnicaService } from '@app/core/nat/justificativa-analise-tecnica/jutificativa-analise-tecnica.service';
import { JustificativaAnaliseTecnicaFilter } from '@app/core/shared/models/justificativa-analise-tecnica-filter';
import { PosicaoAnaliseTecnicaFilter } from '@app/core/shared/models/posicao-analise-tecnica-filter';

@Component({
  selector: 'ses-justificativa-analise-tecnica-multiselect',
  templateUrl: './justificativa-analise-tecnica-multiselect.component.html',
  styleUrls: ['./justificativa-analise-tecnica-multiselect.component.scss']
})
export class JustificativaAnaliseTecnicaMultiselectComponent implements OnInit {
  // @Input() filtroNivel: boolean = true;
  @Input() required: boolean = true;
  @Input() filtroAtivo: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */
  @Output() idsSelecionadas = new EventEmitter<string[]>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any[]>();

  constructor( private service: JutificativaAnaliseTecnicaService) {}

  public lsPaciente: any[] = [];
  public pacientelSelecionadas: any[] = [];
  public posicaoAnaliseTecnicaFilter: PosicaoAnaliseTecnicaFilter =  new PosicaoAnaliseTecnicaFilter()
  public filter: JustificativaAnaliseTecnicaFilter = new JustificativaAnaliseTecnicaFilter();

  ngOnInit() {
    this.carregaPaciente();
    this.limparEvent.subscribe({
      next: (resp) => {
        this.limpar();
      },
    });
  }

  public carregaPaciente() {
    this.filter.paginar = false;
    this.service.getJustificarAnaliseByFilter(this.filter)
      .subscribe({
        next: response =>{
        this.lsPaciente = response.list;
        if(this.filtroAtivo){
          this.lsPaciente = this.lsPaciente.filter(filtro => filtro.status)
        }
        }
      })
  }

  filtraPesquisa(event: any) {
    if (event.target.value && event.target.value.length >= 3) {
      this.carregaPaciente();
    }
  }

  public limpar() {
    this.pacientelSelecionadas = [];
    this.idsSelecionadas.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  selecionaRegional(event?: any) {
    let listIds: string[] = [];
    this.pacientelSelecionadas.forEach((regional) => listIds.push(regional.id));
    this.idsSelecionadas.emit(listIds);
    this.elementosCompletoSelecionadas.emit(this.lsPaciente);
  }
}
