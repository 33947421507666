import { PessoaFisica } from './pessoa-fisica.model';
import { ResponsavelEntity } from '../responsavel/responsavel-entity.model';
import { UnidadeSaude } from '@app/core/unidade-saude/unidade-saude.model';
import { Municipio } from '@app/core/regional/municipio.model';
export class Paciente {
	constructor
		(
			public id: string,
			public cns: string,
			public dt_obito: Date,
			public pessoaFisica: PessoaFisica,
			public nacionalidade: number,
			public naturalidade: Municipio,
			public responsavel: ResponsavelEntity,
			public ativo: boolean,
			public autorizados: ResponsavelEntity[],
			public unidadeSaude: UnidadeSaude,
			public data_cadastro: Date,
	) { }
}