import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from '@app/core/shared/message.service';
import { ButtonsModule } from '../../shared/buttons/buttons.module';
import { NavegacaoService } from '@app/core/navegacao/navegacao.service';
import { Router } from '@angular/router';

import { UsuarioService } from '@app/core/usuario/usuario.service';
import { AvisosService } from '../avisos.service';
import { Regiao } from '@app/core/regiao/Regiao.model';
import { LoginService } from '@app/core/security/login/login.service';
import * as moment from 'moment';

interface Niveis {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-novo',
  templateUrl: './novo.component.html',
  styleUrls: ['./novo.component.scss']
})
export class NovoComponent implements OnInit, OnDestroy {

  public niveis: Array<string> = [
    "REGIÃO",
    "REGIONAL",
    "UNIDADE"
  ]

  listaRegional: Array<any>;
  listaRegiao: Array<any>;
  listaUnidade: Array<any>;
  todosAtivo: boolean = false;
  titulo: String = '';
  maxObs = 2000
  private dtInicioAnterior : Date= new Date();

  constructor(
    private userService: UsuarioService,
    private buttonsModule: ButtonsModule,
    private navegacaoService: NavegacaoService,
    private router: Router,
    private avisosService: AvisosService,
    private messageService: MessageService,
    private loginService: LoginService,
  ) { }

  ngOnInit() {
    this.nivelDeAcessoChange(this.avisosService.aviso.nivel);
    this.titulo = this.avisosService.titulo;
    this.dtInicioAnterior = new Date(this.avisosService.aviso.dt_inicio);
  }

  ngOnDestroy(): void {
    this.avisosService.aviso = this.avisosService.getNovoAviso();
  }

  private noEdit(): boolean {
    // HABILITAR DEPOIS DE VERIFICAR SE REALMENTE PRECISA, TALVEZ ESSE DEVA ESTAR NO AVISO.COMPONENT
    /*if(!this.navegacaoService.permissaoAvisos || !this.avisosService.aviso) return true;
    else if( this.navegacaoService.permissaoAvisos.inserir && !this.avisosService.aviso.id) return true;
    else if( this.navegacaoService.permissaoPaciente.editar  && this.avisosService.aviso.id) return true;*/
    return false;
  }

  private noClear(): boolean {
    // HABILITAR DEPOIS DE VERIFICAR SE REALMENTE PRECISA, TALVEZ ESSE DEVA ESTAR NO AVISO.COMPONENT
    /*if(!this.navegacaoService.permissaoAvisos || !this.avisosService.aviso) return true;
    else if( !this.navegacaoService.permissaoAvisos.editar && this.avisosService.aviso.id) return false;
    else if( this.navegacaoService.permissaoAvisos.inserir) return true;
    return false;*/
    return true;
  }

  salvar() {

    if (!this.validMinDate()) {
      return;
    }

    this.avisosService.eventSaveAviso.emit();

    if (!this.todosAtivo && this.avisosService.aviso.nivel_aviso == "TODOS") {
      this.avisosService.aviso.nivel_aviso = null;
    }

    if (this.todosAtivo) {
      this.avisosService.aviso.nivel_aviso = "TODOS";
    }
    this.avisosService.aviso.referencia_id = this.loginService.user.referencia.id;
    this.avisosService.cadastrar(this.avisosService.aviso).subscribe(id => {
      this.avisosService.aviso.id = id.id
      this.messageService.addToast("Operação realizada com sucesso!", "", "success")
      this.voltar();
    },
      response => this.messageService.addToast(response.error.descricao, "", "error")
    )
  }

  getTamanhObs() {
    if (this.avisosService.aviso) {
      if (this.avisosService.aviso.observacao) {
        if (this.avisosService.aviso.observacao.length == null && this.avisosService.aviso.observacao.length == undefined) return 0
        return this.avisosService.aviso.observacao.length
      }
    }
    return 0
  }

  voltar() {
    this.limpar();
    this.router.navigate(['navegacao/avisos']);
  }

  limpar() {
    //Adicionar ação de limpar os campos

    this.avisosService.aviso.dt_inicio = null;
    this.avisosService.aviso.dt_expiracao = null;
    this.avisosService.aviso.nivel_aviso = null;
    this.avisosService.aviso.titulo = null;
    this.avisosService.aviso.observacao = null;
    this.avisosService.aviso.nivel = null;
  }

  nivelDeAcessoChange(niveis: String) {
    switch (niveis) {
      case "REGIONAL":
        this.listaRegional = (this.userService.infoUser.regionais);
        break;
      case "REGIÃO":
        this.listaRegiao = (this.userService.infoUser.regioes);
        //this.avisosService.aviso.nivel = "REGIÃO";
        break;
      case "UNIDADE":
        //this.listaUnidade = (this.userService.infoUser.regionalorigin.unidadesaudelist);
        this.listaUnidade = (this.userService.infoUser.unidades);
        //this.avisosService.aviso.nivel = "UNIDADE";
        break;
      default:
        this.todosAtivo = true;
    }
  }

  getListaNivel(nivel: String): Array<any> {
    if (nivel == "REGIONAL") {

      return this.listaRegional;

    } else if (nivel == "UNIDADE") {

      return this.listaUnidade;

    } else {
      return this.listaRegiao;
    }
  }

  /**
   * Valida se a data de início é menor que data atual ou data inicial anterior <br>
   * Se for novo, dtInicioAnterior recebe a data atual. Se o usuário selecionar uma Data Inícial menor que dtInicioAnterior exibe mensagem <br>
   * Se for edição, dtInicioAnterior recebe a data inicio do objeto em edição, se o usuário alterar a data início para uma data inferior exibe mensagem <br>
   * @returns retorna TRUE se a data for valida
   */
  validMinDate(): boolean {
    if (this.dtInicioAnterior.getDate() !== this.avisosService.aviso.dt_inicio.getDate()) {
      if (moment(this.avisosService.aviso.dt_inicio).format('DD-MM-YYYY') < moment(new Date()).format('DD-MM-YYYY')) {
        this.messageService.addToast("Data de Início não pode ser menor que a Data Atual", "", "error");
        return false;
      }
    }
    return true;
  }

}
