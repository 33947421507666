import { LogradouroFiltro } from './logradouro-filtro.model ';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { ListaJSON } from "../default/lista-json.model";
import { Logradouro } from "./logradouro.model";
import { LogradouroPesquisa } from "./logradouro-pesquisa.model";
import { Bairro } from "./bairro.model";
import { SGS_URL} from '@app/app.api';

@Injectable()
export class EnderecoService {

    tipo: string
    constructor(private http: HttpClient){}

    buscarLogradouro(cep: string): Observable<Logradouro> {
      return this.http.post<Logradouro>(`${SGS_URL.CORE}/endereco/logradouro/buscar`, {cep: cep})
    }

    buscarLogradouros(logradouroPesquisa: LogradouroPesquisa): Observable<ListaJSON<Logradouro>> {
      return this.http.post<ListaJSON<Logradouro>>(`${SGS_URL.CORE}/endereco/logradouros/buscar`, logradouroPesquisa)
    }

    buscarLogradourosByFiltro(filtro: LogradouroFiltro): Observable<Logradouro[]> {
      return this.http.post<Logradouro[]>(`${SGS_URL.CORE}/endereco/logradouros/buscarLogradourosByFiltro`, filtro)
    }

    setTipo(tipo: string) {
      this.tipo = tipo
    }

    getTipo(): string {
      return this.tipo
    }

    getBairroByNomeMunicipio(municipio: string): Observable<Bairro[]> {
      return this.http.post<Bairro[]>(`${SGS_URL.CORE}/endereco/getBairroByNomeMunicipio`, {municipio: municipio});
    }

    getLogradourosByNomeBairro(filtro: LogradouroFiltro): Observable<Logradouro[]> {
     return this.http.post<Logradouro[]>(`${SGS_URL.CORE}/endereco/logradouros/buscarLogradourosByFiltro`, filtro)
    }
}
