import { Municipio } from '@app/core/regional/municipio.model';
import { Pessoa } from './pessoa.model';
import { PessoaFisica } from './pessoa-fisica.model';
import { Injectable, EventEmitter } from "@angular/core"
import { HttpClient } from '@angular/common/http'
import { observable, Observable } from 'rxjs'
import { Paciente } from "./paciente.model";
import { UsuarioService } from '@app/core/usuario/usuario.service';
import { UnidadeSaude } from '@app/core/unidade-saude/unidade-saude.model';
import { LoginService } from '@app/core/security/login/login.service';
import { ListaJSON } from '@app/core/shared/default/lista-json.model';
import { Logradouro } from '@app/core/shared/endereco/logradouro.model';
import { Endereco } from '@app/core/shared/endereco/endereco.model';
import { Contato } from '@app/core/shared/models/contato.model';
import { SGS_URL, CORE } from '@app/app.api';

@Injectable()
export class PacienteService {
  private _paciente: Paciente = this.getNovoPaciente();
  private _pacienteFilter = this.getPacienteFilter();
  private _isVisibledFilter: boolean = false;

  private _eventSavePaciente: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _eventClearPaciente: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _eventChangeEnderecoPaciente: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _eventRecarregarTelefones: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _indexCadastro = 0;
  public readonly DADOS_PESSOAIS = 0;
  public readonly JUDICIAL = 3;
  public readonly MEDICAMENTOS_PROCEDIMENTOS = 4;

  public readonly BACK_PESQUISA_PACIENTE = 0;
  public readonly BACK_PESQUISA_DISPENSACAO = 1;
  public readonly BACK_RESPONSAVEL = 2;
  private _backRouter: number;

  public lsIdsSelecionadosMultiSelect: any[] = [];
  public haItensSelecionadosMultiSelect = false;

  constructor
    (
      private http: HttpClient,
      private loginService: LoginService,
      private userService: UsuarioService) {
  }

  buscaPacientes(filtro: string): Observable<Paciente[]> {
    return this.http.post<Paciente[]>(`${SGS_URL.CORE}/${CORE.PACIENTE}/buscaPacientes/${filtro}`, {})
  }

  buscarPacientesByNome(nomePaciente: string): Observable<any[]> {
    return this.http.post<any[]>(`${SGS_URL.CORE}/${CORE.PACIENTE}/buscarPacientesByNome/${nomePaciente}`, {})
  }

  cadastrar(paciente: Paciente): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id;
    //return new Observable<any>()
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.PACIENTE}/c/${id_referencia}/${id_usuario}`, paciente)
  }

  updateStatus(id_paciente: string, ativo: boolean): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.PACIENTE}/s/${id_paciente}/${id_usuario}/${ativo}/${id_referencia}`, {})
  }

  listar(): Observable<ListaJSON<Paciente>> {
    return this.http.post<ListaJSON<Paciente>>(`${SGS_URL.CORE}/${CORE.PACIENTE}/p`, this._pacienteFilter)
  }

  buscaPacienteByIdResponsavel(id_responsavel: string): Observable<ListaJSON<Paciente>> {
    return this.http.post<ListaJSON<Paciente>>(`${SGS_URL.CORE}/${CORE.PACIENTE}/buscaPacienteByIdResponsavel`, { responsavel: id_responsavel })
  }

  getPacienteOrPessoaFisicaByCpf(cpf_: string): Observable<any>
  {
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.PACIENTE}/getPacienteOrPessoaFisicaByCpf`, { cpf : cpf_ })
  }

  buscaPaciente(id_paciente: string): Observable<Paciente> {
    return this.http.post<Paciente>(`${SGS_URL.CORE}/${CORE.PACIENTE}/id/${id_paciente}`, {})
  }

  updatePrescricoesByMunicipioChange(id_pac:String, muni_old:String, muni_new:String): Observable<number>
  {
    return this.http.post<number>(`${SGS_URL.CORE}/${CORE.PACIENTE}/updatePrescricoesByMunicipioChange`, {id_pac:id_pac, id_ref: this.loginService.user.referencia.id , muni_old:muni_old, muni_new:muni_new});
  }

  /*
   //Método genérico para buscar pacientes respeitando regional
   public getAllPacientesByRegional(idRegional: string, Objetoentidade: any, nomeEntidade: string, idReferencia: string): Observable<ListaJSON<any>> {
    return this.http.post<ListaJSON<any>>(`${SGS_URL.CORE}/${CORE.PACIENTE}/findAllPacienteByRegional/${idRegional}/${idReferencia}/${nomeEntidade}`, Objetoentidade);
  }
  */

  getNovoPaciente(): Paciente {
    let logradouro = new Logradouro(null, null, null, null, null, null, null);
    let endreco = new Endereco(null, null, null, null, '4', logradouro, '4', null, null, null);
    let pessoa = new Pessoa(null, null, [endreco], []);
    let pessoaFisica = new PessoaFisica(null, null, null, null, 1, null, null, null, null, null, null, pessoa, null);
    return new Paciente(null, null, null, pessoaFisica, 1, null, null, true, [], null, null);
  }

  // Muitos dados estão vindo na migração de outros sistemas, por conta disso, para não ficar quebrando as telas
  // Caso o paciente não tenha determinados dados, fazemos como se fosse um novo cadastro para aquele dado expecífico
  corrigePacientesQuebrados(paciente: Paciente) {
    if (paciente) {

      let logradouro = new Logradouro(null, null, null, null, null, null, null);
      let endreco = new Endereco(null, null, null, null, '4', logradouro, '4', null, null, null);
      let pessoa = new Pessoa(null, null, [endreco], []);
      let pessoaFisica = new PessoaFisica(null, null, null, null, 1, null, null, null, null, null, null, pessoa, null);

      if (paciente.pessoaFisica == null) {
        paciente.pessoaFisica = pessoaFisica;
      }

      if (paciente.pessoaFisica.pessoa == null) {
        paciente.pessoaFisica.pessoa = pessoa;
      }

      return paciente;

    } else {
      return this.getNovoPaciente();
    }
  }

  corrigeContatos(contatos: Contato[]): Contato[] {
    let local = [{ id: "", tipo: "", telefone: "", contato: "", index: 1 }, { id: "", tipo: "", telefone: "", contato: "", index: 2 },
    { id: "", tipo: "", telefone: "", contato: "", index: 3 }, { id: "", tipo: "", telefone: "", contato: "", index: 4 }]
    let i: number
    for (i = 0; i < contatos.length; i++) {
      local[i] = contatos[i]
    }
    return local
  }

  //Método genérico para buscar pacientes respeitando regional
  public getAllPacientesByRegional(idRegional: string, Objetoentidade: any, nomeEntidade: string, idReferencia: string): Observable<ListaJSON<any>> {
    return this.http.post<ListaJSON<any>>(`${SGS_URL.CORE}/${CORE.PACIENTE}/findAllPacienteByRegional/${idRegional}/${idReferencia}/${nomeEntidade}`, Objetoentidade);
  }

  searchAllUnidadeSaude(value: string): Observable<any> {
    return this.http.get<any>(`${SGS_URL.CORE}/${CORE.UNIDADE_SAUDE}/get_unidades/${value}`);
  }

  searchUnidadeSaudeByIBG(idLogradouro, tipoEndereco, id_referencia): Observable<UnidadeSaude> {
    return this.http.post<UnidadeSaude>(`${SGS_URL.CORE}/${CORE.UNIDADE_SAUDE}/getUnidadeByIBGE`, { logradouro: idLogradouro, tipo: tipoEndereco, idReferencia: id_referencia });
  }

  public getNewEndereco() {
    return new Endereco(null, null, null, null, null, new Logradouro(null, null, null, null, null, null, null), null, null, null, null);
  }

  public getNewContato() {
    return new Contato(null, null, null, null, null);
  }

  public getPacienteFilter() {
    return {
      regional: null,
      unidade_saude: null,
      ls_ids_regionais: this.userService.getIdsRegionaisByInfoUser(),
      ls_ids_unidades: this.userService.getIdsUnidadesSaudeByInfoUser(),
      municipio: "",
      responsavel: "",
      cns: "",
      nome_cpf: "",
      mae: "",
      processo: "",
      sgpe: "",
      direction: "asc",
      sort: "Nome",
      pageIndex: 0,
      pageSize: 10
    }
  }

  //OBS: o mesmo método se encontra em dispensação service
  //Implementei o método aqui por que faz mais sentido e deixei o método lá também para não quebrar nenhuma outra tela
  public findAllPacienteByUnidadeSaude(value: string, idUnidadeSaude: string): Observable<Paciente[]> {
    return this.http.post<Paciente[]>(`${SGS_URL.CORE}/${CORE.PACIENTE}/findPacienteEntityByUnidadeSaudeDispensar`,
    { filtro: value, referencia: this.loginService.user.referencia.id, idUnidade: idUnidadeSaude })
  }

  public getPacientesByLsUnidadeSaude(lsIdUnidade: Array<String>): Observable<Paciente[]> {
    return this.http.post<Paciente[]>(`${SGS_URL.CORE}/${CORE.PACIENTE}/findPacienteByLsUnidadesSaude`,
      { ls_unidade_saude: lsIdUnidade, controle: true, filtro: "" });
  }

  public getNaturalidadeByPaciente(id_pac:String ): Observable<Municipio> {
    return this.http.post<Municipio>(`${SGS_URL.CORE}/${CORE.PACIENTE}/getNaturalidadeByPaciente`,{ id_pac:id_pac });
  }

  public getNewLsIdsSelecionados() {
    this.lsIdsSelecionadosMultiSelect = [];
  }


  //MÉTODOS SETS E GETS

  public get indexCadastro() {
    return this._indexCadastro;
  }
  public set indexCadastro(value) {
    this._indexCadastro = value;
  }
  public get pacienteFilter() {
    return this._pacienteFilter;
  }
  public set pacienteFilter(value) {
    this._pacienteFilter = value;
  }
  public get eventSavePaciente(): EventEmitter<boolean> {
    return this._eventSavePaciente;
  }
  public set eventSavePaciente(value: EventEmitter<boolean>) {
    this._eventSavePaciente = value;
  }
  public get isVisibledFilter(): boolean {
    return this._isVisibledFilter;
  }
  public set isVisibledFilter(value: boolean) {
    this._isVisibledFilter = value;
  }
  public get paciente(): Paciente {
    return this._paciente;
  }
  public set paciente(value: Paciente) {
    this._paciente = value;
  }
  public get eventClearPaciente(): EventEmitter<boolean> {
    return this._eventClearPaciente;
  }
  public set eventClearPaciente(value: EventEmitter<boolean>) {
    this._eventClearPaciente = value;
  }
  public get eventChangeEnderecoPaciente(): EventEmitter<boolean> {
    return this._eventChangeEnderecoPaciente;
  }
  public set eventChangeEnderecoPaciente(value: EventEmitter<boolean>) {
    this._eventChangeEnderecoPaciente = value;
  }
  public get backRouter(): number {
    return this._backRouter;
  }
  public set backRouter(value: number) {
    this._backRouter = value;
  }

  public get eventRecarregarTelefones(): EventEmitter<boolean> {
    return this._eventRecarregarTelefones;
  }
  public set eventRecarregarTelefones(value: EventEmitter<boolean>) {
    this._eventRecarregarTelefones = value;
  }
}
