
import { Injectable } from '@angular/core';
import { SGS_URL } from '@app/app.api';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { LoginService } from '../security/login/login.service';
import { ReferenciaMaterialPesquisa } from './referencia-material-pesquisa.model';

@Injectable()
export class ReferenciamaterialService {

  // private actionDataEmitter = new BehaviorSubject<ReferenciaMaterial>(null);

  constructor(private http: HttpClient, private loginService: LoginService) { }

  /* get referenciaMaterial(): Observable<ReferenciaMaterial> {
   private actionDataEmitter = new BehaviorSubject<ReferenciaMaterial>(null);
 
 
   /*emitReferenciaMaterial(referenciaMaterial: ReferenciaMaterial) {
     this.actionDataEmitter.next(referenciaMaterial);
   }*/

  //Realiza vinculo entre um material/medicamento e uma referencia
  incluirMaterialReferencia(idMaterial: String, idReferencia:String) {
    const idUsuario = this.loginService.user.id;
    return this.http.post<any>(`${SGS_URL.CORE}/referenciaMaterial/incluirReferenciaMaterial`, { idReferencia: idReferencia, idMaterial: idMaterial, idUsuario: idUsuario })
  }

  //Retorna um material/medicamento de acordo com id da referencia
  getMateriaisByIdReferencia(referenciaMaterialPesquisa: ReferenciaMaterialPesquisa, descricao : String) {
    return this.http.post<any>(`${SGS_URL.CORE}/referenciaMaterial/getMateriaisByReferencia`, { idReferencia: referenciaMaterialPesquisa.idReferencia, pageSize: referenciaMaterialPesquisa.pageSize, pageIndex: referenciaMaterialPesquisa.pageIndex, sort: referenciaMaterialPesquisa.sort, direction: referenciaMaterialPesquisa.direction, descricao_mat : descricao });
  }

  //Remove vinculo entre um material/medicamento e uma referencia
  removerReferenciaMaterial(idMaterial: String,idReferencia:String) {
    const idUsuario = this.loginService.user.id;
    return this.http.post<any>(`${SGS_URL.CORE}/referenciaMaterial/deleteReferenciaMaterial`, { idReferencia: idReferencia, idMaterial: idMaterial, idUsuario: idUsuario });
  }
  
  vincularReferenciaMaterial(lsReferencias: any[],idMaterial:String)
  {
    return this.http.post<any>(`${SGS_URL.CORE}/referenciaMaterial/vincularReferenciaMaterial`, { lsReferencias: lsReferencias, idMaterial: idMaterial });
  }
}
