import { Referencia } from './../centro-custo/Referencia.model';
import { ItemDispensacao } from "./item-dispensacao.model";
import { Usuario } from '@app/core/usuario/usuario.model';
import { UnidadeSaude } from '@app/core/unidade-saude/unidade-saude.model';
import { Paciente } from "../../core/paciente/paciente.model";
import { PessoaFisica } from "../../core/paciente/pessoa-fisica.model";

export class Dispensacao {
    constructor(
        public id: string,
        public paciente: Paciente,
        public retiradoPor: PessoaFisica,
        public data_cadastro: Date,
        public observacao: string,
        public usuarioSGS:Usuario,
        public unidadeSaude:UnidadeSaude,
        public itens_dispensados: ItemDispensacao[],
        public mes:number,
        public ano:number,
        public qtd_itens:string,
        public usuarioCancelSGS:Usuario,
        public data_estorno:Date,
        public referencia:Referencia,
        public status:Boolean,
        public mostra_botao_estorno: Boolean
    ) { }
}
