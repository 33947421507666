import { Logradouro } from "./logradouro.model";

export class Endereco {

    public id: string = "";
    public numero: string = "";
    public complemento: string = "";
    public referencia: string = "";
    public zona: string = "";
    public logradouro: Logradouro;
    public tipo: string = "";
    public nome_bairro: string = "";
    public nome_logradouro: string = "";
    public cep: string = "";

    constructor(
        id: string,
        numero: string,
        complemento: string,
        referencia: string,
        zona: string,
        logradouro: Logradouro,
        tipo: string,
        nome_bairro: string,
        nome_logradouro: string,
        cep: string) {
            this.id = id;
            this.numero = numero;
            this.complemento = complemento;
            this.referencia = referencia;
            this.zona = zona;
            this.logradouro = logradouro;
            this.tipo = tipo;
            this.nome_bairro = nome_bairro;
            this.nome_logradouro = nome_logradouro;
            this.cep = cep;
    }
}

