import { Injectable } from "@angular/core"
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { TipoRegistro } from './tipo-registro.model';
import { TipoRegistroPesquisa } from './tipo-registro-pesquisa/tipo-registro-pesquisa.model';
import { LoginService } from "../security/login/login.service";
import { ListaJSON } from "../shared/default/lista-json.model";
import { SGS_URL } from "@app/app.api";

@Injectable()
export class TipoRegistroService {

    tipoRegistro: TipoRegistro

    constructor(private http: HttpClient, private loginService: LoginService){}

    cadastrar(tipoRegistro: TipoRegistro) : Observable<any> {
      let id_referencia = this.loginService.user.referencia.id;
      let id_usuario = this.loginService.user.id;
      return this.http.post<any>(`${SGS_URL.CORE}/tipoRegistro/c/${id_referencia}/${id_usuario}`, tipoRegistro)
    }

    inativar(tipoRegistro: TipoRegistro) : Observable<any> {
      let id_referencia = this.loginService.user.referencia.id;
      let id_usuario = this.loginService.user.id;
      return this.http.get<any>(`${SGS_URL.CORE}/tipoRegistro/s/${tipoRegistro.id}/${id_usuario}/${tipoRegistro.status == 'Ativo'}/${id_referencia}`)
    }

    listar(tipoRegistroPesquisa: TipoRegistroPesquisa): Observable<ListaJSON<TipoRegistro>> {
      return this.http.post<ListaJSON<TipoRegistro>>(`${SGS_URL.CORE}/tipoRegistro/p`, tipoRegistroPesquisa)
    }

    buscaTipoRegistro(id_tipo_registro: string): Observable<TipoRegistro> {
      return this.http.post<TipoRegistro>(`${SGS_URL.CORE}/tipoRegistro/id/${id_tipo_registro}`, {})
    }

    getTipoRegistro() {
      if (this.tipoRegistro === undefined) {
        this.tipoRegistro = new TipoRegistro("", "", "")
      }
      return this.tipoRegistro
    }

    setTipoRegistro(tipoRegistro: TipoRegistro) {
      this.tipoRegistro = tipoRegistro
    }
}
