import { AlvaraModel } from './../alvara/alvara.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DevolucaoValoresModel } from './devolucao-valores.model';
import { Observable } from 'rxjs';
import { ContasBancariasModel } from '@app/judicial/contas-bancarias/contas-bancarias-model';
import { FilterItemsPrescricaoByProcess } from '../sequestro-valores/filter-items-prescricao-by-process.model';
import { LoginService } from '@app/core/security/login/login.service';
import { SGS_URL, JUDICIAL} from '@app/app.api';
import { SequestroValoresModel } from '../sequestro-valores/sequestro-valores.model';
import { ListaJSON } from '@app/core/shared/default/lista-json.model';
import { ItemPrescricao } from '@app/core/prescricao/item-prescricao.model';

@Injectable({
  providedIn: 'root'
})
export class DevolucaoValoresService {
  private _devolucaoValoresAtivo: DevolucaoValoresModel; //objeto que deverá ser salvo no banco de dados
  private _filterItemsPrescricao: FilterItemsPrescricaoByProcess; //filtra uma lista de itens de objetos
  private _filterItemsDevolucao: FilterItemsPrescricaoByProcess; //filtra uma lista de itens de objetos

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) { }


  //procura todas as contas de devolução ativas do sistema filtrado pelo nome
  searchFilterContasBancariasAtivas(filtro: any): Observable<ContasBancariasModel[]> {
    return this.http.get<ContasBancariasModel[]>(`${SGS_URL.JUDICIAL}/${JUDICIAL.CONTA_BANCARIA}/get_contas_ativas/${filtro}`);
  }
  //procura todas as contas de devolução ativas do sistema
  searchAllContasBancariasAtivas(): Observable<ContasBancariasModel[]> {
    return this.http.get<ContasBancariasModel[]>(`${SGS_URL.JUDICIAL}/${JUDICIAL.CONTA_BANCARIA}/get_contas_ativas`);
  }

  //procura por todos os sequestro de valores ativo do processo em questao
  searchFilterSequestroValores(filtro: any): Observable<SequestroValoresModel[]> {
    return this.http.get<SequestroValoresModel[]>(`${SGS_URL.JUDICIAL}/${JUDICIAL.SEQUESTRO_VALORES}/get_sequestro_ativos/${filtro}`);
  }

  //procura por todos os alvarás de valores ativo do processo em questao
  searchFilterAlvara(filtro: any): Observable<AlvaraModel[]> {
    return this.http.get<AlvaraModel[]>(`${SGS_URL.JUDICIAL}/${JUDICIAL.ALVARA}/get_alvaras_ativos/${filtro}`);
  }

  //busca todos os itens da prescrição do processo ativo
  searchAllItemPrescricao(): Observable<ListaJSON<ItemPrescricao>> 
  {
    this._filterItemsPrescricao.referencia = this.loginService.user.referencia ? this.loginService.user.referencia.id : null;
    return this.http.post<ListaJSON<ItemPrescricao>>(`${SGS_URL.JUDICIAL}/${JUDICIAL.PROCESSO}/getItemPrescricaoByProcess`, this._filterItemsPrescricao);
  }

  /**Método para salvar um objeto e recuperar o id para preencher a classe **/
  save(): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id;
    return this.http.post<any>(`${SGS_URL.JUDICIAL}/${JUDICIAL.DEVOLUCAO_VALORES}/c/${id_referencia}/${id_usuario}`, this._devolucaoValoresAtivo)
  }

  searchAllDevolucoes(): Observable<ListaJSON<DevolucaoValoresModel>>
  {
    return this.http.post<ListaJSON<DevolucaoValoresModel>>(`${SGS_URL.JUDICIAL}/${JUDICIAL.DEVOLUCAO_VALORES}/p`, this._filterItemsDevolucao);
  }

  //Atualiza o status de um objeto no banco de dados
  updateActive(devolucao: DevolucaoValoresModel): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id
    return this.http.post<any>(`${SGS_URL.JUDICIAL}/${JUDICIAL.DEVOLUCAO_VALORES}/s/${devolucao.id}/${id_usuario}/${devolucao.status}/${id_referencia}`, {})
  }

  /**Métodos Sets e Gets **/

  public get devolucaoValoresAtivo(): DevolucaoValoresModel {
    return this._devolucaoValoresAtivo;
  }
  public set devolucaoValoresAtivo(value: DevolucaoValoresModel) {
    this._devolucaoValoresAtivo = value;
  }

  public get filterItemsPrescricao(): FilterItemsPrescricaoByProcess {
    return this._filterItemsPrescricao;
  }
  public set filterItemsPrescricao(value: FilterItemsPrescricaoByProcess) {
    this._filterItemsPrescricao = value;
  }
  public get filterItemsDevolucao(): FilterItemsPrescricaoByProcess {
    return this._filterItemsDevolucao;
  }
  public set filterItemsDevolucao(value: FilterItemsPrescricaoByProcess) {
    this._filterItemsDevolucao = value;
  }
}
