import { ParametrizacaoQuestoesAnaliseTecnicaRequest } from './../../shared/models/parametrizacao-questoes-analise-tecnica-request';
import { ParametrizacaoQuestoesAnaliseTecnicaFilter } from '@app/core/shared/models/parametrizacao-questoes-analise-tecnica-filter';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SGS_URL, CORE } from '@app/app.api';
import { ResponseComLength } from '@app/core/shared/models/response-com-length.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParametrizacaoQuestoesAnaliseTecnicaService {

  constructor(private http: HttpClient) {}

  public getParametroQuestoesByFilter(params: ParametrizacaoQuestoesAnaliseTecnicaFilter): Observable<ResponseComLength> {
    //TODO: Alterar ANY para tipo correto;
    return this.http.post<ResponseComLength>(`${SGS_URL.JUDICIAL}/${CORE.NAT_QUESTAO_ANALISE_TECNICA}/listar-questao-analise-tecnica`, { params });
  }

  public updateParametroQuestoesAnaliseTecnica(params: ParametrizacaoQuestoesAnaliseTecnicaRequest): Observable<any> {
    //TODO: Alterar ANY para tipo correto;
    return this.http.post<any>(`${SGS_URL.JUDICIAL}/${CORE.NAT_QUESTAO_ANALISE_TECNICA}/update-questao-analise-tecnica`,{ params });
  }

  public saveParametroQuestoesAnaliseTecnica(params: ParametrizacaoQuestoesAnaliseTecnicaRequest): Observable<any> {
    //TODO: Alterar ANY para tipo correto;
    return this.http.post<any>(`${SGS_URL.JUDICIAL}/${CORE.NAT_QUESTAO_ANALISE_TECNICA}/cadastrar-questao-analise-tecnica`, { params });
  }
}
