import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { SGS_URL } from "../../app.api";
import { LoginService } from "../security/login/login.service";
import { ListaJSON } from '../shared/default/lista-json.model';
import { TipoEstabelecimentoPesquisa } from './tipo-estabelecimento-pesquisa/tipo-estabelecimento-pesquisa.model';
import { TipoEstabelecimento } from './tipo-estabelecimento.model';

@Injectable()
export class TipoEstabelecimentoService {

  tipoEstabelecimento: TipoEstabelecimento

  constructor(private http: HttpClient,
    private loginService: LoginService) { }

  cadastrar(tipoEstabelecimento: TipoEstabelecimento): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id
    return this.http.post<any>(`${SGS_URL.CORE}/tipoEstabelecimento/c/${id_referencia}/${id_usuario}`, tipoEstabelecimento)
  }

  listar(tipoEstabelecimentoPesquisa: TipoEstabelecimentoPesquisa): Observable<ListaJSON<TipoEstabelecimento>> {
    return this.http.post<ListaJSON<TipoEstabelecimento>>(`${SGS_URL.CORE}/tipoEstabelecimento/p`, tipoEstabelecimentoPesquisa)
  }

  buscaTipoEstabelecimento(id_tipo_estabelecimento: string): Observable<TipoEstabelecimento> {
    return this.http.post<TipoEstabelecimento>(`${SGS_URL.CORE}/tipoEstabelecimento/id/${id_tipo_estabelecimento}`, {})
  }

  getTipoEstabelecimento() {
    if (this.tipoEstabelecimento === undefined) {
      this.tipoEstabelecimento = new TipoEstabelecimento("", "", "")
    }
    return this.tipoEstabelecimento
  }

  setTipoEstabelecimento(tipoEstabelecimento: TipoEstabelecimento) {
    this.tipoEstabelecimento = tipoEstabelecimento
  }
}
