import { Injectable, Inject } from "@angular/core"
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { User } from './user.model'
import { filter } from 'rxjs/operators'
import { Router, NavigationEnd } from '@angular/router'
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service'
import { Option } from "../../shared/models/option.model";
import { UsuarioNivelAcess } from "@app/core/usuario/usuario-nivel-acess.model"
import { SGS_URL } from "@app/app.api"
import { StorageCriptorafado } from "@app/core/shared/util/storage"

@Injectable()
export class LoginService {
  lastUrl: string;


  constructor(@Inject(SESSION_STORAGE) private storageService: WebStorageService, private http: HttpClient, private router: Router) {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => this.lastUrl = e.url)
  }

  isLoggeddIn(): boolean {
    return this.user != null ? true : false;
  }

  login(login: string, password: string): Observable<any> {
    return this.http.post<User>(`${SGS_URL.CORE}/aut/login`, { params : {login: login, password: password} });
  }

  //busca as informações do usuário referente ao estabelecimento ativo [utilizado no recebimento, distribuição e dispensação]
  public searchInfoUser(): Observable<UsuarioNivelAcess> {
    let user = this.user ? this.user.id : null;
    let referencia = this.user && this.user.referencia ? this.user.referencia.id : null;

    return this.http.post<UsuarioNivelAcess>(`${SGS_URL.CORE}/aut/find_info`, { usuario: user, referencia: referencia });
  }

  handleLogin(path: string = this.lastUrl) {
    this.router.navigate(['/login', btoa(path)])
  }

  getReferencias(id: string): Observable<Option[]> {
    return this.http.post<Option[]>(`${SGS_URL.CORE}/aut/referencias/${id}`, {})
  }

  public get user(): User {
    //return this.storageService.get("user");
    return StorageCriptorafado.getSessionUser();
  }
  public set user(value: User) {
    StorageCriptorafado.setSessionUser(value);
    //this.storageService.set("user", value);
  }

  public get infoUser(): UsuarioNivelAcess {
    //return this.storageService.get("infUser");
    return StorageCriptorafado.getSessionInfoUser();
  }
  public set infoUser(value: UsuarioNivelAcess) {
    StorageCriptorafado.setSessionInfoUser(value);
    //this.storageService.set("infUser", value);
  }

  public clearLoginService(){
    StorageCriptorafado.clean();
  }
}
