import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TipoParecer } from "@app/core/shared/models/tipo-parecer";
import { TipoParecerFilter } from "@app/core/shared/models/tipo-parecer-filter";
import { TipoParecerService } from "./tipo-parecer.service";

@Component({
  selector: "ses-tipo-parecer-select",
  templateUrl: "./parecer-select.component.html",
  styleUrls: ["./parecer-select.component.scss"],
})
export class ParecerSelectComponent implements OnInit {
  @Input() required: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();
  @Input() parecerSelecionado: TipoParecer = new TipoParecer();
  @Input() id: string = "parecer"

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */
  @Output() idSelecionada = new EventEmitter<string>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any>();

  constructor(private tipoParecerService: TipoParecerService) {}

  public lsParecer: TipoParecer[] = [];
  public filter: TipoParecerFilter = new TipoParecerFilter();

  ngOnInit() {
    this.filter.paginar = false;

    this.tipoParecerService.getTipoParecerByFilter(this.filter).subscribe({
      next: (response) => {
        this.lsParecer = response.list;
        if (this.parecerSelecionado) {
          this.parecerSelecionado = this.lsParecer.find(
            (filter) => filter.id == this.parecerSelecionado.id
          );
        }
      },
    });

    this.limparEvent.subscribe({
      next: (resp) => {
        this.limpar();
      },
    });
  }

  public selecionarParecer() {
    this.idSelecionada.emit(this.parecerSelecionado.id + "");
    this.elementosCompletoSelecionadas.emit(this.parecerSelecionado);
  }

  public limpar() {
    this.parecerSelecionado = null;
    this.idSelecionada.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }
}
