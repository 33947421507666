import { User } from "@app/core/security/login/user.model";

export class HistoricoObservacaoItemPrescricao {
  constructor(
    public id: string,
    public descricao: string,
    public dt_cadastro: Date,
    public usuario: User
  ) {}
}
