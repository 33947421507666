import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DecisaoSuperiorAnaliseTecnicaService } from '@app/core/nat/decisao-superior-analise-tecnica/decisao-superior-analise-tecnica.service';
import { DecisaoSuperiorAnaliseTecnicaFilter } from '@app/core/shared/models/decisao-superior-analise-tecnica-filter';

@Component({
  selector: 'ses-decisao-superior-multiselect',
  templateUrl: './decisaoSuperiorMultiselect.component.html',
  styleUrls: ['./decisaoSuperiorMultiselect.component.scss']
})
export class DecisaoSuperiorMultiselectComponent implements OnInit {
  @Input() required: boolean = true;
  @Input() filtroAtivo: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */
  @Output() idsSelecionadas = new EventEmitter<string[]>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any[]>();

  constructor( private decisaoSuperiorService: DecisaoSuperiorAnaliseTecnicaService) {}

  public lsElementos: any[] = [];
  public pacientelSelecionadas: any[] = [];
  public filter: DecisaoSuperiorAnaliseTecnicaFilter = new DecisaoSuperiorAnaliseTecnicaFilter();

  ngOnInit() {
    this.carregaElementos();
    this.limparEvent.subscribe({
      next: (resp) => {
        this.limpar();
      },
    });
  }

  public carregaElementos() {
    this.filter.paginar = false;
    this.decisaoSuperiorService.getDecisaoSuperiorAnaliseByFilter(this.filter)
      .subscribe({
        next: response =>{
        this.lsElementos = response.list;
        if(this.filtroAtivo){
          this.lsElementos = this.lsElementos.filter(filtro => filtro.status)
        }
        }
      })
  }

  filtraPesquisa(event: any) {
    if (event.target.value && event.target.value.length >= 3) {
      this.carregaElementos();
    }
  }

  public limpar() {
    this.pacientelSelecionadas = [];
    this.idsSelecionadas.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  selecionaElementos(event?: any) {
    let listIds: string[] = [];
    this.pacientelSelecionadas.forEach((regional) => listIds.push(regional.id));
    this.idsSelecionadas.emit(listIds);
    this.elementosCompletoSelecionadas.emit(this.lsElementos);
  }
}
