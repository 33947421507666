import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DecisaoSuperiorAnaliseTecnicaService } from '@app/core/nat/decisao-superior-analise-tecnica/decisao-superior-analise-tecnica.service';
import { PosicaoAnaliseTecnicaService } from '@app/core/nat/posicao-analise-tecnica/posicao-analise-tecnica.service';
import { DecisaoSuperiorAnaliseTecnicaFilter } from '@app/core/shared/models/decisao-superior-analise-tecnica-filter';
import { DecisaoSuperiorAnaliseTecnicaRequest } from '@app/core/shared/models/decisao-superior-analise-tecnica-request';
import { DecisaoSuperiorAnaliseTecnicaResponse } from '@app/core/shared/models/decisao-superior-analise-tecnica-response';
import { PosicaoAnaliseTecnicaFilter } from '@app/core/shared/models/posicao-analise-tecnica-filter';
import { PosicaoAnaliseTecnicaResponse } from '@app/core/shared/models/posicao-analise-tecnica-response';

@Component({
  selector: 'ses-decisao-superior-autocomplete',
  templateUrl: './decisao-superior-autocomplete.component.html',
  styleUrls: ['./decisao-superior-autocomplete.component.scss']
})
export class DecisaoSuperiorAutocompleteComponent implements OnInit {

  @Input() filtroAtivo: boolean;
  @Input() required: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();
  @Input() decisaoSuperiorSelecionada: DecisaoSuperiorAnaliseTecnicaResponse = new DecisaoSuperiorAnaliseTecnicaResponse();
  @Input() decisaoSuperioridSelecionada: string;
  @Input() id: string = "posicao-analise-tecnica"

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */

  @Output() idSelecionada = new EventEmitter<string>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any>();

  constructor(
    private posicaoAnaliseTecnicaService: PosicaoAnaliseTecnicaService,
    private decisaoSuperiorAnaliseTecnicaService: DecisaoSuperiorAnaliseTecnicaService
  ) { }

  public decisaoSuperiorFilter: DecisaoSuperiorAnaliseTecnicaFilter = new DecisaoSuperiorAnaliseTecnicaFilter();
  public lsDecisaoSuperior: DecisaoSuperiorAnaliseTecnicaResponse[] = [];

  ngOnInit() {
    this.decisaoSuperiorFilter.paginar = false;


    this.decisaoSuperiorAnaliseTecnicaService
      .getDecisaoSuperiorAnaliseByFilter(this.decisaoSuperiorFilter)
      .subscribe({
        next: response => {
          this.lsDecisaoSuperior = response.list;
          if(this.decisaoSuperioridSelecionada){
            this.decisaoSuperiorSelecionada = this.lsDecisaoSuperior.find(filter => filter.id == +this.decisaoSuperioridSelecionada )
          }
          if (this.filtroAtivo) {
            this.lsDecisaoSuperior = this.lsDecisaoSuperior.filter(filtro => filtro.status == true);
          }
        }
      })

    this.limparEvent.subscribe({
      next: () => {
        this.limpar();
      },
    });
  }

  public selecionarPosicao() {
    if(this.decisaoSuperiorSelecionada.id){
      this.idSelecionada.emit(
        this.decisaoSuperiorSelecionada ? this.decisaoSuperiorSelecionada.id + "" : null
        );
        this.elementosCompletoSelecionadas.emit(this.decisaoSuperiorSelecionada);
      }else{
        this.idSelecionada.emit(null);
        this.elementosCompletoSelecionadas.emit(this.decisaoSuperiorSelecionada);
      }
  }

  public filter(name: string): any[] {
    let filterValue = name.toLowerCase();
    if (name !== "") {
      return this.lsDecisaoSuperior.filter((option) =>
        option.descricao.toLowerCase().includes(filterValue)
      );
    }
    return this.lsDecisaoSuperior;
  }

  public limpar() {
    this.decisaoSuperiorSelecionada = null;
    this.idSelecionada.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  public displayFn(posicao: any) {
    return posicao ? posicao.descricao : "";
  }
}
