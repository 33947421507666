import { OptionsModel } from './options.model'
export class InputFilter {

  constructor(
    public identificadorBanco: string,
    public type: string ,
    public label: string,
    public placeholder: string,
    public value: [string],
    public options: [OptionsModel],
    public operadorBanco: string
  ){}
}
