import { Referencia } from "@app/core/centro-custo/Referencia.model";
import { TipoParecer } from "./tipo-parecer";

export class ParametrizacaoQuestoesAnaliseTecnicaResponse {
  public id: number;
  public descricao: string;
  public status: boolean;
  public recomendacoes: string;
  public referencia: string;
 
}
