import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SGS_URL, CORE } from '@app/app.api';

import { CategoriaSituacao } from '@app/core/categoria-situacao/categoria-situacao.model';
import { ListaJSON } from '@app/core/shared/default/lista-json.model';

import { LoginService } from '@app/core/security/login/login.service';

@Injectable()
export class CategoriaSituacaoService {

	categoriaSituacao = new CategoriaSituacao();

	private _filter: any;

	constructor(
		private http: HttpClient,
		private loginService: LoginService) { }

	cadastrar(): Observable<any> {
		const id_usuario = this.loginService.user.id;
		const id_referencia = this.loginService.user.referencia.id;
		return this.http.post<any>(`${SGS_URL.CORE}/${CORE.CATEGORIA_SITUACAO}/c/${id_referencia}/${id_usuario}`, this.categoriaSituacao);
	}

	listar(): Observable<CategoriaSituacao[]> {
		var idReferencia = this.loginService.user.referencia.id;
		return this.http.post<CategoriaSituacao[]>(`${SGS_URL.CORE}/${CORE.CATEGORIA_SITUACAO}/getByIdReferencia`, { idReferencia: idReferencia });
	}

	updateStatus(id: string, status: boolean): Observable<any> {
		return this.http.post<any>(`${SGS_URL.CORE}/${CORE.CATEGORIA_SITUACAO}/updateStatus`, { id: id, status: status });
	}

	search(): Observable<ListaJSON<CategoriaSituacao>> {
		return this.http.post<ListaJSON<CategoriaSituacao>>(`${SGS_URL.CORE}/${CORE.CATEGORIA_SITUACAO}/p`, this._filter);
	}

	public get filter(): any {
		return this._filter;
	}
	public set filter(value: any) {
		this._filter = value;
	}
}
