import { Material } from "../material/material.model";
import { UnidadeSaude } from "../unidade-saude/unidade-saude.model";

export class EstoqueMinimo {
  constructor(public id: string,
              public idUnidadeSaude: Number,
              public idMedicamento:Number,
              public qtdMinima:Number,
              public idUser:Number,
              public idReferencia:Number
              ) {}
}
