import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { CORE, SGS_URL } from "@app/app.api";
import { Referencia } from "@app/core/centro-custo/Referencia.model";
import { PrescricaoService } from "@app/core/prescricao/prescricao.service";
import { SituacaoProcessoService } from "@app/core/situacao-processo/situacao-processo.service";
import { UsuarioService } from "@app/core/usuario/usuario.service";
import { SESSION_STORAGE, WebStorageService } from "angular-webstorage-service";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AlertaService } from "../alerta/alerta.service";
import { AvisosService } from "../avisos/avisos.service";
import { LoginService } from "../security/login/login.service";
import { InformDialogComponent } from "../shared/dialog-personal/inform-dialog/inform-dialog.component";
import { MessageService } from "../shared/message.service";
import { DEVELOP_MODE, MODULOS } from "./../../app.api";
import { NavegacaoService } from "./navegacao.service";


@Component({
  selector: "app-navegacao",
  templateUrl: "./navegacao.component.html",
  styleUrls: ["./navegacao.component.scss"],
})
export class NavegacaoComponent implements OnInit, OnDestroy {
  //utilizado para encerra a inscrição em um evento
  private subscribeUserInfo: Subscription;
  private subscribeUpdatePermissao: Subscription;
  private isLoading = false;

  public producao = environment.production;

  readonly DEVELOP_MODE = DEVELOP_MODE;

  readonly MENU_NULL: number = -1;
  readonly MENU_EM_DESENVOLVIMENTO: number = 0;
  readonly MENU_ADM: number = 1;
  readonly MENU_CAD: number = 2;
  readonly MENU_JUD: number = 3;
  readonly MENU_MAPA: number = 4;
  readonly MENU_DIS: number = 5;
  readonly MENU_REL: number = 6;
  readonly MENU_DISTRIBUICAO: number = 7;
  readonly MENU_DISPENSACAO: number = 8;
  readonly MENU_TUBERCULOSE: number = 9;
  readonly MENU_NAT: number = 15;

  readonly environment_name: string = environment.name;

  private readonly MENU_ESTRATEGICO: number = 10;

  private menuSelecionado: number = this.MENU_NULL;

  //exibe/oculta o estado do projeto (Desenvolvimento/Teste/Homologação/Produção)
  private isVisibledTitle: boolean = false;
  private titleHeaderStatus: string = "";

  private recallAlertPacient: boolean = false;

  private isRecallAlert: boolean = true;
  private key_control: boolean = false;
  private key_m: boolean = false;

  readonly JUDICIAL: string = MODULOS.JUDICIAL;

  /** Variaveis para ativar/ocultar o 'active' do botão da tela: true = botão fica ativo*/
  isButtonMenuJudicial: boolean = false; //1
  isButtonMenuMapa: boolean = false; //2
  isButtonMenuEstoque: boolean = false; //3
  isButtonMenuDispensacao: boolean = false; //4
  isButtonMenuRelatorio: boolean = false; //5
  isButtonMenuCadastro: boolean = false; //6
  isButtonMenuConfiguracao: boolean = false; //7
  isButtonMenuTuberculose: boolean = false; //8
  isButtonMenuInicio: boolean = false; //9
  isButtonMenuDashboard: boolean = false; //10
  isButtonMenuAlterarCadastroUsuario: boolean = false; // 11
  isButtonMenuDiretoPaciente: boolean = false; // 12
  isButtonMenuPrescricaoEstrategico: boolean = false; //13
  isButtonMenuPrescricaoEspecializado: boolean = false; //14
  isButtonMenuNAT: boolean = false; //15

  isButtonModuloJudicial: boolean = false; //1
  isButtonModuloTuberculose: boolean = false; //2
  isButtonModuloEstrategico: boolean = false; //3
  isButtonModuloEspecializado: boolean = false; //3

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));
  private interval;
  private timeLeft: number = 10;
  public msgDialog: string = `Deslogando automaticamente em 5 segundos. Clique em qualquer área da tela para cancelar.`;
  public displayDialog: boolean = false;

  constructor(
    @Inject(SESSION_STORAGE) private storage: WebStorageService,
    private navegacaoService: NavegacaoService,
    private breakpointObserver: BreakpointObserver,
    private loginService: LoginService,
    private router: Router,
    private usuarioService: UsuarioService,
    private prescricaoService: PrescricaoService,
    private situacaoProcessoService: SituacaoProcessoService,
    private messageService: MessageService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    if (!this.usuarioService.infoUser && this.loginService.infoUser)
      this.usuarioService.infoUser = this.loginService.infoUser;
    if (!this.loginService.user) this.router.navigate(["login"]);
    else if (!this.loginService.user.referencia)
      this.router.navigate(["login"]);
    else {
      this.navegacaoService.resetPermissoes();
      this.updateAcess();
      this.subscribeUpdatePermissao =
        this.navegacaoService.eventPermissao.subscribe((resp) =>
          this.updateAcess()
        );
    }

    environment.name;
    this.startTimer();
  }

  ngOnDestroy(): void {
    if (this.subscribeUserInfo) this.subscribeUserInfo.unsubscribe();
    if (this.subscribeUpdatePermissao)
      this.subscribeUpdatePermissao.unsubscribe();
    this.usuarioService.infoUser = null;
  }

  updateAcess() {
    //busca nas variaveis globais, se deverá exibir o header
    this.isVisibledTitle = environment.EXIBIR_HEADER;
    this.titleHeaderStatus = environment.ESTADO_PROJETO;

    this.navegacaoService.getPermissoes().subscribe((permissoes) => {
      for (let permissao of permissoes) {
        switch (permissao.id_permissao) {
          case "1":
            this.navegacaoService.permissaoPaciente = permissao;
            break;

          case "2":
            this.navegacaoService.permissaoSituacaoProcesso = permissao;
            break;

          case "3":
            this.navegacaoService.permissaoTipoAtendimento = permissao;
            break;

          case "4":
            this.navegacaoService.permissaoTipoMedicamento = permissao;
            break;

          case "5":
            this.navegacaoService.permissaoRegiao = permissao;
            break;

          case "6":
            this.navegacaoService.permissaoTipoRegistro = permissao;
            break;

          case "7":
            this.navegacaoService.permissaoOrigemMedicamento = permissao;
            break;

          case "8":
            this.navegacaoService.permissaoTipoDocumento = permissao;
            break;

          case "9":
            this.navegacaoService.permissaoResponsavel = permissao;
            break;

          case "10":
            this.navegacaoService.permissaoUsuario = permissao;
            break;

          case "11":
            this.navegacaoService.permissaoTipoSolicitacao = permissao;
            break;

          case "12":
            this.navegacaoService.permissaoPeriodicidade = permissao;
            break;

          case "13":
            this.navegacaoService.permissaoMedicamentosProcedimentos =
              permissao;
            break;

          case "14":
            this.navegacaoService.permissaoEspecialidade = permissao;
            break;

          case "15":
            this.navegacaoService.permissaoProfissionais = permissao;
            break;

          case "16":
            this.navegacaoService.permissaoGrupo = permissao;
            break;

          case "17":
            this.navegacaoService.permissaoOriginDestino = permissao;
            break;

          case "18":
            this.navegacaoService.permissaoUnidadeAssistencia = permissao;
            break;

          case "19":
            this.navegacaoService.permissaoTipoEstabelecimento = permissao;
            break;

          case "20":
            this.navegacaoService.permissaoTipoPrograma = permissao;
            break;

          case "21":
            this.navegacaoService.permissaoPrograma = permissao;
            break;

          case "22":
            this.navegacaoService.permissaoProcesso = permissao;
            break;

          case "23":
            this.navegacaoService.permissaoPrescricao = permissao;
            break;

          case "24":
            this.navegacaoService.permissaoProtocolo = permissao;
            break;

          case "25":
            this.navegacaoService.permissaoRegional = permissao;
            break;
          /*
        case "26": this.navegacaoService.permissaoTipoRealizacao = permissao;
        break;
        */
          case "27":
            this.navegacaoService.permissaoParametros = permissao;
            break;
          /*
        case "28": this.navegacaoService.permissao = permissao;
        break;
        */
          case "29":
            this.navegacaoService.permissaoUnidadeSaude = permissao;
            break;

          case "30":
            this.navegacaoService.permissaoNivel = permissao;
            break;

          case "31":
            this.navegacaoService.permissaoRessarcimento = permissao;
            break;

          case "32":
            this.navegacaoService.permissaoMotivoExclusao = permissao;
            break;

          case "33":
            this.navegacaoService.permissaoReceita = permissao;
            break;
          /*
        case "34": this.navegacaoService.permissao = permissao;
          break;
        */
          /*
        case "35": this.navegacaoService.permissao = permissao;
          break;
        */
          /*
        case "36": this.navegacaoService.permissao = permissao;
          break;
        */
          /*
        case "37": this.navegacaoService.permissao = permissao;
          break;
        */
          /*
        case "38": this.navegacaoService.permissao = permissao;
          break;
        */
          case "39":
            this.navegacaoService.permissaoTipoMapa = permissao;
            break;
          /*
        case "40": this.navegacaoService.permissao = permissao;
          break;
        */
          case "41":
            this.navegacaoService.permissaoRecebimento = permissao;
            break;

          case "42":
            this.navegacaoService.permissaoDistribuicao = permissao;
            break;
          /*
        case "43": this.navegacaoService.permissao = permissao;
          break;
        */
          /*
        case "44": this.navegacaoService.permissao = permissao;
          break;
        */
          /*
        case "45": this.navegacaoService.permissao = permissao;
          break;
        */
          case "46":
            this.navegacaoService.permissaoDispensacao = permissao;
            break;

          case "47":
            this.navegacaoService.permissaoRecibo = permissao;
            break;

          case "48":
            this.navegacaoService.permissaoParametrizacao = permissao;
            break;

          case "49":
            this.navegacaoService.permissaoTipoFornecimento = permissao;
            break;

          case "50":
            this.navegacaoService.permissaoContasBancarias = permissao;
            break;

          case "51":
            this.navegacaoService.permissaoSequestroValores = permissao;
            break;

          case "52":
            this.navegacaoService.permissaoAlvara = permissao;
            break;

          case "53":
            this.navegacaoService.permissaoDevolucaoValores = permissao;
            break;

          case "54":
            this.navegacaoService.permissaoGerarMapa = permissao;
            break;

          case "55":
            this.navegacaoService.permissaoMotivoSequestro = permissao;
            break;

          case "56":
            this.navegacaoService.permissaoCentroCusto = permissao;
            break;
          /*
        case "57": this.navegacaoService.permissao = permissao;
          break;
        */
          /*
        case "58": this.navegacaoService.permissao = permissao;
          break;
        */
          /*
        case "59": this.navegacaoService.permissao = permissao;
          break;
        */
          /*
        case "60": this.navegacaoService.permissao = permissao;
          break;
        */
          case "61":
            this.navegacaoService.permissaoMotivoDevolucao = permissao;
            break;

          case "62":
            this.navegacaoService.permissaoDashbord = permissao;
            break;

          case "63":
            this.navegacaoService.permissaoRelatorioAlvara = permissao;
            break;

          case "64":
            this.navegacaoService.permissaoConsultaEstoque = permissao;
            break;

          case "65":
            this.navegacaoService.permissaoRelatorioDinamico = permissao;
            break;

          case "66":
            this.navegacaoService.permissaoRelatorioEstoque = permissao;
            break;

          case "67":
            this.navegacaoService.permissaoRelatorioHistoricoNat = permissao;
            break;

          case "68":
            this.navegacaoService.permissaoRelatorioMapa = permissao;
            break;

          case "69":
            this.navegacaoService.permissaoRelatorioPaciente = permissao;
            break;

          case "70":
            this.navegacaoService.permissaoRelatorioPrescricao = permissao;
            break;

          case "71":
            this.navegacaoService.permissaoRelatorioResidualGeral = permissao;
            break;

          case "72":
            this.navegacaoService.permissaoRelatorioRessarcimento = permissao;
            break;

          case "73":
            this.navegacaoService.permissaoRelatorioSequestro = permissao;
            break;

          case "74":
            this.navegacaoService.permissaoAtualizacaoPrescricao = permissao;
            break;

          case "75":
            this.navegacaoService.permissaoAlertPacienteNotReceive = permissao;
            break;

          case "76":
            this.navegacaoService.permissaoComarca = permissao;
            break;

          case "77":
            this.navegacaoService.permissaoRelatorioObitos = permissao;
            break;

          case "78":
            this.navegacaoService.permissaoRelatorioValidadePermissao =
              permissao;
            break;

          case "79":
            this.navegacaoService.permissaoPrescricaoTuberculose = permissao;
            break;

          case "80":
            this.navegacaoService.permissaoAnalizeEstrategico = permissao;
            break;

          case "81":
            this.navegacaoService.permissaoReferenciaMaterial = permissao;
            break;
          /* Número 82 já está sendo utilizado no Histórico
        case "82": this.navegacaoService.permissao = permissao;
          break;
        */
          case "83":
            this.navegacaoService.permissaoTransferenciaEntreModulos =
              permissao;
            break;

          case "84":
            this.navegacaoService.permissaoDoacaoMedicamento = permissao;
            break;

          case "85":
            this.navegacaoService.permissaoRelatorioPrescricaoTuberculose =
              permissao;
            break;
          /*
        case "86": this.navegacaoService.permissaoAjusteEstoque = permissao;
          break;
        */
          case "87":
            this.navegacaoService.permissaoRelatorioEstoqueSemDemanda =
              permissao;
            break;

          case "88":
            this.navegacaoService.permissaoRelatorioMedicamentosNaoRetirados =
              permissao;
            break;

          case "89":
            this.navegacaoService.permissaoRelatorioMapaDetalhado = permissao;
            break;

          case "90":
            this.navegacaoService.permissaoRelatorioGuiaEntrada = permissao;
            break;

          case "91":
            this.navegacaoService.permissaoRelatorioDispensacaoGeral =
              permissao;
            break;

          case "92":
            this.navegacaoService.permissaoAvisos = permissao;
            break;

          case "93":
            this.navegacaoService.permissaoAlertas = permissao;
            break;

          case "94":
            this.navegacaoService.permissaoRelatorioMedicamentoProxVenc =
              permissao;
            break;

          case "95":
            this.navegacaoService.permissaoCid = permissao;
            break;

          case "96":
            this.navegacaoService.permissaoCategoriaSituacao = permissao;
            break;

          case "97":
            this.navegacaoService.permissaoFluxoPrescricao = permissao;
            break;

          case "98":
            this.navegacaoService.permissaoRelatorioEntregaMaterial = permissao;
            break;

          case "99":
            this.navegacaoService.permissaoRelatorioRecibosNaoInseridos =
              permissao;
            break;

          case "100":
            this.navegacaoService.permissaoEstoqueMinimo = permissao;
            break;

          case "101":
            this.navegacaoService.permissaoSituacaoProcedimento = permissao;
            break;

          case "102":
            this.navegacaoService.permissaoProcedimento = permissao;
            break;

          case "103":
            this.navegacaoService.permissaoAutorizadorGrupoMedicamento =
              permissao;
            break;

          case "104":
            this.navegacaoService.permissaoSaldoEstoqueBloquear = permissao;
            break;

          case "105":
            this.navegacaoService.permissaoSaldoEstoqueDesbloquear = permissao;
            break;

          case "106":
            this.navegacaoService.permissaoPacienteContato = permissao;
            break;

          case "107":
            this.navegacaoService.permissaoRelatorioSaldoBloqueado = permissao;
            break;

          case "108":
            this.navegacaoService.permissaoRelatorioPacienteSemDispensacao =
              permissao;
            break;

          case "109":
            this.navegacaoService.permissaoComsus = permissao;
            break;

          case "110":
            this.navegacaoService.permissaoRelatorioComsus = permissao;
            break;

          case "111":
            this.navegacaoService.permissaoInventarioContagem = permissao;
            break;

          case "112":
            this.navegacaoService.permissaoInventarioAprovacao = permissao;
            break;

          case "113":
            this.navegacaoService.permissaoInventarioListagemEstabelecimento =
              permissao;
            break;

          case "114":
            this.navegacaoService.permissaoRelatorioInventario = permissao;
            break;

          case "115":
            this.navegacaoService.permissaoRelatorioEstoqueMaiorQueConsumo =
              permissao;
            break;

          case "116":
            this.navegacaoService.permissaoRelatorioContracautela = permissao;
            break;

          case "117":
            this.navegacaoService.permissaoRelatorioGuiasDistribuicao =
              permissao;
            break;

          case "118":
            this.navegacaoService.permissaoItensEmMovimentacao = permissao;
            break;

          case "119":
            this.navegacaoService.permissaoExtratoMaterial = permissao;
            break;

          case "120":
            this.navegacaoService.permissaoCadastroParametroAnaliseTecnica = permissao;
            break;

          case "121":
              this.navegacaoService.permissaoParametrizarQuestoesAnalise = permissao;
          break;

          case "122":
            this.navegacaoService.permissaoAnaliseTecnica = permissao;
          break;

          case "123":
            this.navegacaoService.permissaoRelatorioAnaliseTecnica = permissao;
          break;

          case "124":
            this.navegacaoService.permissaoConfigurarParametroModulos = permissao;
          break;

          case "125":
            this.navegacaoService.permissaoAnaliseMaterial = permissao;
          break;
          case "126":
            this.navegacaoService.permissaoRelatorioComprovacaoAtendimento = permissao;
          case "127":
            this.navegacaoService.permissaoRelatorioLivroControlados = permissao;
          break;
        }
      }
    });
  }

  sair() {
    clearInterval(this.interval);
    this.usuarioService.infoUser = null;
    this.usuarioService.infoUser = null;
    this.loginService.user = null;
    this.storage.set("user", null);
    this.router.navigate(["login"]);
  }

  selectMenu(value) {
    this.selecionaMenu(value);
  }

  isDevelop() {
    return this.MENU_EM_DESENVOLVIMENTO === this.menuSelecionado;
  }

  isMenuAdm() {
    return this.MENU_ADM === this.menuSelecionado;
  }

  selecionaCad() {
    this.selecionaMenu(this.MENU_CAD);
  }

  isMenuCad() {
    return this.MENU_CAD === this.menuSelecionado;
  }

  selecionaJud() {
    this.selecionaMenu(this.MENU_JUD);
  }

  selecionaMapa() {
    this.selecionaMenu(this.MENU_MAPA);
  }

  selecionaTuberculose() {
    this.selecionaMenu(this.MENU_TUBERCULOSE);
  }
  private selecionaEstrategico() {
    this.selecionaMenu(this.MENU_ESTRATEGICO);
  }

  selecionaRelatorio() {
    this.selecionaMenu(this.MENU_REL);
  }

  selecionaDis() {
    this.selecionaMenu(this.MENU_DIS);
  }

  selecionaDistribuicao() {
    this.selecionaMenu(this.MENU_DISTRIBUICAO);
  }

  selecionaDispensacao() {
    this.selecionaMenu(this.MENU_DISPENSACAO);
  }

  isMenuDispensacao() {
    return this.MENU_DISPENSACAO === this.menuSelecionado;
  }

  isMenuJud() {
    return this.MENU_JUD === this.menuSelecionado;
  }

  isMenuMapa() {
    return this.MENU_MAPA === this.menuSelecionado;
  }

  isMenuTuberculose() {
    return this.MENU_TUBERCULOSE === this.menuSelecionado;
  }

  private isMenuEstrategico() {
    return this.MENU_ESTRATEGICO === this.menuSelecionado;
  }

  isMenuDis() {
    return this.MENU_DIS === this.menuSelecionado;
  }

  isMenuRelatorio() {
    return this.MENU_REL === this.menuSelecionado;
  }

  isDistruicao() {
    return this.MENU_DISTRIBUICAO == this.menuSelecionado;
  }

  selecionaMenu(menuNovo: number) {
    if (menuNovo == undefined || menuNovo === this.menuSelecionado) {
      this.menuSelecionado = this.MENU_NULL;
    } else {
      this.menuSelecionado = menuNovo;
    }
  }

  gerarRelatorioValidadePrescricao() {
    window.location.href = `${SGS_URL.CORE}/${CORE.DOWNLOAD_RELATORIO}/${CORE.VALIDADE_PRESCRICAO}`;
  }

  visibledDevelop() {
    return this.DEVELOP_MODE;
  }

  private visibledConfiguracao(): boolean {
    return (
      this.navegacaoService.permissaoGrupo.visualizar ||
      this.navegacaoService.permissaoUsuario.visualizar ||
      this.navegacaoService.permissaoNivel.visualizar ||
      this.navegacaoService.permissaoRegiao.visualizar ||
      this.navegacaoService.permissaoRegional.visualizar ||
      this.navegacaoService.permissaoUnidadeSaude.visualizar ||
      this.navegacaoService.permissaoUnidadeAssistencia.visualizar ||
      this.navegacaoService.permissaoReferenciaMaterial.visualizar ||
      this.navegacaoService.permissaoParametros.visualizar ||
      this.navegacaoService.permissaoCentroCusto.visualizar ||
      this.navegacaoService.permissaoCid.visualizar ||
      this.navegacaoService.permissaoFluxoPrescricao.visualizar ||
      this.navegacaoService.permissaoCategoriaSituacao.visualizar ||
      this.navegacaoService.permissaoSituacaoProcesso.visualizar ||
      this.navegacaoService.permissaoSituacaoProcedimento.visualizar ||
      this.navegacaoService.permissaoProcedimento.visualizar ||
      this.navegacaoService.permissaoAutorizadorGrupoMedicamento.visualizar||
      this.navegacaoService.permissaoConfigurarParametroModulos.visualizar

    );
  }

  private visibledCadastro(): boolean {
    return (
      this.navegacaoService.permissaoPaciente.visualizar ||
      this.navegacaoService.permissaoMedicamentosProcedimentos.visualizar ||
      this.navegacaoService.permissaoMotivoDevolucao.visualizar ||
      this.navegacaoService.permissaoOrigemMedicamento.visualizar ||
      this.navegacaoService.permissaoPeriodicidade.visualizar ||
      this.navegacaoService.permissaoProfissionais.visualizar ||
      this.navegacaoService.permissaoResponsavel.visualizar ||
      this.navegacaoService.permissaoTipoAtendimento.visualizar ||
      this.navegacaoService.permissaoEspecialidade.visualizar ||
      this.navegacaoService.permissaoTipoEstabelecimento.visualizar ||
      this.navegacaoService.permissaoTipoMedicamento.visualizar ||
      this.navegacaoService.permissaoTipoPrograma.visualizar ||
      this.navegacaoService.permissaoPrograma.visualizar ||
      this.navegacaoService.permissaoTipoRegistro.visualizar ||
      this.navegacaoService.permissaoReferenciaMaterial.visualizar
    );
  }

  private visibledJudicial(): boolean {
    return (
      MODULOS.JUDICIAL == this.loginService.user.referencia.id &&
      (this.navegacaoService.permissaoContasBancarias.visualizar ||
        this.navegacaoService.permissaoProcesso.visualizar ||
        //      this.navegacaoService.permissaoSituacaoProcesso.visualizar ||
        this.navegacaoService.permissaoAtualizacaoPrescricao.visualizar ||
        this.navegacaoService.permissaoMotivoExclusao.visualizar ||
        this.navegacaoService.permissaoMotivoSequestro.visualizar ||
        this.navegacaoService.permissaoOriginDestino.visualizar ||
        this.navegacaoService.permissaoTipoSolicitacao.visualizar ||
        this.navegacaoService.permissaoTipoDocumento.visualizar ||
        this.navegacaoService.permissaoComarca.visualizar ||
        this.navegacaoService.permissaoTipoFornecimento.visualizar ||
        this.navegacaoService.permissaoComsus.visualizar)
    );
  }

  private visibledNAT(): boolean {
    return (
      this.navegacaoService.permissaoCadastroParametroAnaliseTecnica.visualizar ||
      this.navegacaoService.permissaoParametrizarQuestoesAnalise.visualizar ||
      this.navegacaoService.permissaoAnaliseTecnica.visualizar
      );
  }

  private visibledSeparadorJudicial(): boolean {
    return (
      this.navegacaoService.permissaoMotivoExclusao.visualizar ||
      this.navegacaoService.permissaoMotivoSequestro.visualizar ||
      this.navegacaoService.permissaoOriginDestino.visualizar ||
      this.navegacaoService.permissaoTipoSolicitacao.visualizar ||
      this.navegacaoService.permissaoTipoDocumento.visualizar ||
      this.navegacaoService.permissaoTipoFornecimento.visualizar
    );
  }

  private visualizarTuberculose(): boolean {
    return (
      MODULOS.TUBERCULOSE == this.loginService.user.referencia.id &&
      (this.navegacaoService.permissaoSituacaoProcesso.visualizar ||
        this.navegacaoService.permissaoAtualizacaoPrescricao.visualizar ||
        this.navegacaoService.permissaoReferenciaMaterial.visualizar ||
        this.navegacaoService.permissaoPrescricaoTuberculose.visualizar ||
        this.navegacaoService.permissaoPrescricao.visualizar)
    );
  }

  private visualizarEstrategico(): boolean {
    return (
      this.loginService.user.referencia.id == MODULOS.ESTRATEGICO &&
      (this.navegacaoService.permissaoSituacaoProcesso.visualizar ||
        this.navegacaoService.permissaoAtualizacaoPrescricao.visualizar ||
        this.navegacaoService.permissaoPrescricao.visualizar)
    );
  }

  private visualizarMapa(): boolean {
    return (
      this.navegacaoService.permissaoGerarMapa.visualizar ||
      this.navegacaoService.permissaoParametrizacao.visualizar ||
      this.navegacaoService.permissaoTipoMapa.visualizar
    );
  }

  private visualizarEstoque(): boolean {
    return (
      this.navegacaoService.permissaoConsultaEstoque.visualizar ||
      this.navegacaoService.permissaoDistribuicao.visualizar ||
      this.navegacaoService.permissaoRecebimento.visualizar ||
      this.navegacaoService.permissaoTransferenciaEntreModulos.visualizar ||
      this.navegacaoService.permissaoDoacaoMedicamento.visualizar ||
      this.navegacaoService.permissaoEstoqueMinimo.visualizar ||
      this.navegacaoService.permissaoInventarioContagem.visualizar ||
      this.navegacaoService.permissaoInventarioAprovacao.visualizar ||
      this.navegacaoService.permissaoRelatorioInventario.visualizar ||
      this.navegacaoService.permissaoItensEmMovimentacao.visualizar ||
      this.navegacaoService.permissaoAnaliseMaterial.visualizar
    );
    //      this.navegacaoService.permissaoInventarioListagemEstabelecimento.visualizar;
  }

  private visualizarDispensacao(): boolean {
    return (
      this.navegacaoService.permissaoDispensacao.inserir ||
      this.navegacaoService.permissaoDispensacao.visualizar
    );
  }

  private visualizarRelatorios(): boolean {
    return (
      this.navegacaoService.permissaoRelatorioAlvara.visualizar ||
      this.navegacaoService.permissaoRelatorioDinamico.visualizar ||
      this.navegacaoService.permissaoRelatorioEstoque.visualizar ||
      this.navegacaoService.permissaoRelatorioHistoricoNat.visualizar ||
      this.navegacaoService.permissaoRelatorioMapa.visualizar ||
      this.navegacaoService.permissaoRelatorioPaciente.visualizar ||
      this.navegacaoService.permissaoRelatorioPrescricao.visualizar ||
      this.navegacaoService.permissaoRelatorioResidualGeral.visualizar ||
      this.navegacaoService.permissaoRelatorioObitos.visualizar ||
      this.navegacaoService.permissaoRelatorioValidadePermissao.visualizar ||
      this.navegacaoService.permissaoRelatorioSequestro.visualizar ||
      this.navegacaoService.permissaoRelatorioRessarcimento.visualizar ||
      this.navegacaoService.permissaoRelatorioPrescricaoTuberculose
        .visualizar ||
      this.navegacaoService.permissaoRelatorioEstoqueSemDemanda.visualizar ||
      this.navegacaoService.permissaoRelatorioMedicamentosNaoRetirados
        .visualizar ||
      this.navegacaoService.permissaoRelatorioGuiaEntrada.visualizar ||
      this.navegacaoService.permissaoRelatorioMedicamentoProxVenc.visualizar ||
      this.navegacaoService.permissaoRelatorioEntregaMaterial.visualizar ||
      this.navegacaoService.permissaoRelatorioRecibosNaoInseridos.visualizar ||
      this.navegacaoService.permissaoRelatorioSaldoBloqueado.visualizar ||
      this.navegacaoService.permissaoRelatorioComsus.visualizar ||
      this.navegacaoService.permissaoRelatorioInventario.visualizar ||
      this.navegacaoService.permissaoRelatorioEstoqueMaiorQueConsumo
        .visualizar ||
      this.navegacaoService.permissaoRelatorioContracautela.visualizar ||
      this.navegacaoService.permissaoRelatorioGuiasDistribuicao.visualizar
    );
  }

  private visualizarPrescricaoEstrategico(): boolean {
    return (
      MODULOS.ESTRATEGICO == this.loginService.user.referencia.id &&
      (this.navegacaoService.permissaoSituacaoProcesso.visualizar ||
        this.navegacaoService.permissaoAtualizacaoPrescricao.visualizar ||
        this.navegacaoService.permissaoReferenciaMaterial.visualizar ||
        this.navegacaoService.permissaoPrescricao.visualizar)
    );
  }

  private visualizarPrescricaoEspecializado(): boolean {
    return (
      MODULOS.ESPECIALIZADO == this.loginService.user.referencia.id &&
      (this.navegacaoService.permissaoSituacaoProcesso.visualizar ||
        this.navegacaoService.permissaoAtualizacaoPrescricao.visualizar ||
        this.navegacaoService.permissaoReferenciaMaterial.visualizar ||
        this.navegacaoService.permissaoPrescricao.visualizar)
    );
  }

  public visibledProcedimentoEspecializado(): boolean {
    return (
      MODULOS.ESPECIALIZADO == this.loginService.user.referencia.id &&
      (this.navegacaoService.permissaoProcedimento.visualizar ||
        this.navegacaoService.permissaoSituacaoProcedimento.visualizar)
    );
  }

  public alterarDadosDeCadastro(): void {
    this.setFalseVariavelButtonMenu();
    this.isButtonMenuAlterarCadastroUsuario = true;
    this.usuarioService.alterarDadosCadastrais = true;
    this.router.navigate(["navegacao/usuario/cadastro"]);
  }

  private setFalseVariavelButtonMenu() {
    this.isButtonMenuJudicial = false; //1
    this.isButtonMenuMapa = false; //2
    this.isButtonMenuEstoque = false; //3
    this.isButtonMenuDispensacao = false; //4
    this.isButtonMenuRelatorio = false; //5
    this.isButtonMenuCadastro = false; //6
    this.isButtonMenuConfiguracao = false; //7
    this.isButtonMenuTuberculose = false; //8
    this.isButtonMenuInicio = false; //9
    this.isButtonMenuDashboard = false; //10
    this.isButtonMenuAlterarCadastroUsuario = false; //11
    this.isButtonMenuDiretoPaciente = false; //12
    this.isButtonMenuPrescricaoEstrategico = false; //13
    this.isButtonMenuPrescricaoEspecializado = false; //14
    this.isButtonMenuNAT = false;
  }

  public verificaMenuAtivo(numeroMenu: number): void {
    this.setFalseVariavelButtonMenu();

    if (numeroMenu === 1) {
      //Judicial
      this.isButtonMenuJudicial = true;
    } else if (numeroMenu === 2) {
      //Mapa
      this.isButtonMenuMapa = true;
    } else if (numeroMenu === 3) {
      //3 Estoque
      this.isButtonMenuEstoque = true;
    } else if (numeroMenu === 4) {
      // Dispensacao
      this.isButtonMenuDispensacao = true;
    } else if (numeroMenu === 5) {
      //Relatorio
      this.isButtonMenuRelatorio = true;
    } else if (numeroMenu === 6) {
      //Cadastro
      this.isButtonMenuCadastro = true;
    } else if (numeroMenu === 7) {
      //Configuracao
      this.isButtonMenuConfiguracao = true;
    } else if (numeroMenu === 8) {
      //Tuberculose
      this.isButtonMenuTuberculose = true;
    } else if (numeroMenu === 9) {
      //Inicio
      this.isButtonMenuInicio = true;
    } else if (numeroMenu === 10) {
      //Dashboard
      this.isButtonMenuDashboard = true;
    } else if (numeroMenu === 12) {
      //Paciente Direto
      this.isButtonMenuDiretoPaciente = true;
    } else if (numeroMenu === 13) {
      //Prescrição Estratégico
      this.isButtonMenuPrescricaoEstrategico = true;
    } else if (numeroMenu === 14) {
      this.isButtonMenuPrescricaoEspecializado = true; //Prescrição Especializado
    } else if (numeroMenu === 15) {
      this.isButtonMenuNAT = true;
    }
  }

  private setFalseVariavelButtonModulo() {
    this.isButtonModuloJudicial = false; //1
    this.isButtonModuloTuberculose = false; //2
    this.isButtonModuloEstrategico = false; //3
    this.isButtonModuloEspecializado = false; //4
  }

  public verificaModuloAtivo(numeroModulo: number): void {
    this.setFalseVariavelButtonMenu();
    this.setFalseVariavelButtonModulo();
    this.verificaMenuAtivo(9); //Sempre ao iniciar o módulo, a página 'inicio' fica ativa, portanto a flag para exibir o item do menu selecionado é true
    if (numeroModulo === 1) {
      //Judicial
      this.isButtonModuloJudicial = true;
    } else if (numeroModulo === 2) {
      //Tuberculose
      this.isButtonModuloTuberculose = true;
    } else if (numeroModulo === 3) {
      //3 Estratégico
      this.isButtonModuloEstrategico = true;
    } else if (numeroModulo === 4) {
      // Especializado
      this.isButtonModuloEspecializado = true;
    }
    AlertaService.alertaEmitter.emit(this.loginService.user.referencia.id);
    AvisosService.avisosEmitter.emit(this.loginService.user.referencia.id);
  }

  public changeModuleNovo(idReferencia: string, numeroModulo: number) {
    if (this.loginService.user == null) {
      this.messageService.addToast(
        "Sessão Expirada, faça novamento o login",
        "Erro",
        "error"
      );
      return;
    }
    let user = this.loginService.user;
    user.referencia = this.getReferencia(idReferencia);
    this.loginService.user = user;
    this.updateInfoUser();
    this.verificaModuloAtivo(numeroModulo);
    this.updateAcess();
  }

  getReferencia(id: string): Referencia {
    for (let ref of this.loginService.user.referencias_permitidas) {
      if (ref.id.match(id)) {
        return ref;
      }
    }
  }

  private updateInfoUser() {
    this.isLoading = true;
    this.loginService.searchInfoUser().subscribe(
      (resp) => {
        if (resp) {
          if (resp.regioes != null && resp.regioes.length > 0) {
            resp.regionais = [];
            for (let item of resp.regioes) {
              if (item.regionais == null) continue;
              if (item.regionais.length < 1) continue;

              for (let regional of item.regionais) {
                resp.regionais.push(regional);
              }
            }
          }
          if (resp.regionais != null && resp.regionais.length > 0) {
            resp.regionalorigin = resp.regionais[0];
          } else if (resp.unidades != null && resp.unidades.length > 0) {
            resp.unidadeorigin = resp.unidades[0];
            resp.regionaldestine = resp.unidades[0].regional;
          }
        }
        this.usuarioService.infoUser = resp;
        this.isLoading = false;
        this.loginService.infoUser = this.usuarioService.infoUser;
        this.router.navigate(["navegacao/inicio"]);
      },
      (error) => {
        this.messageService.addToast(
          "Erro ao tentar recuperar os niveis do usuário",
          "Erro",
          "error"
        );
        this.isLoading = false;
      }
    );
  }

  @HostListener("click") reiniciaTimmer() {
    clearInterval(this.interval);
    this.startTimer();
  }

  private startTimer(): void {
    let refDialog;
    this.timeLeft = 3600;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
      if (this.timeLeft == 5) {
        refDialog = this.dialog.open(InformDialogComponent);
        this.timeLeft--;
      }
      if (this.timeLeft == 0) {
        refDialog.close();
        clearInterval(this.interval);
        this.sair();
      }
    }, 1000);
  }

  permiteVisualizarModulo(id: string): boolean {
    for (let ref of this.loginService.user.referencias_permitidas) {
      if (ref.id.match(id)) {
        return true;
      }
    }
    return false;
  }

  permiteVisualizarRelatorio(id: string): boolean {
    if (this.loginService.user.referencia.id == id) return true;
    return false;
  }

  //Alerta de gambiarra!!!
  //Problema: nas tabelas, ao selecionar um texto para copiar, o sistema executava a função click da linha.
  //Isso por que, quando se clica em um texto, arrasta e depois solta, o sistema entende que é somente um click.
  //Então caso o usuário quisesse copiar uma informação de uma dessas tables, o sistema iria chamar a função que está associada ao click.
  //Para driblar esse problema, esse método verifica se algum texto foi selecionado.
  //Se foi, ele dá um return no método que o click chamou.
  @HostListener("mouseup") copiarDadosDeTabelas(): void {
    let textoSelecionado = window.getSelection();

    if (textoSelecionado.toString().length > 0) {
      this.navegacaoService.copiarDados = true;
    } else {
      this.navegacaoService.copiarDados = false;
    }
  }

  //#region Permissão sub-menu relatorio

    public validaMenuRelatorioJudicial(): boolean{

      if (this.navegacaoService.permissaoRelatorioAlvara.visualizar && (this.permiteVisualizarRelatorio('1'))) return true;
      if (this.navegacaoService.permissaoRelatorioComsus.visualizar && (this.permiteVisualizarRelatorio('1'))) return true;
      if (this.navegacaoService.permissaoRelatorioRessarcimento.visualizar && (this.permiteVisualizarRelatorio('1'))) return true;
      if (this.navegacaoService.permissaoRelatorioSequestro.visualizar && (this.permiteVisualizarRelatorio('1'))) return true;
      if (this.navegacaoService.permissaoRelatorioValidadePermissao.visualizar && (this.permiteVisualizarRelatorio('1'))) return true;

      return false;
    }

    public validaMenuRelatorioTuberculose(): boolean{

      if (this.navegacaoService.permissaoRelatorioPrescricaoTuberculose.visualizar && (this.permiteVisualizarRelatorio('4'))) return true;

      return false;
    }

    public validaMenuRelatorioAnaliseTecnica(): boolean{

      if (this.navegacaoService.permissaoRelatorioAnaliseTecnica.visualizar && (this.permiteVisualizarRelatorio('1'))) return true;
      if (this.navegacaoService.permissaoRelatorioHistoricoNat.visualizar && (this.permiteVisualizarRelatorio('1'))) return true;

      return false;
    }

    public validaMenuRelatorioMapa(): boolean{

      if (this.navegacaoService.permissaoRelatorioMapaDetalhado.visualizar && (this.permiteVisualizarRelatorio('1') || this.permiteVisualizarRelatorio('2'))) return true;
      if (this.navegacaoService.permissaoRelatorioMapa.visualizar && (this.permiteVisualizarRelatorio('1') || this.permiteVisualizarRelatorio('2'))) return true;

      return false;
    }

    public validaMenuRelatorioEstoque(): boolean{

      if (this.navegacaoService.permissaoRelatorioEstoque.visualizar && (this.permiteVisualizarRelatorio('1') || this.permiteVisualizarRelatorio('2') || this.permiteVisualizarRelatorio('4'))) return true;
      if (this.navegacaoService.permissaoRelatorioSaldoBloqueado.visualizar && (this.permiteVisualizarRelatorio('1') || this.permiteVisualizarRelatorio('2'))) return true;
      if (this.navegacaoService.permissaoRelatorioEstoqueSemDemanda.visualizar && (this.permiteVisualizarRelatorio('1') || this.permiteVisualizarRelatorio('2'))) return true;
      if (this.navegacaoService.permissaoRelatorioEstoqueMaiorQueConsumo.visualizar && (this.permiteVisualizarRelatorio('1') || this.permiteVisualizarRelatorio('2'))) return true;
      if (this.navegacaoService.permissaoExtratoMaterial.visualizar && (this.permiteVisualizarRelatorio('1') || this.permiteVisualizarRelatorio('2') || this.permiteVisualizarRelatorio('4'))) return true;
      if (this.navegacaoService.permissaoRelatorioGuiaEntrada.visualizar && (this.permiteVisualizarRelatorio('1') || this.permiteVisualizarRelatorio('2'))) return true;
      if (this.navegacaoService.permissaoRelatorioGuiasDistribuicao.visualizar && (this.permiteVisualizarRelatorio('1')) || this.permiteVisualizarRelatorio('2') || this.permiteVisualizarRelatorio('4')) return true;
      if (this.navegacaoService.permissaoRelatorioInventario.visualizar && (this.permiteVisualizarRelatorio('1') || this.permiteVisualizarRelatorio('2'))) return true;
      if (this.navegacaoService.permissaoRelatorioMedicamentoProxVenc.visualizar && (this.permiteVisualizarRelatorio('1') || this.permiteVisualizarRelatorio('2') || this.permiteVisualizarRelatorio('4'))) return true;
      if (this.navegacaoService.permissaoRelatorioResidualGeral.visualizar && (this.permiteVisualizarRelatorio('1'))) return true;

      return false;
    }

    public validaMenuRelatorioDispensacao(): boolean{

      if (this.navegacaoService.permissaoRelatorioContracautela.visualizar && (this.permiteVisualizarRelatorio('1') || this.permiteVisualizarRelatorio('2') || this.permiteVisualizarRelatorio('4'))) return true;
      if (this.navegacaoService.permissaoRelatorioDispensacaoGeral.visualizar && (this.permiteVisualizarRelatorio('1') || this.permiteVisualizarRelatorio('2'))) return true;
      if (this.navegacaoService.permissaoRelatorioEntregaMaterial.visualizar && (this.permiteVisualizarRelatorio('1') || this.permiteVisualizarRelatorio('2') || this.permiteVisualizarRelatorio('4'))) return true;
      if (this.navegacaoService.permissaoRelatorioMedicamentosNaoRetirados.visualizar && (this.permiteVisualizarRelatorio('1') || this.permiteVisualizarRelatorio('2') || this.permiteVisualizarRelatorio('4'))) return true;
      if (this.navegacaoService.permissaoRelatorioPacienteSemDispensacao.visualizar && (this.permiteVisualizarRelatorio('1') || this.permiteVisualizarRelatorio('2') || this.permiteVisualizarRelatorio('4'))) return true;
      if (this.navegacaoService.permissaoRelatorioMedicamentosNaoRetirados.visualizar && (this.permiteVisualizarRelatorio('1') || this.permiteVisualizarRelatorio('2') || this.permiteVisualizarRelatorio('4'))) return true;
      if (this.navegacaoService.permissaoRelatorioRecibosNaoInseridos.visualizar && (this.permiteVisualizarRelatorio('1'))) return true;

      return false;
    }

    public validaMenuRelatorioPaciente(): boolean{

      if (this.navegacaoService.permissaoRelatorioDinamico.visualizar && (this.permiteVisualizarRelatorio('1'))) return true;
      if (this.navegacaoService.permissaoRelatorioPaciente.visualizar && (this.permiteVisualizarRelatorio('1') || this.permiteVisualizarRelatorio('2') || this.permiteVisualizarRelatorio('4'))) return true;
      if (this.navegacaoService.permissaoRelatorioPrescricao.visualizar && (this.permiteVisualizarRelatorio('1') || this.permiteVisualizarRelatorio('2'))) return true;
      if (this.navegacaoService.permissaoRelatorioObitos.visualizar && (this.permiteVisualizarRelatorio('1') || this.permiteVisualizarRelatorio('4'))) return true;

      return false;
    }

  //#endregion

  //#region Permissão sub-menu configuração

    public validaMenuConfiguracaoAbasDistribuicao(): boolean{

      if (this.navegacaoService.permissaoMotivoDevolucao.visualizar) return true;

      return false;
    }

    public validaMenuConfiguracaoAbasMapa(): boolean{

      if (this.navegacaoService.permissaoParametrizacao.visualizar) return true;
      if (this.navegacaoService.permissaoTipoMapa.visualizar) return true;

      return false;
    }

    public validaMenuConfiguracaoAbasMedicamento(): boolean{

      if (this.navegacaoService.permissaoAutorizadorGrupoMedicamento.visualizar) return true;
      if (this.navegacaoService.permissaoReferenciaMaterial.visualizar) return true;
      if (this.navegacaoService.permissaoOrigemMedicamento.visualizar) return true;
      if (this.navegacaoService.permissaoTipoMedicamento.visualizar) return true;

      return false;
    }

    public validaMenuConfiguracaoAbasParametroGeral(): boolean{

      if (this.navegacaoService.permissaoCategoriaSituacao.visualizar) return true;
      if (this.navegacaoService.permissaoCentroCusto.visualizar) return true;
      if (this.navegacaoService.permissaoOrigemMedicamento.visualizar) return true;
      if (this.navegacaoService.permissaoNivel.visualizar) return true;
      if (this.navegacaoService.permissaoParametros.visualizar) return true;
      if (this.navegacaoService.permissaoConfigurarParametroModulos.visualizar) return true;
      if (this.navegacaoService.permissaoRegional.visualizar) return true;
      if (this.navegacaoService.permissaoRegiao.visualizar) return true;
      if (this.navegacaoService.permissaoUnidadeAssistencia.visualizar) return true;
      if (this.navegacaoService.permissaoUnidadeSaude.visualizar) return true;

      return false;
    }

    public validaMenuConfiguracaoAbasPrescicao(): boolean{

      if (this.navegacaoService.permissaoCid.visualizar) return true;
      if (this.navegacaoService.permissaoFluxoPrescricao.visualizar) return true;
      if (this.navegacaoService.permissaoMotivoExclusao.visualizar) return true;
      if (this.navegacaoService.permissaoPeriodicidade.visualizar) return true;
      if (this.navegacaoService.permissaoProcedimento.visualizar) return true;
      if (this.navegacaoService.permissaoSituacaoProcedimento.visualizar) return true;
      if (this.navegacaoService.permissaoSituacaoProcesso.visualizar) return true;

      return false;
    }

    public validaMenuConfiguracaoAbasProcesso(): boolean{

      if (this.navegacaoService.permissaoComarca.visualizar) return true;
      if (this.navegacaoService.permissaoMotivoSequestro.visualizar) return true;
      if (this.navegacaoService.permissaoTipoAtendimento.visualizar) return true;

      return false;
    }

    public validaMenuConfiguracaoAbasProfissional(): boolean{

      if (this.navegacaoService.permissaoEspecialidade.visualizar) return true;
      if (this.navegacaoService.permissaoTipoRegistro.visualizar) return true;

      return false;
    }

    public validaMenuConfiguracaoAbasProtocolo(): boolean{

      if (this.navegacaoService.permissaoOriginDestino.visualizar) return true;
      if (this.navegacaoService.permissaoTipoDocumento.visualizar) return true;
      if (this.navegacaoService.permissaoTipoSolicitacao.visualizar) return true;

      return false;
    }

  //#endregion
}
