import { Referencia } from '@app/core/centro-custo/Referencia.model';
import { Usuario } from "../usuario/usuario.model";
import { Permissao } from "./permissao.model";

export class Grupo {
  constructor(public id: string,
              public nome: string,           
              public usuarios: Usuario[],
              public referencia: Referencia,        
              public permissoes: Permissao[]) {}
}
