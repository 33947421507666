import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { AlertaDTO } from './alerta-dto.model';
import { AlertaParams } from './alerta-params';

import { AlertaService } from './alerta.service';
import { LoginService } from '@app/core/security/login/login.service';

import { MatTableDataSource, MatPaginator } from '@angular/material';

@Component({
	selector: 'app-alerta',
	templateUrl: './alerta.component.html',
	styleUrls: ['./alerta.component.scss']
})
export class AlertaComponent implements OnInit {

	@ViewChild("paginatorAlerta") paginator: MatPaginator

	dataSource = new MatTableDataSource<AlertaDTO>();
	displayedColumns: string[] = ['nome', 'quantidade'];

	alerta = new AlertaDTO();

	constructor(private alertaService: AlertaService, private loginService: LoginService, private router: Router) { }

	ngOnInit() {
		this.paginator.pageIndex = 0;
		this.paginator.pageSize = 5;
	}

	ngAfterViewInit() {

		if (this.dataSource.data.length === 0) {
			this.carregaListaAlerta(this.loginService.user.referencia.id);
		}

		// Quando alterar o módulo no menu, carrega a lista (result é o idRerefencia vindo do componente navegacao.component.ts)
		AlertaService.alertaEmitter.subscribe(result => {
			this.carregaListaAlerta(result);
		});		
	}

	carregaListaAlerta(idReferencia: string) {		
		this.alertaService.findAlertaAtivoByReferencia(idReferencia).subscribe(result => {
			
			this.dataSource.data = result;
			this.paginator.length = result.lenght;
			this.dataSource.paginator = this.paginator;

			for (var alerta of this.dataSource.data) {
				const params = alerta.query_params.split(';'); //pega os paramêtros do alerta que estão cadastrados na coluna queryParams;

				var queryMap = new Map<any, any>();
				queryMap.set('idAlerta', alerta.id);

				for (var p of params) {
					if (AlertaParams.ID_REGIONAL.toString() === p) {
						if(this.loginService.infoUser.regionalorigin === null) queryMap.set(AlertaParams.ID_REGIONAL, 0);
						else queryMap.set(AlertaParams.ID_REGIONAL, this.loginService.infoUser.regionalorigin.id);
					}
					if (AlertaParams.ID_UNIDADE_SAUDE.toString() === p) {
						if(this.loginService.infoUser.unidadeorigin === null) queryMap.set(AlertaParams.ID_UNIDADE_SAUDE, 0);
						else queryMap.set(AlertaParams.ID_UNIDADE_SAUDE, this.loginService.infoUser.unidadeorigin.id);
					}					
					if (AlertaParams.ID_REFERENCIA.toString() === p) {
						queryMap.set(AlertaParams.ID_REFERENCIA, idReferencia);
					}
					if (AlertaParams.ID_USUARIO.toString() === p) {
						queryMap.set(AlertaParams.ID_USUARIO, this.loginService.infoUser.id);
					}
				}

				queryMap = this.converteMap(queryMap);

				//executa a QUERY cadastrada no Alerta e atribui a quantidade que é exibido no html
				this.alertaService.findOcorrencia(queryMap).subscribe(result => {
					alerta.quantidade = result;
				});
			}
		});
	}

	/** Redireciona para a rota do Alerta */
	redireciona(rota: string) {
		this.router.navigate([rota]);
	}

	/** Recebe um map e converte em uma matriz: (chave - valor) para enviar para o back-end */
	private converteMap(map: any): any {
		const convMap = {};
		map.forEach((val: string, key: string) => {
			convMap[key] = val;
		});
		return convMap;
	}
}
