import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedInGuard } from '../security/loggedin.guard';
import { AvisosComponent } from './avisos.component';
import { NovoComponent } from '././cadastro/novo.component';

const routes: Routes = [
  { path: '',  component: AvisosComponent, canLoad: [LoggedInGuard], canActivateChild: [LoggedInGuard] },
  { path: 'cadastro', component: NovoComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AvisosRoutingModule { }
