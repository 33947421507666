/*
Adiciona o valor null ao input quando o valor for String vazia.

Diretiva desenvolvida com objetivo de corrigir problema ao apagar informação
com teclas backspace e delete em inputs do tipo matAutocomplete. Ao fazer está
ação o input converte de forma equivocada o dado do tipo objeto p/ o tipo string 
causando erro 400 na comunição rest entre o front e o back;

Como usar? Basta adicionar no input o atributo: nullValue
*/

import {NgControl} from '@angular/forms';
import {Directive, ElementRef, HostListener} from "@angular/core";

@Directive({
  selector: 'input[nullValue]'
})
export class NullDefaultValueDirective {
  constructor(private el: ElementRef, private control: NgControl ) {}

  @HostListener('input', ['$event.target'])
  onEvent(target: HTMLInputElement){    
    this.control.viewToModelUpdate((target.value === '') ? null : target.value);   
  }
}
