import { TipoDocumento } from "./tipo-documento.model";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LoginService } from "@app/core/security/login/login.service";
import { Observable } from "rxjs";
import { SGS_URL } from "@app/app.api";
import { TipoDocumentoPesquisa } from "./tipo-documento-pesquisa/tipo-documento-pesquisa.model";
import { ListaJSON } from "@app/core/shared/default/lista-json.model";

@Injectable()
export class TipoDocumentoService {
  tipoDocumento: TipoDocumento

  constructor(private http: HttpClient, private loginService: LoginService, ) { }

  cadastrar(tipoDocumento: TipoDocumento): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id
    return this.http.post<any>(`${SGS_URL.JUDICIAL}/tipodocumento/c/${id_referencia}/${id_usuario}`, tipoDocumento)
  }

  inativar(tipoDocumento: TipoDocumento): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id
    return this.http.get<any>(`${SGS_URL.JUDICIAL}/tipodocumento/s/${tipoDocumento.id}/${id_usuario}/${tipoDocumento.status == 'Ativo'}/${id_referencia}`)
  }

  listar(tipoDocumentoPesquisa: TipoDocumentoPesquisa): Observable<ListaJSON<TipoDocumento>> {
    return this.http.post<ListaJSON<TipoDocumento>>(`${SGS_URL.JUDICIAL}/tipodocumento/p`, tipoDocumentoPesquisa)
  }

  getTipoDocumento() {
    if (this.tipoDocumento === undefined) {
      this.resetTipoDocumento();
    }
    return this.tipoDocumento
  }

  buscaTipoDocumento(id_tipodocumento: string): Observable<TipoDocumento> {
    return this.http.post<TipoDocumento>(`${SGS_URL.JUDICIAL}/tipodocumento/id/${id_tipodocumento}`, {})
  }

  setTipoDocumento(tipoDocumento: TipoDocumento) {
    this.tipoDocumento = tipoDocumento
  }

  resetTipoDocumento(): TipoDocumento {
    return (this.tipoDocumento = new TipoDocumento("", "", "", "", "Ativo"));
  }

}
