import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'card-ses',
  templateUrl: './card-ses.component.html',
  styleUrls: ['./card-ses.component.scss']
})
export class CardSesComponent implements OnInit {

  @Input() titulo: string = '';

  constructor() { }

  ngOnInit() {
  }

}
