import { MapaJudicialItem } from './mapa-judicial-item.model';
import { Regional } from '@app/core/regional/regional.model';
import { UnidadeSaude } from '@app/core/unidade-saude/unidade-saude.model';
import { User } from "@app/core/security/login/user.model";
import { Material } from "@app/core/material/material.model";
import { TipoMedicamento } from "@app/core/tipo-medicamento/tipo-medicamento.model";
import { MotivoDevolucao } from "@app/core/motivo-devolucao/motivo-devolucao.model";
import { Paciente } from "@app/core/paciente/paciente.model";
import { Referencia } from "@app/core/centro-custo/Referencia.model";
import { SituacaoProcesso } from '../situacao-processo/situacao-processo.model';
import { TipoMapa } from '../tipo-mapa/tipo.mapa.model';

export class MapaJudicial
{
  constructor(
    public id: string,    
    public mapa_por: string,
    public mes: string,
    public ano: string,
    public observacao: string,
    public usuario:User,
    public regional: Regional,
    public situacao_processo: SituacaoProcesso,
    public paciente: Paciente,
    public medicamento: Material,
    public tipo_mapa: TipoMapa,
    public tipo_medicamento: TipoMedicamento [],
    public mapa_urgente: boolean,
    public dt_inicial_prescricao: Date,
    public dt_final_prescricao: Date,
    public unidades:UnidadeSaude [],
    public motivoDevolucao:MotivoDevolucao,
    public situacoesPrescricao:SituacaoProcesso[],

    public dt_envio_sgm:Date,
    public dt_alteracao:Date,
    public situacao_sgm:number,
    public itens_mapa:MapaJudicialItem [],
    public unidade_saude: UnidadeSaude,
    public referencia: Referencia,

   //utilizado somente para ordenação de table devido o distinct
    private order_tipomedicamento:string,
    private order_tipomapa:string,
    private order_regional:string,
    private order_situacao:string,
    private order_unidadesaude:string,
    private order_motivo_devolucao:string,
    )
    { }
}
