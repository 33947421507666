import { NavegacaoService } from '@app/core/navegacao/navegacao.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatPaginator, MatDialogRef } from '@angular/material';
import { ViewChild } from '@angular/core';
import { tap } from 'rxjs/internal/operators/tap';
import { UnidadeAssistenciaService } from '@app/core/unidade-assistencia/unidade-assistencia.service';
import { Logradouro } from '../../endereco/logradouro.model';
import { EnderecoService } from '../../endereco/endereco.service';
import { LogradouroPesquisa } from '../../endereco/logradouro-pesquisa.model';
import { ResponsavelService } from '@app/core/responsavel/responsavel.service';
import { PacienteService } from '@app/core/paciente/paciente.service';

@Component({
  selector: 'app-modal-endereco',
  templateUrl: './modal-endereco.component.html',
  styleUrls: ['./modal-endereco.component.scss']
})
export class ModalEnderecoComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator
  logradouros: Logradouro[]
  displayedColumns: string[] = ['Logradouro', 'Bairro', 'Municipio', 'UF', 'CEP']
  pesquisaLogradouroForm: FormGroup
  dataSource: MatTableDataSource<Logradouro>
  logradouro: Logradouro
  public loading = false
  closeResult: string

  constructor( private dialogRef: MatDialogRef<ModalEnderecoComponent>, private enderecoService: EnderecoService, private pacienteService: PacienteService,
    private responsavelService: ResponsavelService, private unidadeAssistenciaService: UnidadeAssistenciaService,
    private fb: FormBuilder, private navegacaoService: NavegacaoService) { }

  ngOnInit() {
    this.pesquisaLogradouroForm = this.fb.group({
      logradouro: this.fb.control(''),
      bairro: this.fb.control(''),
      municipio: this.fb.control(''),
      uf: this.fb.control('')
    })
    this.limpar()
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.buscar())).subscribe();
  }

  buscar() {
    this.loading = true
    let logradouroPesquisa = new LogradouroPesquisa(this.pesquisaLogradouroForm.value.logradouro, this.pesquisaLogradouroForm.value.bairro,
      this.pesquisaLogradouroForm.value.municipio, this.pesquisaLogradouroForm.value.uf,
      this.paginator.pageIndex, this.paginator.pageSize)
    this.enderecoService.buscarLogradouros(logradouroPesquisa).subscribe(jsonList => {
      this.logradouros = jsonList.list
      this.paginator.length = jsonList.length
      this.dataSource = new MatTableDataSource<Logradouro>(this.logradouros)
      this.loading = false
    })
  }

  limpar() {
    this.logradouros = []
    this.dataSource = new MatTableDataSource<Logradouro>(this.logradouros)
    this.pesquisaLogradouroForm.setValue({ logradouro: "", bairro: "", municipio: "", uf: "SC" })
    this.paginator.pageIndex = 0
    this.paginator.pageSize = 5
    this.paginator.length = 0
    this.buscar()
  }

  onRowClicked(row) {

    if (this.navegacaoService.copiarDados) {
      return;
    }

    this.logradouro = row
    this.selecionar()
    this.dialogRef.close();
  }

  selecionar(): boolean {
    if (this.logradouro != undefined && this.enderecoService.getTipo() != undefined) {
      if (this.enderecoService.getTipo() === "paciente") this.pacienteService.paciente.pessoaFisica.pessoa.enderecos[0].logradouro = this.logradouro
      else if (this.enderecoService.getTipo() === "responsavel") this.pacienteService.paciente.pessoaFisica.pessoa.enderecos[0].logradouro = this.logradouro
      else if (this.enderecoService.getTipo() === "unidade"){
        this.unidadeAssistenciaService.getUnidadeAssistencia().endereco.logradouro = this.logradouro
        this.unidadeAssistenciaService.getUnidadeAssistencia().endereco.cep = this.logradouro.cep
      } 
    }
    return true
  }

  filtrar() {
    this.buscar()
  }

}
