import { MatDialogRef } from '@angular/material';
import { PrescricaoService } from '@app/core/prescricao/prescricao.service';
import { Component, OnInit } from '@angular/core';
import { MessageService } from '@app/core/shared/message.service';
import { NavegacaoService } from '@app/core/navegacao/navegacao.service';
import { UnidadeSaudeService } from '@app/core/unidade-saude/unidade-saude.service';
import { ReceitaService } from '@app/core/receita/receita.service';
import { UnidadeSaudeModel } from '../unidade-saude.model';

@Component({
  selector: 'app-prescricao-transferencia-modal',
  templateUrl: './prescricao-transferencia-modal.component.html',
  styleUrls: ['./prescricao-transferencia-modal.component.scss']
})
export class PrescricaoTransferenciaModalComponent implements OnInit {

  private loading = false;
  private unidadeSaude: Object = {};
  private unidadeSaudeSelecionada: UnidadeSaudeModel;
  private listaUnidadeSaude: any[];
  private dataTransferencia: Date;

  constructor(
    private prescricaoService: PrescricaoService,
    private messageService: MessageService,
    private navegacaoService: NavegacaoService,
    private receitaService: ReceitaService,
    private unidadeSaudeService: UnidadeSaudeService,
    private dialogRef: MatDialogRef<PrescricaoTransferenciaModalComponent>) { }

  ngOnInit() {
    // Verificando permissões do usuário
    if (!this.navegacaoService.permissaoReceita.visualizar
      && !this.navegacaoService.permissaoPrescricaoTuberculose.visualizar
      && (!this.navegacaoService.permissaoPrescricaoTuberculose.editar || !this.navegacaoService.permissaoPrescricaoTuberculose.inserir)) {
      // Se o usuário não tiver permissão, o modal não irá abrir
      this.prescricaoService.chamarModalTransferencia = false;
    }
    this.buscarUnidadesSaude();
  }

  public close(): void {
    this.limparCampos();
    this.dialogRef.close();
    this.prescricaoService.atualizarListaPrescricao.emit(true);
  }

  // Limpa campo de Unidade de Saúde
  private limparUnidadeSaude() {
    this.unidadeSaude = {};
    this.unidadeSaudeSelecionada = new UnidadeSaudeModel("", "");
    this.buscarUnidadesSaude();
  }

  // Limpa todos os campos do modal
  private limparCampos() {
    this.unidadeSaude = {};
    this.unidadeSaudeSelecionada = new UnidadeSaudeModel("", "");
    this.dataTransferencia = null;
  }

  // Busca todas as unidades de Saúde
  private buscarUnidadesSaude() {
    this.loading = true;
    this.unidadeSaudeService.buscaUnidadeSaudeByNome("").subscribe(res => this.listaUnidadeSaude = res);
    this.loading = false;
  }

  // Atribui a unidade selecionada para variavel que será enviada pelo service
  private setUnidadeSaude(unidadeSaude) {
    if (unidadeSaude != null && unidadeSaude != undefined && unidadeSaude != "") {
      this.unidadeSaude = unidadeSaude;
      this.unidadeSaudeSelecionada = new UnidadeSaudeModel(unidadeSaude.id, unidadeSaude.descricao);
    } else {
      this.messageService.addToast("Selecione uma Unidade de Saúde", "Alerta!", "warning");
      return;
    }
  }

  // Busca as unidades de Saúde que contem os characteres escritos pelo usuário
  private buscarUnidadeSaudeByNome(nomeUnidadeSaude) {
    if (typeof nomeUnidadeSaude === "string" && nomeUnidadeSaude !== "") {
      this.unidadeSaudeService.buscaUnidadeSaudeByNome(nomeUnidadeSaude).subscribe(
        res => this.listaUnidadeSaude = res);
    }
  }

  // // Fecha o modal
  // private fecharModal() {
  //   this.prescricaoService.chamarModalTransferencia = false;
  //   this.limparCampos();
  //   this.prescricaoService.atualizarListaPrescricao.emit(true);
  // }

  // Manda os parametros para o service e depois para o back end
  private transferir() {

    // Verifica se a unidade de saúde está vazia
    if (Object.keys(this.unidadeSaude).length === 0) {
      this.messageService.addToast("Selecione uma Unidade de Saúde", "Alerta!", "warning");
      return;
    }

    // Verifica se a data está preenchida
    if (this.dataTransferencia == null || this.dataTransferencia == undefined) {
      this.messageService.addToast("Selecione uma Data de Transferência", "Alerta!", "warning");
      return;
    }

    this.loading = true;

    // Manda os parametros para o service
    this.prescricaoService.tranferirPrescricao(this.dataTransferencia, this.unidadeSaudeSelecionada.id,
      this.receitaService.receita.prescricao.id).subscribe( ok => {
        this.loading = false;
        this.messageService.addToast("Transferido com sucesso!", "Sucesso!", "success");
        this.close();
      }, error => {
        this.messageService.addToast(error, "Error!", "error");
        this.close();
      });
    this.loading = false;

  }

}
