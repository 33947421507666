import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ses-debug-card',
  templateUrl: './debug-card.component.html',
  styleUrls: ['./debug-card.component.scss']
})
export class DebugCardComponent implements OnInit {

  @Input() nomeComponente: string = '';
  @Input() modulo: string = '';
  @Input() tag: string = '';
  @Input() lsParametros: string[] = [];
  @Input() lsRetornos: string[] = [];

  constructor() { }

  ngOnInit() {
    this.lsParametros.sort();
    this.lsRetornos.sort();
  }

}
