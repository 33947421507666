import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { InputFilter } from './inputs/input-filter.model';
import { DashbordService } from './../dashbord.service'

@Component({
  selector: 'app-modal-filtro',
  templateUrl: './modal_filtro.componente.html',
  styleUrls: ['/modal-filtro.component.scss']
})

export class ModalFiltroComponent implements OnInit {

  public listInputs: [InputFilter];
  public nomeDoGrafico: string = "";

  constructor(
    public activeModal: NgbActiveModal,
    private dashbordService: DashbordService
  ) { }

  ngOnInit() {
    this.iniciarTela();
  }

  aplicarFiltros(){
    this.dashbordService.graficosSelecionado.aplicarFiltros = true;
    this.dashbordService.eventDashUpdate.emit();
  }

  iniciarTela(){
    if(this.dashbordService.graficosSelecionado &&
      this.dashbordService.graficosSelecionado.filtros &&
      this.dashbordService.graficosSelecionado.filtros.length > 0)
    {
      this.listInputs = this.dashbordService.graficosSelecionado.filtros;
      this.nomeDoGrafico = this.dashbordService.graficosSelecionado.title;
    }
  }
}
