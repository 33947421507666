import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SGS_URL, CORE } from '@app/app.api';
import { Observable } from 'rxjs';
import { LoginService } from '../security/login/login.service';
import { UsuarioService } from '../usuario/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class PacienteComsusService {

  public lsIdsSelecionadosMultiSelect: any[] = [];
  public haItensSelecionadosMultiSelect = false;

  constructor(
    private http: HttpClient
  ) { }

  buscarPacientesComsusByNome(nomePaciente: string): Observable<any[]> {
    return this.http.post<any[]>(`${SGS_URL.CORE}/${CORE.PACIENTE_COMSUS}/buscarPacienteCOMSUSPorNomeCPF/${nomePaciente}`, {})
  }

  public getNewLsIdsSelecionados() {
    this.lsIdsSelecionadosMultiSelect = [];
  }
}
