import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PosicaoAnaliseTecnicaService } from "@app/core/nat/posicao-analise-tecnica/posicao-analise-tecnica.service";
import { PosicaoAnaliseTecnicaFilter } from "@app/core/shared/models/posicao-analise-tecnica-filter";
import { PosicaoAnaliseTecnicaResponse } from "@app/core/shared/models/posicao-analise-tecnica-response";

@Component({
  selector: "ses-posicao-analise-tecnica-autocomplete",
  templateUrl: "./posicao-analise-tecnica-autocomplete.component.html",
  styleUrls: ["./posicao-analise-tecnica-autocomplete.component.scss"],
})
export class PosicaoAnaliseTecnicaAutocompleteComponent implements OnInit {
  @Input() filtroAtivo: boolean;
  @Input() required: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();
  @Input() posicaoSelecionada: PosicaoAnaliseTecnicaResponse = new PosicaoAnaliseTecnicaResponse();
  @Input() posicaoidSelecionada: string;
  @Input() id: string = "posicao-analise-tecnica"

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */

  @Output() idSelecionada = new EventEmitter<string>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any>();

  constructor(
    private posicaoAnaliseTecnicaService: PosicaoAnaliseTecnicaService
  ) { }

  public posicaoAnaliseTecnicaFilter: PosicaoAnaliseTecnicaFilter =
    new PosicaoAnaliseTecnicaFilter();
  public lsPosicaoAnaliseTecnica: PosicaoAnaliseTecnicaResponse[] = [];

  ngOnInit() {
    this.posicaoAnaliseTecnicaFilter.paginar = false;

    this.carregaInformacoes();

    this.limparEvent.subscribe({
      next: () => {
        this.limpar();
      },
    });
  }

  public carregaInformacoes() {
    if (this.filtroAtivo && !this.posicaoidSelecionada) {
      this.posicaoAnaliseTecnicaFilter.status = this.filtroAtivo;
    }

    this.posicaoAnaliseTecnicaService
      .getPosicaoAnaliseByFilter(this.posicaoAnaliseTecnicaFilter)
      .subscribe({
        next: (response) => {
          this.lsPosicaoAnaliseTecnica = response.list;
          if(this.posicaoidSelecionada){
            this.posicaoSelecionada = this.lsPosicaoAnaliseTecnica.find(filter => filter.id == +this.posicaoidSelecionada )
          }
        },
      });
  }

  public selecionarPosicao() {
    if(this.posicaoSelecionada){
      this.idSelecionada.emit(this.posicaoSelecionada.id ? this.posicaoSelecionada.id.toString() : null);
      this.elementosCompletoSelecionadas.emit(this.posicaoSelecionada);
    }else{
      this.idSelecionada.emit(null);
      this.elementosCompletoSelecionadas.emit(this.posicaoSelecionada);
    }
  }

  public filter(name: string): any[] {
    let filterValue = name.toLowerCase();
    if (name !== "") {
      return this.lsPosicaoAnaliseTecnica.filter((option) =>
        option.descricao.toLowerCase().includes(filterValue)
      );
    }
    return this.lsPosicaoAnaliseTecnica;
  }

  public limpar() {
    if(this.posicaoidSelecionada){
      this.posicaoidSelecionada = null;
      this.carregaInformacoes();
    }
    this.posicaoSelecionada = null;
    this.idSelecionada.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  public displayFn(posicao: any) {
    return posicao ? posicao.descricao : "";
  }
}
