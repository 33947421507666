import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'save-clear-back',
  templateUrl: './save-clear-back.component.html',
  styleUrls: ['./save-clear-back.component.scss']
})
export class SaveClearBackComponent implements OnInit {

  @Output("save") saveEvent = new EventEmitter();
  @Output("clear") clearEvent = new EventEmitter();
  @Output("back") backEvent = new EventEmitter();
  @Output("details") detailsEvent = new EventEmitter();
  @Output("recallModel") modelEvent = new EventEmitter();

  @Input() btnSaveText = 'Salvar';
  @Input() btnClearText = 'Limpar';
  @Input() btnBackText = 'Voltar';
  @Input() btnDetailsText = 'Detalhes';
  @Input() btnModelText = 'Modelo';

  @Input("No_Save") notSave: boolean = true;
  @Input("No_Clear") notClear: boolean = true;
  @Input("No_Back") notBack: boolean = true;
  @Input("No_Details") notDetails: boolean = false;
  @Input("No_Model") notModel: boolean = false;


  @Input("Disabled_save") disabledButtonSave: boolean = false

  @Input("DisibledModel") isInativeModel: boolean = false;

  @Input("Details_Open") detailsOpen: boolean = true;
  @Input("Icon_Details_Open") iconOpen: string = 'dehaze';
  @Input("Icon_Details_Close") iconClose: string = 'dehaze';

  @Input("Icon_save") iconSave: string = 'save';

  @Input("ToolTipModel") tooltipModel: string = "";
  @Input("TooltipDetails") tooltipDetails: string = "Exibe informações adcionais";

  constructor() { }
  ngOnInit() { }

  save() {
    this.saveEvent.emit();
  }
  clear() {
    this.clearEvent.emit();
  }
  back() {
    this.backEvent.emit();
  }
  details() {
    this.detailsOpen = !this.detailsOpen;
    this.detailsEvent.emit();
  }

  model() {
    this.modelEvent.emit();
  }
}
