import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { EditorModule } from 'primeng/editor';
import { throwError } from 'rxjs';

@Component({
  selector: 'editor-ses',
  templateUrl: './editor-ses.component.html',
  styleUrls: ['./editor-ses.component.scss']
})

export class EditorSesComponent implements OnInit {

  @Input() text: string = "";
  @Input() forceEmitText = new EventEmitter<any>();
  @Output() textosSelecionadas: EventEmitter<string> = new EventEmitter();

  public oldText: string = "";

  ngOnInit(): void {
    this.oldText = this.text;

    this.forceEmitText.subscribe(event =>{
      this.textosSelecionadas.emit(this.text);
    })
  }

  public save(event?: any){
    if( !this.text || !this.text.includes(this.oldText) || (!this.oldText || this.oldText.length == 0 )){
      if (this.text && this.text.length > 0) this.converteTag();
      this.oldText = this.text;
      this.textosSelecionadas.emit(this.text);
    }
  }

  public limpar(){
    this.text = "";
    this.oldText = "";
    this.textosSelecionadas.emit(null);
  }

  public converteTag() {
    this.text = this.text.replace("<strong>","<b>");
    this.text = this.text.replace("</strong>","</b>");
    this.text = this.text.replace("<em>","<i>");
    this.text = this.text.replace("</em>","</i>");
  }

}
