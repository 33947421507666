import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatTableDataSource } from '@angular/material';
import { Observable } from "rxjs";
import { ListaJSON } from "./lista-json.model";
import { CacheService } from '../cache/cache.service';
import { LoginService } from '@app/core/security/login/login.service';
import { SGS_URL, _PREFIXCACHE_NOVO_, _PREFIXCACHE_ARRAY_ } from '@app/app.api';


@Injectable()
export class DefaultService {

  constructor(
    public cache: CacheService,
    protected http: HttpClient,
    protected loginService: LoginService) {
  }

  public cadastrar<E>(model: E, url_api: string): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id
    this.setModel(model, url_api)
    return this.http.post<any>(`${SGS_URL.CORE}/${url_api}/c/${id_referencia}/${id_usuario}`, model);
  }

  public inativar<E>(idModel, status, url_api: string): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id
    return this.http.get<any>(`${SGS_URL.CORE}/${url_api}/s/${idModel}/${id_usuario}/${status}/${id_referencia}`);
  }

  pesquisar<E>(pesquisaModel, url_api: string): Observable<ListaJSON<E>> {
    return this.http.post<ListaJSON<E>>(
      `${SGS_URL.CORE}/${url_api}/p`, pesquisaModel
    );
  }

  pesquisarById<E>(id: string, url_api: string): Observable<E> {
    return this.http.post<E>(`${SGS_URL.CORE}/${url_api}/id/${id}`, {}
    );
  }

  pesquisarByAtivos<E>(url_api: string): Observable<ListaJSON<E>> {
    return this.http.post<ListaJSON<E>>(`${SGS_URL.CORE}/${url_api}/a`, {})
  }

  getModel<E>(url_api: string): E {
    let model: E = this.cache.get(url_api) as E
    if (!model) {
      this.resetModel(url_api);
    }
    return model;
  }

  setModel<E>(model: E, url_api: string) {
    this.validar<E>(model)
    this.cache.set(url_api, model)
  }

  getNovoModel<E>(url_api: string): E {
    let objetoCache = this.cache.get(url_api);
    let newModel = this.cache.get(_PREFIXCACHE_NOVO_ + url_api);
    newModel.descricao = "";
    if(objetoCache.id !== ""){
      newModel.id = objetoCache.id;
      return newModel as E;
    }
    return newModel as E;
  }

  setNovoModel<E>(url_api: string, novoModel: E) {
    this.cache.set(_PREFIXCACHE_NOVO_ + url_api, novoModel)
    let modelFromCache: E = this.cache.get(url_api)
    if (!modelFromCache) {
      this.cache.set(url_api, novoModel)
    }
  }

  resetModel(url_api: string) {
    let m = this.getNovoModel(url_api);
    if (m) this.setModel(m, url_api)
  }

  validar<E>(model: E) {
    const anyModel = model as any
    if (anyModel && anyModel.id && anyModel.id.id) throw new Error("Má programação. Entidade com duas id(s) instanciadas");
  }

  setModelArray<E>(url_api: string, modelArray: E[]) {
    this.setDataSource(url_api, new MatTableDataSource<E>(modelArray))
  }

  setDataSource<E>(url_api: string, dataSouce: MatTableDataSource<E>) {
    return this.cache.set(_PREFIXCACHE_ARRAY_ + url_api, dataSouce)
  }

  getDataSource<E>(url_api: string): MatTableDataSource<E> {
    return this.cache.get(_PREFIXCACHE_ARRAY_ + url_api)
  }
}
