import { Inject, Injectable } from "@angular/core";
import { SESSION_STORAGE, WebStorageService } from "angular-webstorage-service";
import { MessageService } from "../message.service";
import { CachedValue } from "./cached.value.model";


@Injectable()
export class CacheService {

  private saved = false;
  private UPDATE_DELAY = 250 // ms
  private cacheArr: CachedValue[]
  private localStorage: Storage;

  constructor(
    @Inject(SESSION_STORAGE) protected sessionStorage: WebStorageService,
    protected messageService: MessageService) {
    try {
      this.localStorage = window.localStorage;
    } catch (e) {
      this.localStorage = undefined
    }
    this.clear()
    this.runSchedule()
  }

  /**
   * Procura no(s) cache(s) disponíveis pela key específica.
   *
   * Caso este valor esteja na sessão atribui para a memória
   * local (cache).
   *
   * @returns valor : any
   */
  public get(key: string): any {
    if (!key) throw new Error("A key must not be null or undefined")
    let cachedValue: CachedValue = this.getFromCache(key)
    if (cachedValue && cachedValue.value) return cachedValue.value
    cachedValue = this.getFromSession(key)
    if (cachedValue && cachedValue.value) {
      cachedValue = this.setOnCache(key, cachedValue)
      return cachedValue.value
    }
    return null
  }

  /**
   * Atribui um valor na memória local.
   *
   * Os valores contidos na sessão são atualizados pelo
   * próprio módulo via timer @see reload()
   *
   * @rerturns valor-atribuido : any
   */
  public set(key: string, newValue: any): any {
    if (!key) throw new Error("A key must not be null or undefined")
    if (!newValue) {
      this.remove(key)
      return null
    }
    return this.setOnCache(key, newValue)
  }


  public remove(key: string): void {
    if (!key) return
    this.cacheArr[key] = null
    this.sessionStorage.remove(key)
  }

  /**
   * Recarrega os valores atuais do cache com os valores
   * disponíveis na sessão.
   */
  public reload() {
    if (this.sessionStorage) {
      let arr = this.getKeys()
      for (let i = 0; i < arr.length; i++) {
        const key = arr[i]
        const value = this.cacheArr[key]
        const sessionValue = this.sessionStorage.get(key)
        const localValue = this.localStorage.get(key)
        this.cacheArr[i] = sessionValue
      }
    }
  }

  /**
   * Limpa o cache da sessão.
   */
  public clearSession() {
    if (this.cacheArr) {
      let arr = this.getKeys()
      for (let i = 0; i < arr.length; i++) {
        const key = arr[i]
        this.sessionStorage.remove(key)
      }
    }
  }

  /**
   * Limpa o cache da memória.
   */
  public clear() {
    if (this.cacheArr) {
      let arr = this.getKeys()
      for (let i = 0; i < arr.length; i++) {
        const key = arr[i]
        this.localStorage.remove(key)
      }
    }
    this.cacheArr = []
  }

  protected setOnCache(key: string, newValue: any): CachedValue {
    let dataAtual: Date = new Date()
    let newCachedValue: CachedValue
    if (this.cacheArr[key]) {
      newCachedValue = this.cacheArr[key]
    } else {
      newCachedValue = new CachedValue()
    }
    newCachedValue.value = newValue
    newCachedValue.alteration = dataAtual
    newCachedValue.lastRequestDate = dataAtual
    this.checkNotifyLastChange(newCachedValue)
    return this.cacheArr[key] = newCachedValue
  }

  protected checkNotifyLastChange(newValue: any) {
    //TODO : verificar alterações e criar módulo para
    // exibição de dialog para recuperação de dados
  }

  /**
   * Busca em memória pela key, caso não encontre retorna NULL
   *
   *  @returns value : any
   */
  protected getFromCache(key: string): CachedValue {
    if (this.cacheArr) return this.cacheArr[key]
    return null
  }

  /**
   * Busca em sessão pela key, caso não encontre retorna NULL
   *
   *  @returns value : any
   */
  protected getFromSession(key: string): CachedValue {
    let sessionCached: CachedValue = this.sessionStorage.get(key)
    if (sessionCached) {
      return sessionCached
    }
    return null
  }


  protected salvarTemporaryCache() {
    this.saved = false
    let arr = this.getKeys()
    for (let index = 0; index < arr.length; index++) {
      const key: string = arr[index]
      const cached: CachedValue = this.cacheArr[key]
      const sessionValue = this.sessionStorage.get(key)
      const localValue = this.localStorage.getItem(key)
    }
    this.saved = true;
  }

  protected runSchedule() {
    setTimeout(() => {
      this.salvarTemporaryCache()
      if (this.saved) {
        this.runSchedule()
      }
    }, this.UPDATE_DELAY)
  }

  protected getKeys() {
    return Object.keys(this.cacheArr);
  }
}
