import { LoginService } from '@app/core/security/login/login.service';
import { Injectable } from "@angular/core"
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { SGS_URL, CORE } from "@app/app.api";
import { UsuarioNivel } from "../nivel/usuario-nivel.model";

@Injectable()
export class NivelService {

    constructor(
      private http: HttpClient,
      private loginService: LoginService ) {
    }

    cadastrarNivel(usuarioNivel: UsuarioNivel): Observable<any> {
      return this.http.post<any>(`${SGS_URL.CORE}/${CORE.NIVEL}/c/${this.loginService.user.referencia.id}/${this.loginService.user.id}`, usuarioNivel)
    }

    getNivelAll(id_tipo: string, id_usuario: string, id_referencia: string): Observable<any[]> {
      return this.http.post<any[]>(`${SGS_URL.CORE}/${CORE.NIVEL}/nivel/todos/${id_tipo}/${id_usuario}/${id_referencia}`, {})
    }

}
