import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LoginService } from "@app/core/security/login/login.service";
import { UnidadeSaudeService } from "@app/core/unidade-saude/unidade-saude.service";

@Component({
  selector: "ses-unidade-autocomplete",
  templateUrl: "./unidade-autocomplete.component.html",
  styleUrls: ["./unidade-autocomplete.component.scss"],
})
export class UnidadeAutocompleteComponent implements OnInit {
  @Input() filtroNivel: boolean = true;
  @Input() required: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();
  @Input() id: string = "unidade";

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */
  @Output() idSelecionada = new EventEmitter<string>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any>();

  constructor(
    private loginService: LoginService,
    private unidadeService: UnidadeSaudeService
  ) {}

  public unidadeSelecionada: any;
  public lsUnidade: any[] = [];

  ngOnInit() {
    if (this.filtroNivel) {
      this.lsUnidade = this.loginService.infoUser.unidades;
    } else {
      this.carregaUnidades();
    }
    this.limparEvent.subscribe({
      next: (resp) => {
        this.limpar();
      },
    });
  }

  public carregaUnidades() {
    this.unidadeService.getAllUnidadesSaude().subscribe({
      next: (resp) => {
        this.lsUnidade = resp;
      },
    });
  }

  public filter(name: string): any[] {
    let filterValue = name.toLowerCase();
    if (name !== "") {
      return this.lsUnidade.filter((option) =>
        option.nome.toLowerCase().includes(filterValue)
      );
    }
    return this.lsUnidade;
  }

  public displayFn(unidade: any) {
    return unidade ? unidade.nome : "";
  }

  public selecionarUnidade() {
    this.idSelecionada.emit(this.unidadeSelecionada.id);
    this.elementosCompletoSelecionadas.emit(this.unidadeSelecionada);
  }

  public limpar() {
    this.unidadeSelecionada = null;
    this.idSelecionada.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }
}
