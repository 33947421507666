import { ModalSesComponent } from './../components/components-util/modal-ses/modal-ses.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { ModalOpen } from './modal-open';

@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss']
})
export class DebugComponent implements OnInit {

  public lsStatus = [
    { descricao: "Todos", status: 1 },
    { descricao: "Autocomplete", status: 2 },
    { descricao: "Multiselect", status: 3 },
    { descricao: "Select", status: 4 },
    { descricao: "Pick-List", status: 5 },
    { descricao: "Editor", status: 6 },
    { descricao: "Modal", status: 7 },
  ];

  public tpComponente: number = 1;

  constructor(
    private router: Router,
    private dialog: MatDialog,) { }

  ngOnInit() {
    if(environment.production){
      this.router.navigate(['navegacao/inicio']);
    }
  }

  abreModal(){
    let _dialogRef = this.dialog.open(ModalOpen, {
      width: "50vw",
      height: "40vh",
      panelClass: "modal"
    });
  }

}
