import { CentroCusto } from './../centro-custo/centro-custo.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Regional } from './regional.model';
import { RegionalFilter } from './regional-filter-pesquisa.model';
import { Observable } from 'rxjs';
import { UnidadeAssistencia } from '../unidade-assistencia/unidade-assistencia.model';
import { SGS_URL, CORE } from '@app/app.api';
import { LoginService } from '../security/login/login.service';
import { ListaJSON } from '../shared/default/lista-json.model';
import { GetAllByStatusFilter } from '../shared/models/get-all-by-status.filter.model';

@Injectable({
  providedIn: 'root'
})
export class RegionalService {

  //objeto que será salvo no banco de dados
  private _regionalActive: Regional;
  private referencia:any = this.loginService.user.referencia.id;

  //objeto responsável por filtrar a tabela de Regionais
  private _regionalSearch: RegionalFilter;
  private _unidadeFilter;
  private _unidadeCentroCusto;

  public lsIdsSelecionadosMultiSelect: any[] = [];
  public haItensSelecionadosMultiSelect = false;

  constructor
    (
      private http: HttpClient,
      private loginService: LoginService
    ) { }

  //Busca lista de Regionais no back-end
  searchListRegionais():Observable<ListaJSON<Regional>> {
    this._regionalSearch.referencia= Number (this.loginService.user.referencia.id)
    return this.http.post<ListaJSON<Regional>>(`${SGS_URL.CORE}/${CORE.REGIONAL}/p`, this._regionalSearch);
  }

  getRegionaisByDescricao(descricao:string):Observable<any> {
    this.getNewRegionalSearch();
    this.referencia = this.loginService.user.referencia.id;
    this._regionalSearch.referencia = Number (this.referencia);
    this._regionalSearch.descricao = descricao;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.REGIONAL}/getRegionaisByDescricao`, this._regionalSearch);
  }

  getRegionaisByDescricaoAndReferencia(descricao:string, referencia:any):Observable<any> {
    this.getNewRegionalSearch();
    this.referencia = referencia;
    this._regionalSearch.referencia = Number (this.referencia);
    this._regionalSearch.descricao = descricao;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.REGIONAL}/getRegionaisByDescricao`, this._regionalSearch);
  }


  listar(regionalPesquisa: RegionalFilter): Observable<ListaJSON<Regional>> {
    return this.http.post<ListaJSON<Regional>>(`${SGS_URL.CORE}/regional/p`, regionalPesquisa)
  }

  //buscar todas as unidades de asssistencia  com estabelecimento R
  searchListUnidadeAssistenciaR(): Observable<UnidadeAssistencia[]> {
    return this.http.post<UnidadeAssistencia[]>(`${SGS_URL.CORE}/${CORE.UNIDADE_ASSISTENCIA}/getListUnidadesAssistenciaisR`, this._unidadeFilter);
  }

    //Atualiza o status de um objeto no banco de dados
    updateActive(regional:Regional): Observable<any>
    {
      const id_usuario = this.loginService.user.id;
      const id_referencia = this.loginService.user.referencia.id
      return this.http.post<any>(`${SGS_URL.CORE}/${CORE.REGIONAL}/s/${regional.id}/${id_usuario}/${regional.status}/${id_referencia}`, {})
    }

  //buscar todos os centros de custo não utilizados
  searchListCentroCusto(): Observable<CentroCusto[]> {
    return this.http.post<CentroCusto[]>(`${SGS_URL.CORE}/${CORE.CENTRO_CUSTO}/getListCentroCusto`, this._unidadeFilter);
  }

  /**Método para salvar um objeto e recuperar o id para preencher a classe **/
  save(): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.REGIONAL}/c/${id_referencia}/${id_usuario}`, this.regionalActive)
  }

  getNewRegionalSearch() {
    this.regionalSearch = new RegionalFilter(null, null, null, null, null, null, null, null);
  }

  public getAllByStatus(filter: GetAllByStatusFilter) {
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.REGIONAL}/all`, filter);
  }

  public getNewLsIdsSelecionados() {
    this.lsIdsSelecionadosMultiSelect = [];
  }

  //=======[Métodos Sets e Gets]=======

  public get regionalActive(): Regional {
    return this._regionalActive;
  }
  public set regionalActive(value: Regional) {
    this._regionalActive = value;
  }
  public get regionalSearch(): RegionalFilter {
    return this._regionalSearch;
  }
  public set regionalSearch(value: RegionalFilter) {
    this._regionalSearch = value;
  }
  public get unidadeFilter(): any {
    return this._unidadeFilter;
  }
  public set unidadeFilter(value: any) {
    this._unidadeFilter = value;
  }
  public get unidadeCentroCusto() {
    return this._unidadeCentroCusto;
  }
  public set unidadeCentroCusto(value) {
    this._unidadeCentroCusto = value;
  }


}
