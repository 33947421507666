
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { SituacaoProcessoPesquisa } from './situacao-processo-pesquisa/situacao-processo-pesquisa.model';
import { SituacaoProcesso } from './situacao-processo.model';
import { LoginService } from '@app/core/security/login/login.service';
import { ListaJSON } from '@app/core/shared/default/lista-json.model';
import { SGS_URL, CORE } from '@app/app.api';
import { MotivoExclusao } from '@app/judicial/motivo-exclusao/motivo-exclusao.model';
import { DescricaoModel } from '../shared/descricao/descricao.model';

@Injectable()
export class SituacaoProcessoService {

  situacaoProcesso = new SituacaoProcesso(null, null,null,null,null,null,null,true,null, null, null, [], null);

  //lista de situações de processos que não geram mapa, o qual será escolhido para aplicar em prescrições
  private _lista_situacoes_processos_nao_geram_mapa: SituacaoProcesso[];
  //lista do component multselect search
  public lsIdsSelecionadosMultiSelect: any[] = [];
  public haItensSelecionadosMultiSelect = false;

  private referencia: any = this.loginService.user.referencia.id;

  constructor(
    private http: HttpClient,
    private loginService: LoginService) { }

  public cadastrar(situacaoProcesso: SituacaoProcesso): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id
    this.situacaoProcesso.referencia = this.loginService.user.referencia;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.SITUACAO_PROCESSO}/c/${id_referencia}/${id_usuario}`, situacaoProcesso)
  }

  public updateStatus(situacaoProcesso: SituacaoProcesso, status:boolean): Observable<any>
  {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.SITUACAO_PROCESSO}/s/${situacaoProcesso.id}/${id_usuario}/${status}/${id_referencia}`,{})
  }

  listar(situacaoProcessoPesquisa: SituacaoProcessoPesquisa): Observable<ListaJSON<SituacaoProcesso>> {
    situacaoProcessoPesquisa.referencia = this.loginService.user.referencia.id;
    return this.http.post<ListaJSON<SituacaoProcesso>>(`${SGS_URL.CORE}/${CORE.SITUACAO_PROCESSO}/p`, situacaoProcessoPesquisa)
  }

  buscaSituacaoProcesso(id_situacao_processo: string): Observable<SituacaoProcesso> {
    return this.http.post<SituacaoProcesso>(`${SGS_URL.CORE}/${CORE.SITUACAO_PROCESSO}/id/${id_situacao_processo}`, {})
  }

  //busca todas as situações da prescrição que não geram mapa
  public findAllSituacoesProcessoGerenarionMap(isGerar:Boolean): Observable<SituacaoProcesso []> {
    return this.http.post<SituacaoProcesso[]>(`${SGS_URL.CORE}/${CORE.SITUACAO_PROCESSO}/find_all_situacoes_processo_gerenario_map`, {isGerar:isGerar, referencia: this.loginService.user.referencia.id})
  }

  listarSituacaoProcessoMaterial(nome: string): Observable<SituacaoProcesso[]> {
    return this.http.post<SituacaoProcesso[]>(`${SGS_URL.JUDICIAL}/situacaoProcesso/buscaSituacaoByNome/`, {nome, referencia: this.loginService.user.referencia.id})
  }

  public buscaSituacaoByNome(nome: string): Observable<SituacaoProcesso[]> {
    return this.http.post<SituacaoProcesso[]>(`${SGS_URL.CORE}/${CORE.SITUACAO_PROCESSO}/buscaSituacaoByNome`, { nome: nome, referencia: this.loginService.user.referencia.id });
  }

  public buscaSituacaoProcessoByReferencia(): Observable<SituacaoProcesso[]> {
    this.referencia = this.loginService.user.referencia.id;
    return this.http.post<SituacaoProcesso[]>(`${SGS_URL.CORE}/${CORE.SITUACAO_PROCESSO}/buscaSituacaoProcessoByReferencia`, {referencia: this.referencia});
  }

  public buscaSituacaoProcessoByReferenciaParametro(referencia:any): Observable<SituacaoProcesso[]> {
    this.referencia = referencia;
    return this.http.post<SituacaoProcesso[]>(`${SGS_URL.CORE}/${CORE.SITUACAO_PROCESSO}/buscaSituacaoProcessoByReferencia`, {referencia: this.referencia});
  }

  getNovaSituacaoProcesso(): SituacaoProcesso {
    let listMotivos: MotivoExclusao[] = []
    return this.situacaoProcesso = new SituacaoProcesso("", null,"", true, false, false, false, true, false, false, false, listMotivos, null)
  }

  public getListaSituacaoDestino(situacaoOrigemId:string): Observable<SituacaoProcesso []> {
    return this.http.post<SituacaoProcesso[]>(`${SGS_URL.CORE}/${CORE.SITUACAO_PROCESSO}/getListaSituacaoDestino`, { situacaoOrigemId : situacaoOrigemId} );
  }

  getSituacaoProcesso() {
    if (this.situacaoProcesso === undefined) {
      this.situacaoProcesso = this.getNovaSituacaoProcesso()
    }
    return this.situacaoProcesso
  }

  setSituacaoProcesso(situacaoProcesso: SituacaoProcesso) {

    this.situacaoProcesso = situacaoProcesso
  }

  public get lista_situacoes_processos_nao_geram_mapa(): SituacaoProcesso[] {
    return this._lista_situacoes_processos_nao_geram_mapa;
  }

  public set lista_situacoes_processos_nao_geram_mapa(value: SituacaoProcesso[]) {
    this._lista_situacoes_processos_nao_geram_mapa = value;
  }

  public getNewLsIdsSelecionados() {
    this.lsIdsSelecionadosMultiSelect = [];
  }

}
