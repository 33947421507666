import { Injectable, EventEmitter } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SGS_URL, CORE } from '@app/app.api';

import { LoginService } from '@app/core/security/login/login.service';


@Injectable({
	providedIn: 'root'
})
export class AlertaService {

	idReferencia: string = '';
	
	static alertaEmitter = new EventEmitter();

	constructor(private httpclient: HttpClient,
		private loginService: LoginService) {
		this.idReferencia = this.loginService.user.referencia.id;
	}

	public findAlertaAtivoByReferencia(idReferencia: string): Observable<any> {
		return this.httpclient.post<any>(`${SGS_URL.CORE}/${CORE.ALERTA}/findAlertaAtivoByReferencia`, { idReferencia: idReferencia });
	}
	
	public findOcorrencia(queryMap: any): Observable<any> {
		return this.httpclient.post<any>(`${SGS_URL.CORE}/${CORE.ALERTA}/findOcorrencia`, queryMap );
	}
	
	public findAlertaByCriterio(ativo: Boolean) {
		return this.httpclient.post<any>(`${SGS_URL.CORE}/${CORE.ALERTA}/findAlertaByCriterio`, { idReferencia: this.idReferencia, ativo: ativo });
	}
	
	public updateAlertaAtivo(idAlerta: string, ativo: boolean) {
		return this.httpclient.post<any>(`${SGS_URL.CORE}/${CORE.ALERTA}/updateAlertaAtivo`, { idAlerta: idAlerta, ativo: ativo });
	}

}
