import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mat-select-multiple',
  templateUrl: './mat-select-multiple.component.html',
  styleUrls: ['./mat-select-multiple.component.scss']
})
export class MatSelectMultipleComponent implements OnInit {
  @Input() placeholder: string = "Placeholder";        //placeholder (descrição) do componente
  @Input() tooltip: string = "";                       //tooltip (dica) do componente
  @Input() required:boolean = false;                   //Campo que deixa o preenchimento do componente como obrigatório. [true = Obrigatório] e [false Não Obrigatório]
  @Input() disabled:boolean = false;                   //Booleano que deixa o componente desabilitado ou habilitado. [true = desabilitado] e [false = habilitado]
  @Input() botaoTodosLimpar:boolean = false;           //Booleano do ngIf para apresentar botoões TODOS e LIMPAR dentro da lista. [true = aparece] e [false = esconde]

  @Input() listaSelecionados: any [] = [];             //Lista de itens selecionados até o momento
  @Input() listaItensDisponiveis: any [] = [];         //Lista de itens disponíveis para seleção

  @Output("getSelectedItens") getSelectedItens = new EventEmitter<[any []]>();  //Evento que vai retornar os itens para quem chama o component

  //Lista dos motivos selecionados que vão ser apresentados no mat-select
  private itensSelecionadosDesc: string
  private allItensActive:boolean = false;

  constructor() { }

  ngOnInit() {
    //Caso a tela já tenha itens salvos no banco, ele joga esses itens quando carrega a tela para aparecer no mat-select
    this.concatenateItensSelecionados(this.listaSelecionados)
  }

  //Compara os itens para aparecer marcado (check) caso estejam selecionados quando carregar a tela
  comparaItem(item1: any, item2: any) {
    return item1 && item2 ? item1.id === item2.id : item1 === item2;
  }

  //Limpa o que foi selecionado no componente e envia pra tela para limpar a lista.
  limparComponent(){
    let newList: any[] = []
    this.listaSelecionados = newList;
    this.getSelectedItens.emit([this.listaSelecionados]);
  }

  //Seleciona todos os itens da Lista quando clica no Botão Todos
  selecionaTudo(){
    this.listaSelecionados = this.listaItensDisponiveis
    this.concatenateItensSelecionados(this.listaSelecionados)
  }

  //Limpa todos os itens da Lista quando clica no Botão Limpar
  LimpaSelecoes(){
    this.listaSelecionados = []
    this.concatenateItensSelecionados
    this.concatenateItensSelecionados(this.listaSelecionados)
  }

  //Concatena os motivos selecionados, da o espaçamento customizado e aciona o evento
  //que devolve os dados selecionados para a tela que implementou esse component
  private concatenateItensSelecionados(itens: any){
    if(itens){
      this.itensSelecionadosDesc = ""
      let count = 0
      for (let i of itens) {
        this.itensSelecionadosDesc = this.itensSelecionadosDesc + i.descricao
        if( (count+1) < itens.length){
          this.itensSelecionadosDesc = this.itensSelecionadosDesc + ",  "
        }
        count++
      }
      this.getSelectedItens.emit([this.listaSelecionados]);
    }
  }

}
