
export class RegionalFilter 
 {
    constructor 
    (
        
        public descricao: string,        //busca pela descrição do objeto
        public centro_custo: string,     //busca pelo nome do Centro de Custo
        public unidade_assitencial: string,          //busca pelo nome_resumido da unidade de assitencia
        public pageIndex: number,        //define apartir de qual valor será buscado os objetos
        public pageSize: number,         //define a quantidade de objetos que será buscado
        public sort: string,             //define por qual atributo será ordenado os objetos
        public direction: string,         //define se a ordenação será crescente ou descrescente
        public referencia?: number,

    ) {}
  }
  