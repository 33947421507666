import { LoginService } from './../security/login/login.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable, EventEmitter } from "@angular/core"
import { SGS_URL, CORE } from '@app/app.api';
import { UsuarioService } from '@app/core/usuario/usuario.service';
import { ListaJSON } from '../shared/default/lista-json.model';
import { ItemEntrada } from '../recebimento/item-entrada.model';
import { MovimentoSaldoBloqueio } from '../recebimento/movimento-saldo-bloqueio.model';
import { MovimentoSaldoBloqueioTooltip } from '../recebimento/movimento-saldo-bloqueio-tooltip.model';

@Injectable({
  providedIn: 'root'
})
export class EstoqueService {
  //exibe/oculta o painel de pesquisa
  private _isPanelSearch: boolean = false;

  //objeto com filtro de pesquisa de produtos no estoque
  private _filterEstoque = this.getFilterEstoque();

  private _itemEntrada: ItemEntrada;
  private _bloquear: boolean;
  private _movimentoSaldoBloqueio: MovimentoSaldoBloqueio;
  private _eventRecarregarConsultaEstoque: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private userService: UsuarioService,
    private loginService:LoginService,
    private httpclient: HttpClient
  ) { }

  //Busca os medicamentos de um estabelecimento
  public findAllItensInputByEstablishment(): Observable<ListaJSON<ItemEntrada>>
  {
    this._filterEstoque.referencia = this.loginService.user.referencia.id;
    return this.httpclient.post<ListaJSON<ItemEntrada>>(`${SGS_URL.CORE}/${CORE.ITEM_ENTRADA}/findAllItenInByEstablishiment`, this._filterEstoque);
  }

  public fetchBloqueioInfos(listIds:string[]): Observable<MovimentoSaldoBloqueioTooltip[]>
  {
    return this.httpclient.post<MovimentoSaldoBloqueioTooltip[]>(`${SGS_URL.CORE}/${CORE.ITEM_ENTRADA}/bloqueioInfo`, listIds);
  }

  public saldoBloquear(): Observable<MovimentoSaldoBloqueio>
  {
    this._movimentoSaldoBloqueio.bloqueadoPor = this.loginService.user;
    this._movimentoSaldoBloqueio.itemEntrada = this._itemEntrada;

    return this.httpclient.post<MovimentoSaldoBloqueio>(`${SGS_URL.CORE}/${CORE.ITEM_ENTRADA}/bloquearSaldo`, this._movimentoSaldoBloqueio);
  }

  public saldoDesbloquear(): Observable<MovimentoSaldoBloqueio>
  {
    this._movimentoSaldoBloqueio.bloqueadoPor = this.loginService.user;
    this._movimentoSaldoBloqueio.itemEntrada = this._itemEntrada;

    return this.httpclient.post<MovimentoSaldoBloqueio>(`${SGS_URL.CORE}/${CORE.ITEM_ENTRADA}/desbloquearSaldo`, this._movimentoSaldoBloqueio);
  }

  public getFilterEstoque() {
    return {
      local: '1',
      medicamento: "",
      lote: "",
      unidadeSaude: this.userService.infoUser && this.userService.infoUser.unidadeorigin ? this.userService.infoUser.unidadeorigin.id: null,
      regional:     this.userService.infoUser && this.userService.infoUser.regionalorigin ? this.userService.infoUser.regionalorigin.id: null,
      referencia:null,
      somente_modulo:true,
      direction: 'asc',
      sort: "data_validade",
      pageIndex: 0,
      pageSize: 5,
    };
  }

  //=======[ Métodos Sets e Gets ]=======

  public get isPanelSearch(): boolean {
    return this._isPanelSearch;
  }
  public set isPanelSearch(value: boolean) {
    this._isPanelSearch = value;
  }

  public get filterEstoque() {
    return this._filterEstoque;
  }
  public set filterEstoque(value) {
    this._filterEstoque = value;
  }

  public get itemEntrada() {
    return this._itemEntrada;
  }
  public set itemEntrada(value) {
    this._itemEntrada = value;
  }

  public get movimentoSaldoBloqueio() {
    return this._movimentoSaldoBloqueio ;
  }
  public set movimentoSaldoBloqueio(value) {
    this._movimentoSaldoBloqueio = value;
  }

  public get bloquear() {
    return this._bloquear;
  }
  public set bloquear(value: boolean) {
    this._bloquear = value;
  }

  public get eventRecarregarConsultaEstoque(): EventEmitter<boolean> {
    return this._eventRecarregarConsultaEstoque;
  }
  public set eventRecarregarConsultaEstoque(value: EventEmitter<boolean>) {
    this._eventRecarregarConsultaEstoque = value;
  }
}
