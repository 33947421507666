import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Processo } from './processo.model';
import { Observable } from 'rxjs';
import { SGS_URL, JUDICIAL, CORE } from '../../app.api';
import { LoginService } from '@app/core/security/login/login.service';
import { ListaJSON } from '@app/core/shared/default/lista-json.model';
import { Paciente } from '@app/core/paciente/paciente.model';
import { Reu } from './reu.model';
import { Prescricao } from '@app/core/prescricao/prescricao.model';

@Injectable({
  providedIn: 'root'
})
export class ProcessoService 
{ 
  private _processo: Processo; 
  private _processoPesquisa = this.getFilterEmptyProcessoPesquisa(); 
  private _eventClearProcesso: EventEmitter<boolean> = new EventEmitter<boolean>();   
 
  public BACK_PROCESSO = 0;
  public BACK_HOME = 0;
  public BACK_PESQUISA_PROCESSO = 1;
  public BACK_CADASTRO_PACIENTE = 2;

  constructor(private http: HttpClient, private loginService: LoginService) { }

  cadastrar(): Observable<any>
  {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id;
        return this.http.post<any>(`${SGS_URL.JUDICIAL}/processo/c/${id_referencia}/${id_usuario}`, this._processo)
  }

  deletarProcesso(idProcesso: String): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id;
    return this.http.post<any>(`${SGS_URL.JUDICIAL}/${JUDICIAL.PROCESSO}/deletarProcesso`, {processo_id: idProcesso, id_referencia: id_referencia, id_usuario: id_usuario })
  }

  listar(id_autor: String): Observable<Processo[]> {
    return this.http.post<Processo[]>(`${SGS_URL.JUDICIAL}/${JUDICIAL.PROCESSO}/getProcessosByPaciente`, {paciente: id_autor})
  }

  listarAll(): Observable<ListaJSON<Processo>>
  {
    return this.http.post<ListaJSON<Processo>>(`${SGS_URL.JUDICIAL}/${JUDICIAL.PROCESSO}/p`, this.processoPesquisa);
  }

 
  getPrescricao(idProcesso: String, idPrescricao: String): Observable<Prescricao> {
    return this.http.get<any>(`${SGS_URL.JUDICIAL}/${CORE.PRESCRICAO}/buscarPrescricao/${idProcesso}/${idPrescricao}`)
  }


  // download(idDocumento: string): Observable<any> {
  //   return this.http.get<any>(`${SGS_URL.JUDICIAL}/download/${idDocumento}`)
  // }

  getNovoProcesso(paciente:Paciente): Processo
  {
    return new Processo(null, paciente, null, null, null, null, null, null, '0', '0', new Date());
  }
 

  deleteProtocolo(id_protocolo:string):Observable<any>
  {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id;

    return this.http.post<any>(`${SGS_URL.JUDICIAL}/${JUDICIAL.PROTOCOLO}/s/${id_protocolo}/${id_usuario}/true/${id_referencia}`, {});
  }

  public getReusActives():Observable<Reu[]>
  {
    return this.http.get<Reu[]>(`${SGS_URL.JUDICIAL}/${JUDICIAL.PROCESSO}/get_all_reus_actives`);
  }

  public getFilterEmptyProcessoPesquisa()
  {
    return {
      num_processo:null,
      nome_cpf:null,
      direction:"asc",
      sort: "nome",
      pageIndex:0,
      pageSize:5,
     }
  }

  ///métodos sets e  gets
  public get processo(): Processo 
  {
    return this._processo;
  }
  public set processo(value: Processo) 
  {
    this._processo = value;
  }
  public get processoPesquisa() {
    return this._processoPesquisa;
  }
  public set processoPesquisa(value) {
    this._processoPesquisa = value;
  }
  public get eventClearProcesso(): EventEmitter<boolean> {
    return this._eventClearProcesso;
  }
  public set eventClearProcesso(value: EventEmitter<boolean>) {
    this._eventClearProcesso = value;
  }

}
