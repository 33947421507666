import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SGS_URL, CORE } from '@app/app.api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReferenciaService {

  public lsIdsSelecionadosMultiSelect: any[] = [];
  public haItensSelecionadosMultiSelect = false;

  constructor(private http: HttpClient) { }

  public getNewLsIdsSelecionados() {
    this.lsIdsSelecionadosMultiSelect = [];
  }

  public buscarReferenciaByNome(nome: string): Observable<any> {
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.REFERENCIA}/buscarReferenciaByNomeMultiSelect`, {nome: nome})
  }
}
