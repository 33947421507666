import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cns'
})
export class CnsPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if(value == null) return null;
    
    if(value.length > 14)
    {
      return value.substring(0, 3) + " " +
      value.substring(3, 7) + " " +
      value.substring(7, 11) + " " +
      value.substring(11, 15);    
    }
    else  return value;
  }

}
