import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ParametrizacaoQuestoesAnaliseTecnicaService } from "@app/core/nat/parametrizacao-questoes-analise/parametrizacao-questoes-analise-tecnica.service";
import { ParametrizacaoQuestoesAnaliseTecnicaFilter } from "@app/core/shared/models/parametrizacao-questoes-analise-tecnica-filter";
import { ParametrizacaoQuestoesAnaliseTecnicaResponse } from "@app/core/shared/models/parametrizacao-questoes-analise-tecnica-response";

@Component({
  selector: "ses-questao-analise-tecnica",
  templateUrl: "./questao-analise-tecnica.component.html",
  styleUrls: ["./questao-analise-tecnica.component.scss"],
})
export class QuestaoAnaliseTecnicaComponent implements OnInit {
  @Input() filtroAtivo: boolean = true;
  @Input() required: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();

  //TODO: Alterar tipo para o correto
  @Input() parametrizacaoQuestoesSelecionada: ParametrizacaoQuestoesAnaliseTecnicaResponse = new ParametrizacaoQuestoesAnaliseTecnicaResponse();

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */

  @Output() idSelecionada = new EventEmitter<string>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any>();

  //TODO: Alterar tipo para o correto
  constructor(
    private parametrizacaoQuestoesAnaliseTecnicaService: ParametrizacaoQuestoesAnaliseTecnicaService
  ) {}

  //TODO: Alterar tipo para o correto
  public posicaoAnaliseTecnicaFilter: ParametrizacaoQuestoesAnaliseTecnicaFilter = new ParametrizacaoQuestoesAnaliseTecnicaFilter();
  public lsPosicaoAnaliseTecnica: any[] = [];

  ngOnInit() {
    this.posicaoAnaliseTecnicaFilter.paginar = false;

    if (this.filtroAtivo) {
      this.posicaoAnaliseTecnicaFilter.status = this.filtroAtivo;
    }

    this.parametrizacaoQuestoesAnaliseTecnicaService.getParametroQuestoesByFilter(this.posicaoAnaliseTecnicaFilter)
      .subscribe({
        next: (response) => {
          this.lsPosicaoAnaliseTecnica = response.list;
        },
      });

    this.limparEvent.subscribe({
      next: () => {
        this.limpar();
      },
    });
  }

  public selecionarParametrizacaoQuestoes() {
    this.idSelecionada.emit(this.parametrizacaoQuestoesSelecionada ? this.parametrizacaoQuestoesSelecionada.id + "" : null);
    this.elementosCompletoSelecionadas.emit(this.parametrizacaoQuestoesSelecionada);
  }

  public filter(name: string): any[] {
    let filterValue = name.toLowerCase();
    if (name !== "") {
      return this.lsPosicaoAnaliseTecnica.filter((option) =>
        option.descricao.toLowerCase().includes(filterValue)
      );
    }
    return this.lsPosicaoAnaliseTecnica;
  }

  public limpar() {
    this.parametrizacaoQuestoesSelecionada = null;
    this.idSelecionada.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  public displayFn(posicao: any) {
    return posicao ? posicao.descricao : "";
  }
}
