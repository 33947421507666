import { Entrada } from './../recebimento/entrada.model';
import { Referencia } from './../centro-custo/Referencia.model';
import { ItemEntrada } from '@app/core/recebimento/item-entrada.model';
import { LoginService } from '@app/core/security/login/login.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UsuarioService } from '../usuario/usuario.service';
import { ListaJSON } from '../shared/default/lista-json.model';
import { SGS_URL, CORE } from '@app/app.api';

@Injectable({
  providedIn: 'root'
})
export class TransferenciaEntreModulosService
{
  private _searchFilter = this.getEmptySearchFilter();
  constructor(private userService:UsuarioService, 
              private loginService:LoginService,
              private httpClient:HttpClient) { } 
  
  public searchAllMedicamento():Observable<ListaJSON<ItemEntrada>>  
  {
    this._searchFilter.referencia = this.loginService.user.referencia.id;
    this._searchFilter.regional = this.userService.infoUser && this.userService.infoUser.regionalorigin ? this.userService.infoUser.regionalorigin.id:  null;
    this._searchFilter.unidadeSaude = this.userService.infoUser && this.userService.infoUser.unidadeorigin ? this.userService.infoUser.unidadeorigin.id:  null;
    return this.httpClient.post<ListaJSON<ItemEntrada>>(`${SGS_URL.CORE}/${CORE.RECEBIMENTO}/p`, this._searchFilter);
  }

  public getAlllReferencesActivesNotCurrent():Observable<Referencia[]>  
  {
    return this.httpClient.post<Referencia[]>(`${SGS_URL.CORE}/${CORE.REFERENCIA}/get_alll_references_actives_not_current`, {referencia:  this.loginService.user.referencia.id});
  }

  public transferir(entrada:Entrada):Observable<any>  
  {
    entrada.regional      = this.userService.infoUser.regionalorigin;
    entrada.unidade_saude = this.userService.infoUser.unidadeorigin;
    return this.httpClient.post<any>(`${SGS_URL.CORE}/${CORE.DISTRIBUICAO}/transfer_to_module/${this.loginService.user.id}/${this.loginService.user.referencia.id}`, entrada);
  }
  
  //estancia objecto genérico de filtro vazio
  public getEmptySearchFilter()
  {
    return {
      referencia: null,
      medicamento: null,   
      unidadeSaude: null,
      regional:     null,   
      direction: 'asc',
      sort: "data_validade",
      pageIndex: 0,
      pageSize: 5,
    }
  }

  //metodos sets e gets
  public get searchFilter() 
  {
    return this._searchFilter;
  }
  public set searchFilter(value) 
  {
    this._searchFilter = value;
  }
}
