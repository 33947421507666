import { environment } from 'src/environments/environment';

export const DEVELOP_MODE = environment.DEVELOP_MODE;

export const MODULOS = {
  JUDICIAL: '1',
  ESTRATEGICO: '2',
  TUBERCULOSE:'4',
  ESPECIALIZADO:'5'
}

export const SGS_URL = {
  CORE:        environment.url_core,
  JUDICIAL:    environment.url_judicial,
  TUBERCULOSE: environment.url_tuberculose,
  ESTRATEGICO: environment.url_estrategico,
  ESPECIALIZADO: environment.url_especializado
}
export const ESTRATEGICO = {
  EXEMPLO:"exemplo"
};
export const ESPECIALIZADO = {
  PROCEDIMENTO:"procedimento",
  PROCEDIMENTO_ORIGEM:"procedimentoOrigem",
  PROCEDIMENTO_CID:"procedimentoCid",
  SITUACAO_PROCEDIMENTO_CID:"situacaoProcedimentoCid",
  PROCEDIMENTO_FORMA_FATURAMENTO:"procedimentoFormaFaturamento",
  PROCEDIMENTO_ATRIBUTO:"procedimentoAtributo"
};

export const TUBERCULOSE =
{
  TRATAMENTO:"tratamento_tuberculose",
  TRATAMENTO_INDICACAO:"tratamento_tuberculose_indicacao",
  DOWNLOAD_RELATORIO: "downloadRelatorio"
};

// Constantes de API dos módulos para acesso do DefaultService
export const  CORE = {
  ALERTA: "alerta",
  GRUPO_MEDICAMENTO: "grupoMaterial",
  AVISOS: "avisos",
  CENTRO_CUSTO:"centro_custo",
  CID:"cid",
  COMSUS:"comsus",
  CONTAGEM_INVENTARIO:"contagemRelatorio",
  CATEGORIA_SITUACAO: "categoriaSituacao",
  DASHBORD: "dashbord",
  DISPENSACAO: "dispensacao",
  DISPENSACAO_GERAL: "dispensacaoGeral",
  DISTRIBUICAO:"distribuicao",
  DOCUMENTO: "documento",
  DOWNLOAD_DOCUMENTO:"downloadDocumento",
  DOWNLOAD_GUIA_DISTRIBUICAO: "downloadGuiaDistribuicao",
  DOWNLOAD_RELATORIO:"downloadRelatorio",
  ENTREGA_MATERIAL:"entregaMaterial",
  ESTOQUE:"estoque",
  FLUXO_PRESCRICAO: "fluxoPrescricao",
  GRUPO:"grupo",
  GUIA_DISTRIBUICAO: "guiaDistribuicao",
  HISTORICO_SITUACAO:"historico-situacao",
  INVENTARIO: "inventario",
  ITEM_DISPENSACAO: "item_dispensacao",
  ITEM_ENTRADA:"item_entrada",
  ITEM_SAIDA: "item_saida",
  ITEM_MAPA_JUDICIAL: "itemMapaJudicial",
  INVENTARIO_ESTABELECIMENTO: "inventario-estabelecimento",
  LIST_ITENS_MODAL: "listItensModal",
  LOTES: "searchLote",
  MAPA_JUDICIAL: "mapaJudicial",
  MAPA_DETALHADO: "mapaDetalhado",
  MAPA_GUIA_ENTRADA: "mapaGuiaEntrada",
  MATERIAL: "material",
  MATERIAL_COMPLEMENTO: "mcomp", //sem rest service
  MATERIAL_INVENTARIO:"material_inventario",
  MATERIAL_SIGTAP: "msigtap", //sem rest service
  MEDICAMENTO_PROX_VENCIMENTO: "medicamentoProxVenc",
  MOTIVO_DEVOLUCAO: "motivoDevolucao",
  NIVEL: "nivel",
  OBITO:"obito",
  PACIENTE:"paciente",
  PACIENTE_COMSUS:"paciente-comsus",
  PARAMETROS:"parametros",
  PARAMETRIZACAO_MEDICAMENTO: "parametrizacao_medicamento",
  PERIODICIDADE:"periodicidade",
  PERMISSAO:'permissao',
  PRESCRICAO:"prescricao",
  PRESCRICAO_DINAMICA:"prescricaoDinamica",
  PRESCRICAO_NAT:"prescricaoNAT",
  PROFISSIONAL:"profissional",
  RECEBIMENTO:"recebimento",
  RECIBO: "recibo",
  RECIBOS_ESTORNADOS: "recibosEstornados",
  RECIBOS_NAO_INSERIDOS: "recibosNaoInseridos",
  RECEITA: "receita",
  REFERENCIA:"referencia",
  REFERENCIA_MATERIAL:"referenciaMaterial",
  REGIONAL: "regional",
  REGIAO: "regiao",
  RESPONSAVEL:"responsavel",
  RELATORIO_NEGATIVA:"relatorioNegativa",
  SITUACAO_PROCESSO: "situacaoProcesso",
  SITUACAO_INTEGRACAO_INVENTARIO:"situacao-integracao-inventario",
  SITACAO_PROCEDIMENTO: "situacaoProcedimento",
  TIPO_ATENDIMENTO: "tipoAtendimento",
  TIPO_ESTABELECIMENTO: "tipoEstabelecimento",
  TIPO_MAPA: "tipoMapa",
  TIPO_MEDICAMENTO: "tipomedicamento",
  TIPO_PROGRAMA: "tipoprograma",
  TIPO_ANALISE_TECNICA: "tipoAnaliseTecnica",
  NAT_POSICAO_ANALISE_TECNICA: "nat-posicao-analise-tecnica",
  NAT_TIPO_PARECER: "nat-tipo-parecer",
  NAT_JUSTIFICATIVA_ANALISE_TECNICA:"natJustificativaAnaliseTecnica",
  NAT_DECISAO_SUPERIOR_ANALISE_TECNICA: "nat-decisao-superior-analise-tecnica",
  NAT_QUESTAO_ANALISE_TECNICA: "nat-questao-analise-tecnica",
  NAT_ASSOCIACAO_QUESTAO_ANALISE_TECNICA: "nat-parametrizacao-questao-analise-tecnica",
  NAT_ANALISE_TECNICA: "nat-analise-tecnica",
  NAT_ANALISE_TECNICA_ITEM_PRESCRICAO: "nat-analise-tecnica-prescricao-item-prescricao",
  PROGRAMA: "programa",
  UNIDADE_ASSISTENCIA: "unidadeAssistencia",
  UNIDADE_MEDIDA: "unidadeMedida",
  UNIDADE_SAUDE: "unidadeSaude",
  USUARIO:"usuario",
  VALIDADE_PRESCRICAO:"validade_prescricao",
  PARAMETROS_MATERIAIS_CONTAGEM_INVENTARIO: "parametros_materiais_contagem_inventario",
  PERSISTIR_CONTAGEM: "persistir_contagem",
  CLASSIFICACAO_SUBSTANCIA_ANVISA: "classificacao-substancia-anvisa",
}

// Constantes de API dos módulos para acesso do DefaultService
export const JUDICIAL = {
  ALVARA:"alvara",
  ALVARA_CSV:"alvaraCSV",
  BANCO: "banco",
  COMARCA:"comarca",
  CONTA_BANCARIA: "conta_bancaria",
  DEVOLUCAO_VALORES:"devolucao_valores",
  INFO_TEC_NAT:"informacao_tecnica_nat",
  MOTIVO_EXCLUSAO:"motivo-exclusao",
  MOTIVO_SEQUESTRO: "motivo_sequestro",
  NAT_INFO:"nat-info",
  NAT_JUIZ:"nat-juiz",
  NAT_POSICIONAMENTO:"nat-posicionamento",
  ORIGEM_DESTINO: "origemdestino",
  ORIGEM_MEDICAMENTO: "origemdestino",
  PROCESSO:"processo",
  PROTOCOLO:"protocolo",
  RESIDUAL_GERAL:"residualGeral",
  RESSARCIMENTO: "ressarcimento",
  SEQUESTRO_VALORES: "sequestro_valores",
  SEQUESTRO_VALORES_CSV:"sequestro_valores_csv",
  TIPO_FORNECIMENTO:"tipofornecimento",
  TIPO_PROCEDIMENTO:"tipo-procedimento",
  TIPO_SOLICITACAO:  "tiposolicitacao",
  PARAMETROS_MATERIAIS_CONTAGEM_INVENTARIO: "materiais_contagem_inventario",
  PERSISTIR_CONTAGEM: "persistir_contagem",
}

export const SEXO =
{
  FEMININO:1,
  MASCULINO:2
}
// Constantes do módulo de cache
export const CACHE_PREFIX = {
  NOVO: "_new",
  ARRAY: "_arr",
  ABA: "_aba"
}
export const _PREFIXCACHE_NOVO_ = CACHE_PREFIX.NOVO
export const _PREFIXCACHE_ARRAY_ =  CACHE_PREFIX.ARRAY
export const _PREFIXCACHE_ABA_ = CACHE_PREFIX.ABA
