import { Referencia } from './../centro-custo/Referencia.model';
import { Injectable, EventEmitter } from "@angular/core"
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Usuario } from "./usuario.model";
import { Grupo } from '@app/core/grupo/grupo.model';
import { MatTableDataSource } from "@angular/material";
import { UsuarioPesquisa } from "./usuario-pesquisa/usuario_pesquisa.model";
import { UsuarioGrid } from "./usuario-grid.model";
import { UsuarioNivel } from "../nivel/usuario-nivel.model";
import { UsuarioNivelAcess } from "./usuario-nivel-acess.model";
import { LoginService } from '../security/login/login.service';
import { SGS_URL, CORE } from '@app/app.api';
import { ListaJSON } from '../shared/default/lista-json.model';
import { DescricaoModel } from '../shared/descricao/descricao.model';

@Injectable()
export class UsuarioService {

  usuario: Usuario
  usuarios: UsuarioGrid[] = [];
  dataSource = new MatTableDataSource<UsuarioGrid>(this.usuarios)
  p_length: number
  p_index: number
  p_size: number
  nome: string
  email: string
  login: string
  usuarioNivel: UsuarioNivel
  lsGruposUsuarioPai:Grupo[]=[];
  lsGruposUsuarioFilho:Grupo[]=[];
  alterarDadosCadastrais: boolean;
  lsIdsRegionais: string[];
  lsIdsUnidades: string[];

  //usuário que será criado/editado
  private _usuarioSelecionado: Usuario = this.getNovoUsuario();
  private _usuarioPesquisa = this.getUsuarioPesquisaEmpty();

  //informações do usuário em relação ao acesso ao estabelecimento [regiões, regionais, unidades]
  private _infoUser: UsuarioNivelAcess = null;

  //evento chamado por outros componentes para atualização dos niveis de permissões  [regiões, regionais, unidades]
  private _eventInfoUser: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private http: HttpClient, private loginService: LoginService) { }

  cadastrar(): Observable<any> {
    let idUsuario = this.loginService.user.id;
    let idReference = this.loginService.user.referencia.id;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.USUARIO}/c/${idReference}/${idUsuario}`, this._usuarioSelecionado);
  }

  listar(usuarioPesquisa: UsuarioPesquisa): Observable<ListaJSON<UsuarioGrid>> {
    return this.http.post<ListaJSON<UsuarioGrid>>(`${SGS_URL.CORE}/${CORE.USUARIO}/listar`, usuarioPesquisa)
  }

  completeUser(): Observable<any> {
    return this.http.post<ListaJSON<any>>(`${SGS_URL.CORE}/${CORE.USUARIO}/complete_user`, { id: this.usuarioSelecionado.id });
  }
  //busca todos os usuários contído no banco de dados
  public list(): Observable<ListaJSON<Usuario>> {
    return this.http.post<ListaJSON<Usuario>>(`${SGS_URL.CORE}/${CORE.USUARIO}/p`, this._usuarioPesquisa)
  }

  updateAlertDate(): Observable<any> {
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.USUARIO}/update_alert_date`, { id: this.loginService.user.id });
  }

  updateAlertSetting(user): Observable<any> {
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.USUARIO}/update_alert_config`, user);
  }

  buscaUsuario(id_usuario: string): Observable<Usuario> {
    return this.http.post<Usuario>(`${SGS_URL.CORE}/${CORE.USUARIO}/buscar/${id_usuario}`, {})
  }

  buscaNivel(id_usuario: string, id_referencia:string): Observable<UsuarioNivel> {
    return this.http.post<UsuarioNivel>(`${SGS_URL.CORE}/${CORE.USUARIO}/nivel/buscar/${id_usuario}/${id_referencia}`, {})
  }

  setUsuario(usuario: Usuario) {
    this.usuario = usuario
  }

  getNovoUsuario(): Usuario {
    this.usuario = new Usuario(null, null, null, null, null, null, false, null, null, [], true, null, 1, 30, null, false, false);
    return this.usuario
  }

  getNovoUsuarioNivel(): UsuarioNivel {
    this.usuarioNivel = new UsuarioNivel("", "", "", "", "", true, [])
    return this.usuarioNivel
  }

  updateAtivo(id_usuario: string, ativo: boolean): Observable<any> {
    let id_user = this.loginService.user.id;
    let id_referencia = this.loginService.user.referencia.id;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.USUARIO}/s/${id_usuario}/${id_user}/${ativo}/${id_referencia}`, {})
  }

  getUsuariosByGrupo(grupo_id: string, status: boolean): Observable<Usuario[]> {
    return this.http.post<Usuario[]>(`${SGS_URL.CORE}/${CORE.USUARIO}/grupo`, { status: status, grupo: grupo_id })
  }

  getTodosUsuarios(): Observable<Usuario[]> {
    return this.http.post<Usuario[]>(`${SGS_URL.CORE}/${CORE.USUARIO}/todos`, {})
  }

  setUsuarioNivel(usuarioNivel: UsuarioNivel) {
    this.usuarioNivel = usuarioNivel
  }

  getUsuarioNivel(): UsuarioNivel {
    if (this.usuarioNivel === undefined) {
      this.usuarioNivel = this.getNovoUsuarioNivel()
    }
    return this.usuarioNivel
  }

  listarReferenciasPrograma(): Observable<DescricaoModel[]> {
    return this.http.post<DescricaoModel[]>(`${SGS_URL.CORE}/${CORE.PROGRAMA}/listar`, {})
  }

  //busca todas as referencias do sistema
  public listarReferencias(): Observable<ListaJSON<Referencia>> {
    return this.http.post<ListaJSON<Referencia>>(`${SGS_URL.CORE}/${CORE.REFERENCIA}/a`, {})
  }

  public get eventInfoUser(): EventEmitter<boolean> {
    return this._eventInfoUser;
  }
  public set eventInfoUser(value: EventEmitter<boolean>) {
    this._eventInfoUser = value;
  }

  public updateInfoUser() {
    this.loginService.searchInfoUser().subscribe(resp => {
      if (resp) {
        if (resp.regioes != null && resp.regioes.length > 0) {
          for (let item of resp.regioes) {
            for (let regional of item.regionais) {
              resp.regionais.push(regional);
            }
          }
        }
        if (resp.regionais != null && resp.regionais.length > 0) {
          resp.regionalorigin = resp.regionais[0];
        }
        else if (resp.unidades != null && resp.unidades.length > 0) {
          resp.unidadeorigin = resp.unidades[0]
          resp.regionaldestine = resp.unidades[0].regional;
        }
      }
      this.infoUser = resp;
    }
    );
  }


  // Retorna uma lista com os ids das Regionais com vínculo ao usuário
  public getIdsRegionaisByInfoUser() {
    this.lsIdsRegionais = [];
    if (this.infoUser && this.infoUser.regionais && this.infoUser.regionais.length > 0) {
      for (let regional of this.infoUser.regionais) {
        if (regional != null && regional.id != null) {
          this.lsIdsRegionais.push(regional.id);
        }
      }
    }
    return this.lsIdsRegionais;
  }

  // Retorna uma lista com os ids das Unidades com vínculo ao usuário
  public getIdsUnidadesSaudeByInfoUser() {
    this.lsIdsUnidades = [];
    if (this.infoUser && this.infoUser.unidades && this.infoUser.unidades.length > 0) {
      for (let unidade of this.infoUser.unidades) {
        if (unidade != null && unidade.id != null) {
          this.lsIdsUnidades.push(unidade.id);
        }
      }
    }
    return this.lsIdsUnidades;
  }


  public getUsuarioPesquisaEmpty() {
    return {
      nome_filter: "",
      email_filter: "",
      login_filter: "",
      direction: "asc",
      sort: "nome",
      pageIndex: 0,
      pageSize: 5,
    }
  }

  //Métodos Sets e Gets

  public get usuarioSelecionado(): Usuario {
    return this._usuarioSelecionado;
  }
  public set usuarioSelecionado(value: Usuario) {
    this._usuarioSelecionado = value;
  }

  public get infoUser(): UsuarioNivelAcess {
    return this._infoUser;
  }
  public set infoUser(value: UsuarioNivelAcess) {
    this._infoUser = value;
  }
  public get usuarioPesquisa() {
    return this._usuarioPesquisa;
  }
  public set usuarioPesquisa(value) {
    this._usuarioPesquisa = value;
  }
  getUsuario(): Usuario {
    if (this.usuario === undefined) {
      this.usuario = this.getNovoUsuario()
    }
    return this.usuario
  }

  getUsuarios(): UsuarioGrid[] {
    return this.usuarios
  }

  setUsuarios(usuarios: UsuarioGrid[]) {
    this.usuarios = usuarios
  }

  getDataSource(): MatTableDataSource<UsuarioGrid> {
    return this.dataSource
  }

  setDataSource(dataSource: MatTableDataSource<UsuarioGrid>) {
    this.dataSource = dataSource
  }

  getP_length(): number {
    return this.p_length
  }

  setP_length(p_length: number) {
    this.p_length = p_length
  }

  getP_index(): number {
    return this.p_index
  }

  setP_index(p_index: number) {
    this.p_index = p_index
  }

  getP_size(): number {
    return this.p_size
  }

  setP_size(p_size: number) {
    this.p_size = p_size
  }

  getNome(): string {
    return this.nome
  }

  setNome(nome: string) {
    this.nome = nome
  }

  getEmail(): string {
    return this.email
  }

  setEmail(email: string) {
    this.email = email
  }

  getLogin(): string {
    return this.login
  }

  setLogin(login: string) {
    this.login = login
  }
}
