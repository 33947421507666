/**
 * Classe modelo para enviar e receber informações do back-end [model rest e dto]
 */
export class BancoModel
{
    constructor
    (
        public id: string,                  
        public codigo_banco: string,               
        public nome_banco: string,                
    ){}
}
