import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MaterialService } from "@app/core/material/material.service";
import { LoginService } from "@app/core/security/login/login.service";
import { SelectItem } from "primeng/api";

@Component({
  selector: "ses-medicamento-multiselect",
  templateUrl: "./medicamento-multiselect.component.html",
  styleUrls: ["./medicamento-multiselect.component.scss"],
})
export class MedicamentoMultiselectComponent implements OnInit {
  constructor(
    private loginService: LoginService,
    private materialService: MaterialService
  ) {}

  @Input() required: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();
  @Input() lsmaterialSelecionda: any[] = [];

  @Output() idsSelecionadas: EventEmitter<any> = new EventEmitter<any>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any[]>();

  public lsmaterial: any[] = [];
  public alturaOverflow = "0px";

  ngOnInit() {
    this.carregaMedicamento("a");
    this.limparEvent.subscribe({
      next: (resp) => {
        this.limpar();
      },
    });
  }

  selecionaMedicamentos(event?: any) {
    let listIds: string[] = [];
    //this.lsmaterial = [];
    //this.alturaOverflow = "0px";

    this.lsmaterialSelecionda.forEach((element) => listIds.push(element.id));
    this.idsSelecionadas.emit(listIds);

    this.elementosCompletoSelecionadas.emit(this.lsmaterialSelecionda);
  }

  public filter(event: any) {
    if (event.target.value.length >= 3) {
      this.carregaMedicamento(event.target.value);
    } else {
      this.carregaMedicamento("a");
      this.alturaOverflow = "0px";
    }
  }

  carregaMedicamento(descrica: string) {
    this.materialService
      .getMaterialByDescOrCiascAndRerencia(
        descrica,
        this.loginService.user.referencia.id
      )
      .subscribe((resp) => {
        if (resp) {
          this.lsmaterial = resp;
          this.calculoTamanhoOverflow();
        }
      });
  }

  limpar() {
    this.lsmaterial = [];
    this.lsmaterialSelecionda = [];
    this.idsSelecionadas.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  calculoTamanhoOverflow() {
    let altura: number = 0;
    altura = this.lsmaterial.length * 45;
    if (altura > 200) {
      this.alturaOverflow = "200px";
    } else {
      this.alturaOverflow = altura + "px";
    }
  }

  validaTamanholista(): boolean {
    return this.lsmaterialSelecionda.length > 0 ? true : false;
  }
}
