import { Endereco } from "@app/core/shared/endereco/endereco.model";

export class Responsavel {
  constructor(public id: string,
              public cpf: string,
              public nome: string,
              public rg: string,
              public cns: string,
              public parentesco: string,
              public endereco: Endereco,
              public telefone: string,
              public celular: string,
              public status: string,
              public orgao_emissor: string) {}
}
