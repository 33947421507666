import { Paciente } from './../paciente/paciente.model';
import { Referencia } from './../centro-custo/Referencia.model';
import { HistoricoSituacao } from './historico-situacao.model';
import { Processo } from "@app/judicial/processo/processo.model";
import { ItemPrescricao } from './item-prescricao.model';
import { TipoProcedimento } from '@app/judicial/tipo-procedimento/tipo-procedimento.model';
import { UnidadeSaude } from '@app/core/unidade-saude/unidade-saude.model';
import { TipoPrograma } from '@app/core/tipo-programa/tipo-programa.model';
import { User } from '@app/core/security/login/user.model';
import { TipoFornecimento } from '@app/judicial/tipo-fornecimento/tipo-fornecimento.model';

export class Prescricao {
  constructor(
    public id: string,
    public processo: Processo,
    public data_cadastro: Date,
    public data_prescricao: Date,
    public objeto: number,
    public referencia: Referencia,
    public itens: ItemPrescricao[],
    public tipo_fornecimento: TipoFornecimento,
    public numero_fornecimento: number,
    public status: boolean,
    public usuario_cadastro: User,
    public historicoSituacao: HistoricoSituacao[],
    public tipoProcedimento: TipoProcedimento,
    public unidadeSaudeProcedimento: UnidadeSaude,
    public ultima_retirada:Date, //utilizado na tela de alerta de paciente    
    public paciente:Paciente,
    
    //para fins de ordenação devido o DISTINC de consulta da query
    public order_sit_proc,
    public order_num_proc,
    public order_ciasc,
    public order_medic,
    public order_unid_saude,
    public order_paciente,
    public order_municipo,
    
    // Para utilização no modal de receita
    public prescricaoChecked: boolean
  ) { }
}
