import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginService } from '../security/login/login.service';
import { SGS_URL, CORE } from '@app/app.api';
import { ListaJSON } from '../shared/default/lista-json.model';
import { SituacaoProcedimento } from './situacao-procedimento.model';

@Injectable({
  providedIn: 'root'
})
export class SituacaoProcedimentoService {

  public situacaoProcedimento: SituacaoProcedimento = new SituacaoProcedimento();
  private _filter;

  constructor(private http: HttpClient,
    private loginService: LoginService) { }

  save(): Observable<any> {
    const id_usuario = this.loginService.user.id;
    var id_referencia = this.loginService.user.referencia.id;    
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.SITACAO_PROCEDIMENTO}/c/${id_referencia}/${id_usuario}`, this.situacaoProcedimento);
  }

  listar(): Observable<ListaJSON<SituacaoProcedimento>> {
    this._filter.referencia = this.loginService.user.referencia.id;
    return this.http.post<ListaJSON<SituacaoProcedimento>>(`${SGS_URL.CORE}/${CORE.SITACAO_PROCEDIMENTO}/p`, this._filter);
  }

  /** retorna uma lista de SituacaoProcedimento ilike nome */
  getListaSituacaoProcedimento(nome:string): Observable<SituacaoProcedimento[]> {
    return this.http.post<SituacaoProcedimento[]>(`${SGS_URL.CORE}/${CORE.SITACAO_PROCEDIMENTO}/getListaSituacaoProcedimento`, { referenciaId: this.loginService.user.referencia.id, nome : nome });
  }

  getById(id: string): Observable<SituacaoProcedimento> {
    return this.http.post<SituacaoProcedimento>(`${SGS_URL.CORE}/${CORE.SITACAO_PROCEDIMENTO}/getById`, { id : id });
  }

  public get filter(): any {
    return this._filter;
  }
  public set filter(value: any) {
    this._filter = value;
  }

}
