import { LoginService } from "@app/core/security/login/login.service";
import { Subscription } from "rxjs";
import { RegionalService } from "./../../../regional/regional.service";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MaterialService } from "@app/core/material/material.service";
import { UsuarioService } from "@app/core/usuario/usuario.service";
import { MunicipioService } from "../../municipio/municipio.service";
import { MessageService } from "../../message.service";
import { InputService } from "../../input.service";
import { TipoMaterialService } from "@app/core/material/tipo-material.service";
import { UnidadeSaudeService } from "@app/core/unidade-saude/unidade-saude.service";
import { SituacaoProcessoService } from "@app/core/situacao-processo/situacao-processo.service";
import { PacienteService } from "@app/core/paciente/paciente.service";
import { TipoProgramaService } from "@app/core/tipo-programa/tipo-programa.service";
import { OrigemDestinoService } from "@app/judicial/origem-destino/origem-destino.service";
import { PacienteComsusService } from "@app/core/paciente/paciente-comsus.service";
import { ReferenciaService } from "@app/core/referencia/referencia.service";
import { MotivoComsusService } from "@app/core/motivo-comsus/motivo-comsus.service";
import { SituacaoComsusService } from "@app/core/situacao-comsus/situacao-comsus.service";
import { InventarioService } from "@app/core/inventario/inventario.service";
import { InvetarioEstabelecimentosAbertoFilter } from "../../models/invetario-estabelecimentos-aberto.filter.models";
import { TipoAtendimentoService } from "@app/core/tipo-atendimento/tipo-atendimento.service";
import { Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-multi-select-autocomplete",
  templateUrl: "./multi-select-autocomplete.component.html",
  styleUrls: ["./multi-select-autocomplete.component.scss"],
})
// @autores: Bruno e Martinho
export class MultiSelectAutocompleteComponent implements OnInit, OnDestroy {
  @Input() placeholderPesquisar: string = null;
  @Input() titulo: string = null;
  @Input() chaveDescricao: string = "descricao";
  @Input() tipoObjeto: string = "Objeto";
  @Input() nivel: boolean = true;
  @Input() required: boolean = false;
  @Input() valorMinimoCharacterPesquisa: number = 4;
  @Input() preCarregarDados: boolean = false;
  @Input() singleSelect: boolean = false;
  @Input() comsus: boolean = false;
  @Input() inventario: boolean = false;

  @Output() change : EventEmitter<boolean> = new EventEmitter<boolean>();

  private configuracoes = {};

  private textoSelecaoTodos: string = "Selecionar todos";
  private textoRemocaoTodos: string = "Remover todos";
  private classesHTML: string = "mat-input-element uppercase";
  private textoPadraoSemDados: string =
    "Sem dados para exibir, digite no filtro para pesquisar";
  private mensagem: string = "";
  private referencia: any;
  private descricaoBuscaInput: string = "";
  private lsObjetos: any[] = [];
  private lsObjetosSelecionados: any[] = [];
  private lsIdsSelecionados: any[] = [];
  private regionaisDoUsuario: any[] = [];
  private unidadeSaudeDoUsuario: any[] = [];

  // Tipos de consulta
  private MATERIAL: boolean = false;
  private MUNICIPIO: boolean = false;
  private UNIDADESAUDE: boolean = false;
  private REGIONAL: boolean = false;
  private TIPOMATERIAL: boolean = false;
  private SITUACAOPROCESSO: boolean = false;
  private PACIENTE: boolean = false;
  private PACIENTECOMSUS: boolean = false;
  private TIPOPROGRAMA: boolean = false;
  private ORIGEMDESTINO: boolean = false;
  private REFERENCIA: boolean = false;
  private MOTIVOCOMSUS: boolean = false;
  private SITUACAOCOMSUS: boolean = false;
  private TIPOATENDIMENTO: boolean = false;

  private subcribeValidacaoRequired: Subscription;
  private subcribeVLimparTodasListas: Subscription;

  constructor(
    private messageService: MessageService,
    private inputService: InputService,
    private materialService: MaterialService,
    private municipioService: MunicipioService,
    private usuarioService: UsuarioService,
    private regionalService: RegionalService,
    private tipoMaterialService: TipoMaterialService,
    private unidadeSaudeService: UnidadeSaudeService,
    private situacaoProcessoService: SituacaoProcessoService,
    private pacienteService: PacienteService,
    private tipoProgramaService: TipoProgramaService,
    private origemDestinoService: OrigemDestinoService,
    private pacienteComsusService: PacienteComsusService,
    private referenciaService: ReferenciaService,
    private motivoComsusService: MotivoComsusService,
    private situacaoComsusService: SituacaoComsusService,
    private loginService: LoginService,
    private inventarioServices: InventarioService,
    private tipoAtendimentoService: TipoAtendimentoService
  ) { }

  ngOnInit() {
    this.atualizaReferenciaLogadaUsuario();
    this.atribuicaoChaves();
    this.formataTipoObjetoLowCase();
    this.atribuicaoPadraoParametrosDoMegazord();
    this.adicionaDescricaoCharacterMinimo();
    this.setarConfiguracoes();
    this.verificacaoTipoObjeto();
    this.preCarregarRegistros();
    this.atribuiRegionaisUnidadesDoUsuarioVariaveisLocais();
    this.atualizarListaIdsObjetosSelecionados();
    this.escutarEventos();
  }

  ngOnDestroy(): void {
    this.limparListas();
    this.unsubscribeEventos();
  }

  private escutarEventos() {
    this.subcribeValidacaoRequired =
      this.inputService.eventVerificaRequired.subscribe((resp) =>
        this.validacaoRequired()
      );
    this.subcribeVLimparTodasListas =
      this.inputService.eventLimparTodasListas.subscribe((resp) =>
        this.limparListas()
      );
  }

  private unsubscribeEventos() {
    if (this.subcribeValidacaoRequired)
      this.subcribeValidacaoRequired.unsubscribe();
    if (this.subcribeVLimparTodasListas)
      this.subcribeVLimparTodasListas.unsubscribe();
  }

  private setarConfiguracoes() {
    this.configuracoes = {
      text: this.adicionaAsteriscoTituloRequired(),
      selectAllText: this.textoSelecaoTodos,
      unSelectAllText: this.textoRemocaoTodos,
      classes: this.classesHTML,
      enableSearchFilter: true,
      lazyLoading: false,
      labelKey: this.chaveDescricao,
      noDataLabel: this.textoPadraoSemDados,
      searchAutofocus: true,
      singleSelection: this.singleSelect,
      showCheckbox: !this.singleSelect,
      // Documentação da biblioteca: https://github.com/CuppaLabs/angular2-multiselect-dropdown
    };
  }

  private adicionaAsteriscoTituloRequired() {
    return this.required ? this.titulo + "*" : this.titulo;
  }

  private atualizaReferenciaLogadaUsuario() {
    this.referencia = this.loginService.user.referencia.id;
  }

  private selecionarItem(item: any) {
    this.atualizarListaIdsObjetosSelecionados();
  }

  private selecionarTodos(itens: any) {
    this.atualizarListaIdsObjetosSelecionados();
  }

  private removerItemSelecionado(item: any) {
    const index = this.lsObjetosSelecionados.indexOf(item, 0);
    if (index > -1) {
      this.lsObjetosSelecionados.splice(index, 1);
    }

    this.limparListaIdsSelecionados();
    this.atualizarListaIdsObjetosSelecionados();
  }

  private removerTodos(itens: any) {
    this.limparListaIdsSelecionados();
    this.atualizarListaIdsObjetosSelecionados();
  }

  private limparListas() {
    
    this.lsObjetos = [];
    this.lsObjetosSelecionados = [];
    this.descricaoBuscaInput = "";
    this.limparListaIdsSelecionados();
    this.limparListaIdsServices();
    this.preCarregarRegistros();
    this.preSetarUnidadesRegionaisByNivelUsuario();
    this.change.emit(true);
  }

  private limparListaIdsSelecionados() {
    this.lsIdsSelecionados = [];
    this.resetaCamposRequired();
  }

  private resetaCamposRequired() {
    this.inputService.todosCamposRequiredPreenchidos = false;
  }

  private buscarTodosRegistros() {
    return this.descricaoBuscaInput.length < 1 ? true : false;
  }

  private preCarregarRegistros() {
    if (this.preCarregarDados) this.pesquisar("");
  }

  private formataTipoObjetoLowCase() {
    this.tipoObjeto = this.tipoObjeto.toLowerCase();
  }

  /**
   * Para novas consultas, execute os passos:
   - Criar um novo tipo de consulta (seguindo o padrão já feito);
   - Adicionar esse tipo de consulta no método "verificacaoTipoObjeto()"
   - Adicione no método atribuicaoChaves()
   - Adicionar no método "pesquisar" o novo case com o apontamento do respectivo service que necessita
   - Depois criar um label no html do component para poder consultar, levando em consideração a descricao/nome (campo que deseja)
   - Adicionar também no método limparListaIdsServices() a limpeza da lista
   - Lembre de criar métodos de manipulação, a lista seguindo os exemplos já desenvolvidos e a flag haItensSelecionadosMultiSelect, legal levar em consideração também
    que para um melhor funcionamento, é obrigatorio que a lista de ids manipulada tenha 1 por service. Ou seja, lista de material, em
    seu respectivo service (MaterialService), etc e não 2 listas dentro do MaterialService (exemplo).
   */
  private pesquisar(event) {
    if (this.descricaoBuscaInput.length < this.valorMinimoCharacterPesquisa) {
      this.lsObjetos = [];
      return;
    }

    switch (this.tipoObjeto) {
      case "material": {
        this.materialService
          .getMaterialByDescOrCiascAndRerencia(
            this.descricaoBuscaInput,
            this.referencia
          )
          .subscribe((resp) => {
            if (resp) {
              this.lsObjetos = resp;
            }
          });
        break;
      }

      case "municipio": {
        this.municipioService
          .buscaMunicipiosLocalidadeByNomeUf(this.descricaoBuscaInput, "SC")
          .subscribe((resp) => {
            this.lsObjetos = resp;
          });
        break;
      }

      case "unidadesaude": {
        if (!this.nivel) {
          this.unidadeSaudeService
            .buscaUnidadeSaudeByNomeAndReferencia(
              this.descricaoBuscaInput,
              this.referencia
            )
            .subscribe((resp) => {
              this.lsObjetos = resp;
            });
        } else {
          this.getUnidadesSaudeVinculoUsuarioByDescricao(
            this.descricaoBuscaInput
          );
        }
        break;
      }

      case "regional": {
        if (!this.nivel) {
          this.regionalService
            .getRegionaisByDescricaoAndReferencia(
              this.descricaoBuscaInput,
              this.referencia
            )
            .subscribe((resp) => {
              this.lsObjetos = resp;
            });
        } else {
          this.getRegionaisVinculoUsuarioByDescricao(this.descricaoBuscaInput);
        }
        break;
      }

      case "tipomaterial": {
        if (this.buscarTodosRegistros()) {
          this.tipoMaterialService
            .listarTodosTiposMateriais()
            .subscribe((resp) => {
              this.lsObjetos = resp;
            });
        } else {
          this.tipoMaterialService
            .listarTipoMaterial(this.descricaoBuscaInput)
            .subscribe((resp) => {
              this.lsObjetos = resp;
            });
        }
        break;
      }

      case "situacaoprocesso": {
        if (this.buscarTodosRegistros()) {
          this.situacaoProcessoService
            .buscaSituacaoProcessoByReferenciaParametro(this.referencia)
            .subscribe((resp) => {
              this.lsObjetos = resp;
            });
        } else {
          this.situacaoProcessoService
            .buscaSituacaoByNome(this.descricaoBuscaInput)
            .subscribe((resp) => {
              this.lsObjetos = resp;
            });
        }
        break;
      }

      case "paciente": {
        this.pacienteService
          .buscarPacientesByNome(this.descricaoBuscaInput)
          .subscribe((resp) => {
            this.lsObjetos = resp;
          });
        break;
      }

      case "pacientecomsus": {
        this.pacienteComsusService
          .buscarPacientesComsusByNome(this.descricaoBuscaInput)
          .subscribe((resp) => {
            this.lsObjetos = resp;
          });
        break;
      }

      case "tipoprograma": {
        this.tipoProgramaService
          .buscaTipoProgramaByNome(this.descricaoBuscaInput)
          .subscribe((resp) => {
            this.lsObjetos = resp;
          });
        break;
      }

      case "origemdestino": {
        this.origemDestinoService
          .getOrigemDestinoByDescricao(this.descricaoBuscaInput)
          .subscribe((resp) => {
            this.lsObjetos = resp;
          });
        break;
      }

      case "referencia": {
        this.referenciaService
          .buscarReferenciaByNome(this.descricaoBuscaInput)
          .subscribe((resp) => {
            this.lsObjetos = resp;
          });
        break;
      }

      case "motivocomsus": {
        this.motivoComsusService
          .getMotivoComsusByDescricao(this.descricaoBuscaInput)
          .subscribe((resp) => {
            this.lsObjetos = resp;
          });
        break;
      }

      case "situacaocomsus": {
        this.situacaoComsusService
          .getSituacaoComsusByDescricao(this.descricaoBuscaInput)
          .subscribe((resp) => {
            this.lsObjetos = resp;
          });
        break;
      }

      case "tipoatendimento": {
        if (this.buscarTodosRegistros()) {
          this.tipoAtendimentoService
            .listarTodosTipoAtendimentoMaterial()
            .subscribe({next: (resp) => {
              this.lsObjetos = resp;
            },error: (erro) => {
              this.messageService.addToast("Erro ao buscar Tipos de Atendimento!", "Erro", "error");
            },complete: () =>{              
            }});
            
        } else {
          this.tipoAtendimentoService
            .listarTipoAtendimentoMaterial(this.descricaoBuscaInput)
            .subscribe({next: (resp) => {
              this.lsObjetos = resp;
            },error: (erro) => {
              this.messageService.addToast("Erro ao buscar Tipos de Atendimento!", "Erro", "error");
            },complete: () =>{              
            }});
        }
        break;
      }

      default: {
        break;
      
      }
    }
  }

  private getIdsDosItensSelecionados() {
    for (let objeto of this.lsObjetosSelecionados) {
      if (objeto && objeto.id && objeto.id != null) {
        if (this.lsIdsSelecionados.indexOf(objeto.id) === -1) {
          this.lsIdsSelecionados.push(objeto.id);
        }
      }
    }
  }

  private verificacaoTipoObjeto() {
    switch (this.tipoObjeto) {
      case "material":
        this.MATERIAL = true;
        break;
      case "municipio":
        this.MUNICIPIO = true;
        break;
      case "unidadesaude":
        this.UNIDADESAUDE = true;
        break;
      case "regional":
        this.REGIONAL = true;
        break;
      case "tipomaterial":
        this.TIPOMATERIAL = true;
        break;
      case "situacaoprocesso":
        this.SITUACAOPROCESSO = true;
        break;
      case "paciente":
        this.PACIENTE = true;
        break;
      case "pacientecomsus":
        this.PACIENTECOMSUS = true;
        break;
      case "tipoprograma":
        this.TIPOPROGRAMA = true;
        break;
      case "origemdestino":
        this.ORIGEMDESTINO = true;
        break;
      case "referencia":
        this.REFERENCIA = true;
        break;
      case "motivocomsus":
        this.MOTIVOCOMSUS = true;
        break;
      case "situacaocomsus":
        this.SITUACAOCOMSUS = true;
        break;
      case "tipoatendimento":
        this.TIPOATENDIMENTO = true;
        break;
      default: {
        break;
      }
    }
  }

  private atribuicaoChaves() {
    switch (this.tipoObjeto) {
      case "material":
        this.chaveDescricao = "descricao";
        break;
      case "municipio":
        this.chaveDescricao = "nome";
        break;
      case "unidadesaude":
        this.chaveDescricao = "nome";
        break;
      case "regional":
        this.chaveDescricao = "descricao";
        break;
      case "tipomaterial":
        this.chaveDescricao = "descricao";
        break;
      case "situacaoprocesso":
        this.chaveDescricao = "descricao";
        break;
      case "paciente":
        this.chaveDescricao = "nome";
        break;
      case "pacientecomsus":
        this.chaveDescricao = "nome";
        break;
      case "tipoprograma":
        this.chaveDescricao = "descricao";
        break;
      case "origemdestino":
        this.chaveDescricao = "descricao";
        break;
      case "referencia":
        this.chaveDescricao = "descricao";
        break;
      case "motivocomsus":
        this.chaveDescricao = "descricao";
        break;
      case "situacaocomsus":
        this.chaveDescricao = "descricao";
        break;
      case "tipoatendimento":
        this.chaveDescricao = "descricao";
      default: {
        break;
      }
    }
  }

  private adicionaDescricaoCharacterMinimo() {
    this.placeholderPesquisar =
      this.placeholderPesquisar +
      " (Mín: " +
      this.valorMinimoCharacterPesquisa +
      ")";
  }

  private limparListaIdsServices() {
    this.resetaCamposRequired();
    this.limparFlagCampoSelecionadoServices();

    switch (this.tipoObjeto) {
      case "material": {
        this.materialService.getNewLsIdsSelecionados();
        break;
      }

      case "municipio": {
        this.municipioService.getNewLsIdsSelecionados();
        break;
      }

      case "unidadesaude": {
        this.unidadeSaudeService.getNewLsIdsSelecionados();
        break;
      }

      case "regional": {
        this.regionalService.getNewLsIdsSelecionados();
        break;
      }

      case "tipomaterial": {
        this.tipoMaterialService.getNewLsIdsSelecionados();
        break;
      }

      case "situacaoprocesso": {
        this.situacaoProcessoService.getNewLsIdsSelecionados();
        break;
      }

      case "paciente": {
        this.pacienteService.getNewLsIdsSelecionados();
        break;
      }

      case "pacientecomsus": {
        this.pacienteComsusService.getNewLsIdsSelecionados();
        break;
      }

      case "tipoprograma": {
        this.tipoProgramaService.getNewLsIdsSelecionados();
        break;
      }

      case "origemdestino": {
        this.origemDestinoService.getNewLsIdsSelecionados();
        break;
      }

      case "referencia": {
        this.referenciaService.getNewLsIdsSelecionados();
        break;
      }

      case "motivocomsus": {
        this.motivoComsusService.getNewLsIdsSelecionados();
        break;
      }

      case "situacaocomsus": {
        this.situacaoComsusService.getNewLsIdsSelecionados();
        break;
      }

      case "tipoAtendimento": {
        this.tipoAtendimentoService.getNewLsIdsSelecionados();
        break;
      }

      default: {
      }
    }
  }

  private limparFlagCampoSelecionadoServices() {
    switch (this.tipoObjeto) {
      case "material": {
        this.materialService.haItensSelecionadosMultiSelect = false;
        break;
      }

      case "municipio": {
        this.municipioService.haItensSelecionadosMultiSelect = false;
        break;
      }

      case "unidadesaude": {
        this.unidadeSaudeService.haItensSelecionadosMultiSelect = false;
        break;
      }

      case "regional": {
        this.regionalService.haItensSelecionadosMultiSelect = false;
        break;
      }

      case "tipomaterial": {
        this.tipoMaterialService.haItensSelecionadosMultiSelect = false;
        break;
      }

      case "situacaoprocesso": {
        this.situacaoProcessoService.haItensSelecionadosMultiSelect = false;
        break;
      }

      case "paciente": {
        this.pacienteService.haItensSelecionadosMultiSelect = false;
        break;
      }

      case "pacientecomsus": {
        this.pacienteComsusService.haItensSelecionadosMultiSelect = false;
        break;
      }

      case "tipoprograma": {
        this.tipoProgramaService.haItensSelecionadosMultiSelect = false;
        break;
      }

      case "origemdestino": {
        this.origemDestinoService.haItensSelecionadosMultiSelect = false;
        break;
      }

      case "referencia": {
        this.referenciaService.haItensSelecionadosMultiSelect = false;
        break;
      }

      case "motivocomsus": {
        this.motivoComsusService.haItensSelecionadosMultiSelect = false;
        break;
      }

      case "situacaocomsus": {
        this.situacaoComsusService.haItensSelecionadosMultiSelect = false;
        break;
      }

      case "tipoatendimento": {
        this.tipoAtendimentoService.haItensSelecionadosMultiSelect = false;
        break;
      }

      default: {
      }
    }
  }

  private montarMensagemObrigatorio() {
    this.mensagem = "O campo " + this.titulo + " é obrigatório";
  }

  private validacaoRequired() {
    this.montarMensagemObrigatorio();
    if (!this.required) return;

    switch (this.tipoObjeto) {
      case "material": {
        if (this.materialService.lsIdsSelecionadosMultiSelect.length < 1) {
          this.messageService.addToast(this.mensagem, "Atenção!", "warning");
          this.inputService.todosCamposRequiredPreenchidos = false;
        } else {
          this.inputService.todosCamposRequiredPreenchidos = true;
        }
        break;
      }

      case "municipio": {
        if (this.municipioService.lsIdsSelecionadosMultiSelect.length < 1) {
          this.messageService.addToast(this.mensagem, "Atenção!", "warning");
          this.inputService.todosCamposRequiredPreenchidos = false;
        } else {
          this.inputService.todosCamposRequiredPreenchidos = true;
        }
        break;
      }

      case "unidadesaude": {
        if (this.unidadeSaudeService.lsIdsSelecionadosMultiSelect.length < 1) {
          this.messageService.addToast(this.mensagem, "Atenção!", "warning");
          this.inputService.todosCamposRequiredPreenchidos = false;
        } else {
          this.inputService.todosCamposRequiredPreenchidos = true;
        }
        break;
      }

      case "regional": {
        if (this.regionalService.lsIdsSelecionadosMultiSelect.length < 1) {
          this.messageService.addToast(this.mensagem, "Atenção!", "warning");
          this.inputService.todosCamposRequiredPreenchidos = false;
        } else {
          this.inputService.todosCamposRequiredPreenchidos = true;
        }
        break;
      }

      case "tipomaterial": {
        if (this.tipoMaterialService.lsIdsSelecionadosMultiSelect.length < 1) {
          this.messageService.addToast(this.mensagem, "Atenção!", "warning");
          this.inputService.todosCamposRequiredPreenchidos = false;
        } else {
          this.inputService.todosCamposRequiredPreenchidos = true;
        }
        break;
      }

      case "situacaoprocesso": {
        if (
          this.situacaoProcessoService.lsIdsSelecionadosMultiSelect.length < 1
        ) {
          this.messageService.addToast(this.mensagem, "Atenção!", "warning");
          this.inputService.todosCamposRequiredPreenchidos = false;
        } else {
          this.inputService.todosCamposRequiredPreenchidos = true;
        }
        break;
      }

      case "paciente": {
        if (this.pacienteService.lsIdsSelecionadosMultiSelect.length < 1) {
          this.messageService.addToast(this.mensagem, "Atenção!", "warning");
          this.inputService.todosCamposRequiredPreenchidos = false;
        } else {
          this.inputService.todosCamposRequiredPreenchidos = true;
        }
        break;
      }

      case "pacientecomsus": {
        if (
          this.pacienteComsusService.lsIdsSelecionadosMultiSelect.length < 1
        ) {
          this.messageService.addToast(this.mensagem, "Atenção!", "warning");
          this.inputService.todosCamposRequiredPreenchidos = false;
        } else {
          this.inputService.todosCamposRequiredPreenchidos = true;
        }
        break;
      }

      case "tipoprograma": {
        if (this.tipoProgramaService.lsIdsSelecionadosMultiSelect.length < 1) {
          this.messageService.addToast(this.mensagem, "Atenção!", "warning");
          this.inputService.todosCamposRequiredPreenchidos = false;
        } else {
          this.inputService.todosCamposRequiredPreenchidos = true;
        }
        break;
      }

      case "origemdestino": {
        if (this.origemDestinoService.lsIdsSelecionadosMultiSelect.length < 1) {
          this.messageService.addToast(this.mensagem, "Atenção!", "warning");
          this.inputService.todosCamposRequiredPreenchidos = false;
        } else {
          this.inputService.todosCamposRequiredPreenchidos = true;
        }
        break;
      }

      case "referencia": {
        if (this.referenciaService.lsIdsSelecionadosMultiSelect.length < 1) {
          this.messageService.addToast(this.mensagem, "Atenção!", "warning");
          this.inputService.todosCamposRequiredPreenchidos = false;
        } else {
          this.inputService.todosCamposRequiredPreenchidos = true;
        }
        break;
      }

      case "motivocomsus": {
        if (this.motivoComsusService.lsIdsSelecionadosMultiSelect.length < 1) {
          this.messageService.addToast(this.mensagem, "Atenção!", "warning");
          this.inputService.todosCamposRequiredPreenchidos = false;
        } else {
          this.inputService.todosCamposRequiredPreenchidos = true;
        }
        break;
      }

      case "situacaocomsus": {
        if (
          this.situacaoComsusService.lsIdsSelecionadosMultiSelect.length < 1
        ) {
          this.messageService.addToast(this.mensagem, "Atenção!", "warning");
          this.inputService.todosCamposRequiredPreenchidos = false;
        } else {
          this.inputService.todosCamposRequiredPreenchidos = true;
        }
        break;
      }

      case "tipoatendimento": {
        if (this.tipoAtendimentoService.lsIdsSelecionadosMultiSelect.length < 1) {
          this.messageService.addToast(this.mensagem, "Atenção!", "warning");
          this.inputService.todosCamposRequiredPreenchidos = false;
        } else {
          this.inputService.todosCamposRequiredPreenchidos = true;
        }
        break;
      }

      default: {
        break;
      }
    }
  }

  private atribuicaoPadraoParametrosDoMegazord() {
    switch (this.tipoObjeto) {
      case "material": {
        if (!this.verificaParametrosExternos()) {
          this.titulo = "MEDICAMENTO";
          this.placeholderPesquisar = "Pesquisar por Medicamentos";
        }
        break;
      }

      case "municipio": {
        if (!this.verificaParametrosExternos()) {
          this.titulo = "MUNICÍPIO";
          this.placeholderPesquisar = "Pesquisar por Município";
        }
        break;
      }

      case "unidadesaude": {
        if (!this.verificaParametrosExternos()) {
          this.titulo = "UNIDADE DE SAÚDE: ";
          this.placeholderPesquisar = "Pesquisar por Unidade de saúde";
        }
        break;
      }

      case "regional": {
        if (!this.verificaParametrosExternos()) {
          this.titulo = "REGIONAL";
          this.placeholderPesquisar = "Pesquisar por Regional";
        }
        break;
      }

      case "tipomaterial": {
        if (!this.verificaParametrosExternos()) {
          this.titulo = "TIPO DE MATERIAL";
          this.placeholderPesquisar = "Pesquisar por Tipo de Material";
        }
        break;
      }

      case "situacaoprocesso": {
        if (!this.verificaParametrosExternos()) {
          this.titulo = "SITUAÇÃO PROCESSO";
          this.placeholderPesquisar = "Pesquisar por Situação do Processo";
        }
        break;
      }

      case "paciente": {
        if (!this.verificaParametrosExternos()) {
          this.titulo = "PACIENTE";
          this.placeholderPesquisar = "Pesquisar por nome ou CPF do Paciente";
        }
        break;
      }
      case "pacientecomsus": {
        if (!this.verificaParametrosExternos()) {
          this.titulo = "PACIENTE COMSUS";
          this.placeholderPesquisar =
            "Pesquisar por nome ou CPF do Paciente COMSUS";
        }
        break;
      }

      case "tipoprograma": {
        if (!this.verificaParametrosExternos()) {
          this.titulo = "TIPO PROGRAMA";
          this.placeholderPesquisar = "Pesquisar por Tipo de Programa";
        }
        break;
      }

      case "origemdestino": {
        if (!this.verificaParametrosExternos()) {
          this.titulo = "ORIGEM";
          this.placeholderPesquisar = "Pesquisar por Origem";
        }
        break;
      }

      case "referencia": {
        if (!this.verificaParametrosExternos()) {
          this.titulo = "REFERENCIA";
          this.placeholderPesquisar = "Pesquisar por Referencia";
        }
        break;
      }

      case "motivocomsus": {
        if (!this.verificaParametrosExternos()) {
          this.titulo = "MOTIVO COMSUS";
          this.placeholderPesquisar = "Pesquisar por Motivo Comsus";
        }
        break;
      }

      case "situacaocomsus": {
        if (!this.verificaParametrosExternos()) {
          this.titulo = "SITUAÇÃO COMSUS";
          this.placeholderPesquisar = "Pesquisar por Situação Comsus";
        }
        break;
      }

      case "tipoatendimento": {
        if (!this.verificaParametrosExternos()) {
          this.titulo = "TIPO DE ATENDIMENTO";
          this.placeholderPesquisar = "Pesquisar por Tipo de Atendimento";
        }
        break;
      }

      default: {
      }
    }
  }

  private verificaParametrosExternos() {
    return this.titulo != null && this.placeholderPesquisar != null
      ? true
      : false;
  }

  private atualizarListaIdsObjetosSelecionados() {
    this.getIdsDosItensSelecionados();
    this.preSetarUnidadesRegionaisByNivelUsuario();

    switch (this.tipoObjeto) {
      case "material": {
        this.materialService.getNewLsIdsSelecionados();
        this.materialService.lsIdsSelecionadosMultiSelect =
          this.lsIdsSelecionados;
        this.materialService.haItensSelecionadosMultiSelect = true;        
        break;
      }

      case "municipio": {
        this.municipioService.getNewLsIdsSelecionados();
        this.municipioService.lsIdsSelecionadosMultiSelect =
          this.lsIdsSelecionados;
        this.municipioService.haItensSelecionadosMultiSelect = true;
        break;
      }

      case "tipomaterial": {
        this.tipoMaterialService.getNewLsIdsSelecionados();
        this.tipoMaterialService.lsIdsSelecionadosMultiSelect =
          this.lsIdsSelecionados;
        this.tipoMaterialService.haItensSelecionadosMultiSelect = true;
        break;
      }

      case "situacaoprocesso": {
        this.situacaoProcessoService.getNewLsIdsSelecionados();
        this.situacaoProcessoService.lsIdsSelecionadosMultiSelect =
          this.lsIdsSelecionados;
        this.situacaoProcessoService.haItensSelecionadosMultiSelect = true;
        break;
      }

      case "paciente": {
        this.pacienteService.getNewLsIdsSelecionados();
        this.pacienteService.lsIdsSelecionadosMultiSelect =
          this.lsIdsSelecionados;
        this.pacienteService.haItensSelecionadosMultiSelect = true;
        break;
      }

      case "pacientecomsus": {
        this.pacienteComsusService.getNewLsIdsSelecionados();
        this.pacienteComsusService.lsIdsSelecionadosMultiSelect =
          this.lsIdsSelecionados;
        this.pacienteComsusService.haItensSelecionadosMultiSelect = true;
        break;
      }

      case "tipoprograma": {
        this.tipoProgramaService.getNewLsIdsSelecionados();
        this.tipoProgramaService.lsIdsSelecionadosMultiSelect =
          this.lsIdsSelecionados;
        this.tipoProgramaService.haItensSelecionadosMultiSelect = true;
        break;
      }

      case "origemdestino": {
        this.origemDestinoService.getNewLsIdsSelecionados();
        this.origemDestinoService.lsIdsSelecionadosMultiSelect =
          this.lsIdsSelecionados;
        this.origemDestinoService.haItensSelecionadosMultiSelect = true;
        break;
      }

      case "referencia": {
        this.referenciaService.getNewLsIdsSelecionados();
        this.referenciaService.lsIdsSelecionadosMultiSelect =
          this.lsIdsSelecionados;
        this.referenciaService.haItensSelecionadosMultiSelect = true;
        break;
      }

      case "motivocomsus": {
        this.motivoComsusService.getNewLsIdsSelecionados();
        this.motivoComsusService.lsIdsSelecionadosMultiSelect =
          this.lsIdsSelecionados;
        this.motivoComsusService.haItensSelecionadosMultiSelect = true;
        break;
      }

      case "situacaocomsus": {
        this.situacaoComsusService.getNewLsIdsSelecionados();
        this.situacaoComsusService.lsIdsSelecionadosMultiSelect =
          this.lsIdsSelecionados;
        this.situacaoComsusService.haItensSelecionadosMultiSelect = true;
        break;
      }

      case "tipoatendimento": {
        this.tipoAtendimentoService.getNewLsIdsSelecionados();
        this.tipoAtendimentoService.lsIdsSelecionadosMultiSelect =
          this.lsIdsSelecionados;
        this.tipoAtendimentoService.haItensSelecionadosMultiSelect = true;
        break;
      }

      default: {
      }
    }
    this.change.emit(true);
  }

  // Caso o usuário não selecione alguma unidade, quando a flag de nivel estiver ativa,
  // irá buscar todas as unidades que o usuário tem vínculo
  private preSetarUnidadesRegionaisByNivelUsuario() {
    switch (this.tipoObjeto) {
      case "unidadesaude": {
        this.unidadeSaudeService.getNewLsIdsSelecionados();
        this.unidadeSaudeService.lsIdsSelecionadosMultiSelect =
          this.nivel && this.lsIdsSelecionados.length >= 1
            ? this.lsIdsSelecionados
            : this.usuarioService.getIdsUnidadesSaudeByInfoUser();
        this.unidadeSaudeService.haItensSelecionadosMultiSelect =
          this.lsIdsSelecionados.length > 0 ? true : false;
        break;
      }

      case "regional": {
        this.regionalService.getNewLsIdsSelecionados();
        this.regionalService.lsIdsSelecionadosMultiSelect =
          this.nivel && this.lsIdsSelecionados.length >= 1
            ? this.lsIdsSelecionados
            : this.usuarioService.getIdsRegionaisByInfoUser();
        this.regionalService.haItensSelecionadosMultiSelect =
          this.lsIdsSelecionados.length > 0 ? true : false;
        break;
      }

      default: {
      }
    }
  }

  private getUnidadesSaudeVinculoUsuarioByDescricao(descricao: string) {
    if (this.unidadeSaudeDoUsuario && this.unidadeSaudeDoUsuario != null) {
      this.lsObjetos = [];
      for (let unidade of this.unidadeSaudeDoUsuario) {
        if (unidade && unidade.nome) {
          if (
            unidade.nome.toUpperCase().indexOf(descricao.toUpperCase()) !== -1
          ) {
            this.lsObjetos.push(unidade);
          }
        }
      }
    }
  }

  private getRegionaisVinculoUsuarioByDescricao(descricao: string) {
    if (this.regionaisDoUsuario && this.regionaisDoUsuario.length != null) {
      this.lsObjetos = [];
      for (let regional of this.regionaisDoUsuario) {
        if (regional && regional.descricao) {
          if (
            regional.descricao
              .toUpperCase()
              .indexOf(descricao.toUpperCase()) !== -1
          ) {
            this.lsObjetos.push(regional);
          }
        }
      }
    }
  }

  private async atribuiRegionaisUnidadesDoUsuarioVariaveisLocais() {
    this.validaInvertarioUnidade();
    this.validaInvertarioRegional();
  }

  /**
   * Validação se as unidades carregadas esttão em inventario ou não
   */
  private validaInvertarioUnidade() {
    if (this.usuarioService.infoUser.unidades && this.usuarioService.infoUser.unidades != null) {
      if (!this.inventario) {
        this.unidadeSaudeDoUsuario = this.usuarioService.infoUser.unidades;
        return;
      }

      this.inventarioServices.getSituacaoInvetarioEstabelecimentosAberto(this.converteNivelEmFiltroPesquisa(this.usuarioService.infoUser.unidades)).subscribe({
        next: (resp) => {
          this.unidadeSaudeDoUsuario = this.converteListaIdEmObjeto(resp, this.usuarioService.infoUser.unidades);
        }
      });
    }
  }

  /**
   * Validação se as regional carregadas esttão em inventario ou não
   */
  private validaInvertarioRegional() {
    if (this.usuarioService.infoUser.regionais && this.usuarioService.infoUser.regionais != null) {
      if (!this.inventario) {
        this.regionaisDoUsuario = this.usuarioService.infoUser.regionais;
        return;
      }
      this.inventarioServices.getSituacaoInvetarioEstabelecimentosAberto(this.converteNivelEmFiltroPesquisa(this.usuarioService.infoUser.regionais, true)).subscribe({
        next: (resp) => {
          this.regionaisDoUsuario = this.converteListaIdEmObjeto(resp, this.usuarioService.infoUser.regionais);
        }
      });
    }
  }


  /**
   * Criação do filtro com base no nivel informado;
   * @param listaNivel 
   * @param is_regional 
   * @returns InvetarioEstabelecimentosAbertoFilter
   */
  private converteNivelEmFiltroPesquisa(listaNivel: any, is_regional: boolean = false): InvetarioEstabelecimentosAbertoFilter {
    let _invetarioEstabelecimentosAbertoFilter: InvetarioEstabelecimentosAbertoFilter = new InvetarioEstabelecimentosAbertoFilter();
    _invetarioEstabelecimentosAbertoFilter.is_regional = is_regional;

    listaNivel.forEach(estabelecimento => {
      _invetarioEstabelecimentosAbertoFilter.list_id.push(+estabelecimento.id);
    });

    return _invetarioEstabelecimentosAbertoFilter
  }

  /**
   * Converte lista de id em objeto id
   * @param listId 
   * @param listNivel 
   * @returns any
   */
  private converteListaIdEmObjeto(listId: number[], listNivel: any): any {
    let _listNivelSemInventario: any = [];
    listId.forEach(id => {
      _listNivelSemInventario.push(listNivel.filter(estabelecimento => +estabelecimento.id == id)[0]);
    });

    return _listNivelSemInventario;
  }

  /**
    Método antigo que tem o promise
    =======================================================================================================================================
    async filtraListaNaoEmInventario(elementos: any[], flag: boolean): Promise<any> {
      let _listReturnElementos: any[] = [];
      let _invetarioEstabelecimentosAbertoFilter: InvetarioEstabelecimentosAbertoFilter = new InvetarioEstabelecimentosAbertoFilter();
  
      _invetarioEstabelecimentosAbertoFilter.is_regional = flag;
  
      elementos.forEach(element => {
        _invetarioEstabelecimentosAbertoFilter.list_id.push(+element.id);
      });
  
      this.inventarioServices.getSituacaoInvetarioEstabelecimentosAberto(_invetarioEstabelecimentosAbertoFilter).subscribe({
        next: (resp) => {
          resp.forEach((element) => { _listReturnElementos.push(elementos.filter((estabelecimento) => estabelecimento.id == element)[0]) });
          _listReturnElementos.sort((a, b) => +a.id > +b.id ? 1 : -1)
          return _listReturnElementos;
        }
      });
  
      return _listReturnElementos;
    }
    */
}

/*
  ============================= EXEMPLO DE UTILIZAÇÃO =============================
  OBS: Lembrando que os atributos e parametros são opcionais (não precisa colocar),
  com excessão do obrigatório:
    * tipoObjeto

  ATENÇÃO:
  - Caso queira um single select, só adicione a flag singleSelect e passe o valor true
  - Se for utilizar o preCarregarDados, deixei o valor minimo de pesquisa como 0

  __________________________ No arquivo html ______________________________________

  <div class="col-sgs-6 mat-form-field-wrapper">
      <app-multi-select-autocomplete
        [placeholderPesquisar]="placeholderPesquisarTipoMaterial"
        [titulo]="tituloTipoMaterial"
        [chaveDescricao]="chaveDescricaoTipoMaterial"
        [tipoObjeto]="tipoObjetoTipoMaterial"
        [nivel]="nivelTipoMaterial"
        [required]="requiredTipoMaterial"
        [valorMinimoCharacterPesquisa]="valorMinimoCharacterPesquisaTipoMaterial"
        [preCarregarDados]="preCarregarDadosTipoMaterial"
      </app-multi-select-autocomplete>
    </div>

    __________________________ No arquivo ts ______________________________________

    placeholderPesquisarTipoMaterial = "Pesquisar por Tipo de Medicamento";
    tituloTipoMaterial = "Tipo de Medicamento";
    chaveDescricaoTipoMaterial = "descricao";
    tipoObjetoTipoMaterial = "tipomaterial";
    nivelTipoMaterial = false;
    requiredTipoMaterial = false;
    valorMinimoCharacterPesquisaTipoMaterial = 0;
    preCarregarDadosTipoMaterial = true;

    __________________ EXEMPLO VALIDAÇÃO DE REQUIRED NA CLASSE TS: _________________

    private todosCamposRequiredValidados() {
      this.inputService.eventVerificaRequired.emit(true);
      return this.inputService.todosCamposRequiredPreenchidos;
    }

    private  gerarRelatorio() {
      if (!this.todosCamposRequiredValidados()) return;
      ...
    }
    __________________ EXEMPLO LIMPEZA DE TODOS OS CAMPOS NA CLASSE TS: ____________
    private limparTodosCampos() {
      this.inputService.eventLimparTodasListas.emit(true);
      ...
    }

*/
