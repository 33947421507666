import { JUDICIAL } from './../../app.api';

import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Processo } from '@app/judicial/processo/processo.model';
import { Ressarcimento } from './ressarcimento.model';
import { SGS_URL, CORE } from '@app/app.api';
import { LoginService } from '@app/core/security/login/login.service';

@Injectable()
export class RessarcimentoService {
  constructor(
    private http: HttpClient,
    protected loginService: LoginService) {
  }

  private _ressarcimento: Ressarcimento;
  private _processoAtivo: Processo;

  private _filterRessarcimento = this.getFilterEmpty();
 
  getNovoRessarcimento(): Ressarcimento
 {    
    this._ressarcimento = new Ressarcimento("", null, null, "", null, null,
      null, null, "", "", "", "", "", "", "")
    return this._ressarcimento;
}

  cadastrar(ressarcimento: Ressarcimento): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id;
    return this.http.post<any>(`${SGS_URL.JUDICIAL}/${JUDICIAL.RESSARCIMENTO}/c/${id_referencia}/${id_usuario}`, ressarcimento)
  }

  getRessarcimentoByProcesso(idProcesso: String): Observable<any> {
    return this.http.post<any>(`${SGS_URL.JUDICIAL}/${JUDICIAL.RESSARCIMENTO}/getByProcesso`, this._filterRessarcimento)
  }

  buscaRessarcimento(idRessarcimento: string) {
    return this.http.get<any>(`${SGS_URL.JUDICIAL}/${JUDICIAL.RESSARCIMENTO}/getRessarcimentoById/${idRessarcimento}`)
  }

  buscaPrescricaoByRessarcimento(id_ressarcimento: string){
    return this.http.get<any>(`${SGS_URL.JUDICIAL}/${CORE.PRESCRICAO}/buscaPrescricaoByRessarcimento/${id_ressarcimento}`)
  }

  buscaMunicipioByRessarcimento(id_ressarcimento: string){
    return this.http.get<any>(`${SGS_URL.JUDICIAL}/${JUDICIAL.RESSARCIMENTO}/getMunicipioByRessarcimento/${id_ressarcimento}`)
  }

  public getFilterEmpty()
  {
    return {
      processo: null, 
      sort:'dt_cobranca',
      direction:'desc' 
    }
  }

  public get ressarcimento(): Ressarcimento {
    return this._ressarcimento;
  }
  public set ressarcimento(value: Ressarcimento) {
    this._ressarcimento = value;
  }

  public get processoAtivo(): Processo {
    return this._processoAtivo;
  }
  public set processoAtivo(value: Processo) {
    this._processoAtivo = value;
  }
  public get filterRessarcimento() {
    return this._filterRessarcimento;
  }
  public set filterRessarcimento(value) {
    this._filterRessarcimento = value;
  }
}
