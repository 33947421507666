import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PosicaoAnaliseTecnicaResponse } from "@app/core/shared/models/posicao-analise-tecnica-response";

@Component({
  selector: "ses-posicao-analise-tecnica-select",
  templateUrl: "./posicao-analise-tecnica-select.component.html",
  styleUrls: ["./posicao-analise-tecnica-select.component.scss"],
})
export class PosicaoAnaliseTecnicaSelectComponent implements OnInit {
  @Input() filtroNivel: boolean = true;
  @Input() required: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */
  @Output() idSelecionada = new EventEmitter<string>();
  @Output() posicaoAnaliseTecnicaSelecionada = new EventEmitter<any>();

  constructor() {}

  public lsPosicaoAnaliseTecnica: PosicaoAnaliseTecnicaResponse[] = [];
  public posicaoAnaliseTecnica: PosicaoAnaliseTecnicaResponse =
    new PosicaoAnaliseTecnicaResponse();

  ngOnInit() {
    this.limparEvent.subscribe({
      next: (resp) => {
        this.limpar();
      },
    });
  }

  public selecionarPosicao() {
    this.idSelecionada.emit(this.posicaoAnaliseTecnica.id +'');
    this.posicaoAnaliseTecnicaSelecionada.emit(this.posicaoAnaliseTecnica);
  }

  public limpar() {
    this.posicaoAnaliseTecnica = null;
    this.idSelecionada.emit(null);
    this.posicaoAnaliseTecnicaSelecionada.emit(null);
  }
}
