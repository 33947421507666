import { Injectable } from "@angular/core"
import { HttpClient } from '@angular/common/http'
import { Option } from '../../shared/models/option.model';


@Injectable({
  providedIn: 'root'
})

export class UtilService {

  public meses: Option[] =
  [
    { chave: '1', valor: 'Janeiro' },
    { chave: '2', valor: 'Fevereiro' },
    { chave: '3', valor: 'Março' },
    { chave: '4', valor: 'Abril' },
    { chave: '5', valor: 'Maio' },
    { chave: '6', valor: 'Junho' },
    { chave: '7', valor: 'Julho' },
    { chave: '8', valor: 'Agosto' },
    { chave: '9', valor: 'Setembro' },
    { chave: '10', valor: 'Outubro' },
    { chave: '11', valor: 'Novembro' },
    { chave: '12', valor: 'Dezembro' }
  ]

  public mesesDoisDigitos: Option[] =
  [
    { chave: '01', valor: 'Janeiro' },
    { chave: '02', valor: 'Fevereiro' },
    { chave: '03', valor: 'Março' },
    { chave: '04', valor: 'Abril' },
    { chave: '05', valor: 'Maio' },
    { chave: '06', valor: 'Junho' },
    { chave: '07', valor: 'Julho' },
    { chave: '08', valor: 'Agosto' },
    { chave: '09', valor: 'Setembro' },
    { chave: '10', valor: 'Outubro' },
    { chave: '11', valor: 'Novembro' },
    { chave: '12', valor: 'Dezembro' }
  ]

  public anos: Option[] =
  [
    { chave: '2010', valor: '2010' },
    { chave: '2011', valor: '2011' },
    { chave: '2012', valor: '2012' },
    { chave: '2013', valor: '2013' },
    { chave: '2014', valor: '2014' },
    { chave: '2015', valor: '2015' },
    { chave: '2016', valor: '2016' },
    { chave: '2017', valor: '2017' },
    { chave: '2018', valor: '2018' },
    { chave: '2019', valor: '2019' },
    { chave: '2020', valor: '2020' },
    { chave: '2021', valor: '2021' },
    { chave: '2022', valor: '2022' },
    { chave: '2023', valor: '2023' },
    { chave: '2024', valor: '2024' },
    { chave: '2025', valor: '2025' },
    { chave: '2026', valor: '2026' },
    { chave: '2027', valor: '2027' },
    { chave: '2028', valor: '2028' },
    { chave: '2029', valor: '2029' },
    { chave: '2030', valor: '2030' }
  ]

  public anoCorrente = this.anoAtual();

  constructor(private http: HttpClient){}

  public anoAtual(): string {
    let data = new Date();
    let ano = data.getFullYear();
    return String(ano)
  }

  public mesAtual(): string {
    let data = new Date();
    let mes = data.getMonth();
    return String(mes)
  }

  public static formatCPF(value)
  {
    if(value == null) return null;

    //retirando os caracteres especiais do texto
    let ch = /\^|~|\|\/|_|\?|,|\*|\.|\-/g;
    value = value.replace(ch, "");

     if (value.trim().length == 11) {
       return value.substring(0, 3) + "." +
         value.substring(3, 6) + "." +
         value.substring(6, 9) + "-" +
         value.substring(9);
     }
     else if(value.trim().length == 14)
     {
       return value.substring(0, 2) + "." +
         value.substring(2, 5) + "." +
         value.substring(5, 8) + "/" +
         value.substring(8, 12) + "-" +
         value.substring(12);
     }
     else return value;
  }

  /*
  * Método de máscara dinâmica para campos que tem CPF e CPNJ
  *
  * (Exemplo de chamada no HTML (Depois de chamar o UtilService no contrutor (.TS))
  * abaixo do método validadorCpfParaCampoCpfCnpj.)
  */
  public mascaraCpfCnpjDinamica (cpfOuCnpj: string): string {
    if (cpfOuCnpj && cpfOuCnpj != null && cpfOuCnpj.length != null && cpfOuCnpj.length != 0) {
      return (cpfOuCnpj.length < 12) ? '000.000.000-009' : '00.000.000/0000-00';
    }
  }

  /*
  * Método que verifica o campo de cpf e cnpj,
  * se for cpf, ele faz a verificação do mesmo
  *
  * (Exemplo de chamada no HTML (Depois de chamar o UtilService no contrutor (.TS)) abaixo do método.)
  */
  public validadorCpfParaCampoCpfCnpj(cpfOuCnpj: string): boolean {
    if (cpfOuCnpj && cpfOuCnpj != null && cpfOuCnpj.length != null && cpfOuCnpj.length != 0) {
      // Se for cpf
      if (cpfOuCnpj.length < 12) {
        return this.validaCPFNaoStatic(cpfOuCnpj);
      } else {
        // Se for cnpj
        return true;
      }
    }
  }

  /* Exemplo de chamada no HTML (Depois de chamar o UtilService no contrutor):
  <mat-form-field class="col-sgs-3">
    <mat-label>CPF/CNPJ</mat-label>
      <input matInput [(ngModel)]="contasService.contasDevolucaoActive.cnpj" required
      [class.text-danger]="!utilService.validadorCpfParaCampoCpfCnpj(contasService.contasDevolucaoActive.cnpj)"
      [mask]="utilService.mascaraCpfCnpjDinamica(contasService.contasDevolucaoActive.cnpj)">
  </mat-form-field>
  */

  public static validaCPF (cpf: String):boolean {

    var numeros, digitos, soma, i, resultado, digitos_iguais;
    digitos_iguais = 1;
    if(cpf==null)
      return false
    if (cpf.length < 11)
          return false;
    for (i = 0; i < cpf.length - 1; i++)
          if (cpf.charAt(i) != cpf.charAt(i + 1))
                {
                digitos_iguais = 0;
                break;
                }
    if (!digitos_iguais)
          {
          numeros = cpf.substring(0,9);
          digitos = cpf.substring(9);
          soma = 0;
          for (i = 10; i > 1; i--)
                soma += numeros.charAt(10 - i) * i;
          resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
          if (resultado != digitos.charAt(0))
                return false;
          numeros = cpf.substring(0,10);
          soma = 0;
          for (i = 11; i > 1; i--)
                soma += numeros.charAt(11 - i) * i;
          resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
          if (resultado != digitos.charAt(1))
                return false;
          return true;
          }
    else
        return false;
  }

  public validaCPFNaoStatic(cpf: String): boolean {
    var numeros, digitos, soma, i, resultado, digitos_iguais;
    digitos_iguais = 1;
    if (cpf.length < 11) return false;
    for (i = 0; i < cpf.length - 1; i++)
      if (cpf.charAt(i) != cpf.charAt(i + 1)) {
        digitos_iguais = 0;
        break;
      }
    if (!digitos_iguais) {
      numeros = cpf.substring(0, 9);
      digitos = cpf.substring(9);
      soma = 0;
      for (i = 10; i > 1; i--) soma += numeros.charAt(10 - i) * i;
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(0)) return false;
      numeros = cpf.substring(0, 10);
      soma = 0;
      for (i = 11; i > 1; i--) soma += numeros.charAt(11 - i) * i;
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(1)) return false;
      return true;
    } else return false;
  }

}
