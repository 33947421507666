import { PosicaoAnaliseTecnicaResponse } from "./posicao-analise-tecnica-response";

export class JustificativaAnaliseTecnicaResponse {
   id: number;
   descricao: string;
   status: boolean;
   created: Date;
   updated: Date;
   usuario: {nome: string; id: number};
   posicaoAnaliseTecnica: PosicaoAnaliseTecnicaResponse;
}

