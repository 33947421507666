import { Injectable } from "@angular/core"
import { SGS_URL, CORE } from "src/app/app.api"
import { HttpClient, HttpParams } from '@angular/common/http'
import { LoginService } from "@app/core/security/login/login.service";
import { Documento } from "./documento.model";
import { Observable } from "rxjs";

@Injectable()
export class DocumentoService {

  constructor(private http: HttpClient,
    private loginService: LoginService) { }

  updateArquivo(file: File, documento: Documento): Observable<any> {
    let id_usuario = this.loginService.user.id;
    let id_referencia = this.loginService.user.referencia.id
    let formData: FormData = new FormData()
    formData.append('upload', file)
    formData.append('documento', JSON.stringify(documento))
    let params = new HttpParams()

    const options = {
      params: params,
      reportProgress: true
    }
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.DOCUMENTO}/updateFile/${id_referencia}/${id_usuario}`, formData, options)
  }

}
