import { Referencia } from '@app/core/centro-custo/Referencia.model';
export class Permissao {
  constructor(public id: string,
              public id_permissao: string,
              public nome: string,
              public visualizar: boolean,
              public inserir: boolean,
              public editar: boolean,
              public excluir: boolean,
              public referencia:Referencia,
              public menu:number  
              ) {}
}
