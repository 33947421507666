import { FilterItemsPrescricaoByProcess } from './../sequestro-valores/filter-items-prescricao-by-process.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlvaraModel } from './alvara.model';
import { Observable } from 'rxjs';
import { LoginService } from '@app/core/security/login/login.service';
import { SGS_URL, JUDICIAL} from '@app/app.api';
import { ListaJSON } from '@app/core/shared/default/lista-json.model';
import { ItemPrescricao } from '@app/core/prescricao/item-prescricao.model';

@Injectable({
  providedIn: 'root'
})
export class AlvaraService {

  private _alvaraAtivo: AlvaraModel; //Objeto ativo que será salvo ou editado
  private _filterItemsPrescricao: FilterItemsPrescricaoByProcess; //filtra uma lista de itens de objetos
  private _filterItemsAlvara: FilterItemsPrescricaoByProcess; //filtra uma lista de alvarás
  private _visibleNewAlvara: boolean; //exibi ou não o botão novo alvará e ativa e desativa um alvará

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) { }

  /**Método para salvar um objeto e recuperar o id para preencher a classe **/
  save(): Observable<any> {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id;
    return this.http.post<any>(`${SGS_URL.JUDICIAL}/${JUDICIAL.ALVARA}/c/${id_referencia}/${id_usuario}`, this._alvaraAtivo)
  }

  //busca todos os itens da prescrição do processo ativo
  searchAllItemPrescricao(): Observable<ListaJSON<ItemPrescricao>>
  {
    this._filterItemsPrescricao.referencia = this.loginService.user.referencia ? this.loginService.user.referencia.id : null;
    return this.http.post<ListaJSON<ItemPrescricao>>(`${SGS_URL.JUDICIAL}/${JUDICIAL.PROCESSO}/getItemPrescricaoByProcess`, this._filterItemsPrescricao);
  }

  searchAllAlvaras(): Observable<ListaJSON<AlvaraModel>> {
    return this.http.post<ListaJSON<AlvaraModel>>(`${SGS_URL.JUDICIAL}/${JUDICIAL.ALVARA}/p`, this._filterItemsAlvara);
  }

  //Atualiza o status de um objeto no banco de dados
  updateActive(alvara:AlvaraModel): Observable<any>
  {
    const id_usuario = this.loginService.user.id;
    const id_referencia = this.loginService.user.referencia.id
    return this.http.post<any>(`${SGS_URL.JUDICIAL}/${JUDICIAL.ALVARA}/s/${alvara.id}/${id_usuario}/${alvara.status}/${id_referencia}`, {})
  }

  //Metódos Sets e Gets

  public get filterItemsPrescricao(): FilterItemsPrescricaoByProcess {
    return this._filterItemsPrescricao;
  }
  public set filterItemsPrescricao(value: FilterItemsPrescricaoByProcess) {
    this._filterItemsPrescricao = value;
  }
  public get alvaraAtivo() {
    return this._alvaraAtivo;
  }
  public set alvaraAtivo(value) {
    this._alvaraAtivo = value;
  }
  public get filterItemsAlvara(): FilterItemsPrescricaoByProcess {
    return this._filterItemsAlvara;
  }
  public set filterItemsAlvara(value: FilterItemsPrescricaoByProcess) {
    this._filterItemsAlvara = value;
  }
  public get visibleNewAlvara(): boolean {
    return this._visibleNewAlvara;
  }
  public set visibleNewAlvara(value: boolean) {
    this._visibleNewAlvara = value;
  }

}
