import { Observable } from 'rxjs';
import { SGS_URL, JUDICIAL } from '../../app.api';
import { HttpClient } from '@angular/common/http';
import { Protocolo } from './protocolo.model';
import { Injectable } from '@angular/core';
import { Processo } from '@app/judicial/processo/processo.model';
import { LoginService } from '@app/core/security/login/login.service';
import { ListaJSON } from '@app/core/shared/default/lista-json.model';

@Injectable({
  providedIn: 'root'
})
export class ProtocoloService {

  constructor(private http: HttpClient, private loginService: LoginService) { }

  private _protocolo: Protocolo;
  private _protocoloFilter = this.getEmptyProtocoloFilter();

  getOrigemDestino(origemDestino: boolean): Observable<any> {
    return this.http.get<any>(`${SGS_URL.JUDICIAL}/protocolo/getOrigemDestino/${origemDestino}`)
  }

  cadastrarProtocolo(): Observable<any> {
    return this.http.post<any>(`${SGS_URL.JUDICIAL}/${JUDICIAL.PROTOCOLO}/c/${this.loginService.user.referencia.id}/${this.loginService.user.id}`, this._protocolo)
  }

  //busca os protocolos no banco de dados
  public getProtocolosByProcesso(): Observable<ListaJSON<Protocolo>> {
    return this.http.post<ListaJSON<Protocolo>>(`${SGS_URL.JUDICIAL}/${JUDICIAL.PROTOCOLO}/p`, this._protocoloFilter);
  }

  public getProtocolo(processo: Processo) {
    let advogado = processo.advogado_autor;
    let juiz = processo.juiz_autor;
    return new Protocolo(null, processo, null, null, null, null, null, null, null, null, new Date(), null, false, null, null, null, null, '0', juiz, advogado, null, null, true, null);
  }

  public getEmptyProtocoloFilter() {
    return {
      idProcesso: null,
      direction: "desc",
      sort: "data_correspondencia",
      pageIndex: 0,
      pageSize: 5
    }
  }

  public get protocolo(): Protocolo {
    return this._protocolo;
  }
  public set protocolo(value: Protocolo) {
    this._protocolo = value;
  }
  public get protocoloFilter() {
    return this._protocoloFilter;
  }
  public set protocoloFilter(value) {
    this._protocoloFilter = value;
  }
}
