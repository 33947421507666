import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rg'
})
export class RgPipe implements PipeTransform {

  transform(value: any, args?: any): any
  {    
    if(value == null) return null;
    else if(typeof value == 'string' && value.length == 7) return value.substring(0,1) + "." + value.substring(1, 4) + "." + value.substring(4);

    
    return value;
  }
}
