import { Injectable, EventEmitter, Input } from "@angular/core"
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { CORE, SGS_URL } from "@app/app.api";
import { LoginService } from '@app/core/security/login/login.service';

import { Avisos } from "./avisos.model";
import { ListaJSON } from "../shared/default/lista-json.model";

@Injectable()
export class AvisosService {
    static avisosEmitter = new EventEmitter();

    private _aviso: Avisos = this.getNovoAviso();
    private _avisosFilter = this.getAvisosFilter();

    private _eventSaveAviso: EventEmitter<boolean> = new EventEmitter<boolean>();
    private _eventClearAviso: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() avisos: Avisos;
    id_referencia: string = '';
    avisoEditar: Avisos;
    public titulo: String = 'Cadastrar Novo';

    constructor
        (
            private http: HttpClient,
            private loginService: LoginService
        ) {
          this.id_referencia = this.loginService.user.referencia.id;
        }

    setAviso(aviso: Avisos) {
      this.avisoEditar = aviso
    }

    getNovoAviso(): Avisos {
        return new Avisos(null, new Date(), null, null, null, null, true, null, null);
    }

    cadastrar(avisos: Avisos): Observable<any> {
      const id_usuario = this.loginService.user.id;
      const id_referencia = this.loginService.user.referencia.id;
      return this.http.post<any>(`${SGS_URL.CORE}/${CORE.AVISOS}/c/${id_referencia}/${id_usuario}`, avisos)
    }

    listar(): Observable<any> {
      return this.http.post<any>(`${SGS_URL.CORE}/${CORE.AVISOS}/findAvisosList`, this.loginService.user.referencia.id)
    }

    listarModulo(idReferencia: String): Observable<any> {

      return this.http.post<any>(`${SGS_URL.CORE}/${CORE.AVISOS}/findAvisosModulo`, { idReferencia: idReferencia })
    }

    buscaAviso(avisoID: String): Observable<Avisos>{
      return this.http.post<Avisos>(`${SGS_URL.CORE}/${CORE.PROFISSIONAL}/id/${avisoID}`, {})
    }

    updateStatusAtivo(id: string, status: boolean): Observable<any>{
      const id_usuario = this.loginService.user.id;
      const id_referencia = this.loginService.user.referencia.id;
      return this.http.post<any>(`${SGS_URL.CORE}/${CORE.AVISOS}/s/${id}/${id_usuario}/${status}/${id_referencia}`, {})
    }

    public getAvisosFilter() {
      return {
        dt_inicio: "",
        nivel_aviso: "",
        observacao: "",
        titulo: "",
        ativo: "",
        dt_expiracao: "",
        direction: "asc",
        sort: "dt_inicio",
        pageSize: 5,
        pageIndex: 0,
      }
    }

    public get avisosFilter() {
      return this._avisosFilter;
    }
    public set avisosFilter(value) {
      this._avisosFilter = value;
    }

    public get aviso(): Avisos {
        return this._aviso;
    }

    public set aviso(value: Avisos) {
        this._aviso = value;
    }

    public get eventSaveAviso(): EventEmitter<boolean> {
        return this._eventSaveAviso;
      }
      public set eventSaveAviso(value: EventEmitter<boolean>) {
        this._eventSaveAviso = value;
      }

    public get eventClearAviso(): EventEmitter<boolean> {
        return this._eventClearAviso;
      }
    public set eventClearAviso(value: EventEmitter<boolean>) {
        this._eventClearAviso = value;
      }
}
