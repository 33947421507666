import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InputService {

  private _eventVerificaRequired: EventEmitter<boolean> = new EventEmitter();
  private _eventLimparTodasListas: EventEmitter<boolean> = new EventEmitter();

  public todosCamposRequiredPreenchidos: boolean = true;

  constructor() { }

  public get eventVerificaRequired(): EventEmitter<boolean> {
    return this._eventVerificaRequired;
  }
  public set eventVerificaRequired(value: EventEmitter<boolean>) {
    this._eventVerificaRequired = value;
  }
  public get eventLimparTodasListas(): EventEmitter<boolean> {
    return this._eventLimparTodasListas;
  }
  public set eventLimparTodasListas(value: EventEmitter<boolean>) {
    this._eventLimparTodasListas = value;
  }
  

}
