import { ClassificacaoSubstanciaAnvisaFilter } from './../shared/models/classificacao-substancia-anvisa-filter';
import { ResponseComLength } from './../shared/models/response-com-length.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CORE, SGS_URL } from '@app/app.api';
import { Observable } from 'rxjs-compat';
import { ClassificacaoSubstanciaANVISA } from '../shared/models/classificacao-substancia-anvisa';

@Injectable({
  providedIn: 'root'
})
export class ClassificacaoSubstanciaAnvisaService {

  constructor(private http: HttpClient) {}

  public cadastrarClassificacaoSubstanciaAnvisa(params: ClassificacaoSubstanciaANVISA): Observable<number> {
    return this.http.post<number>(`${SGS_URL.JUDICIAL}/${CORE.CLASSIFICACAO_SUBSTANCIA_ANVISA}/cadastrar-classificacao-substancia-anvisa`, params );
  }

  public updateClassificacaoSubstanciaAnvisa(params: ClassificacaoSubstanciaANVISA): Observable<number> {
    return this.http.post<number>(`${SGS_URL.JUDICIAL}/${CORE.CLASSIFICACAO_SUBSTANCIA_ANVISA}/update-classificacao-substancia-anvisa`, params );
  }

  public findClassificacaoSubstanciaAnvisaByFilter(params: ClassificacaoSubstanciaAnvisaFilter): Observable<ResponseComLength> {
    return this.http.post<ResponseComLength>(`${SGS_URL.JUDICIAL}/${CORE.CLASSIFICACAO_SUBSTANCIA_ANVISA}/find-classificacao-substancia-anvisa`, params );
  }
}
