import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AvisosService } from '../avisos.service';
import { LoginService } from '@app/core/security/login/login.service';
import { Avisos } from '../avisos.model';
import { UsuarioService } from '@app/core/usuario/usuario.service';

@Component({
  selector: 'app-consulta',
  templateUrl: './consulta.component.html',
  styleUrls: ['./consulta.component.scss']
})
export class ConsultaComponent implements OnInit {

  @ViewChild("paginatorAvisosConsulta") paginator: MatPaginator;
  dataSourceAvisos = new MatTableDataSource<Avisos>();
  displayedColumnsAvisos: string[] = ['titulo', 'descricao'];

  avisos: Avisos;

  constructor(
    private avisosService: AvisosService,
    private loginService: LoginService,
    private modalService: NgbModal,
    private usuarioService: UsuarioService
  ) { }

  ngOnInit() {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 5;
  }

  ngAfterViewInit() {
    this.loadAvisos();
    //Atualiza a Lista quando muda de Módulo
    AvisosService.avisosEmitter.subscribe(result => {
      this.buscarListaModulo(result);
    });
  }

  // BUSCA OS AVISOS COM REFERENCIA NO MÓDULO.
  // ALGUMAS REGRAS SÃO TRATADAS DIRETO NO BANCO, POR SEREM REGRAS PADRÕES COMO:
  // ----> DATA_INICIAL <= ATUAL
  // ----> DATA_EXPIRAÇAO >= ATUAL
  // ----> ID_REFERENCIA = MODULO DA REFERENCIA
  // ----> ATIVO = TRUE
  // FEITO ISSO O FRONT RECEBE UMA LISTA DO OBJETO COM OS AVISOS 'VALIDOS', E APRESENTA NA TABELA APENAS OS AVISOS QUE DIZ RESPEITO A SUA REGIONAL, UNIDADE OU REGIOES
  buscarListaModulo(idReferencia: String) {
    setTimeout(() => {
      this.avisosService.listarModulo(idReferencia).subscribe(result => {
        let avisosAUX = new Array<Avisos>();
        if (result != null) {
          // VERIFICA SE O AVISO É PARA TODOS.
          result.forEach((item) => {
            if (item.nivel_aviso === "TODOS") {
              avisosAUX.push(item);

              // VERIFICA SE O AVISO É PARA A REGIONAL.
            }
            if (this.usuarioService.infoUser.regionais != null && this.usuarioService.infoUser.regionais.length > 1) {
              this.usuarioService.infoUser.regionais.forEach((itemRegional) => {
                if (item.nivel_aviso == itemRegional.descricao) avisosAUX.push(item);
              });

            } else if (this.usuarioService.infoUser.regionais != null) {
              if (item.nivel_aviso == this.usuarioService.infoUser.regionais[0].descricao) avisosAUX.push(item);
            }

            // VERIFICA SE O AVISO É PARA UNIDADE
            if (this.usuarioService.infoUser.unidades != null && this.usuarioService.infoUser.unidades.length > 1) {
              this.usuarioService.infoUser.unidades.forEach((itemUnidade) => {
                if (item.nivel_aviso == itemUnidade.nome) avisosAUX.push(item);
              });

            } else if (this.usuarioService.infoUser.unidades != null) {
              if (item.nivel_aviso == this.usuarioService.infoUser.unidades[0].nome) {
                avisosAUX.push(item);
              }
            }

            // VERIFICA SE O AVISO É PARA REGIÕES.
            if (this.usuarioService.infoUser.regioes != null && this.usuarioService.infoUser.regioes.length > 1) {
              this.usuarioService.infoUser.regioes.forEach((itemRegioes) => {
                if (item.nivel_aviso == itemRegioes.descricao) avisosAUX.push(item);
              });
            } else if (this.usuarioService.infoUser.regioes != null) {
              if (item.nivel_aviso == this.usuarioService.infoUser.regioes[0].descricao) {
                avisosAUX.push(item);
              }
            }
          });
        }
        this.dataSourceAvisos.data = avisosAUX;
        this.paginator.length = result.lenght;
        this.dataSourceAvisos.paginator = this.paginator;
      });
    }, 500);

  }

  maisInfoAviso(aviso: Avisos, modal) {
    this.avisos = aviso;
    this.modalService.open(modal, { size: 'lg' });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  loadAvisos() {
    setTimeout(() => {
      if (this.dataSourceAvisos.data.length === 0) {
        AvisosService.avisosEmitter.emit(this.loginService.user.referencia.id);
      }
    }, 500);
  }

}
