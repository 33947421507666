import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TipoAnaliseTecnicaFilter } from "@app/core/shared/models/tipo-analise-tecnica-filter";
import { TipoAnaliseTecnicaResponse } from "@app/core/shared/models/tipo-analise-tecnica-response";
import { Observable } from "rxjs";
import { SGS_URL, CORE } from "@app/app.api";
import { ResponseComLength } from "@app/core/shared/models/response-com-length.model";
import { TipoAnaliseTecnicaRequest } from "@app/core/shared/models/tipo-analise-tecnica-request";

@Injectable({
  providedIn: "root",
})
export class TipoAnaliseTecnicaService {
  constructor(private http: HttpClient) {}


  public getTipoAnaliseByFilter(params: TipoAnaliseTecnicaFilter): Observable<ResponseComLength> {
    return this.http.post<ResponseComLength>(
      `${SGS_URL.JUDICIAL}/${CORE.TIPO_ANALISE_TECNICA}/listar-tipo-analise-tecnica`,
      { params }
    );
  }

  public updateTipoAnaliseTecnica(params: TipoAnaliseTecnicaRequest): Observable<any>{
    return this.http.post<any>(
      `${SGS_URL.JUDICIAL}/${CORE.TIPO_ANALISE_TECNICA}/update-tipo-analise-tecnica`,
      { params }
    );
  }

  public postTipoAnaliseTecnica(params: TipoAnaliseTecnicaRequest): Observable<any>{
    return this.http.post<any>(
      `${SGS_URL.JUDICIAL}/${CORE.TIPO_ANALISE_TECNICA}/cadastrar-tipo-analise-tecnica`,
      { params }
    );
  }
}
