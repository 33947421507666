import { Paginador } from "./paginador.model";

export class DecisaoSuperiorAnaliseTecnicaFilter extends Paginador{
  paginar: boolean = true;
  direction: string = "desc";
  sort: string;
  pagaIndex:number;
  pageSize:number;
  referencia:number;
  descricao: string;
  id_parecer: string;
}
