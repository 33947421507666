import { Material } from './../material/material.model';
import { MatTableDataSource } from '@angular/material';
import { ListaJSON } from './../shared/default/lista-json.model';
import { CORE } from '@app/app.api';
import { Injectable, EventEmitter } from "@angular/core"
import { SGS_URL } from "../../app.api"
import { HttpClient } from '@angular/common/http'
import { Receita } from "./receita.model";
import { Observable } from "rxjs";
import { LoginService } from "@app/core/security/login/login.service";
import { ProcessoService } from '@app/judicial/processo/processo.service';

@Injectable()
export class ReceitaService {

  //medicamento pertencente a um grupo de receita na tela de receitas por medicamentos
  private _medicamentoReceita: Material;

  //objeto receita que será enviado para cadastro
  private _receita: Receita = this.getNewReceita();

  //filtro da tabela de receitas
  private _filterReceita = this.getFilterReceita();

  //utilizado para chamar/encerrar o modal de cadastro de receita (utilizado na aba medicamentos/procedimentos do cadastro do paciente)
  private _recallModalCadastro: boolean = false;

  //utilizado para chamar/encerrar o modal de pesquisa de receita (utilizado na aba medicamentos/procedimentos do cadastro do paciente)
  private _recallModalPesquisa: boolean = false;

  //evento dipsearado quando sobe uma receita para o servidor
  private _eventUpdateReceita: EventEmitter<boolean> = new EventEmitter<boolean>();

  //evento utilizado para recarregar o modal por conta do bug do limpar que quebra o upload do arquivo tarefa 9679
  private _eventReloadModalReceita: EventEmitter<boolean> = new EventEmitter<boolean>();

  //datasource da tela de pesquisa de receita por medicamento
  private _dataSourceReceitaPesquisa: MatTableDataSource<Receita> = new MatTableDataSource<Receita>();
  private _lenthPaginator: number;

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private processoService: ProcessoService
  ) { }

  public existReceita(idReceita: string): Observable<boolean> {
    return this.http.post<boolean>(`${SGS_URL.CORE}/${CORE.RECEITA}/exists`, { id: idReceita });
  }

  public downloadReceita(idReceita: string) {

    this.http.get(`${SGS_URL.CORE}/${CORE.RECEITA}/download/${idReceita}`,{responseType:'arraybuffer'}).subscribe(resp =>{
      let file =  new Blob([resp], {type: 'application/pdf'});
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL,'_blank');
    })
  }

  public cadastrarReceita(lsIdsPrescricoesSelecionadas: string[]): Observable<any> {
    let id_usuario = this.loginService.user.id;
    let id_referencia = this.loginService.user.referencia.id
    let formData: FormData = new FormData();

    formData.append('usuario', id_usuario);
    formData.append('referencia', id_referencia);
    formData.append('file', this.receita.file);
    formData.append('fileName', encodeURIComponent(this.receita.file_name));
    formData.append('descricao', encodeURIComponent(this.receita.descricao));
    formData.append('email_comaj', encodeURIComponent(this.receita.email_comaj));
    formData.append('prescricao', this.receita.prescricao ? this.receita.prescricao.id : null);
    formData.append('paciente', this.receita.paciente ? this.receita.paciente.id : null);
    formData.append('data_apresentacao', this.receita.data_apresentacao ? new Date(this.receita.data_apresentacao).valueOf().toString() : null);
    formData.append('data_receituario', this.receita.data_receituario ? new Date(this.receita.data_receituario).valueOf().toString() : null);
    formData.append('email_flag', this.receita.email_flag.toString());
    formData.append('lsIdsPrescricoesSelecionadas', lsIdsPrescricoesSelecionadas.toString());
    if(this.receita.itens_nao_cadastrados){formData.append('itens_nao_cadastrados', this.receita.itens_nao_cadastrados.toString());}
    else{formData.append('itens_nao_cadastrados', null);}
    
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.RECEITA}/cadastrar`, formData);
  }

  public deleteReceita(id_receita: string): Observable<any> {
    let id_usuario = this.loginService.user.id;
    let id_referencia = this.loginService.user.referencia.id
    return this.http.get<any>(`${SGS_URL.CORE}/${CORE.RECEITA}/s/${id_receita}/${id_usuario}/false/${id_referencia}`)
  }

  public listarReceitasByProcesso(id_processo: string): Observable<any> {
    return this.http.get<any>(`${SGS_URL.CORE}/${CORE.RECEITA}/getByProcesso/${id_processo}`)
  }

  public getNewReceita() {
    return new Receita(null, null, null, null, null, null, null, null, null, false, null);
  }

  public getAllReceitaByPrescricao(idPrescricao: string): Observable<ListaJSON<Receita>> {
    this._filterReceita.paciente = null;
    this._filterReceita.prescricao = idPrescricao;
    this._filterReceita.referencia = this.loginService.user.referencia.id;
    return this.http.post<ListaJSON<Receita>>(`${SGS_URL.CORE}/${CORE.RECEITA}/p/`, this._filterReceita)
  }

  public getAllReceitasByPaciente(idPaciente: string): Observable<ListaJSON<Receita>> {
    this._filterReceita.paciente = idPaciente;
    this._filterReceita.prescricao = null;
    this._filterReceita.referencia = this.loginService.user.referencia.id;
    return this.http.post<ListaJSON<Receita>>(`${SGS_URL.CORE}/${CORE.RECEITA}/p/`, this._filterReceita)
  }

  //cria um objeto de pesquisa vazio
  public getFilterReceita() {
    return {
      apresetacao: null,
      receituario: null,
      nome: null,
      paciente: null,
      prescricao: null,
      referencia: null,
      direction: "desc",
      sort: "data_cadastro",
      pageIndex: 0,
      pageSize: 5,
      data_inicial: null
    }
  }

  public get filterReceita() {
    return this._filterReceita;
  }
  public set filterReceita(value) {
    this._filterReceita = value;
  }
  public get receita(): Receita {
    return this._receita;
  }
  public set receita(value: Receita) {
    this._receita = value;
  }
  public get recallModalCadastro(): boolean {
    return this._recallModalCadastro;
  }
  public set recallModalCadastro(value: boolean) {
    this._recallModalCadastro = value;
  }
  public get recallModalPesquisa(): boolean {
    return this._recallModalPesquisa;
  }
  public set recallModalPesquisa(value: boolean) {
    this._recallModalPesquisa = value;
  }
  public get dataSourceReceitaPesquisa(): MatTableDataSource<Receita> {
    return this._dataSourceReceitaPesquisa;
  }
  public set dataSourceReceitaPesquisa(value: MatTableDataSource<Receita>) {
    this._dataSourceReceitaPesquisa = value;
  }
  public get lenthPaginator(): number {
    return this._lenthPaginator;
  }
  public set lenthPaginator(value: number) {
    this._lenthPaginator = value;
  }
  public get medicamentoReceita(): Material {
    return this._medicamentoReceita;
  }
  public set medicamentoReceita(value: Material) {
    this._medicamentoReceita = value;
  }

  public get eventUpdateReceita(): EventEmitter<boolean> {
    return this._eventUpdateReceita;
  }
  public set eventUpdateReceita(value: EventEmitter<boolean>) {
    this._eventUpdateReceita = value;
  }
  public get eventReloadModalReceita(): EventEmitter<boolean> {
    return this._eventReloadModalReceita;
  }
  public set eventReloadModalReceita(value: EventEmitter<boolean>) {
    this._eventReloadModalReceita = value;
  }

}
