import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LoginService } from "@app/core/security/login/login.service";
import { UnidadeSaudeService } from "@app/core/unidade-saude/unidade-saude.service";

@Component({
  selector: "ses-unidade-saude-multiselect",
  templateUrl: "./unidade-saude-multiselect.component.html",
  styleUrls: ["./unidade-saude-multiselect.component.scss"],
})
export class UnidadeSaudeMultiselectComponent implements OnInit {
  @Input() filtroNivel: boolean = true;
  @Input() required: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();
  @Input() selectionLimit: number = null;

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */
  @Output() idsSelecionadas = new EventEmitter<string[]>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any[]>();

  constructor(
    private loginService: LoginService,
    private unidadeService: UnidadeSaudeService
  ) {}

  public lsUnidade: any[] = [];
  public unidadeSelecionadas: any[] = [];

  ngOnInit() {
    if (this.filtroNivel) {
      this.lsUnidade = this.loginService.infoUser.unidades;
    } else {
      this.carregaUnidades();
    }
    this.limparEvent.subscribe({
      next: (resp) => {
        this.limpar();
      },
    });
  }

  public carregaUnidades() {
    this.unidadeService.getAllUnidadesSaude().subscribe({
      next: (resp) => {
        this.lsUnidade = resp;
      },
    });
  }

  public limpar() {
    this.unidadeSelecionadas = [];
    this.idsSelecionadas.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  selecionaRegional(event?: any) {
    let listIds: string[] = [];
    this.unidadeSelecionadas.forEach((regional) => listIds.push(regional.id));
    this.idsSelecionadas.emit(listIds);
    this.elementosCompletoSelecionadas.emit(this.unidadeSelecionadas);
  }
}
