import { JustificativaAnaliseTecnicaResponse } from './../../../models/justificativa-analise-tecnica-response';
import { JustificativaAnaliseTecnicaRequest } from '@app/core/shared/models/justificativa-analise-tecnica-request';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { JutificativaAnaliseTecnicaService } from "@app/core/nat/justificativa-analise-tecnica/jutificativa-analise-tecnica.service";
import { PosicaoAnaliseTecnicaService } from "@app/core/nat/posicao-analise-tecnica/posicao-analise-tecnica.service";
import { JustificativaAnaliseTecnicaFilter } from "@app/core/shared/models/justificativa-analise-tecnica-filter";
import { PosicaoAnaliseTecnicaFilter } from "@app/core/shared/models/posicao-analise-tecnica-filter";
import { PosicaoAnaliseTecnicaResponse } from "@app/core/shared/models/posicao-analise-tecnica-response";

@Component({
  selector: "ses-justificativa-analise-tecnica-autocomplete",
  templateUrl: "./justificativa-analise-tecnica-autocomplete.component.html",
  styleUrls: ["./justificativa-analise-tecnica-autocomplete.component.scss"],
})
export class JustificativaAnaliseTecnicaAutocompleteComponent
  implements OnInit
{
  @Input() required: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();
  @Input() justificativaSelecionada: JustificativaAnaliseTecnicaResponse = new JustificativaAnaliseTecnicaResponse();
  @Input() justificativaidSelecionada: string;
  @Input() id: string = "justificativa-analise-tecnica";
  @Input() idPosicao: string;
  @Input() idJustificativa: string;

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */

  @Output() idSelecionada = new EventEmitter<string>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any>();

  constructor(
    private justificativaAnaliseTecnicaService: JutificativaAnaliseTecnicaService
  ) {}

  public justificativaAnaliseTecnicaFilter: JustificativaAnaliseTecnicaFilter =
    new JustificativaAnaliseTecnicaFilter();

  public lsJustificativaAnaliseTecnica: JustificativaAnaliseTecnicaResponse[] = [];

  ngOnInit() {
    this.justificativaAnaliseTecnicaFilter.paginar = false;

    this.buscarDados();

    this.limparEvent.subscribe({
      next: () => {
        this.limpar();
      },
    });
  }

  public buscarDados() {
    if (this.idPosicao && this.justificativaidSelecionada == null) {
      this.justificativaAnaliseTecnicaFilter.id_posicao = this.idPosicao;
    }

    this.justificativaAnaliseTecnicaService
      .getJustificarAnaliseByFilter(this.justificativaAnaliseTecnicaFilter)
      .subscribe({
        next: (response) => {
          this.lsJustificativaAnaliseTecnica = response.list;

          if (this.justificativaidSelecionada) {
            this.justificativaSelecionada = this.lsJustificativaAnaliseTecnica.find((filter) => filter.id == +this.justificativaidSelecionada);
          }
        },
      });
  }

  public selecionarJustificativa() {
    if(this.justificativaSelecionada){
      this.idSelecionada.emit(this.justificativaSelecionada.id ? this.justificativaSelecionada.id.toString() : null);
        this.elementosCompletoSelecionadas.emit(this.justificativaSelecionada);
      }else{
        this.idSelecionada.emit(null);
        this.elementosCompletoSelecionadas.emit(this.justificativaSelecionada);
      }
  }

  public filter(name: string): any[] {
    let filterValue = name.toLowerCase();
    if (name !== "") {
      return this.lsJustificativaAnaliseTecnica.filter((option) =>
        option.descricao.toLowerCase().includes(filterValue)
      );
    }
    return this.lsJustificativaAnaliseTecnica;
  }

  public limpar() {
    if (this.justificativaidSelecionada) {
      this.justificativaidSelecionada = null;
      this.buscarDados();
    }
    this.justificativaSelecionada = null;
    this.idSelecionada.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  public displayFn(posicao: any) {
    return posicao ? posicao.descricao : "";
  }
}
