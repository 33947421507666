import { DefaultModel } from "../default/default.model";

export class DescricaoModel extends DefaultModel {

  constructor(
    id: string,
    public descricao: string,
    status: string) {
    super(id, status)
  }
}
