export class ToastModal {
  constructor(
    public titulo: string,
    public mensagem: string,
    public show: boolean,
    public tipoAviso: string,
    public timer: number,
    public corFundo: string,
    public icone: string
  ) {}
}