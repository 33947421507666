import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'doc'
})
export class DocPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (value == null) return value;
    else if (value.trim() == "") return value.trim();
    else {
      //retirando os caracteres especiais do texto
      let ch = /\^|~|\|\/|_|\?|,|\*|\.|\-/g;
      value = value.replace(ch, "");

      let doc = "";

      if (args != null) doc = args;
      
      if (doc == 'rg') {
        if (typeof value == 'string' && value.length == 7) return value.substring(0, 1) + "." + value.substring(1, 4) + "." + value.substring(4);
        else return value;
      }
      else if (doc == 'cpf') 
      {
        if (value.trim().length == 11) {
          return value.substring(0, 3) + "." +
            value.substring(3, 6) + "." +
            value.substring(6, 9) + "-" +
            value.substring(9);
        }
      }
      else if (doc == 'cnpj')
      {
        if (value.trim().length == 14) {
          return value.substring(0, 2) + "." +
            value.substring(2, 5) + "." +
            value.substring(5, 8) + "/" +
            value.substring(8, 12) + "-" +
            value.substring(12);
        }
      }

      else if (doc == '4-z')
      {       
        let qtd = (4 - value.length) > 0?  4 - value.length: 0;
        let pre = "";
        if(qtd > 0)
        {
          for(let x = 0; x < qtd ; x++)
          {
            pre += '0';            
          }
        }
        return pre + value;       
      }

      //separa o documento em conjunto de 3 com espaço entre o mesmo
      if (value.length > 0) {
        let x = 0;
        let returnString = "";
        while (x < value.length) {
          returnString += value.substring(x, x + 3) + " ";
          x += 3;
        }
        return returnString.trim();
      }
    }
  }
}
