import { Permissao } from './../grupo/permissao.model';
import { Injectable, EventEmitter } from "@angular/core"
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { LoginService } from "../security/login/login.service";
import { SGS_URL, CORE } from "@app/app.api";

@Injectable()
export class NavegacaoService {
  private _permissaoPaciente: Permissao;
  private _permissaoTipoAtendimento: Permissao;
  private _permissaoTipoMedicamento: Permissao;
  private _permissaoRegiao: Permissao;
  private _permissaoTipoRegistro: Permissao;
  private _permissaoOrigemMedicamento: Permissao;
  private _permissaoResponsavel: Permissao;
  private _permissaoUsuario: Permissao;
  private _permissaoPeriodicidade: Permissao;
  private _permissaoMedicamentosProcedimentos: Permissao;
  private _permissaoEspecialidade: Permissao;
  private _permissaoProfissionais: Permissao;
  private _permissaoGrupo: Permissao;
  private _permissaoUnidadeAssistencia: Permissao;
  private _permissaoTipoEstabelecimento: Permissao;
  private _permissaoTipoPrograma: Permissao;
  private _permissaoPrograma: Permissao;
  private _permissaoRegional: Permissao;
  private _permissaoParametros: Permissao;
  private _permissaoUnidadeSaude: Permissao;
  private _permissaoNivel: Permissao;
  private _permissaoContasBancarias: Permissao;
  private _permissaoCentroCusto: Permissao;
  private _permissaoMotivoDevolucao: Permissao;
  private _permissaoComarca: Permissao;
  private _permissaoProcesso: Permissao;
  private _permissaoSituacaoProcesso: Permissao;
  private _permissaoAtualizacaoPrescricao: Permissao;
  private _permissaoMotivoExclusao: Permissao;
  private _permissaoMotivoSequestro: Permissao;
  private _permissaoOriginDestino: Permissao;
  private _permissaoTipoSolicitacao: Permissao;
  private _permissaoTipoDocumento: Permissao;
  private _permissaoTipoFornecimento: Permissao;
  private _permissaoPrescricao: Permissao;
  private _permissaoGerarMapa: Permissao;
  private _permissaoParametrizacao: Permissao;
  private _permissaoTipoMapa: Permissao;
  private _permissaoConsultaEstoque: Permissao;
  private _permissaoDistribuicao: Permissao;
  private _permissaoRecebimento: Permissao;
  private _permissaoDispensacao: Permissao;
  private _permissaoDashbord: Permissao;
  private _permissaoRelatorioAlvara: Permissao;
  private _permissaoRelatorioDinamico: Permissao;
  private _permissaoRelatorioEstoque: Permissao;
  private _permissaoRelatorioHistoricoNat: Permissao;
  private _permissaoRelatorioMapa: Permissao;
  private _permissaoRelatorioPaciente: Permissao;
  private _permissaoRelatorioPrescricao: Permissao;
  private _permissaoRelatorioResidualGeral: Permissao;
  private _permissaoRelatorioObitos: Permissao;
  private _permissaoRelatorioValidadePermissao: Permissao;
  private _permissaoRelatorioRessarcimento: Permissao;
  private _permissaoRelatorioSequestro: Permissao;
  private _permissaoProtocolo: Permissao;
  private _permissaoRessarcimento: Permissao;
  private _permissaoSequestroValores: Permissao;
  private _permissaoDevolucaoValores: Permissao;
  private _permissaoAlvara: Permissao;
  private _permissaoRecibo: Permissao;
  private _permissaoReceita: Permissao;
  private _permissaoPrescricaoTuberculose;
  private _permissaoAlertPacienteNotReceive;
  private _permissaoAnalizeEstrategico;
  private _permissaoReferenciaMaterial: Permissao;
  private _permissaoTransferenciaEntreModulos: Permissao;
  private _permissaoDoacaoMedicamento: Permissao;
  private _permissaoRelatorioPrescricaoTuberculose: Permissao;
  private _permissaoRelatorioEstoqueSemDemanda: Permissao;
  private _permissaoRelatorioMedicamentosNaoRetirados: Permissao;
  private _permissaoRelatorioGuiaEntrada: Permissao;
  private _permissaoRelatorioMapaDetalhado: Permissao;
  private _permissaoRelatorioDispensacaoGeral: Permissao;
  private _permissaoAvisos: Permissao;
  private _permissaoAlertas: Permissao
  private _permissaoRelatorioMedicamentoProxVenc: Permissao;
  private _permissaoCid: Permissao;
  private _permissaoFluxoPrescricao: Permissao;
  private _permissaoCategoriaSituacao: Permissao;
  private _permissaoRelatorioEntregaMaterial: Permissao;
  private _permissaoRelatorioRecibosNaoInseridos: Permissao;
  private _permissaoEstoqueMinimo: Permissao;
  private _permissaoSituacaoProcedimento: Permissao;
  private _permissaoProcedimento: Permissao;
  private _permissaoAutorizadorGrupoMedicamento: Permissao;
  private _permissaoSaldoEstoqueBloquear: Permissao;
  private _permissaoSaldoEstoqueDesbloquear: Permissao;
  private _permissaoPacienteContato: Permissao;
  private _permissaoRelatorioSaldoBloqueado: Permissao;
  private _permissaoRelatorioPacienteSemDispensacao: Permissao;
  private _permissaoComsus: Permissao;
  private _permissaoRelatorioComsus: Permissao;
  private _permissaoInventarioContagem: Permissao;
  private _permissaoInventarioAprovacao: Permissao;
  private _permissaoInventarioListagemEstabelecimento: Permissao;
  private _permissaoRelatorioInventario: Permissao;
  private _permissaoRelatorioEstoqueMaiorQueConsumo: Permissao;
  private _permissaoRelatorioContracautela: Permissao;
  private _permissaoRelatorioGuiasDistribuicao: Permissao;
  private _permissaoItensEmMovimentacao: Permissao;
  private _permissaoExtratoMaterial: Permissao;
  private _permissaoCadastroParametroAnaliseTecnica: Permissao;
  private _permissaoParametrizarQuestoesAnalise: Permissao;
  private _permissaoAnaliseTecnica: Permissao;
  private _permissaoRelatorioAnaliseTecnica: Permissao;
  private _permissaoConfigurarParametroModulos: Permissao;
  private _permissaoAnaliseMaterial: Permissao;
  private _permissaoRelatorioComprovacaoAtendimento: Permissao;
  private _permissaoRelatorioLivroControlados: Permissao;


  private _eventPermissao: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _isLoading = false;

  public copiarDados: boolean;

  constructor(private http: HttpClient, private loginService: LoginService) { }

  getPermissoes(): Observable<Permissao[]> {
    return this.http.post<Permissao[]>(`${SGS_URL.CORE}/${CORE.PERMISSAO}/permissoes_usuario/`, { usuario: this.loginService.user.id, referencia: this.loginService.user.referencia.id });
  }

  public resetPermissoes() {
    this._permissaoPaciente            = new Permissao("", "1", null, false, false, false, false, null, null);
    this._permissaoSituacaoProcesso    = new Permissao("", "2", null, false, false, false, false, null, null);
    this._permissaoTipoAtendimento     = new Permissao("", "3", null, false, false, false, false, null, null);
    this._permissaoTipoMedicamento     = new Permissao("", "4", null, false, false, false, false, null, null);
    this._permissaoRegiao              = new Permissao("", "5", null, false, false, false, false, null, null);
    this._permissaoTipoRegistro        = new Permissao("", "6", null, false, false, false, false, null, null);
    this._permissaoOrigemMedicamento   = new Permissao("", "7", null, false, false, false, false, null, null);
    this._permissaoTipoDocumento       = new Permissao("", "8", null, false, false, false, false, null, null);
    this._permissaoResponsavel         = new Permissao("", "9", null, false, false, false, false, null, null);
    this._permissaoUsuario             = new Permissao("", "10", null, false, false, false, false, null, null);
    this._permissaoTipoSolicitacao     = new Permissao("", "11", null, false, false, false, false, null, null);
    this._permissaoPeriodicidade       = new Permissao("", "12", null, false, false, false, false, null, null);
    this._permissaoMedicamentosProcedimentos = new Permissao("", "13", null, false, false, false, false, null, null);
    this._permissaoEspecialidade       = new Permissao("", "14", null, false, false, false, false, null, null);
    this._permissaoProfissionais       = new Permissao("", "15", null, false, false, false, false, null, null);
    this._permissaoGrupo               = new Permissao("", "16", null, false, false, false, false, null, null);
    this._permissaoOriginDestino       = new Permissao("", "17", null, false, false, false, false, null, null);
    this._permissaoUnidadeAssistencia  = new Permissao("", "18", null, false, false, false, false, null, null);
    this._permissaoTipoEstabelecimento = new Permissao("", "19", null, false, false, false, false, null, null);
    this._permissaoTipoPrograma        = new Permissao("", "20", null, false, false, false, false, null, null);
    this._permissaoPrograma            = new Permissao("", "21", null, false, false, false, false, null, null);
    this._permissaoProcesso            = new Permissao("", "22", null, false, false, false, false, null, null);
    this._permissaoPrescricao          = new Permissao("", "23", null, false, false, false, false, null, null);
    this._permissaoProtocolo           = new Permissao("", "24", null, false, false, false, false, null, null);
    this._permissaoRegional            = new Permissao("", "25", null, false, false, false, false, null, null);
    this._permissaoParametros          = new Permissao("", "27", null, false, false, false, false, null, null);
    this._permissaoUnidadeSaude        = new Permissao("", "29", null, false, false, false, false, null, null);
    this._permissaoNivel               = new Permissao("", "30", null, false, false, false, false, null, null);
    this._permissaoRessarcimento       = new Permissao("", "31", null, false, false, false, false, null, null);
    this._permissaoMotivoExclusao      = new Permissao("", "32", null, false, false, false, false, null, null);
    this._permissaoReceita             = new Permissao("", "33", null, false, false, false, false, null, null);
    this._permissaoTipoMapa            = new Permissao("", "39", null, false, false, false, false, null, null);
    this._permissaoRecebimento         = new Permissao("", "41", null, false, false, false, false, null, null);
    this._permissaoDistribuicao        = new Permissao("", "42", null, false, false, false, false, null, null);
    this._permissaoDispensacao         = new Permissao("", "46", null, false, false, false, false, null, null);
    this._permissaoRecibo              = new Permissao("", "47", null, false, false, false, false, null, null);
    this._permissaoParametrizacao      = new Permissao("", "48", null, false, false, false, false, null, null);
    this._permissaoTipoFornecimento    = new Permissao("", "49", null, false, false, false, false, null, null);
    this._permissaoContasBancarias     = new Permissao("", "50", null, false, false, false, false, null, null);
    this._permissaoSequestroValores    = new Permissao("", "51", null, false, false, false, false, null, null);
    this._permissaoAlvara              = new Permissao("", "52", null, false, false, false, false, null, null);
    this._permissaoDevolucaoValores    = new Permissao("", "53", null, false, false, false, false, null, null);
    this._permissaoGerarMapa           = new Permissao("", "54", null, false, false, false, false, null, null);
    this._permissaoMotivoSequestro     = new Permissao("", "55", null, false, false, false, false, null, null);
    this._permissaoCentroCusto         = new Permissao("", "56", null, false, false, false, false, null, null);
    this._permissaoMotivoDevolucao     = new Permissao("", "61", null, false, false, false, false, null, null);
    this._permissaoDashbord            = new Permissao("", "62", null, false, false, false, false, null, null);
    this._permissaoRelatorioAlvara     = new Permissao("", "63", null, false, false, false, false, null, null);
    this._permissaoConsultaEstoque     = new Permissao("", "64", null, false, false, false, false, null, null);
    this._permissaoRelatorioDinamico   = new Permissao("", "65", null, false, false, false, false, null, null);
    this._permissaoRelatorioEstoque    = new Permissao("", "66", null, false, false, false, false, null, null);
    this._permissaoRelatorioHistoricoNat = new Permissao("", "67", null, false, false, false, false, null, null);
    this._permissaoRelatorioMapa       = new Permissao("", "68", null, false, false, false, false, null, null);
    this._permissaoRelatorioPaciente   = new Permissao("", "69", null, false, false, false, false, null, null);
    this._permissaoRelatorioPrescricao = new Permissao("", "70", null, false, false, false, false, null, null);
    this._permissaoRelatorioResidualGeral = new Permissao("", "71", null, false, false, false, false, null, null);
    this._permissaoRelatorioRessarcimento = new Permissao("", "72", null, false, false, false, false, null, null);
    this._permissaoRelatorioSequestro  = new Permissao("", "73", null, false, false, false, false, null, null);
    this._permissaoAtualizacaoPrescricao = new Permissao("", "74", null, false, false, false, false, null, null);
    this._permissaoAlertPacienteNotReceive = new Permissao("", "75", null, false, false, false, false, null, null);
    this._permissaoComarca             = new Permissao("", "76", null, false, false, false, false, null, null);
    this._permissaoRelatorioObitos     = new Permissao("", "77", null, false, false, false, false, null, null);
    this._permissaoRelatorioValidadePermissao = new Permissao("", "78", null, false, false, false, false, null, null);
    this._permissaoPrescricaoTuberculose = new Permissao("", "79", null, false, false, false, false, null, null);
    this._permissaoAnalizeEstrategico  = new Permissao("", "80", null, false, false, false, false, null, null);
    this._permissaoReferenciaMaterial  = new Permissao("", "81", null, false, false, false, false, null, null);
    this._permissaoTransferenciaEntreModulos  = new Permissao("", "83", null, false, false, false, false, null, null);
    this._permissaoDoacaoMedicamento  = new Permissao("", "84", null, false, false, false, false, null, null);
    this._permissaoRelatorioPrescricaoTuberculose  = new Permissao("", "85", null, false, false, false, false, null, null);
    this._permissaoRelatorioEstoqueSemDemanda  = new Permissao("", "87", null, false, false, false, false, null, null);
    this._permissaoRelatorioMedicamentosNaoRetirados  = new Permissao("", "88", null, false, false, false, false, null, null);
    this._permissaoRelatorioMapaDetalhado  = new Permissao("", "89", null, false, false, false, false, null, null);
    this._permissaoRelatorioGuiaEntrada = new Permissao("", "90", null, false, false, false, false, null, null);
    this._permissaoRelatorioDispensacaoGeral = new Permissao("", "91", null, false, false, false, false, null, null);
    this._permissaoAvisos = new Permissao("", "92", null, false, false, false, false, null, null);
    this._permissaoAlertas = new Permissao("", "93", null, false, false, false, false, null, null);
    this._permissaoRelatorioMedicamentoProxVenc = new Permissao("", "94", null, true, true, true, true, null, null);
    this._permissaoCid = new Permissao("", "95", null, true, true, true, true, null, null);
    this._permissaoCategoriaSituacao = new Permissao("", "96", null, false, false, false, false, null, null);
    this._permissaoFluxoPrescricao = new Permissao("", "97", null, false, false, false, false, null, null);
    this._permissaoRelatorioEntregaMaterial = new Permissao("", "98", null, false, false, false, false, null, null);
    this._permissaoRelatorioRecibosNaoInseridos = new Permissao("", "99", null, false, false, false, false, null, null);
    this._permissaoEstoqueMinimo = new Permissao("", "100", null, false, false, false, false, null, null);
    this._permissaoSituacaoProcedimento = new Permissao("", "101", null, false, false, false, false, null, null);
    this._permissaoProcedimento = new Permissao("", "102", null, false, false, false, false, null, null);
    this._permissaoAutorizadorGrupoMedicamento = new Permissao("", "103", null, false, false, false, false, null, null);
    this._permissaoSaldoEstoqueBloquear = new Permissao("", "104", null, false, false, false, false, null, null);
    this._permissaoSaldoEstoqueDesbloquear = new Permissao("", "105", null, false, false, false, false, null, null);
    this._permissaoPacienteContato = new Permissao("", "106", null, false, false, false, false, null, null);
    this._permissaoRelatorioSaldoBloqueado = new Permissao("", "107", null, false, false, false, false, null, null);
    this._permissaoRelatorioPacienteSemDispensacao = new Permissao("", "108", null, false, false, false, false, null, null);
    this._permissaoComsus = new Permissao("", "109", null, false, false, false, false, null, null);
    this._permissaoRelatorioComsus = new Permissao("", "110", null, false, false, false, false, null, null);
    this._permissaoInventarioContagem = new Permissao("", "111", null, false, false, false, false, null, null);
    this._permissaoInventarioAprovacao = new Permissao("", "112", null, false, false, false, false, null, null);
    this._permissaoInventarioListagemEstabelecimento = new Permissao("", "113", null, false, false, false, false, null, null);
    this._permissaoRelatorioInventario = new Permissao("", "114", null, false, false, false, false, null, null);
    this._permissaoRelatorioEstoqueMaiorQueConsumo = new Permissao("", "115", null, false, false, false, false, null, null);
    this._permissaoRelatorioContracautela = new Permissao("", "116", null, false, false, false, false, null, null);
    this._permissaoRelatorioGuiasDistribuicao = new Permissao("", "117", null, false, false, false, false, null, null);
    this._permissaoItensEmMovimentacao = new Permissao("", "118", null, false, false, false, false, null, null);
    this._permissaoExtratoMaterial = new Permissao("", "119", null, false, false, false, false, null, null);
    this._permissaoCadastroParametroAnaliseTecnica = new Permissao("", "120", null, false, false, false, false, null, null);
    this._permissaoParametrizarQuestoesAnalise = new Permissao("", "121", null, false, false, false, false, null, null);
    this._permissaoAnaliseTecnica = new Permissao("", "122", null, false, false, false, false, null, null);
    this._permissaoRelatorioAnaliseTecnica = new Permissao("", "123", null, false, false, false, false, null, null);
    this._permissaoConfigurarParametroModulos = new Permissao("", "124", null, false, false, false, false, null, null);
    this._permissaoAnaliseMaterial = new Permissao("", "125", null, false, false, false, false, null, null);
    this._permissaoRelatorioComprovacaoAtendimento = new Permissao("", "126", null, false, false, false, false, null, null);
    this._permissaoRelatorioLivroControlados = new Permissao("", "127", null, false, false, false, false, null, null);

  }

  public get isLoading() {
    return this._isLoading;
  }
  public set isLoading(value) {
    this._isLoading = value;
  }
  public get eventPermissao(): EventEmitter<boolean> {
    return this._eventPermissao;
  }
  public set eventPermissao(value: EventEmitter<boolean>) {
    this._eventPermissao = value;
  }
  public get permissaoPaciente(): Permissao {
    return this._permissaoPaciente;
  }
  public set permissaoPaciente(value: Permissao) {
    this._permissaoPaciente = value;
  }
  public get permissaoGrupo(): Permissao {
    return this._permissaoGrupo;
  }
  public set permissaoGrupo(value: Permissao) {
    this._permissaoGrupo = value;
  }
  public get permissaoUsuario(): Permissao {
    return this._permissaoUsuario;
  }
  public set permissaoUsuario(value: Permissao) {
    this._permissaoUsuario = value;
  }
  public get permissaoNivel(): Permissao {
    return this._permissaoNivel;
  }
  public set permissaoNivel(value: Permissao) {
    this._permissaoNivel = value;
  }
  public get permissaoRegiao(): Permissao {
    return this._permissaoRegiao;
  }
  public set permissaoRegiao(value: Permissao) {
    this._permissaoRegiao = value;
  }
  public get permissaoRegional(): Permissao {
    return this._permissaoRegional;
  }
  public set permissaoRegional(value: Permissao) {
    this._permissaoRegional = value;
  }
  public get permissaoUnidadeSaude(): Permissao {
    return this._permissaoUnidadeSaude;
  }
  public set permissaoUnidadeSaude(value: Permissao) {
    this._permissaoUnidadeSaude = value;
  }
  public get permissaoUnidadeAssistencia(): Permissao {
    return this._permissaoUnidadeAssistencia;
  }
  public set permissaoUnidadeAssistencia(value: Permissao) {
    this._permissaoUnidadeAssistencia = value;
  }
  public get permissaoParametros(): Permissao {
    return this._permissaoParametros;
  }
  public set permissaoParametros(value: Permissao) {
    this._permissaoParametros = value;
  }
  public get permissaoCentroCusto(): Permissao {
    return this._permissaoCentroCusto;
  }
  public set permissaoCentroCusto(value: Permissao) {
    this._permissaoCentroCusto = value;
  }
  public get permissaoComarca(): Permissao {
    return this._permissaoComarca;
  }
  public set permissaoComarca(value: Permissao) {
    this._permissaoComarca = value;
  }
  public get permissaoMedicamentosProcedimentos(): Permissao {
    return this._permissaoMedicamentosProcedimentos;
  }
  public set permissaoMedicamentosProcedimentos(value: Permissao) {
    this._permissaoMedicamentosProcedimentos = value;
  }
  public get permissaoMotivoDevolucao(): Permissao {
    return this._permissaoMotivoDevolucao;
  }
  public set permissaoMotivoDevolucao(value: Permissao) {
    this._permissaoMotivoDevolucao = value;
  }
  public get permissaoOrigemMedicamento(): Permissao {
    return this._permissaoOrigemMedicamento;
  }
  public set permissaoOrigemMedicamento(value: Permissao) {
    this._permissaoOrigemMedicamento = value;
  }
  public get permissaoPeriodicidade(): Permissao {
    return this._permissaoPeriodicidade;
  }
  public set permissaoPeriodicidade(value: Permissao) {
    this._permissaoPeriodicidade = value;
  }
  public get permissaoProfissionais(): Permissao {
    return this._permissaoProfissionais;
  }
  public set permissaoProfissionais(value: Permissao) {
    this._permissaoProfissionais = value;
  }
  public get permissaoResponsavel(): Permissao {
    return this._permissaoResponsavel;
  }
  public set permissaoResponsavel(value: Permissao) {
    this._permissaoResponsavel = value;
  }
  public get permissaoTipoAtendimento(): Permissao {
    return this._permissaoTipoAtendimento;
  }
  public set permissaoTipoAtendimento(value: Permissao) {
    this._permissaoTipoAtendimento = value;
  }
  public get permissaoEspecialidade(): Permissao {
    return this._permissaoEspecialidade;
  }
  public set permissaoEspecialidade(value: Permissao) {
    this._permissaoEspecialidade = value;
  }
  public get permissaoTipoEstabelecimento(): Permissao {
    return this._permissaoTipoEstabelecimento;
  }
  public set permissaoTipoEstabelecimento(value: Permissao) {
    this._permissaoTipoEstabelecimento = value;
  }
  public get permissaoTipoMedicamento(): Permissao {
    return this._permissaoTipoMedicamento;
  }
  public set permissaoTipoMedicamento(value: Permissao) {
    this._permissaoTipoMedicamento = value;
  }
  public get permissaoTipoPrograma(): Permissao {
    return this._permissaoTipoPrograma;
  }
  public set permissaoTipoPrograma(value: Permissao) {
    this._permissaoTipoPrograma = value;
  }

  public get permissaoPrograma() {
    return this._permissaoPrograma;
  }
  public set permissaoPrograma(value: Permissao) {
    this._permissaoPrograma = value;
  }
  public get permissaoTipoRegistro(): Permissao {
    return this._permissaoTipoRegistro;
  }
  public set permissaoTipoRegistro(value: Permissao) {
    this._permissaoTipoRegistro = value;
  }
  public get permissaoContasBancarias(): Permissao {
    return this._permissaoContasBancarias;
  }
  public set permissaoContasBancarias(value: Permissao) {
    this._permissaoContasBancarias = value;
  }
  public get permissaoProcesso(): Permissao {
    return this._permissaoProcesso;
  }
  public set permissaoProcesso(value: Permissao) {
    this._permissaoProcesso = value;
  }
  public get permissaoSituacaoProcesso(): Permissao {
    return this._permissaoSituacaoProcesso;
  }
  public set permissaoSituacaoProcesso(value: Permissao) {
    this._permissaoSituacaoProcesso = value;
  }
  public get permissaoAtualizacaoPrescricao(): Permissao {
    return this._permissaoAtualizacaoPrescricao;
  }
  public set permissaoAtualizacaoPrescricao(value: Permissao) {
    this._permissaoAtualizacaoPrescricao = value;
  }
  public get permissaoMotivoExclusao(): Permissao {
    return this._permissaoMotivoExclusao;
  }
  public set permissaoMotivoExclusao(value: Permissao) {
    this._permissaoMotivoExclusao = value;
  }
  public get permissaoMotivoSequestro(): Permissao {
    return this._permissaoMotivoSequestro;
  }
  public set permissaoMotivoSequestro(value: Permissao) {
    this._permissaoMotivoSequestro = value;
  }
  public get permissaoOriginDestino(): Permissao {
    return this._permissaoOriginDestino;
  }
  public set permissaoOriginDestino(value: Permissao) {
    this._permissaoOriginDestino = value;
  }
  public get permissaoTipoSolicitacao(): Permissao {
    return this._permissaoTipoSolicitacao;
  }
  public set permissaoTipoSolicitacao(value: Permissao) {
    this._permissaoTipoSolicitacao = value;
  }
  public get permissaoTipoDocumento(): Permissao {
    return this._permissaoTipoDocumento;
  }
  public set permissaoTipoDocumento(value: Permissao) {
    this._permissaoTipoDocumento = value;
  }
  public get permissaoTipoFornecimento(): Permissao {
    return this._permissaoTipoFornecimento;
  }
  public set permissaoTipoFornecimento(value: Permissao) {
    this._permissaoTipoFornecimento = value;
  }
  public get permissaoPrescricao(): Permissao {
    return this._permissaoPrescricao;
  }
  public set permissaoPrescricao(value: Permissao) {
    this._permissaoPrescricao = value;
  }
  public get permissaoGerarMapa(): Permissao {
    return this._permissaoGerarMapa;
  }
  public set permissaoGerarMapa(value: Permissao) {
    this._permissaoGerarMapa = value;
  }
  public get permissaoParametrizacao(): Permissao {
    return this._permissaoParametrizacao;
  }
  public set permissaoParametrizacao(value: Permissao) {
    this._permissaoParametrizacao = value;
  }
  public get permissaoTipoMapa(): Permissao {
    return this._permissaoTipoMapa;
  }
  public set permissaoTipoMapa(value: Permissao) {
    this._permissaoTipoMapa = value;
  }
  public get permissaoConsultaEstoque(): Permissao {
    return this._permissaoConsultaEstoque;
  }
  public set permissaoConsultaEstoque(value: Permissao) {
    this._permissaoConsultaEstoque = value;
  }
  public get permissaoDistribuicao(): Permissao {
    return this._permissaoDistribuicao;
  }
  public set permissaoDistribuicao(value: Permissao) {
    this._permissaoDistribuicao = value;
  }
  public get permissaoRecebimento(): Permissao {
    return this._permissaoRecebimento;
  }
  public set permissaoRecebimento(value: Permissao) {
    this._permissaoRecebimento = value;
  }
  public get permissaoDispensacao(): Permissao {
    return this._permissaoDispensacao;
  }
  public set permissaoDispensacao(value: Permissao) {
    this._permissaoDispensacao = value;
  }
  public get permissaoDashbord(): Permissao {
    return this._permissaoDashbord;
  }
  public set permissaoDashbord(value: Permissao) {
    this._permissaoDashbord = value;
  }
  public get permissaoRelatorioAlvara(): Permissao {
    return this._permissaoRelatorioAlvara;
  }
  public set permissaoRelatorioAlvara(value: Permissao) {
    this._permissaoRelatorioAlvara = value;
  }
  public get permissaoRelatorioDinamico(): Permissao {
    return this._permissaoRelatorioDinamico;
  }
  public set permissaoRelatorioDinamico(value: Permissao) {
    this._permissaoRelatorioDinamico = value;
  }
  public get permissaoRelatorioEstoque(): Permissao {
    return this._permissaoRelatorioEstoque;
  }
  public set permissaoRelatorioEstoque(value: Permissao) {
    this._permissaoRelatorioEstoque = value;
  }
  public get permissaoRelatorioHistoricoNat(): Permissao {
    return this._permissaoRelatorioHistoricoNat;
  }
  public set permissaoRelatorioHistoricoNat(value: Permissao) {
    this._permissaoRelatorioHistoricoNat = value;
  }
  public get permissaoRelatorioMapa(): Permissao {
    return this._permissaoRelatorioMapa;
  }
  public set permissaoRelatorioMapa(value: Permissao) {
    this._permissaoRelatorioMapa = value;
  }
  public get permissaoRelatorioPaciente(): Permissao {
    return this._permissaoRelatorioPaciente;
  }
  public set permissaoRelatorioPaciente(value: Permissao) {
    this._permissaoRelatorioPaciente = value;
  }
  public get permissaoRelatorioPrescricao(): Permissao {
    return this._permissaoRelatorioPrescricao;
  }
  public set permissaoRelatorioPrescricao(value: Permissao) {
    this._permissaoRelatorioPrescricao = value;
  }
  public get permissaoRelatorioResidualGeral(): Permissao {
    return this._permissaoRelatorioResidualGeral;
  }
  public set permissaoRelatorioResidualGeral(value: Permissao) {
    this._permissaoRelatorioResidualGeral = value;
  }
  public get permissaoRelatorioObitos(): Permissao {
    return this._permissaoRelatorioObitos;
  }
  public set permissaoRelatorioObitos(value: Permissao) {
    this._permissaoRelatorioObitos = value;
  }
  public get permissaoRelatorioValidadePermissao(): Permissao {
    return this._permissaoRelatorioValidadePermissao;
  }
  public set permissaoRelatorioValidadePermissao(value: Permissao) {
    this._permissaoRelatorioValidadePermissao = value;
  }
  public get permissaoRelatorioRessarcimento(): Permissao {
    return this._permissaoRelatorioRessarcimento;
  }
  public set permissaoRelatorioRessarcimento(value: Permissao) {
    this._permissaoRelatorioRessarcimento = value;
  }
  public get permissaoRelatorioSequestro(): Permissao {
    return this._permissaoRelatorioSequestro;
  }
  public set permissaoRelatorioSequestro(value: Permissao) {
    this._permissaoRelatorioSequestro = value;
  }
  public get permissaoProtocolo(): Permissao {
    return this._permissaoProtocolo;
  }
  public set permissaoProtocolo(value: Permissao) {
    this._permissaoProtocolo = value;
  }
  public get permissaoRessarcimento(): Permissao {
    return this._permissaoRessarcimento;
  }
  public set permissaoRessarcimento(value: Permissao) {
    this._permissaoRessarcimento = value;
  }
  public get permissaoSequestroValores(): Permissao {
    return this._permissaoSequestroValores;
  }
  public set permissaoSequestroValores(value: Permissao) {
    this._permissaoSequestroValores = value;
  }
  public get permissaoDevolucaoValores(): Permissao {
    return this._permissaoDevolucaoValores;
  }
  public set permissaoDevolucaoValores(value: Permissao) {
    this._permissaoDevolucaoValores = value;
  }
  public get permissaoAlvara(): Permissao {
    return this._permissaoAlvara;
  }
  public set permissaoAlvara(value: Permissao) {
    this._permissaoAlvara = value;
  }
  public get permissaoRecibo(): Permissao {
    return this._permissaoRecibo;
  }
  public set permissaoRecibo(value: Permissao) {
    this._permissaoRecibo = value;
  }
  public get permissaoReceita(): Permissao {
    return this._permissaoReceita;
  }
  public set permissaoReceita(value: Permissao) {
    this._permissaoReceita = value;
  }
  public get permissaoPrescricaoTuberculose() {
    return this._permissaoPrescricaoTuberculose;
  }
  public set permissaoPrescricaoTuberculose(value) {
    this._permissaoPrescricaoTuberculose = value;
  }
  public get permissaoAlertPacienteNotReceive() {
    return this._permissaoAlertPacienteNotReceive;
  }
  public set permissaoAlertPacienteNotReceive(value) {
    this._permissaoAlertPacienteNotReceive = value;
  }
  public get permissaoAnalizeEstrategico(): Permissao {
    return this._permissaoAnalizeEstrategico;
  }
  public set permissaoAnalizeEstrategico(value: Permissao) {
    this._permissaoAnalizeEstrategico = value;
  }
  public get permissaoReferenciaMaterial(): Permissao {
    return this._permissaoReferenciaMaterial;
  }
  public set permissaoReferenciaMaterial(value: Permissao) {
    this._permissaoReferenciaMaterial = value;
  }
  public get permissaoTransferenciaEntreModulos(): Permissao {
    return this._permissaoTransferenciaEntreModulos;
  }
  public set permissaoTransferenciaEntreModulos(value: Permissao) {
    this._permissaoTransferenciaEntreModulos = value;
  }
  public get permissaoDoacaoMedicamento(): Permissao {
    return this._permissaoDoacaoMedicamento;
  }
  public set permissaoDoacaoMedicamento(value: Permissao) {
    this._permissaoDoacaoMedicamento = value;
  }
  public get permissaoRelatorioPrescricaoTuberculose(): Permissao {
    return this._permissaoRelatorioPrescricaoTuberculose;
  }
  public set permissaoRelatorioPrescricaoTuberculose(value: Permissao) {
    this._permissaoRelatorioPrescricaoTuberculose = value;
  }
  public get permissaoRelatorioEstoqueSemDemanda(): Permissao {
    return this._permissaoRelatorioEstoqueSemDemanda;
  }
  public set permissaoRelatorioEstoqueSemDemanda(value: Permissao) {
    this._permissaoRelatorioEstoqueSemDemanda = value;
  }
  public get permissaoRelatorioSaldoBloqueado(): Permissao {
    return this._permissaoRelatorioSaldoBloqueado;
  }
  public set permissaoRelatorioSaldoBloqueado(value: Permissao) {
    this._permissaoRelatorioSaldoBloqueado = value;
  }
  public get permissaoRelatorioMedicamentosNaoRetirados(): Permissao {
    return this._permissaoRelatorioMedicamentosNaoRetirados;
  }
  public set permissaoRelatorioMedicamentosNaoRetirados(value: Permissao) {
    this._permissaoRelatorioMedicamentosNaoRetirados = value;
  }
  public get permissaoRelatorioGuiaEntrada(): Permissao{
    return this._permissaoRelatorioGuiaEntrada;
  }
  public set permissaoRelatorioGuiaEntrada(value: Permissao){
    this._permissaoRelatorioGuiaEntrada = value;
  }
  public get permissaoRelatorioMapaDetalhado(): Permissao {
    return this._permissaoRelatorioMapaDetalhado;
  }
  public set permissaoRelatorioMapaDetalhado(value: Permissao) {
    this._permissaoRelatorioMapaDetalhado = value;
  }
  public get permissaoRelatorioDispensacaoGeral(): Permissao {
    return this._permissaoRelatorioDispensacaoGeral;
  }
  public set permissaoRelatorioDispensacaoGeral(value: Permissao) {
    this._permissaoRelatorioDispensacaoGeral = value;
  }
  public get permissaoAvisos(): Permissao {
    return this._permissaoAvisos;
  }
  public set permissaoAvisos(value: Permissao) {
    this._permissaoAvisos = value;
  }
  public get permissaoAlertas(): Permissao {
    return this._permissaoAlertas;
  }
    public set permissaoAlertas(value : Permissao) {
    this._permissaoAlertas = value;
  }
  public get permissaoRelatorioMedicamentoProxVenc(): Permissao{
    return this._permissaoRelatorioMedicamentoProxVenc;
  }
  public set permissaoRelatorioMedicamentoProxVenc(value: Permissao){
    this._permissaoRelatorioMedicamentoProxVenc = value;
  }
  public get permissaoCid(): Permissao{
    return this._permissaoCid;
  }
  public set permissaoCid(value: Permissao){
    this._permissaoCid = value;
  }
  public get permissaoCategoriaSituacao(): Permissao{
    return this._permissaoCategoriaSituacao;
  }
  public set permissaoCategoriaSituacao(value: Permissao){
    this._permissaoCategoriaSituacao = value;
  }
  public get permissaoFluxoPrescricao(): Permissao{
    return this._permissaoFluxoPrescricao;
  }
  public set permissaoFluxoPrescricao(value: Permissao){
    this._permissaoFluxoPrescricao = value;
  }
  public get permissaoRelatorioEntregaMaterial(): Permissao{
    return this._permissaoRelatorioEntregaMaterial;
  }
  public set permissaoRelatorioEntregaMaterial(value: Permissao){
    this._permissaoRelatorioEntregaMaterial = value;
  }
  public get permissaoRelatorioRecibosNaoInseridos(): Permissao{
    return this._permissaoRelatorioRecibosNaoInseridos;
  }
  public set permissaoRelatorioRecibosNaoInseridos(value: Permissao){
    this._permissaoRelatorioRecibosNaoInseridos = value;
  }
  public get permissaoEstoqueMinimo(): Permissao{
    return this._permissaoEstoqueMinimo;
  }
  public set permissaoEstoqueMinimo(value: Permissao){
    this._permissaoEstoqueMinimo = value;
  }
  public get permissaoSituacaoProcedimento(): Permissao{
    return this._permissaoSituacaoProcedimento;
  }
  public set permissaoSituacaoProcedimento(value: Permissao){
    this._permissaoSituacaoProcedimento = value;
  }
  public get permissaoProcedimento(): Permissao{
    return this._permissaoProcedimento;
  }
  public set permissaoProcedimento(value: Permissao){
    this._permissaoProcedimento = value;
  }
  public get permissaoAutorizadorGrupoMedicamento(): Permissao{
    return this._permissaoAutorizadorGrupoMedicamento;
  }
  public set permissaoAutorizadorGrupoMedicamento(value: Permissao){
    this._permissaoAutorizadorGrupoMedicamento = value;
  }
  public get permissaoSaldoEstoqueBloquear(): Permissao{
    return this._permissaoSaldoEstoqueBloquear;
  }
  public set permissaoSaldoEstoqueBloquear(value: Permissao){
    this._permissaoSaldoEstoqueBloquear = value;
  }
  public get permissaoSaldoEstoqueDesbloquear(): Permissao{
    return this._permissaoSaldoEstoqueDesbloquear;
  }
  public set permissaoSaldoEstoqueDesbloquear(value: Permissao){
    this._permissaoSaldoEstoqueDesbloquear = value;
  }
  public get permissaoPacienteContato(): Permissao{
    return this._permissaoPacienteContato;
  }
  public set permissaoPacienteContato(value: Permissao){
    this._permissaoPacienteContato = value;
  }
  public get permissaoRelatorioPacienteSemDispensacao(): Permissao {
    return this._permissaoRelatorioPacienteSemDispensacao;
  }
  public set permissaoRelatorioPacienteSemDispensacao(value: Permissao) {
    this._permissaoRelatorioPacienteSemDispensacao = value;
  }
  public get permissaoComsus(): Permissao {
    return this._permissaoComsus;
  }
  public set permissaoComsus(value: Permissao) {
    this._permissaoComsus = value;
  }
  public get permissaoRelatorioComsus(): Permissao {
    return this._permissaoRelatorioComsus;
  }
  public set permissaoRelatorioComsus(value: Permissao) {
    this._permissaoRelatorioComsus = value;
  }
  public get permissaoInventarioContagem(): Permissao {
    return this._permissaoInventarioContagem;
  }
  public set permissaoInventarioContagem(value: Permissao) {
    this._permissaoInventarioContagem = value;
  }
  public get permissaoInventarioAprovacao(): Permissao {
    return this._permissaoInventarioAprovacao;
  }
  public set permissaoInventarioAprovacao(value: Permissao) {
    this._permissaoInventarioAprovacao = value;
  }
  public get permissaoInventarioListagemEstabelecimento(): Permissao {
    return this._permissaoInventarioListagemEstabelecimento;
  }
  public set permissaoInventarioListagemEstabelecimento(value: Permissao) {
    this._permissaoInventarioListagemEstabelecimento = value;
  }
  public get permissaoRelatorioInventario(): Permissao {
    return this._permissaoRelatorioInventario;
  }
  public set permissaoRelatorioInventario(value: Permissao) {
    this._permissaoRelatorioInventario = value;
  }
  public get permissaoRelatorioEstoqueMaiorQueConsumo(): Permissao {
    return this._permissaoRelatorioEstoqueMaiorQueConsumo;
  }
  public set permissaoRelatorioEstoqueMaiorQueConsumo(value: Permissao) {
    this._permissaoRelatorioEstoqueMaiorQueConsumo = value;
  }
  public get permissaoRelatorioContracautela(): Permissao {
    return this._permissaoRelatorioContracautela;
  }
  public set permissaoRelatorioContracautela(value: Permissao) {
    this._permissaoRelatorioContracautela = value;
  }
  public get permissaoRelatorioGuiasDistribuicao(): Permissao {
    return this._permissaoRelatorioGuiasDistribuicao;
  }
  public set permissaoRelatorioGuiasDistribuicao(value: Permissao) {
    this._permissaoRelatorioGuiasDistribuicao = value;
  }

  public get permissaoItensEmMovimentacao(): Permissao {
    return this._permissaoItensEmMovimentacao;
  }
  public set permissaoItensEmMovimentacao(value: Permissao) {
    this._permissaoItensEmMovimentacao = value;
  }

  public get permissaoExtratoMaterial(): Permissao {
    return this._permissaoExtratoMaterial;
  }
  public set permissaoExtratoMaterial(value: Permissao) {
    this._permissaoExtratoMaterial = value;
  }
  public get permissaoCadastroParametroAnaliseTecnica(): Permissao {
    return this._permissaoCadastroParametroAnaliseTecnica;
  }
  public set permissaoCadastroParametroAnaliseTecnica(value: Permissao) {
    this._permissaoCadastroParametroAnaliseTecnica = value;
  }
  public get permissaoParametrizarQuestoesAnalise(): Permissao {
    return this._permissaoParametrizarQuestoesAnalise;
  }
  public set permissaoParametrizarQuestoesAnalise(value: Permissao) {
    this._permissaoParametrizarQuestoesAnalise = value;
  }
  public get permissaoAnaliseTecnica(): Permissao {
    return this._permissaoAnaliseTecnica;
  }
  public set permissaoAnaliseTecnica(value: Permissao) {
    this._permissaoAnaliseTecnica = value;
  }
  public get permissaoRelatorioAnaliseTecnica(): Permissao {
    return this._permissaoRelatorioAnaliseTecnica;
  }
  public set permissaoRelatorioAnaliseTecnica(value: Permissao) {
    this._permissaoRelatorioAnaliseTecnica = value;
  }
  public get permissaoConfigurarParametroModulos(): Permissao {
    return this._permissaoConfigurarParametroModulos;
  }
  public set permissaoConfigurarParametroModulos(value: Permissao) {
    this._permissaoConfigurarParametroModulos = value;
  }
  public get permissaoAnaliseMaterial(): Permissao {
    return this._permissaoAnaliseMaterial;
  }
  public set permissaoAnaliseMaterial(value: Permissao) {
    this._permissaoAnaliseMaterial = value;
  }
  public get permissaoRelatorioComprovacaoAtendimento(): Permissao {
    return this._permissaoRelatorioComprovacaoAtendimento;
  }
  public set permissaoRelatorioComprovacaoAtendimento(value: Permissao) {
    this._permissaoRelatorioComprovacaoAtendimento = value;
  }
  public get permissaoRelatorioLivroControlados(): Permissao {
    return this._permissaoRelatorioLivroControlados;
  }
  public set permissaoRelatorioLivroControlados(value: Permissao) {
    this._permissaoRelatorioLivroControlados = value;
  }

}
