import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Material } from "@app/core/material/material.model";
import { MaterialService } from "@app/core/material/material.service";
import { LoginService } from "@app/core/security/login/login.service";

@Component({
  selector: "ses-medicamento-pick-list",
  templateUrl: "./medicamento-pick-list.component.html",
  styleUrls: ["./medicamento-pick-list.component.scss"],
})
export class MedicamentoPickListComponent implements OnInit {
  @Input() limparEvent = new EventEmitter<any>();
  @Input() medicamentoSelecionado: Material[] = [];

  @Output() idSelecionadas = new EventEmitter<string[]>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any[]>();

  public source: Material[] = [];
  public target: Material[] = [];
  public sourceInitial: Material[] = [];
  public lastSearch: string = "a";

  constructor(
    private loginService: LoginService,
    private materialService: MaterialService
  ) {}

  ngOnInit() {
  this.target = this.medicamentoSelecionado;
    this.filtroMedicamentoByDescricao("a");
    this.limparEvent.subscribe({
      next: (resp) => {
        this.limpar();
      },
    });
  }


  public limpar() {
    this.target = [];
    this.filtroMedicamentoByDescricao("a");
    this.idSelecionadas.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  public selecionaMedicamento() {
    let idSelecionadasString: string[] = [];

    this.target.forEach((medicamento) =>
      idSelecionadasString.push(medicamento.id)
    );
    this.idSelecionadas.emit(idSelecionadasString);

    this.elementosCompletoSelecionadas.emit(this.target);
  }

  public filtroMedicamento(evento: any) {
    if (evento.target && evento.target.value) {
      this.lastSearch = evento.target.value;
      if (evento.target.value.length >= 1) {
        this.filtroMedicamentoByDescricao(evento.target.value);
      } else if (evento.target.value.length == 0) {
        this.filtroMedicamentoByDescricao("a");
      }
    }
  }

  public filtroMedicamentoByDescricao(descricao: string) {
    this.materialService
      .getMaterialByDescOrCiascAndRerencia(
        descricao,
        this.loginService.user.referencia.id
      )
      .subscribe((resp) => {
        if (resp) {
          this.source = this.validaSeExiste(resp);
        }
      });
  }

  validaSeExiste(lsMaterial: Material[]): Material[] {
    let listaAuxiliar: Material[] = [];

    lsMaterial.forEach((material) => {
      if (!this.target.find((filtro) => +filtro.id == +material.id)) {
        listaAuxiliar.push(material);
      }
    });

    return listaAuxiliar;
  }

}


