import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Cid } from "@app/core/cid/cid.model";
import { CidService } from "@app/core/cid/cid.service";

@Component({
  selector: "ses-cid-pick-list",
  templateUrl: "./cid-pick-list.component.html",
  styleUrls: ["./cid-pick-list.component.scss"],
})
export class CidPickListComponent implements OnInit {
  @Input() limparEvent = new EventEmitter<any>();
  @Input() cidSelecionado: Cid[] = [];

  @Output() idSelecionadas = new EventEmitter<string[]>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any[]>();

  public source: Cid[] = [];
  public target: Cid[] = [];
  public sourceInitial: Cid[] = [];
  public lastSearch: string = "a";

  constructor(private cidService: CidService) {}

  ngOnInit() {
    this.target = this.descricaoToUpper(this.cidSelecionado);

    this.filtroCidByDescricao("a");
    this.limparEvent.subscribe({
      next: (resp) => {
        this.limpar();
      },
    });
  }

  descricaoToUpper(cids : Cid[]) : Cid[]{
    cids.forEach(element => {
      element.descricao = element.descricao.toUpperCase();
    });
    return cids;
  }

  public filtroCid(evento: any) {
    if (evento.target && evento.target.value) {
      this.lastSearch = evento.target.value;
      if (evento.target.value.length >= 3) {
        this.filtroCidByDescricao(evento.target.value);
      } else if (evento.target.value.length == 0) {
        this.filtroCidByDescricao("a");
      }
    }
  }

  public limpar() {
    this.target = [];
    this.idSelecionadas.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
    this.filtroCidByDescricao("a");
  }

  public selecionaCid() {
    let idSelecionadasString: string[] = [];

    this.target.forEach((medicamento) =>
      idSelecionadasString.push(medicamento.id)
    );
    this.idSelecionadas.emit(idSelecionadasString);

    this.elementosCompletoSelecionadas.emit(this.target);
  }

  public filtroCidByDescricao(descricao: string) {
    this.cidService.buscaCidsByDescricao(descricao).subscribe((resp) => {
      if (resp) {
        this.source = this.validaSeExiste(resp);
      }
    });
  }

  validaSeExiste(lsCid: Cid[]): Cid[] {
    let listaAuxiliar: Cid[] = [];

    lsCid.forEach((cid) => {
      if (!this.target.find((filtro) => +filtro.id == +cid.id)) {

        listaAuxiliar.push(cid);
      }
    });

    return listaAuxiliar;
  }

}
