import { User } from './../security/login/user.model';
import { Permissao } from './permissao.model';
import { LoginService } from '@app/core/security/login/login.service';
import { Injectable } from "@angular/core"
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Grupo } from "./grupo.model";
import { SGS_URL, CORE } from "@app/app.api";
import { ListaJSON } from "../shared/default/lista-json.model";
import { Usuario } from "../usuario/usuario.model";

@Injectable()
export class GrupoService
{
   private _groupFilter = this.getEmptyGroupFilter();
   private _group: Grupo;

    constructor(private http: HttpClient, private loginService: LoginService){}

    cadastrar(): Observable<any>
    {
      this._group.referencia = this.loginService.user.referencia;
      return this.http.post<any>(`${SGS_URL.CORE}/${CORE.GRUPO}/c/${this.loginService.user.referencia.id}/${this.loginService.user.id}`, this._group);
    }

    public search(): Observable<ListaJSON<Grupo>> {
      this._groupFilter.referencia = this.loginService.user ? this.loginService.user.referencia.id : 0;
      return this.http.post<ListaJSON<Grupo>>(`${SGS_URL.CORE}/${CORE.GRUPO}/p`, this._groupFilter)
    }

    public findAllUserNotGroup():Observable<Usuario[]>
    {
      return this.http.post<Usuario[]>(`${SGS_URL.CORE}/${CORE.USUARIO}/getAllUserNotGroup`, { group: this._group.id, referencia: this.loginService.user.referencia.id });
    }

    public getNovoGrupo(): Grupo
     {
      return new Grupo(null, null, [], null, []);
    }

    public getGrupoVinculadoAoUsuario(usuario_id: string, referencia: string): Observable<Grupo[]> {
      return this.http.get<Grupo[]>(`${SGS_URL.CORE}/${CORE.GRUPO}/getGrupoVinculadoAoUsuario/${usuario_id}/${referencia}`);
    }

    public vincularGruposAoUsuario(idUsuario: string, grupos: Array<Grupo>): Observable<any> {
      return this.http.post<any>(`${SGS_URL.CORE}/${CORE.GRUPO}/vincularGruposAoUsuario/${this.loginService.user.referencia.id}/${idUsuario}`, grupos);
    }

   public getAllPermissoes(): Observable<Permissao[]>
   {
      return this.http.post<Permissao[]>(`${SGS_URL.CORE}/${CORE.PERMISSAO}/all`, {referencia: this.loginService.user.referencia.id})
   }

    public getEmptyGroupFilter()
    {
      return {
                referencia: null,
                filter:null,
                direction:"asc",
                sort:"nome",
                pageIndex:0,
                pageSize:5
             }
    }

    //================[ Sets e Gets ]================

    public get groupFilter()
    {
      return this._groupFilter;
    }
    public set groupFilter(value)
    {
      this._groupFilter = value;
    }
    public get group(): Grupo
    {
      return this._group;
    }
    public set group(value: Grupo)
    {
      this._group = value;
    }
}
