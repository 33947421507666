import { Regional } from '@app/core/regional/regional.model';
import { UnidadeSaude } from '@app/core/unidade-saude/unidade-saude.model';
import { UnidadeSaudeService } from '@app/core/unidade-saude/unidade-saude.service';
import { MapaJudicial } from './mapa-judicial.model';
import { Injectable } from '@angular/core';
import { TipoMedicamento } from '@app/core/tipo-medicamento/tipo-medicamento.model';
import { HttpClient } from '@angular/common/http';
import { LoginService } from '@app/core/security/login/login.service';
import { SituacaoProcessoService } from '../situacao-processo/situacao-processo.service';
import { MaterialService } from '@app/core/material/material.service';
import { UtilService } from '@app/core/shared/util/util.service';
import { Observable } from 'rxjs';
import { SGS_URL, CORE } from '@app/app.api';
import { IdList } from '@app/core/shared/models/IdList.model';
import { SituacaoProcesso } from '../situacao-processo/situacao-processo.model';
import { Material } from '@app/core/material/material.model';
import { ListaJSON } from '@app/core/shared/default/lista-json.model';
import { MapaJudicialItem } from './mapa-judicial-item.model';
import { PacienteService } from '@app/core/paciente/paciente.service';
import { Paciente } from '@app/core/paciente/paciente.model';
import { TipoMapa } from '../tipo-mapa/tipo.mapa.model';
import { MessageService } from '../shared/message.service';
import { ItemAtualizaQuantidade } from './mapa-planejamento-detalhe/item-atualiza-quantidade.model';

@Injectable({
  providedIn: 'root'
})
export class MapaJudicialService
{
  private _mapaAtivo: MapaJudicial;
  private _mapas: MapaJudicial[];
  private _filterMapa: any = this.getMapaFilterEmpty();

  private _regionalFilter: Regional;
  private _tipoMedicamentoFilter: TipoMedicamento;
  private _unidadeSaudeFilter: UnidadeSaude;
  private _tipoMapaFilter: TipoMapa;
  private _filter_item_Mapa;
  private _visibledSearchPanel: boolean;

  public readonly MAPA_POR_MEDICAMENTOS = '1';
  public readonly MAPA_POR_PACIENTES = '2';
  public readonly MAPA_POR_DEVOLUCAO = '3';

  // usado apenas na msg p/ informar quantos mapas faltam enviar tirando os mapas enviados individualmente
  public mapasIndividuaisEnviados:number = 0;

  public enviarTodosSGM:Boolean = true;

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private situacaoProcessoService: SituacaoProcessoService,
    private pacienteService: PacienteService,
    private materialService: MaterialService,
    private unidadeSaudeService: UnidadeSaudeService,
    private utilService: UtilService,
    private messageService:MessageService
  ) { }


  //atualiza a quantidade de um item do mapa
  updateItemMapa(lsItemMapa:ItemAtualizaQuantidade[]): Observable<any> {
    let id_usuario = this.loginService.user.id
    let id_referencia = this.loginService.user.referencia.id
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.ITEM_MAPA_JUDICIAL}/updateItem`,
      {
        lsItemMapa:lsItemMapa,
        user: id_usuario,
        ref: id_referencia
      })
  }
  //atualiza a quantidade de todos os itens do mapa
  updateAllItemMapa(mapajudicial: MapaJudicial): Observable<any>
  {
    let id_usuario = this.loginService.user.id
    let id_referencia = this.loginService.user.referencia.id
    let itens = [];

    for (let item of mapajudicial.itens_mapa)
    {
      itens.push({ idItem: item.id, qtdNew: Number(item.qtde_necessaria).toFixed(2) });
    }

    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.ITEM_MAPA_JUDICIAL}/updateAllItens`,
      {
        user: id_usuario,
        ref: id_referencia,
        mapa: mapajudicial.id,
        itens_mapa: itens,
        unidadeSaude:mapajudicial.unidade_saude.id
      })
  }

  cadastrar(): Observable<IdList>
  {
    this.mapaAtivo.usuario = this.loginService.user;
    this.mapaAtivo.referencia = this.loginService.user.referencia;
    let id_usuario = this.loginService.user.id;
    let id_referencia = this.loginService.user.referencia.id;
    return this.http.post<IdList>(`${SGS_URL.CORE}/${CORE.MAPA_JUDICIAL}/gerarMapa/${id_referencia}/${id_usuario}`, this._mapaAtivo);
  }

  sendSGM(id_mapa: String): Observable<any>
  {
    let id_usuario = this.loginService.user.id
    let id_referencia = this.loginService.user.referencia.id
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.MAPA_JUDICIAL}/send_sgm`, { idMapa: id_mapa, user: id_usuario, ref: id_referencia })
  }

  sendAllSGM(): Observable<any> {
    let id_usuario = this.loginService.user.id
    let id_referencia = this.loginService.user.referencia.id

    let ids = [];
    for (let idMapa of this.mapas)
    {
      if (idMapa.dt_envio_sgm == null)
      {
        if(!this.mapaZerado(idMapa))
        {
          ids.push(idMapa.id);
        }
      }
    }
    if(ids.length != this.mapas.length)
    {
      this.messageService.addToast("Algun mapa não foi enviado por conta do mesmo possuir todos medicamentos com quantidade zerada", "Aviso", "warning")
    }

    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.MAPA_JUDICIAL}/send_sgm_all`, { ids: ids, user: id_usuario, ref: id_referencia })
  }

  mapaZerado(mapa: MapaJudicial)
  {
    if(mapa && mapa.itens_mapa)
    {
      for (let item of mapa.itens_mapa) {
        if (Number(item.qtde_necessaria) > 0) {
          return false;
        }
      }
    }
    return true
  }

  verificaEnviarAllSGM()
  {
    this.enviarTodosSGM = true
    if(this.mapas)
    {
      for(let mapa of this.mapas)
      {
        if (mapa.itens_mapa)
        {
          for(let item of mapa.itens_mapa)
          {
            if(Number(item.qtde_necessaria) > 0)
            {
              setTimeout(() => {this.enviarTodosSGM = false})
              return this.enviarTodosSGM
            }
          }
        }
      }
    }
    setTimeout(() => {this.enviarTodosSGM = true})
    return this.enviarTodosSGM
  }

  buscaUnidadesSaudeAll(filter: string): Observable<UnidadeSaude[]> {
    return this.http.post<UnidadeSaude[]>(`${SGS_URL.CORE}/${CORE.UNIDADE_SAUDE}/searchAllActive`, { nome: filter });
  }

  buscaPacienteAllBySituacao(filter: string): Observable<Paciente[]> {
    return this.http.post<Paciente[]>(`${SGS_URL.CORE}/${CORE.PACIENTE}/searchAllActivebySituacao`, { nome: filter, situacao: "" });
  }

  buscaUnidadesSaudeByRegional(filter: string) {
    let id_regional = this.mapaAtivo != null && this.mapaAtivo.regional != null ? this.mapaAtivo.regional.id : null;
    let id_paciente = this.mapaAtivo != null && this.mapaAtivo.paciente != null ? this.mapaAtivo.paciente.id : null;
    let id_referencia = this.loginService.user.referencia.id;

    return this.http.post<UnidadeSaude[]>(`${SGS_URL.CORE}/${CORE.UNIDADE_SAUDE}/buscaUnidadesSaudeByRegional`, { nome: filter, regional: id_regional, paciente: id_paciente, id_referencia: id_referencia })
  }

  buscaTiposMedicamentoAll(): Observable<TipoMedicamento[]> {
    return this.http.post<TipoMedicamento[]>(`${SGS_URL.CORE}/${CORE.TIPO_MEDICAMENTO}/searchAllActive`, {})
  }

  buscarTipoMedicamentoByMedicamento(id:string): Observable<TipoMedicamento> {
    return this.http.post<TipoMedicamento>(`${SGS_URL.CORE}/${CORE.TIPO_MEDICAMENTO}/buscarTipoMedicamentoByMedicamento`, {idMedicamento: id})
  }

  buscaRegionaisActiveByPaciente(filter: any): Observable<Regional[]> {
    let idPaciente = this.mapaAtivo != null && this.mapaAtivo.paciente != null ? this.mapaAtivo.paciente.id : null;
    let referencia = this.loginService.user.referencia.id;
    return this.http.post<Regional[]>(`${SGS_URL.CORE}/${CORE.REGIONAL}/searchRegionaisActiveByPaciente`, { nome: filter, paciente: idPaciente, referencia: referencia });
  }

  buscaSituacoesAll(): Observable<SituacaoProcesso[]> {
    return this.http.post<SituacaoProcesso[]>(`${SGS_URL.CORE}/${CORE.SITUACAO_PROCESSO}/searchAllActive`, {referencia: this.loginService.user.referencia.id })
  }

  buscaTiposMapaAll(): Observable<TipoMapa[]> {
    return this.http.post<TipoMapa[]>(`${SGS_URL.CORE}/${CORE.TIPO_MAPA}/searchAllActive`, {})
  }

  buscaPacientes(filtro: string): Observable<Paciente[]> {
    return this.http.post<Paciente[]>(`${SGS_URL.CORE}/paciente/buscaPacientes/${filtro}`, {})
  }

  buscaMedicamentosbySituacao(filter: string, idPaciente: string): Observable<Material[]>
  {
    return this.http.post<Material[]>(`${SGS_URL.CORE}/${CORE.MATERIAL}/searchAllMaterialBySituacao`, { referencia:this.loginService.user.referencia.id, nome: filter, paciente: idPaciente });
  }

  searchAllMapasByListId(ids:IdList): Observable<MapaJudicial[]> {
    return this.http.post<MapaJudicial[]>(`${SGS_URL.CORE}/${CORE.MAPA_JUDICIAL}/searchMapasByListId`,ids);
  }

  //Busca lista de mapas judiciais no banco de dados
  search(): Observable<ListaJSON<MapaJudicial>> {
    this._filterMapa.referencia = this.loginService.user.referencia.id
    return this.http.post<ListaJSON<MapaJudicial>>(`${SGS_URL.CORE}/${CORE.MAPA_JUDICIAL}/p`, this._filterMapa);
  }

  deleteMapa(id_mapa: string): Observable<any>
  {
    let id_usuario = this.loginService.user.id;
    let id_referencia = this.loginService.user.referencia.id
    return this.http.get<any>(`${SGS_URL.CORE}/${CORE.MAPA_JUDICIAL}/s/${id_mapa}/${id_usuario}/true/${id_referencia}`)
  }

  //deleta todos os mapas que estão no arrya maps do service
  deleteAllMapas(): Observable<any>
  {
    let id_usuario = this.loginService.user.id
    let id_referencia = this.loginService.user.referencia.id

    let idList = [];
    for (let idMapa of this.mapas) {
      if (idMapa.dt_envio_sgm == null) idList.push(idMapa.id);
    }
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.MAPA_JUDICIAL}/delete`,
      {
        ids: idList,
        user: id_usuario,
        ref: id_referencia
      });
  }

  deleteItemMapa(id_mapa: string, id_item_mapa: string): Observable<any>
  {
    let id_usuario = this.loginService.user.id;
    let id_referencia = this.loginService.user.referencia.id
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.ITEM_MAPA_JUDICIAL}/delete`,
      {
        user: id_usuario,
        ref: id_referencia,
        mapa: id_mapa,
        itemMapa: id_item_mapa,
        filtro: this.filter_item_Mapa
      })
  }

  searchItemsMapas(id_mapa: string): Observable<MapaJudicialItem[]> {
    return this.http.post<MapaJudicialItem[]>(`${SGS_URL.CORE}/${CORE.ITEM_MAPA_JUDICIAL}/searchItemMapa`, {mapa: id_mapa})
  }

  public getMapaEmpty(): MapaJudicial
  {
    return new MapaJudicial("", "1", "", "", "", this.loginService.user,null, null, null, null, null, [], false, null, null, [],null, [], null, null, 0, [], null, this.loginService.user.referencia, null, null, null, null,null, null);
  }

  //retorna um objeto de pesquisa vazio
  public getMapaFilterEmpty(): any
  {
    return {
      nao_enviado:true,
      processo:false,
      processo_ignorado:false,
      processo_erro:false,
      em_processo:false,
      nao_processado:false,
      referencia: null,
      id: null,
      mes: null,
      ano: null,
      regional: null,
      unidade_saude: null,
      tipo_medicamento: null,
      tipo_mapa: null,
      pageIndex: 0,
      pageSize: 10,
      sort: "id",
      direction: "asc"
    };
  }
  public newFilterItemMapa()
  {
    return {active:"id", direction:"asc"}
  }

  /** Métodos Sets  e Gets */

  public get mapaAtivo(): MapaJudicial {
    return this._mapaAtivo;
  }
  public set mapaAtivo(value: MapaJudicial) {
    this._mapaAtivo = value;
  }
  public get filterMapa(): any {
    return this._filterMapa;
  }
  public set filterMapa(value: any) {
    this._filterMapa = value;
  }
  public get mapas(): MapaJudicial[] {
    return this._mapas;
  }
  public set mapas(value: MapaJudicial[]) {
    this._mapas = value;
  }
  public get regionalFilter(): Regional {
    return this._regionalFilter;
  }
  public set regionalFilter(value: Regional) {
    this._regionalFilter = value;
  }
  public get tipoMedicamentoFilter(): TipoMedicamento {
    return this._tipoMedicamentoFilter;
  }
  public set tipoMedicamentoFilter(value: TipoMedicamento) {
    this._tipoMedicamentoFilter = value;
  }
  public get unidadeSaudeFilter(): UnidadeSaude {
    return this._unidadeSaudeFilter;
  }
  public set unidadeSaudeFilter(value: UnidadeSaude) {
    this._unidadeSaudeFilter = value;
  }
  public get tipoMapaFilter(): TipoMapa {
    return this._tipoMapaFilter;
  }
  public set tipoMapaFilter(value: TipoMapa) {
    this._tipoMapaFilter = value;
  }
  public get filter_item_Mapa() {
    return this._filter_item_Mapa;
  }
  public set filter_item_Mapa(value) {
    this._filter_item_Mapa = value;
  }
  public get visibledSearchPanel(): boolean {
    return this._visibledSearchPanel;
  }
  public set visibledSearchPanel(value: boolean) {
    this._visibledSearchPanel = value;
  }
}
