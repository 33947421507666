import { UnidadeSaude } from './../unidade-saude/unidade-saude.model';
import { LoginService } from '@app/core/security/login/login.service';
import { Regiao } from './../regiao/Regiao.model';
import { Option } from './../shared/models/option.model';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from '@angular/common/http';
import { Regional } from "../regional/regional.model";
import { ParametroRegiao } from "./parametro_regiao.model";
import { ParametroRegional } from "./parametro_regional.model";
import { SGS_URL, CORE } from "@app/app.api";

@Injectable()
export class ParametrosService
{
  private _parametroRegiao: ParametroRegiao     = this.newParametroRegiao();
  private _parametroRegional: ParametroRegional = this.newParametroRegional();

  constructor(private http: HttpClient, private loginService:LoginService) { }

  getReferencias(): Observable<Option[]> {
    return this.http.post<Option[]>(`${SGS_URL.CORE}/referencia/referencias/all`, {})
  }

  public getAllRegioes(active:boolean): Observable<Regiao[]>
  {
    return this.http.post<Regiao[]>(`${SGS_URL.CORE}/${CORE.REGIAO}/all`, { referencia:this.loginService.user.referencia.id, active:active });
  }

  getReferenciasByNome(nome): Observable<[Option[]]> {
    // Da para fazer assim (se no back estiver QueryParams):
    // const httpParams : HttpParams = new HttpParams().set('nome', nome);
    // return this.http.get<[Option[]]>(`${SGS_URL.CORE}/referencia/referenciaByNome`, {params:httpParams})
    // Para usar PathParams: utilize esse abaixo:
    return this.http.get<[Option[]]>(`${SGS_URL.CORE}/referencia/referenciaByNome/${nome}`, )
  }


  public getAllRegionaisNotInRegiao(): Observable<Regional[]>
  {
    return this.http.post<Regional[]>(`${SGS_URL.CORE}/${CORE.REGIONAL}/get_all_not_in_regiao`, { referencia:this.loginService.user.referencia.id });
  }

  public getAllRegionais(active:boolean): Observable<Regional[]> {
    return this.http.post<Regional[]>(`${SGS_URL.CORE}/${CORE.REGIONAL}/all`, { referencia:this.loginService.user.referencia.id, active:active });
  }

  public getAllUnidadesNotInRegional(active:boolean): Observable<UnidadeSaude[]> {
    return this.http.post<UnidadeSaude[]>(`${SGS_URL.CORE}/${CORE.UNIDADE_SAUDE}/get_all_not_in_regional`, { referencia:this.loginService.user.referencia.id });
  }

   //Gera um parametro região vazio
  public newParametroRegiao()
  {
    return new ParametroRegiao(null, null, null, []);
  }

  //Gera um parametro regional vazio
  public newParametroRegional()
  {
    return new ParametroRegional(null, null, null, []);
  }

  //salva o parametro do tipo Região no banco de dados
  public saveParametroRegiao(): Observable<any>
  {
    this._parametroRegiao.referencia = this.loginService.user.referencia;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.PARAMETROS}/save_param_regiao/${this.loginService.user.id}/${this.loginService.user.referencia.id}`, this._parametroRegiao);
  }

  //salva o parametro do tipo Região no banco de dados
  public saveParametroRegional(): Observable<any>
  {
    this._parametroRegional.referencia = this.loginService.user.referencia;
    return this.http.post<any>(`${SGS_URL.CORE}/${CORE.PARAMETROS}/save_param_regional/${this.loginService.user.id}/${this.loginService.user.referencia.id}`, this._parametroRegional);
  }

  //métodos sets e gets

  public get parametroRegional(): ParametroRegional
  {
    return this._parametroRegional;
  }
  public set parametroRegional(value: ParametroRegional)
  {
    this._parametroRegional = value;
  }

  public get parametroRegiao(): ParametroRegiao
  {
    return this._parametroRegiao;
  }
  public set parametroRegiao(value: ParametroRegiao)
  {
    this._parametroRegiao = value;
  }
}
