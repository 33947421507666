import { Referencia } from './../centro-custo/Referencia.model';
import { User } from '../security/login/user.model';
export class Usuario {
  constructor(public id: string,
              public email: string,
              public nome: string,
              public login: string,     
              public senha: string,
              public repete: string,
              public admin: boolean,
              public usuario_sgs: User,   
              public matricula: string,
              public referencias_permitidas: Referencia[],
              public status: boolean,
              public cpf:string,
              public alertar:number,   //0 não alertar, 1 alertar sempre ,2 alertar após quantidade de dias [alertar_apos]
              public alertar_apos:number,
              public ultimo_alerta:Date,
              public autorizador_especializado: boolean,
              public autorizador_tecnico_especializado: boolean
              ) {}
}
