import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { LoginService } from "../security/login/login.service";
import { ListaJSON } from '../shared/default/lista-json.model';
import { SGS_URL } from '@app/app.api';
import { EstoqueMinimo } from './estoque-minimo.model';

@Injectable()
export class EstoqueMinimoService {

  private _filterEstoqueMinimo = this.getfilterEstoqueMinimo();

  public estoqueMinimoSelecionadoService: EstoqueMinimo = new EstoqueMinimo(null,
                                                                            null,
                                                                            null,
                                                                            null,
                                                                            Number(this.loginService.user.id),
                                                                            Number(this.loginService.user.referencia.id));

  constructor(private http: HttpClient, private loginService: LoginService ) {}
  
  cadastrar(): Observable<any> {
    return this.http.post<any>(`${SGS_URL.CORE}/estoqueMinimo/c/${this.estoqueMinimoSelecionadoService.idReferencia}/${this.estoqueMinimoSelecionadoService.idUser}`, this.estoqueMinimoSelecionadoService)
  }

  listar(): Observable<ListaJSON<any>> {
    return this.http.post<ListaJSON<any>>(`${SGS_URL.CORE}/estoqueMinimo/p`, this.filterEstoqueMinimo)
  }

  public getfilterEstoqueMinimo() {
    return {
      referencia: this.loginService.user.referencia ? this.loginService.user.referencia.id : null,
      idUnidadeSaude: null,
      idMedicamento: null,
      pageIndex: 0,
      pageSize: 10,
      direction: 'asc',
      sort: null
    }
  }

  public get filterEstoqueMinimo() {
    return this._filterEstoqueMinimo;
  }
  public set filterEstoqueMinimo(value) {
    this._filterEstoqueMinimo = value;
  }
  
}
