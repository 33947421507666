import { Injectable } from "@angular/core"
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { SGS_URL } from "@app/app.api"

@Injectable()
export class VerificarService {

    constructor(private http: HttpClient){}

    verificaCpf(cpf: string, classe: string): Observable<any> {
      return this.http.post<any>(`${SGS_URL.CORE}/verificar/cpf/${cpf}/${classe}`, {})
    }

    verificaCns(cns: string, classe: string): Observable<any> {
      return this.http.post<any>(`${SGS_URL.CORE}/verificar/cns/${cns}/${classe}`, {})
    }

    verificaCnpj(cnpj: string, classe: string): Observable<any> {
      return this.http.post<any>(`${SGS_URL.CORE}/verificar/cnpj/${cnpj}/${classe}`, {})
    }

}
