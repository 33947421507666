import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SGS_URL, CORE } from "@app/app.api";
import { JustificativaAnaliseTecnicaFilter } from "@app/core/shared/models/justificativa-analise-tecnica-filter";
import { JustificativaAnaliseTecnicaRequest } from "@app/core/shared/models/justificativa-analise-tecnica-request";
import { ResponseComLength } from "@app/core/shared/models/response-com-length.model";
import { TipoAnaliseTecnicaFilter } from "@app/core/shared/models/tipo-analise-tecnica-filter";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class JutificativaAnaliseTecnicaService {
  constructor(private http: HttpClient) {}

  public getJustificarAnaliseByFilter(params: JustificativaAnaliseTecnicaFilter): Observable<ResponseComLength> {
    return this.http.post<ResponseComLength>(`${SGS_URL.JUDICIAL}/${CORE.NAT_JUSTIFICATIVA_ANALISE_TECNICA}/listar-justificativa-analise-tecnica`, { params });
  }

  public updateJustificativaAnaliseTecnica(params: JustificativaAnaliseTecnicaRequest): Observable<any> {
    return this.http.post<any>(`${SGS_URL.JUDICIAL}/${CORE.NAT_JUSTIFICATIVA_ANALISE_TECNICA}/update-justificativa-analise-tecnica`,{ params });
  }

  public postJustificativaAnaliseTecnica(params: JustificativaAnaliseTecnicaRequest): Observable<any> {
    return this.http.post<any>(`${SGS_URL.JUDICIAL}/${CORE.NAT_JUSTIFICATIVA_ANALISE_TECNICA}/cadastrar-justificativa-analise-tecnica`, { params });
  }
}
