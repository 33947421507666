import { Referencia } from '@app/core/centro-custo/Referencia.model';

export class CategoriaSituacao {

	public id: string;
	public nome: string;
	public sigla: string;
	public status: boolean;
	public referencia: Referencia;
	
}