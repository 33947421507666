import { TipoMedicamento } from '@app/core/tipo-medicamento/tipo-medicamento.model';
import { Sigtap } from "./material-cadastro/sigtap/sigtap.model";

export class Material {

  constructor(
    public id?: string,
    public descricao?: string,
    public status?: string,
    public nomecomercial?: string,
    public cdciasc?: string,
    public cdunidademedida?: string,
    public descricaounidademedida?: string,
    public cdgrupo?: string,
    public descricaogrupo?: string,
    public cdclasse?: string,
    public descricaoclasse?: string,
    public cdmaterialgenerico?: string,
    public descricaomaterialgenerico?: string,
    public valorestimado?: string,
    public tipomaterial?: string,
    public bloqueado?: boolean,
    public situacao?: string,
    public descricaodetalhada?: string,
    public sigtaps?: Sigtap[],
    public tipoMedicamento?:TipoMedicamento,
    public idunidadesaude?: string,
    public siglasref?:string
    ) { }

}
