import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthSession'
})
export class MonthSessionPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(value == '1') return 'Meses';
    else if(value == '2') return 'Sessões';
    else return null;
  }

}
