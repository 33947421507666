import { LOCALE_ID, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { MaterialImportBasicModule } from '../../core/shared/material-import-basic.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@app/core/shared/buttons/buttons.module';

import { AvisosRoutingModule } from './avisos-routing.routes';
import { AvisosComponent } from './avisos.component';
import { NovoComponent } from './cadastro/novo.component';
import { AvisosService } from './avisos.service';
import { ConsultaComponent } from './consulta/consulta.component';
import  localePtBr from '@angular/common/locales/pt';


registerLocaleData(localePtBr);
@NgModule({
  declarations: [AvisosComponent, NovoComponent, ConsultaComponent],
  imports: [
    CommonModule,
    AvisosRoutingModule,
    MaterialImportBasicModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonsModule,
  ],
  exports:[
    ConsultaComponent,
  ],
 providers:
 [
  AvisosService,
  NovoComponent,
  { provide: LOCALE_ID, useValue: "pt-BR" },
 ],
 schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AvisosModule { }
