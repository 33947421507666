import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-text-confirm-dialog',
  templateUrl: './input-text-confirm-dialog.component.html',
  styleUrls: ['./input-text-confirm-dialog.component.scss']
})
export class InputTextConfirmDialogComponent implements OnInit 
{
  @Input() title: string = "Título";        //título da caixa de confirmação
  @Input() message: string  = "Mensagem";   //pergunta da caixa de confirmação
  @Input() lista: any [] = [];              //Linhas para detalhar o item a ser excluidos
  @Input() btnOkText: string = 'Sim';       //nome do botão OK
  @Input() btnCancelText: string = 'Não';   //nome do botão Cancel
  @Input() display:boolean = false;         //Vizibilidade do Painel [true = visible][false = hidden]
  @Input() exibeCaixaTexto :boolean = true;         //Vizibilidade do Painel [true = visible][false = hidden]
  @Output("confirm") confirmEvent = new EventEmitter<[string, boolean, boolean]>();  //Adicionar evento no botão OK
  @Output("cancel") cancelEvent   = new EventEmitter<boolean>();  //Adicionar evento no botão OK

  @Input() exibirbtnOK:boolean = true;
  @Input() exibirbtnNot:boolean = true;
  @Input()exibirCheckEmail:boolean = false;

  private sendEmail:boolean = false;
  private value:string;

  constructor() { }

  ngOnInit() { }

  accept()
  {
    this.confirmEvent.emit([this.value, true, this.sendEmail]);
    this.limparDados();
  }

  decline()
  {
    this.confirmEvent.emit([null, false, this.sendEmail]);
    this.limparDados();
  }

  onClose() 
  {
    this.limparDados();
    this.cancelEvent.emit(false);  
  }

  limparDados() {
    this.lista = [];
    this.value = null;
  }

}
