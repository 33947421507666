import { Regional } from '@app/core/regional/regional.model';
import { UnidadeSaude } from '@app/core/unidade-saude/unidade-saude.model';
import { Referencia } from '@app/core/centro-custo/Referencia.model';


export class ParametroRegional {
  constructor(public id: string,
              public referencia: Referencia,
              public regional: Regional,
              public unidades: UnidadeSaude[]) {}
}
