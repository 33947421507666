import { LoginService } from './../../core/security/login/login.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListaJSON } from '@app/core/shared/default/lista-json.model';
import { HttpClient } from '@angular/common/http';
import { SGS_URL, TUBERCULOSE } from '@app/app.api';
import { PrescricaoTuberculose } from './prescricao-tuberculose.model';
import { PrescricaoTuberculoseIndicacao } from './prescricao-tuberculose-indicacao.model';

@Injectable({
  providedIn: 'root'
})
export class PrescricaoTuberculoseService {
  private _tratamentoFilter = this.getFilterEmpty();
  private _prescricaoTuberculoseSelecionada: PrescricaoTuberculose = this.getNewPrescricaoTuberculose();

  constructor(private httpclient: HttpClient, private loginService: LoginService) { }

  public getAllTratamentos(): Observable<ListaJSON<PrescricaoTuberculoseIndicacao>> {
    return this.httpclient.post<ListaJSON<PrescricaoTuberculoseIndicacao>>(`${SGS_URL.TUBERCULOSE}/${TUBERCULOSE.TRATAMENTO}/p`, this._tratamentoFilter);
  }

  public save(): Observable<any> {
    return this.httpclient.post<any>(`${SGS_URL.TUBERCULOSE}/${TUBERCULOSE.TRATAMENTO}/c/${this.loginService.user.referencia.id}/${this.loginService.user.id}`, this.prescricaoTuberculoseSelecionada);
  }

  public getTratamentoByID(id: string): Observable<PrescricaoTuberculose> {
    return this.httpclient.post<PrescricaoTuberculose>(`${SGS_URL.TUBERCULOSE}/${TUBERCULOSE.TRATAMENTO}/get_prescricao_by_tuberculose`, { prescricao: id });
  }

  public getAllTratamentoIndicacoesActve(isProvaTuberculina): Observable<PrescricaoTuberculoseIndicacao[]> {
    return this.httpclient.get<PrescricaoTuberculoseIndicacao[]>(`${SGS_URL.TUBERCULOSE}/${TUBERCULOSE.TRATAMENTO_INDICACAO}/all_active/` + isProvaTuberculina);
  }
  public getAllIndicacoes(): Observable<PrescricaoTuberculoseIndicacao[]> {
    return this.httpclient.post<PrescricaoTuberculoseIndicacao[]>(`${SGS_URL.TUBERCULOSE}/${TUBERCULOSE.TRATAMENTO_INDICACAO}/getAllIndicacoes`, {});
  }

  private getFilterEmpty() {
    return { nome: null };
  }


  public get tratamentoFilter() {
    return this._tratamentoFilter;
  }
  public set tratamentoFilter(value) {
    this._tratamentoFilter = value;
  }

  public getNewPrescricaoTuberculose(): PrescricaoTuberculose {
    return new PrescricaoTuberculose(
      null, //id:Number,
      null, //id_prescricao:Number,
      null, //num_prova_tuberculina:Number
      null, // cod_sinan
      null, //bcg:Boolean,
      null, //num_caso_indice:Number,
      null, // num_bac_escarro
      null,
      new PrescricaoTuberculoseIndicacao(
        null,
        null,
        null,
        true,
        null,
        null
      ),
      null //observação
    ) //num_bac_escarro:Number
  }

  /**==========================[Métodos Sets e Gets]===============================**/

  public get prescricaoTuberculoseSelecionada(): PrescricaoTuberculose {
    return this._prescricaoTuberculoseSelecionada;
  }

  public set prescricaoTuberculoseSelecionada(value: PrescricaoTuberculose) {
    this._prescricaoTuberculoseSelecionada = value;
  }

}
