import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PosicaoAnaliseTecnicaSelectComponent } from "./posicao-analise-tecnica-select/posicao-analise-tecnica-select.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatStepperModule,
  MatTableModule,
  MatAutocompleteModule,
  MatIconModule,
} from "@angular/material";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { NgxLoadingModule } from "ngx-loading";
import { NgxMaskModule } from "ngx-mask";
import { ToastaModule } from "ngx-toasta";
import { ButtonsModule } from "../../buttons/buttons.module";
import { DialogPersonalModule } from "../../dialog-personal/dialog.personal.module";
import { MaterialImportBasicModule } from "../../material-import-basic.module";
import { PipeModule } from "../../pipe/pipe.module";
import { ComponentsModule } from "../components.module";
import { ParecerSelectComponent } from './parecer-select/parecer-select.component';

@NgModule({
  declarations: [PosicaoAnaliseTecnicaSelectComponent, ParecerSelectComponent],
  exports: [PosicaoAnaliseTecnicaSelectComponent, ParecerSelectComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialImportBasicModule,
    MatStepperModule,
    CurrencyMaskModule,
    NgxMaskModule,
    ButtonsModule,
    PipeModule,
    NgxLoadingModule,
    ToastaModule,
    DialogPersonalModule,
    MatMomentDateModule,
    ComponentsModule,
    MatTableModule,
    MatAutocompleteModule,
    MatIconModule,
  ],
})
export class SelectedSesModule {}
