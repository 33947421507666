
export class ComplementoCadastro {
  constructor( public id: string,
               public descricaocomercial: string,
               public qtdmaxima: string,
               public qtdminima: string,
               public atendimento_id: string,
               public situacao_id: string,
               public medicamento_id: string,
               public material_id: string,
               public origem_id: string,
               public qtdmultiplicadora?: number,
               public classificacao_id?: string) { }
}
