import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PacienteService } from "@app/core/paciente/paciente.service";

@Component({
  selector: "ses-paciente-autocomplete",
  templateUrl: "./paciente-autocomplete.component.html",
  styleUrls: ["./paciente-autocomplete.component.scss"],
})
export class PacienteAutocompleteComponent implements OnInit {
  @Input() required: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();
  @Input() id: string = "material";

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */
  @Output() idSelecionada = new EventEmitter<string>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any>();

  constructor(private pacienteService: PacienteService) {}

  public pacientelSelecionada: any;
  public lsPaciente: any[] = [];
  private lastNameSearch: string = "";

  ngOnInit() {
    this.pesquisa("a");
    this.limparEvent.subscribe({
      next: (resp) => {
        this.limpar();
      },
    });
  }

  selecionarMaterial() {
    this.idSelecionada.emit(this.pacientelSelecionada.id);
    this.elementosCompletoSelecionadas.emit(this.pacientelSelecionada);
  }

  public filter(evento: any) {
    let name = evento.target.value;
    if (name.length >= 3 && name !== this.lastNameSearch) {
      this.pesquisa(name);
    } else {
      this.lsPaciente = [];
    }
  }
  public pesquisa(name: string) {
    this.pacienteService.buscarPacientesByNome(name).subscribe((resp) => {
      if (resp) {
        this.lastNameSearch = name;
        this.lsPaciente = resp;
      }
    });
  }

  public limpar() {
    this.pacientelSelecionada = null;
    this.idSelecionada.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  public displayFn(material: any) {
    return material ? material.nome : "";
  }
}
