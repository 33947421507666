var api = 'https://conecta.saude.sc.gov.br:8443/sgs/rest';
export const environment = {
  production: true,
  name: 'prod',
  url_core: api,
  url_judicial: api,
  url_tuberculose:api,
  url_estrategico:api,
  url_especializado: api,
  EXIBIR_HEADER: false,
  ESTADO_PROJETO:"",
  DEVELOP_MODE: false,
  ENCRYPTED_KEY: "TjZYc1hFTnVYTWI3THcxUXFZcEsrR2NtMVRiV2w2eVdRWFgvNjNxbXNNVnBCQzRhLzhTSzBwQytpbzI5WEhoS05tOXd3SGozV3NYaWdRamR4REhwc3ZGSi9XMHorb1YrbFUzRWg4RFVYVldhUGRRcnlDQndUWGdscDZIRmhSSFN4WDc1dkJNZTdjQ2NTM3UzaE4wSzhtNmFNeEhvcG5pYkJ6TUxNU2xWSGhnPQ=="
};
