import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PacienteService } from '@app/core/paciente/paciente.service';
import { PrescricaoService } from '@app/core/prescricao/prescricao.service';
import { ResponsavelService } from '@app/core/responsavel/responsavel.service';

@Component({
  selector: 'ses-periodicidade-autocomplete',
  templateUrl: './periodicidade-autocomplete.component.html',
  styleUrls: ['./periodicidade-autocomplete.component.scss']
})
export class PeriodicidadeAutocompleteComponent implements OnInit {

  @Input() required: boolean = true;
  @Input() disabled: boolean = false;
  @Input() limparEvent = new EventEmitter<any>();
  @Input() id: string = "material";
  @Input() periodicidadeidSelecionada: string;
  @Input() periodicidadeNomeSelecionada: string;

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */
  @Output() idSelecionada = new EventEmitter<string>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any>();

  constructor(private prescricaoService: PrescricaoService) {}

  public elementoSelecionado: any;
  public lsElemento: any[] = [];
  private lastNameSearch: string = "";

  ngOnInit() {
    if(this.periodicidadeNomeSelecionada){
      this.pesquisa(this.periodicidadeNomeSelecionada);
    }else{
      this.pesquisa("e");
    }

    this.limparEvent.subscribe({
      next: (resp) => {
        this.limpar();
      },
    });
  }

  selecionarMaterial() {
    this.idSelecionada.emit(this.elementoSelecionado.id);
    this.elementosCompletoSelecionadas.emit(this.elementoSelecionado);
  }

  public filter(evento: any) {
    let name = evento.target.value;
    if (name !== this.lastNameSearch) {
      this.pesquisa(name);
    } else {
      this.lsElemento = [];
    }
  }

  public pesquisa(name: string) {
    this.prescricaoService
      .buscaPeriodicidadeByNome(name)
      .subscribe({
        next: (resp) => {
          if (resp) {
            this.lastNameSearch = name;
            this.lsElemento = resp;

            if(this.periodicidadeidSelecionada){
              this.elementoSelecionado = this.lsElemento.find(filter => filter.id == this.periodicidadeidSelecionada)
            }
          }
        }
      });
  }

  public limpar() {
    if(this.periodicidadeidSelecionada){
      this.periodicidadeidSelecionada = null;
      this.periodicidadeNomeSelecionada = null;
    }
    this.elementoSelecionado = null;
    this.idSelecionada.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
    this.pesquisa("e");
  }

  public displayFn(material: any) {
    return material ? material.descricao : "";
  }
}
