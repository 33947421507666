export class AlertaDTO {

	id?: string;
	titulo?: string;
	descricao?: string;
	query_params?: string;
	rota?: string;
	ativo?: boolean;
	quantidade?: string;
	params?: string;
	referencia_id?: string;

}