import { SituacaoProcesso } from "@app/core/situacao-processo/situacao-processo.model"
import { Referencia } from '@app/core/centro-custo/Referencia.model';

export class FluxoPrescricao {
  constructor(
    public id: string,
    public situacao_origem: SituacaoProcesso,
    public situacao_destino: SituacaoProcesso,
    public referencia: Referencia,
    public status: boolean
  ) {}
}
