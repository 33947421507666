import { Processo } from './../../../judicial/processo/processo.model';
import { CpfPipe } from './cpf/cpf.pipe';
import { CnsPipe } from './cns/cns.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmptyPipe } from './empty/empty.pipe';
import { DocPipe } from './doc/doc.pipe';
import { MonthSessionPipe } from './month-session/month-session.pipe';
import { RgPipe } from './rg/rg.pipe';
import { TelefonePipe } from './telefone/telefone.pipe';
import { ProcessoPipe} from './processo/processo.pipe';
import { CodigoProcedimentoPipe } from './codigo-procedimento/codigo-procedimento.pipe';


@NgModule({
  declarations: [
    CnsPipe,
    CpfPipe,
    EmptyPipe,
    DocPipe,
    MonthSessionPipe,
    RgPipe,
    TelefonePipe,
    ProcessoPipe,
    CodigoProcedimentoPipe
    
  ],
  imports: [
    CommonModule
  ],
  exports:
   [
    CnsPipe,
    CpfPipe,
    EmptyPipe,
    DocPipe,
    RgPipe,
    TelefonePipe,
    MonthSessionPipe,
    ProcessoPipe,
    CodigoProcedimentoPipe
  ]
})
export class PipeModule { }
