import { MatTooltipModule } from '@angular/material';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatCardModule, MatInputModule, MatButtonModule } from '@angular/material';
import { MensagemAvisoModalComponent } from './mensagem-aviso-modal.component';

@NgModule({
    declarations:
        [
            MensagemAvisoModalComponent
        ],
    imports:
        [
            CommonModule,
            MatIconModule,
            MatCardModule,
            MatInputModule,
            MatButtonModule,
            MatTooltipModule
        ],
    exports:
        [
            MensagemAvisoModalComponent
        ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MensagemAvisoModule { }
