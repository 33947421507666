import { Component, OnInit, Inject } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { LoginService } from './login.service'
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service'
import { MessageService } from '../../shared/message.service';
import { UsuarioService } from '@app/core/usuario/usuario.service'

@Component({
  selector: 'mt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup
  navigateTo: string

  private isLoading:boolean = false;

  constructor(@Inject(SESSION_STORAGE) private storage: WebStorageService,
              private fb: FormBuilder, private loginService: LoginService, private userService:UsuarioService,
              private activatedRoute: ActivatedRoute, private messageService: MessageService,
              private router: Router) { }

  ngOnInit()
   {
    this.loginService.clearLoginService();
    this.clearLocalCache();
    this.loginForm = this.fb.group({
      login: this.fb.control('', [Validators.required]),
      password: this.fb.control('', [Validators.required])
    })
    this.navigateTo = this.activatedRoute.snapshot.params['to'] || btoa('/');
  }

  private login()
  {
    if(!this.loginForm.value.login || !this.loginForm.value.password)
    {
      this.messageService.addToast("Senha e ou Usuário nulo(s)", "Erro", "error") ;
      return;
    }

    this.isLoading = true;
    this.loginService.login(this.loginForm.value.login, this.loginForm.value.password).subscribe(user =>
    {

      if(user == null)
      {
        this.messageService.addToast("Senha incorreta e ou Usuário inexistente", "Erro", "error")
        this.isLoading = false;
        return;
      }
      else if(user.referencias_permitidas == null)
      {
        this.messageService.addToast("Usuário não está cadastrado em nenhum módulo, contate o Administrador", "Erro", "error");
        this.isLoading = false;
        return;
      }
      else if(user.referencias_permitidas.length < 1)
      {
        this.messageService.addToast("Usuário não está cadastrado em nenhum módulo, contate o Administrador", "Erro", "error");
        this.isLoading = false;
        return;
      }

      if(user.referencias_permitidas.length > 1)
      {
        this.loginService.user = user;
        this.router.navigate(['/modulos']);
      }
      else
      {
        user.referencia = user.referencias_permitidas[0];
        this.loginService.user = user;
        this.updateInfoUser()
        this.router.navigate(['/navegacao/inicio']);
      }
    },
    error =>
    {
      this.messageService.addToast(error ? error : '', "", "error");
      this.isLoading = false;
    }
    );
  }

  private clearLocalCache = () =>{
    this.userService.infoUser = null;
    this.storage.remove("user");
  }

  private updateInfoUser() {
    this.isLoading = true;
    this.loginService.searchInfoUser().subscribe(resp => {
        if(resp) {
          if (resp.regioes != null && resp.regioes.length > 0) {
            resp.regionais = [];
            for (let item of resp.regioes) {
              if(item.regionais == null) continue;
              if(item.regionais.length < 1) continue;

              for (let regional of item.regionais) {
                resp.regionais.push(regional);
              }
            }
          }
          if (resp.regionais != null && resp.regionais.length > 0) {
            resp.regionalorigin = resp.regionais[0];
          } else if (resp.unidades != null && resp.unidades.length > 0) {
            resp.unidadeorigin = resp.unidades[0]
            resp.regionaldestine = resp.unidades[0].regional;
          }
        }
        this.userService.infoUser = resp;
        this.loginService.infoUser = this.userService.infoUser;
        this.isLoading = false;
        this.router.navigate(['navegacao/inicio']);
      }, error => {
        this.messageService.addToast("Erro ao tentar recuperar os niveis do usuário", "Erro", "error")
        this.isLoading = false;
      }
    );
  }

}
