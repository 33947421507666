import { Regiao } from './../regiao/Regiao.model';
import { Referencia } from '@app/core/centro-custo/Referencia.model';
import { Regional } from "../regional/regional.model";

export class ParametroRegiao 
{
  constructor(public id: string,
              public referencia: Referencia,
              public regiao: Regiao,
              public regionais: Regional[]) {}
}
