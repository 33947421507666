import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClassificacaoSubstanciaAnvisaService } from '@app/core/classificacao-substancia-anvisa/classificacao-substancia-anvisa.service';
import { ClassificacaoSubstanciaAnvisaFilter } from '@app/core/shared/models/classificacao-substancia-anvisa-filter';

@Component({
  selector: 'ses-classificacao-substancia-anvisa-multiselect',
  templateUrl: './classificacao-substancia-anvisa-multiselect.component.html',
  styleUrls: ['./classificacao-substancia-anvisa-multiselect.component.scss']
})
export class ClassificacaoSubstanciaAnvisaMultiselectComponent implements OnInit {
  @Input() required: boolean = true;
  @Input() filtroAtivo: boolean = true;
  @Input() limparEvent = new EventEmitter<any>();

  /**
   * Para fazer o vinculo com a variavel (idSelecionada) de retorno utilizar um
   * metodo que receba $event e vincule a sua variavel
   */
  @Output() idsSelecionadas = new EventEmitter<string[]>();
  @Output() elementosCompletoSelecionadas = new EventEmitter<any[]>();

  constructor( private classificacaoSubstanciaAnvisaService: ClassificacaoSubstanciaAnvisaService) {}

  public lsElementos: any[] = [];
  public elementosSelecionados: any[] = [];
  public filterClassificacao: ClassificacaoSubstanciaAnvisaFilter = new ClassificacaoSubstanciaAnvisaFilter();

  ngOnInit() {
    this.carregaElementos();
    this.limparEvent.subscribe({
      next: (resp) => {
        this.limpar();
      },
    });
  }

  public carregaElementos() {
    this.filterClassificacao.paginar = false;
    this.classificacaoSubstanciaAnvisaService.findClassificacaoSubstanciaAnvisaByFilter(this.filterClassificacao)
      .subscribe({
        next: response =>{
          this.lsElementos = response.list;

          if(this.filtroAtivo)
            this.lsElementos = this.lsElementos.filter(filtro => filtro.status)
        }
      })
  }

  filtraPesquisa(event: any) {
    if (event.target.value && event.target.value.length >= 3) {
      this.carregaElementos();
    }
  }

  public limpar() {
    this.elementosSelecionados = [];
    this.idsSelecionadas.emit(null);
    this.elementosCompletoSelecionadas.emit(null);
  }

  selecionaElementos(event?: any) {
    let listIds: string[] = [];
    this.elementosSelecionados.forEach((elemento) => listIds.push(elemento.id));
    this.idsSelecionadas.emit(listIds);
    this.elementosCompletoSelecionadas.emit(this.lsElementos);
  }
}
