import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

import {FormControl} from '@angular/forms';
import { DashbordService } from './../../dashbord.service'
import { InputFilter } from './input-filter.model';

@Component({
  selector: 'app-input-filter',
  templateUrl: './input-filter.componente.html',
  styleUrls: ['/input-filter.component.scss']
})
export class InputFilterComponent implements OnInit {

  @Input() public inputFilter: InputFilter;
  dataFormat = "YYYY-DD-MM";

  constructor(private dashbordService: DashbordService) {}

  ngOnInit() {

  }

  public encontrarElemento(lista, elemento){
    let index = -1;
    for(let i = 0; i < lista.length; i++){
      if(lista[i].identificadorBanco === elemento.identificadorBanco){
        index = i;
        break;
      }
    }
    return index;
  }

  changeInputSelectMultiple(event, input:InputFilter){
    if(this.dashbordService.filtrosAlterados && this.dashbordService.filtrosAlterados.length > 0){
      let indexObjetoVez = this.encontrarElemento(this.dashbordService.filtrosAlterados, input);
      if(indexObjetoVez != -1){
        this.dashbordService.filtrosAlterados[indexObjetoVez].value = event.value;
      } else {
        this.dashbordService.filtrosAlterados.push({ ...input, value: event.value });
      }
    } else {
        this.dashbordService.filtrosAlterados = [{ ...input, value:  event.value  }];
    }
  }

  changeInputSimples(event, input:InputFilter){
    if(this.dashbordService.filtrosAlterados && this.dashbordService.filtrosAlterados.length > 0){
      let indexObjetoVez = this.encontrarElemento(this.dashbordService.filtrosAlterados, input);
      if(indexObjetoVez != -1){
        this.dashbordService.filtrosAlterados[indexObjetoVez].value = [event.value];
      } else {
        this.dashbordService.filtrosAlterados.push({ ...input, value: [event.value] });
      }
    } else {
      this.dashbordService.filtrosAlterados = [{ ...input, value: [ event.value ] }];
    }
  }

  changeInputMoney(value, input:InputFilter){
    if(this.dashbordService.filtrosAlterados && this.dashbordService.filtrosAlterados.length > 0){
      let indexObjetoVez = this.encontrarElemento(this.dashbordService.filtrosAlterados, input);
      if(indexObjetoVez != -1){
        this.dashbordService.filtrosAlterados[indexObjetoVez].value = [value];
      } else {
        this.dashbordService.filtrosAlterados.push({ ...input, value: [value] });
      }
    } else {
      this.dashbordService.filtrosAlterados = [{ ...input, value: [value] }];
    }
  }

  changeInputDateSimples(event, input:InputFilter){
    if(this.dashbordService.filtrosAlterados && this.dashbordService.filtrosAlterados.length > 0){
      let indexObjetoVez = this.encontrarElemento(this.dashbordService.filtrosAlterados, input);
      if(indexObjetoVez != -1){
        const valorDate = moment(event.target.value).format(this.dataFormat);
        this.dashbordService.filtrosAlterados[indexObjetoVez].value = [ valorDate ];
      } else {
        const valorDate = moment(event.target.value).format(this.dataFormat);
        this.dashbordService.filtrosAlterados.push({ ...input, value: [valorDate] });
      }
    } else {
      const valorDate = moment(event.target.value).format(this.dataFormat);
      this.dashbordService.filtrosAlterados = [{ ...input, value: [ valorDate ] }];
    }
  }
}
